section.projects {

  .project-header {

    .collaborators {
      margin-top: 20px;
      padding-top:15px;
      padding-bottom: 15px;
      background: #FAFBFB;
      margin-bottom: 15px;
    }

    .number {
      color: $theme_black;
      font-weight: bold;
      font-size: 24px;
    }

    .desc {
      font-size: 14px;
    }

    .project-button {
      box-sizing: border-box;
      height: 40px;
      width: 100%;
      border-radius: 4px;
      color: white;
      text-align: center;
      font-size: 20px;
      line-height: 20px;

      &.follow-button {
        background-color: #66707E;

        &:hover {
          background: lighten(#66707E, 10%);
        }

        &:active {
          background: darken(#66707E, 10%);
        }
      }

      &.join-button {
        margin-top: 10px;
        background-color: #5EBDEB;

        &:hover {
          background: lighten(#5EBDEB, 10%);
        }

        &:active {
          background: darken(#5EBDEB, 10%);
        }
      }
    }
  }

  .project-content {
    margin: 0px -20px;
    padding: 60px 20px 40px 20px;
    background: #FAFBFB;

    .nav-tabs {
      margin-bottom: 0px;
      border-color: #E3E4E8;
      border-style: solid;
      border-width: 0px 0px 2px 0px;

      li {
        border-color: #E3E4E8;
        border-style: solid;
        padding: 20px 15px;
        text-align: center;
        background: #FAFBFB;

        &.active {
          border-width: 2px 2px 0px 2px;
          margin-bottom: -2px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          background: white;
          border-bottom: none;
        }

        a, a:hover, a:visited {
          color: #34383B;
          border: none;
        }

      }
    }

    .tab-content {
      background: white;
      border-color: #E3E4E8;
      border-style: solid;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-width: 0px 2px 2px 2px;
      padding: 20px 15px 20px 25px;
    }
  }

}
