section#w-hotels {

  .header-image {
    margin-top: -60px;
  }

  .carousel {
    margin: 0 auto;
    max-width: 1600px;
  }

  .span4 {
    position: relative;
  }

  .span4.listing,
  .span8.listing {

    @media (max-width: 925px) {
      clear: both;
      margin:0;
      width: 100%;

      h1 {
        margin-top: 20px;
      }

      .booking-module-container .booking-module {
        margin:0;
        border-radius: $border-radius-default;
      }
    }
  }

  .booking-module-container  {
    position: relative !important;
    width: 100% !important;
    margin-left: 0;
  }
}
