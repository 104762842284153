.btn,
a.btn {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 10px;
  border: none !important;
  color: $pure-white;
  text-decoration: none;
  letter-spacing: 1px;
  transition: background .1s ease-in;

  &.full-width {
    box-sizing: border-box;
    width: 100%;
  }

  &.margin-bottom {
    margin-bottom: 1em;
  }

  .icon {
    vertical-align: middle;
  }

  &:visited, &:hover {
    color: #fff;
  }

  &.btn-small {
    height: 30px;
    border-radius: $border-radius-default;
    line-height: 30px;
    font-size: 14px;
  }

  &.btn-medium {
    height: 38px;
    border-radius: $border-radius-default;
    line-height: 38px;
    font-size: 18px;
  }

  &.btn-large {
    padding: 0 20px;
    height: 50px;
    border-radius: $border-radius-default;
    font-size: 22px;
    line-height: 50px;
  }

  &.btn-full {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    line-height: 38px;
    font-size: 18px;
  }

  &.btn-gray {
    background: $light-gray;
    color: black;

    &:hover {
      background: $pure-white;
    }

    &:active {
      background: $pure-white;
    }
  }

  &.btn-white {
    border: 2px solid $light_gray !important;
    background: $dnm_white;
    color: black;

    &:hover {
      background: $pure-white;
    }

    &:active {
      background: $pure-white;
    }
  }

  &.btn-gray-dark {
    background: $gray60;
    color: $gray120;
    border: 1px solid $gray120 !important;

    span {
      color: $gray120;
    }

    &:hover {
      background: lighten($gray60, 10%);
    }

    &:active {
      background: darken($gray60, 10%);
    }
  }

  &.btn-gray-darker {
    background: $gray210;
    color: $gray80;
    border: 1px solid $light-gray !important;

    span {
      color: $gray80;
    }

    &:hover {
      background: lighten($gray210, 10%);
    }

    &:active {
      background: darken($gray210, 10%);
    }
  }

  &.btn-gray-light {
    background: $gray120;
    color: $pure-white;
    border: 1px solid $gray120 !important;

    span {
      color: $pure-white;
    }

    &:hover {
      background: lighten($gray120, 10%);
    }

    &:active {
      background: darken($gray120, 10%);
    }
  }

  &.twitter,
  &.facebook,
  &.linkedin,
  &.instagram,
  &.google {
    &.connected {
      background: $red;

      &:hover {
        background: darken($red, 10%);
      }
    }
  }

  &.twitter {
    background: $twitter-blue;

    &:hover {
      background: darken($twitter-blue, 15%);
    }
  }

  &.google {
    background: $google-red;

    &:hover {
      background: darken($google-red, 15%);
    }
  }

  &.facebook {
    background: $facebook-blue;

    &:hover {
      background: darken($facebook-blue, 15%);
    }
  }

  &.github {
    background: $github-dark;

    &:hover {
      background: darken($github-dark, 15%);
    }
  }

  &.linkedin {
    background: $linkedin-blue;

    &:hover {
      background: darken($linkedin-blue, 15%);
    }
  }

  &.instagram {
    background: $instagram-brown;

    &:hover {
      background: darken($instagram-brown, 15%);
    }
  }
}

.btn-group {
  display: inline-block;
  overflow: hidden;

  .btn {
    float: left;
  }

  .btn:first-child:not(:last-child) {
    border-right-radius: 0;
    border-left: none;
  }

  .btn:not(:first-child):not(:last-child) {
    border-left-radius: 0;
    border-right-radius: 0;
  }

  .btn:last-child:not(:first-child) {
    border-left-radius: 0;
    border-right: none;
  }
}
