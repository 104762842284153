.form-horizontal {

  .control-group {
    
    .free-price {
      float: left;
    }

    .price-input-options {
      overflow: hidden;
      padding-left: 30px;
    }

    .price-inputs-wrapper{
      padding-right: 20px;

      label {
        float: left;
        top: 16px;
      }
    }

    .currency-holder {
      position: relative;
      z-index: 2; 
      margin-left: 11px;
      width: 30px;
      color: $gray80;
      text-align: right;       
    }

    .price-input {
      position: relative;
      z-index: 1;
      margin-left: -25px;
      padding-left: 30px;
      width: 120px;
    }
  }
}
