html {
    font-size: 18px;
    line-height: (28/18);
}

body,
input,
textarea,
select,
p,
ul,
ol,
em {
  font-size: 18px;
  line-height: 28px;
  color: $gray120;

  @media (max-width: $iphone-landscape) {
    font-size: 16px;
  }
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a {
  color: $gray80;
  font-weight: 600;

  &:hover {
    color: $gray80;
  }
}

h1 {
  margin-bottom: 20px;
  font-size: 1.77778em;
  line-height: 1.3125em;

  @media (max-width: $iphone-landscape) {
    font-size: 1.33333em;
    line-height: 1.16667em;
  }
}

h2 {
  font-size: 1.33333em;
  line-height: 1.16667em;

  @media (max-width: $iphone-landscape) {
    font-size: 1.11111em;
    line-height: 1.4em;
  }
}

h3 {
  font-size: 1.11111em;
  line-height: 1.4em;
}

h4 {
  font-size: 1em;
  line-height: 1.55556em;
}

p {
  margin-bottom: 20px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

address {
  font-size: 0.77778em;
  line-height: 2em;

  .ico-marker {
    opacity: .7;
  }
}

// pnotice added for IE8 which does not display "reset password" link in modal when p element is used, it works for div though

p.notice,
div.pnotice {
  margin: .5em 0;
  color: #505050;
  font-weight: 600;
  font-size: 12px;
  filter: inherit;
  opacity: .7;
  transition: all .1s ease-in;

  label {
    display: block;
    line-height: 1.6;
  }

  &:hover {
    opacity: 1;
    filter: inherit;
  }
}
