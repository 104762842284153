.booking-module-container {
  position: static;
  width: auto;

  @media (min-width: $booking-module-breakpoint + 1) {
    margin-left: 15px;
  }

  .booking-module {
    .form-horizontal .control-group .controls {
      margin-left: 0px;
    }
  }


  .book {
    width: 100%;

    .btn.disabled, input:disabled{
      opacity: 0.5;
    }

    .btn.click-disabled{
      opacity: 0.75;
    }

    .btn {
      height: 50px !important;
      line-height: 50px !important;
      font-size: 30px !important;
    }

    .suggest-date {
      width: 100%;
      text-align: center;
    }
  }

  .booking-price-info {
    font-size: 0.7em;
    display: none;
  }

  .booking-body {
    display: block;

    .booking-message {
      padding: 20px;

      p {
        color: #000;
        font-size: 13pt;
        text-align: center;
        margin: 0;
      }
    }
  }

  .ico-chevron-right {
    display: none;
  }

  &.collapsed {

    .booking-body {
      display: none;
    }

    .booking-price-info {
      display: block;
    }

    .ico-chevron-down {
      display: none;
    }

    .ico-chevron-right {
      display: block;
    }

    span {
      font-size: 0.7em;

      &.booking-listing-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .booking-module {
    box-sizing: border-box;
    border-radius: $border-radius-default;
    position: relative;
    padding: ($padding-default + 7);
    background: $gray60;
    color: $gray210;
    font-size: 32px;
    line-height: 36px;

    @media (max-width: $booking-module-breakpoint-size) {
      padding: ($padding-default - 3);
      font-size: 26px;
      line-height: 30px;
    }

    @media (max-width: $iphone-portrait - 1) {
        font-size: 22px;
        line-height: 28px;
    }

    .booking-header {

      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $gray80;

      a {
        color: $gray210;
        display: block;
      }

      span {
        font-size: 0.7em;

        &.booking-listing-name {
            width: 90%;
            display: block;
            font-size: 1em;
            line-height: 1.4em;
        }
      }
    }

    form {
      textarea {
        height: inherit;
        resize: none;
      }
    }

    .booking-price {
      overflow: hidden;
      padding-bottom: 15px;
      border-bottom: 1px solid $gray80;
    }

    .rfq-explanation {
      overflow: hidden;
      padding-top: 15px;
      border-top: 1px solid $gray80;
      padding-bottom: 10px;

      span {
        font-size: 0.65em;
      }
    }

    .booking-description, .booking-opening-hours {
      margin: 15px 0px 0px;
      font-size: 0.50em;

      h3,
      span {
        color: $gray160 !important;
      }

      h3 {
        font-size: 1.1em;
        margin-bottom: 10px;
      }

      @media (max-width: $booking-module-breakpoint-size) {
        font-size: 0.6em;
      }

    }

    .availability-week-container {
      .enabled {
        color: #d2d2d2 !important;
      }
    }

    .availability-detailed-container {
      .day-of-week {
        float: left;
        width: 45px;
      }

      .times-in-day {
        float: left;

        span {
          display: block;
        }
      }
    }

    .seller-attachments {
      font-size: 0.50em;

      h3 {
        color: $gray160 !important;
      }

      a {
        color: $gray160 !important;
        &:hover {
          text-decoration: underline !important;
        }
      }

      h3 {
        font-size: 1.1em;
      }

      @media (max-width: $booking-module-breakpoint-size) {
        font-size: 0.6em;
      }
    }

    .label {
      display: block;
      float: left;
      width: 30%;
      background: transparent;
      color: $gray210;
      font-size: 30px;
      text-align: center;
      line-height: normal;

      @media (max-width: $booking-module-breakpoint-size) {
        font-size: 100%;
      }

      &.unavailable {
        opacity: .1;
      }

      .period {
        display: block;
        clear: both;
        margin-top: 4px;
        color: $gray160;
        font-weight: bold;
        font-size: 40%;
        text-transform: uppercase;
        letter-spacing: 1px;

        @media (max-width: $iphone-portrait - 1) {
          font-size: 45%;
        }
      }

      &.label-hourly-price {
        // Only one price field, center it.
        margin-left: 33%;
      }

      &.label-free-price {
        // Center 'Free' text
        width: 100%;
        text-align:center;
      }

      @media (max-width: $iphone-portrait - 1) {
        padding: 0;
        margin: 0;
      }
    }

    .book {
      width: 100%;

      .btn.disabled{
        opacity: 0.5;
      }

      .btn.click-disabled{
        opacity: 0.75;
      }
    }

    .calendar-wrapper {
      border-radius: $border-radius-default;
      float: left;
      box-sizing: border-box;
      margin: 15px 0px;
      width: 48%;
      background: $gray230;
      color: $gray80;
      height: 50px;
      padding-left: 10px;
      white-space: nowrap;
      cursor: pointer;

      &.date-end,
      &.time-picker {
        float: right;
        margin-right:0;
      }

      &.date-end {
        padding-left: 18px;
      }

      .time {
        font-size: 90%;
        margin-top: 8px;
      }

      @media (max-width: $ipad-width) {
        padding-left: 10px !important;

        &.time-picker {
          font-size: 85%;
        }
      }

      @media (max-width: $booking-module-breakpoint) {
        &.time-picker {
          font-size: 100%;
        }
      }

      @media (max-width: $iphone-portrait - 1) {
        padding-left: 0px !important;
      }

      @media (min-width: $booking-module-breakpoint) and (max-width: $booking-module-breakpoint-calendar-icons) {
        padding-left: 0px !important;
      }


      .calendar {
        position: relative;
        display: inline-block;
        margin-top: 8px;

        .dates,
        input {
          display: inline-block;
          width: 30px;
          height: 38px;
          border: 0;
          border: none;
          background: transparent;
          color: transparent;
          color: $gray120;
          vertical-align: middle;

          &:focus {
            outline: none !important;
          }
        }

        @media (max-width: $iphone-portrait - 1) {
          display: block;

          .ico-calender-end, .ico-calender-start {
            display: none;
          }

          .calendar-text {
            width: 100%;
            display: block;
            text-align: center;
          }
        }

        @media (min-width: $booking-module-breakpoint) and (max-width: $booking-module-breakpoint-calendar-icons) {
          display: block;

          .ico-calender-end, .ico-calender-start {
            display: none;
          }

          .calendar-text {
            width: 100%;
            display: block;
            text-align: center;
          }
        }
      }
    }

    .recurring-booking {
      margin-bottom: 30px;
      font-size: 17px;

      .or {
        font-size: 15px;
      }

      .recurring_select {
        position: absolute;
        top: -99999px;
        left: -999999px;
      }
    }

    .number-of-units {

      input {
        clear: none;
        max-width: 88px;
        float: right;
        text-align: center;
      }

      span {
        float: left;
        margin: 15px 0px;
      }
    }

    .quantity {
      position: relative;
      display: block;
      clear: both;
      margin: 15px 0px;

      .resource {
        text-transform: capitalize;
      }

      select {
        display: inline-block;
        right: 0;
        width: 70px;
      }

      span.customSelect {
        float: right;
        border-radius: $border-radius-default;
        min-width: 32px;
        margin: 0px;
        border: none;
        background: transparent;

        i {
          top: 0;
        }
      }
    }

    .price, .discount_price {
      clear: both;
      margin: 15px 0px;
      background-clip: border-box;
      text-align: left;

      span {
        float: right;
      }
    }

    .book-it-out, .exclusive-price {
      width: 100%;

      input {
        width: 100%;
        height: 19px;
      }

      .discount_price {
        margin-top: 0px;
        margin-bottom: 50px;
      }
    }

    .book-it-out {
      display: none;
    }

    .recurring_select {
      width: 100%;
    }

    .pricing-tabs {
      li {
        &.active span {
          color: $gray80;
        }

        a {
          padding-right: 6px;
          padding-left: 6px;
          display: inline-block;
        }
      }
    }

  }

  .booking-submit-row > * {
    margin-left: 0;
  }

  .booking-submit-row {
    .offer-button {
      margin-top: 8px;
    }
  }

  div[data-exclusive-price-content=true] {
    display: none;
  }

  .order_dates .select2-container {
    background: #e6e6e6;
    transition: border 0.2s ease-in, opacity 0.2s ease-in, color 0.2s ease-in;
    margin-bottom: 8px;
    padding: 8px 8px 8px 8px;
    border-radius: 5px;
    height: 49px;

    .select2-offscreen {
      clip: none;
    }

    .select2-choice {
      background-color: #e6e6e6;
      background-image: none;
      border: none;
      line-height: 34px;
      height: auto;
      box-shadow: none;

      .select2-arrow {
        @extend .ico-chevron-down;
        width: 40px;
        background-image: none;
        background-color: #e6e6e6;
        border: none;
        color: rgb(153, 153, 153);
      }
    }

  }

}
