.alert {
  cursor: pointer;
  position: absolute;
  z-index: $flash-z-index;
  border: none;
  border-radius: 0px;
  top: 60px;
  text-align: center;
  color: white;
  text-shadow: none;
  padding: 0px;
  width: 100%;

  &.static {
    position: static;
  }

  p {
    margin: 0px;
    padding: 0px 49px 0px 0px;
    color: white;
    font-size: 24px;
    line-height: 60px;

    span.left-icon {
      margin-right: 15px;
    }

    span.right-icon {
      margin-left: 5px;
    }
  }

  a.close {
    position: absolute;
    top:  0;
    right: 20px;
    color: white;
    font-size: 24px;
    line-height: 60px;
    opacity: .6;

    &:hover {
      color: white;
      opacity: .8;
    }
  }

}

.modal-content{

  .alert{
    position: relative;
    top: auto;
    left: auto;
  }
}
