// Custom datepicker
@keyframes opacity-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dnm-datepicker {
  position: absolute;
  top: 300px;
  left: 300px;
  z-index: $absolute-top-z-index;
  width: 280px;
  background: $black;
  border-radius: $border-radius-default;
  box-shadow: 0 0 40px black;
  background-clip: border-box;
  overflow: hidden;

  @media only screen and (max-width: $iphone-landscape) {
    position: fixed;
    top: 20px !important;
    left: 20px !important;
    right: 20px !important;
  }

  .datepicker-prev,
  .datepicker-next {
    float: left;
    padding: 10px;
    width: 16px;
    height: 20px;
    color: $gray220;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    opacity: .4;

    &:hover {
      opacity: 1;
    }
  }

  .datepicker-next {
    float: right;
  }

  .datepicker-header {
    margin: 0 40px;
    padding-bottom: $padding-small / 2;
    color: $gray240;
    text-align: center;

    .datepicker-month {
      font-size: 20px;
      line-height: 30px;
    }

    .datepicker-year {
      font-size: 10px;
      line-height: 10px;
    }
  }

  .datepicker-week-header,
  .datepicker-days {
    overflow: hidden;
    background: $gray240;

    .datepicker-week-heading,
    .datepicker-day {
      float: left;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
    }
  }

  .datepicker-days {
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    background-clip: border-box;
  }

  .datepicker-text {
    border-bottom: 1px solid $gray210;
    background-color: $black;
    color: $black;
    text-align: center;
    line-height: 32px;
  }

  .datepicker-text-fadein {
    background-color: $gray240;
    padding: 0 5px;
    -webkit-animation-name: opacity-fadein;
    -moz-animation-name: opacity-fadein;
    animation-name: opacity-fadein;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .datepicker-days-wrapper, .time-wrapper {
    position: relative;
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    background-clip: border-box;
    background: $gray240;

    .datepicker-loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $pure-white;
      opacity: 0.9;
    }
  }

  .datepicker-days {
    .datepicker-day {
      color: $gray80;
      font-weight: bold;
      cursor: pointer;

      &.datepicker-day-dow-0 {
        clear: left;
      }

      &:hover {
        background: $gray230;
      }

      &.datepicker-day-other-month {
        color: $gray;
      }

      &.active, &.datepicker-day-other-month.active {
        background: $green;
        color: $pure-white;
      }

      &.active.implicit {
        opacity: 0.75;
      }

      // Past dates

      &.closed,
      &.not-available,
      &.datepicker-day-other-month.not-available,
      &.before-start-date,
      &.datepicker-day-other-month.before-start-date,
      &.disabled,
      &.disabled.closed,
      &.datepicker-day-past,
      &.datepicker-day-past.disabled {
        color: $gray160;
      }

      &.datepicker-booking-end-only {
        background: linear-gradient(135deg, rgba($gray204, 0) 0%, rgba($gray204, 0) 50%, $gray204 50%, $gray204 100%);

        &.active {
          background: linear-gradient(225deg, rgba(204, 204, 204, 0) 0%, rgba(204, 204, 204, 0) 50%, #157a49 50%, #157a49 100%);
          text-shadow: 1px 1px #157a49;
        }

        &.disabled,
        &.not-available,
        &.before-start-date {
          background: none;
        }
      }
    }
  }
}

// Special style for the timepicker

.time-wrapper {
  height: 160px;
  padding-top: 70px;

  .time-start,
  .ico-arrow-right,
  .time-end {
    float: left;
    margin-left: 15px;
    line-height: 40px;
  }

  .time-start,
  .time-end {
    background: $gray230;
    color: $gray60;
    border-radius: $border-radius-default;
    border: 1px solid #767676;
    height: 40px;
    width: 100px;
    position: relative;

    span {
      display: block;
      padding: 0 6px;

      label {}

      i {
        float: right;
        font-size: 12px;
        color: #969696;
      }
    }

    select {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid transparent;
      height: 40px;
      margin: 0;
      opacity: 0;

      option[disabled] {
        color: $gray210;
      }
    }
  }
}
