// Tags component for handling tag list input on MPO Admin, Dashboard

$tags-border-radius: 4px;
$tags-default-color: #1a7fbb;
$tags-text-shadow: 1px -1px rgba(0, 0, 0, 0.4);
$tags-border: 1px solid #ccc;

.token-input-list {
    overflow: hidden;
    height: auto !important;
    list-style-type: none;
    clear: left;
    height: 1%;
    cursor: text;
    z-index: 999;
    margin: 0;
    padding: 3px 12px;
    border: $tags-border;
    line-height: 1;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    li {
        list-style-type: none;

        input {
            border: 0;
            width: 100%;
            padding: 3px 8px;
            background-color: transparent;
            -webkit-appearance: caret;
            border: 0 none !important;
            box-shadow: 0 0 0 transparent !important;
        }
    }

    li.token-input-token {
        overflow: hidden;
        height: auto !important;
        height: 1%;
        margin: 3px;
        padding: 0 5px 0 0;
        border-radius: $tags-border-radius;
        background-color: $tags-default-color;
        color: #fff;
        cursor: default;
        display: inline-block;
        text-shadow: $tags-text-shadow;
        text-transform: lowercase;

        p {
            float: left;
            padding: 5px 0 !important;
            line-height: 1 !important;
            margin: 0 0 0 10px;
            color: #fff;
        }

        span {
            float: right;
            color: #fff;
            cursor: pointer;
            padding-left: 5px;
        }
    }

    li.token-input-selected-token {
        background-color: lighten($tags-default-color, 30%);
        color: #fff;
    }
}

.token-input-dropdown {
    position: absolute;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.6);
    cursor: default;
    font-size: 13px;
    z-index: 1;

    p {
        margin: 0;
        padding: 5px;
        color: #777;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            background-color: #fff;
            padding: 3px 8px;
            list-style-type: none;

            &.token-input-dropdown-item {
                background-color: #fafafa;
            }

            &.token-input-dropdown-item2 {
                background-color: #fff;
            }

            &.token-input-selected-dropdown-item {
                background-color: lighten($tags-default-color, 30%);
                color: #fff;
            }

            em {
                font-weight: bold;
                font-style: normal;
            }
        }
    }
}

// Overrides to match instance admin's styles

#main-container {
    .token-input-list {
        border-radius: $tags-border-radius;
        line-height: 1.4;
    }
}

// Tags filters

.tags-filters {
    padding-top: 12px;
    list-style: none;
    width: 80%;
    float: left;

    li.tag-filter-link {
        display: inline-block;
        position: relative;

        a {
            color: #bbb;
            text-align: center;
        }

        &:after {
            content: "|";
            margin: 0 10px;
            font-weight: 300;
            color: #ddd;
        }

        &:last-child:after {
            display: none;
        }

        .selected {
            color: $tags-default-color;
            position: relative;

            &:after {
                height: 16px;
                width: 16px;
                content: "\00d7";
                background: $tags-default-color;
                text-shadow: none;
                display: inline-block;
                line-height: 16px;
                text-align: center;
                vertical-align: middle;
                border-radius: 100%;
                color: #fff;
                margin-left: 5px;
            }
        }
    }
}
