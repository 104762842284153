// Checkbox & Radio

.checkbox,
.radio,{
  margin-bottom: 12px;
  padding-left: 28px;
  position: relative;

  &:hover {
    color: $checkbox-radio-color;

    a {
      color: $checkbox-radio-color;
    }
  }

  input {
    outline: none !important;
    opacity: 0;
  }

  input[type=radio],
  input[type=checkbox] {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

.checkbox {

  .checkbox-icon-outer {
    border-radius: $border-radius-default;
    height: 22px;
    width: 22px;
    left: 0;
    position: absolute;
    top: 0px;
    background: $gray220;
    transition: all $checkbox-radio-transition ease-in-out;

    .checkbox-icon-inner {
      font-size: 18px;
      position: absolute;
      top: -4px;
      left: 2px;
      opacity: 0;
      transition: all $checkbox-radio-transition ease-in-out;
    }

  }

  &.small-checkbox {

    .checkbox-icon-outer {
      height: 16px;
      width: 16px;
      top: 5px;

      .checkbox-icon-inner {
        font-size: 12px;
        top: -6px;
      }
    }
  }


  &.checked {

    .checkbox-icon-outer {
      color: $white;
      background: $blue;
      transition: all $checkbox-radio-transition ease-in-out;

      .checkbox-icon-inner {
        opacity: 1;
        transition: all $checkbox-radio-transition ease-in-out;
      }
    }
  }
}

// Radio

.radio {

  .radio-icon-outer {
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $gray220;
    transition: all $checkbox-radio-transition ease-in-out;

    .radio-icon-inner {
      width: 4px;
      height: 4px;
      position: absolute;
      left: 50%;
      margin-left: -2px;
      display: block;
      border-radius: 50%;
      top: 50%;
      margin-top: -2px;
      transition: all $checkbox-radio-transition ease-in-out;
    }
  }

  &.checked {

    .radio-icon-outer {
      background: $blue;
      transition: all $checkbox-radio-transition ease-in-out;

      .radio-icon-inner {
        background: $white;
        transition: all $checkbox-radio-transition ease-in-out;
      }
    }
  }
}

.error {

  .radio {

    .radio-icon-outer {
      background: $red;
    }
  }

}

.switch .has-switch span.switch-primary,
.switch .has-switch span.switch-left {
  // overwrite default styles
}


.control-group {

  .controls {

    span.customSelect {

      @include form-field;
      padding-right: 40px;
      font-size: 18px;

      &.disabled{
        opacity: 0.5;
      }

    }

    select[disabled] ~ span.customSelect {
      opacity: 0.5;
    }

    .customSelectInner {
      width: 100% !important;
    }

  }

  &.error {

    .controls {

      span.customSelect{
        border-color: #e58984;
      }
    }


    .open-time,
    .close-time {

      span.customSelect{
        border-color: #e58984;
      }
    }
  }
}

.customSelect {
    position: relative;
}

.cart-item-list .customSelect i {
    display: none;
}

.custom-select-dropdown-icon {
  position: absolute;
  z-index: 0;
  right: 8px;
  top: 10px;
  font-size: 26px;
  color: $gray160;
}


