.container.fluid,
.locations .container {
	box-sizing: border-box;
	position: relative;
	max-width: 960px;
	width:100%;

	.one-third.column {
		box-sizing: border-box;
		margin:0;
		width: 33.3333%;

		&.padding {
			padding: 0 20px;
		}

		&.right {
			float: right;
		}

		@media (max-width: $ipad-width) {
			width: 100%;
			clear: both;
		}
	}

	.two-thirds.column {
		box-sizing: border-box;
		margin:0;
		width: 66.6666%;

		&.right {
			float: right;
		}

		@media (max-width: $ipad-width) {
			width: 100%;
			clear: both;
		}
	}
}
