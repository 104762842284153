.registrations-show {

  .avatar {
    img {
      border-radius: $border-radius-default;
    }
  }

  .info {
    border: 1px solid $gray230;
    border-radius: $border-radius-default;
    padding: $padding-default;

    h2 {
      margin-bottom: $padding-default;
    }
    h4 {
      color: $gray160;
      margin-bottom: $padding-default;
    }

    p {
      color: $gray60;
      margin-bottom: $padding-default;
    }
  }

  .box {
    .part {
      &:first-child {
        margin-top: 0;
      }
      margin-top: 10px;
    }
  }
  .social.info {
    a {
      font-size: 27px;
      color: #505050;

      &.ico-twitter {
        font-size: 120%;
      }

      &.ico-facebook-full:hover {
        color: #3b5998;
      }
      &.ico-linkedin {
        position: relative;
        left: -2px;
        &:hover {
          color: #006699;
        }
      }
      &.ico-twitter:hover {
        color: #55acee;
      }
      &.ico-instagram:hover {
        color: #905f4c;
      }
      &.ico-mail:hover {
        color: #dd4b39;
      }
    }
    .connection {
      &:first-child {
        margin-top: 0;
      }
      padding-bottom: 5px;
      .text {
        color: $gray60;
      }
      .count {
        padding-left: 10px;
        color: $gray160;
      }
    }
  }

  .icons {
    li {
      float: right;
      margin-left: 2 * $padding-medium;

      a {
        font-size: 27px;
        color: $theme_gray;

        &.ico-facebook-full:hover {
          color: $facebook-blue;
        }

        &.ico-twitter:hover {
          color: $twitter-blue;
        }

        &.ico-linkedin:hover {
          color: $linkedin-blue;
        }

        &.ico-instagram:hover {
          color: $instagram-brown;
        }
      }
    }
  }

}

.phone-number-country-code-field {
  position: relative;

  .country-calling-code {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    line-height: 29px;
    width: 40px;
    text-align: right;
  }

  input {
    padding-left: 60px !important;
  }
}

.controls.switch-control {
  display: table-row;

  .cell {
    display: table-cell;

    &.with-label {
      vertical-align: bottom;
      padding-left: 30px;

      label {
        font-weight: bold;
      }
    }
  }
}

.admin-profile-notice {
    background: #FEEFB3;
    padding: 20px;
    margin-bottom: 30px;
    text-align: center;
    border: 1px solid darken(#FEEFB3, 30%);

    p {
        font-size: 16px;
        font-weight: normal;
        color: #9F6000;
    }
}
