.favorite {
  .icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    padding-top: 5px;
  }

  .heart {
    background: url('/assets/wish_lists/heart/unselected.png') no-repeat;
    background-size: contain;
  }

  .heart.selected {
    background: url('/assets/wish_lists/heart/selected.png') no-repeat;
    background-size: contain;
  }

  .tick {
    background: url('/assets/wish_lists/tick/unselected.png') no-repeat;
    background-size: contain;
  }

  .tick.selected {
    background: url('/assets/wish_lists/tick/selected.png') no-repeat;
    background-size: contain;
  }

  .thumbs_up {
    background: url('/assets/wish_lists/thumbs_up/unselected.png') no-repeat;
    background-size: contain;
  }

  .thumbs_up.selected {
    background: url('/assets/wish_lists/thumbs_up/selected.png') no-repeat;
    background-size: contain;
  }

  &:hover {
    .heart {
      background: url('/assets/wish_lists/heart/hover.png') no-repeat;
      background-size: contain;
    }

    .thumbs_up {
      background: url('/assets/wish_lists/thumbs_up/hover.png') no-repeat;
      background-size: contain;
    }

    .tick {
      background: url('/assets/wish_lists/tick/hover.png') no-repeat;
      background-size: contain;
    }
  }
}

.products-show {
  .favorite {
    .icon {
      width: 22px;
      height: 22px;
    }
  }
}
