#details {
  height: 554px;

  @media (max-width: $ipad-width) {
    height:160px;
  }

  @media (max-width: $iphone-landscape) {
    height:100px;
  }
}

.location-photos.row-fluid {

  .span4.pull-right {
    width: 34%;
    margin-left: 0;

    @media (max-width: $ipad-width ) {
      width: 100%;
    }
  }

  .map {

    .map-container {
      height: 554px;
      position: relative;
      z-index: 1;

      @media (max-width: $ipad-width) {
        height:160px !important;
      }

      @media (max-width: $iphone-landscape) {
        height:100px !important;
      }
    }

    address {
      position: relative;
      z-index: 100;
      margin-top: -65px;
      padding: 10px 20px;
      box-sizing: border-box;
      width: auto;
      height: 65px;
      background: rgba(0,0,0,0.7);
      color: $gray240;
      transition: background .2s ease-in;

      &:hover {
        background: rgba(0,0,0,.95);
      }

      strong {
        font-weight: 600;
      }

      @media (max-width: 768px) {
        margin-top:-40px;
        max-width: none;
        width: 100%;
        height:40px;
      }

      @media (max-width: $iphone-landscape) {
        margin-top:0;
        padding: 4px 20px;
        height: auto;
        border: none;
        color: $gray240;
        font-size: 14px;
      }
    }
  }

  .carousel {
    margin-bottom: 0;

    .carousel-control {
      top: 50%;
      width: 26px;
      height: auto;
      font-size: 22px;
    }

    .carousel-caption {
      position: absolute;
      right: 20px !important;
      bottom: 20px;
      overflow: hidden;
      padding: 20px;
      width: 2px;
      height: 6px;
      background: rgba(0,0,0,0);
      color: white;
      font-weight: 600;
      white-space:nowrap;
      opacity: .5;
      transition: opacity .2s ease-in, text-shadow .1s ease-in;

      &:hover {
        width: auto;
        color: white;
        opacity: 1;
        text-shadow: black 0 0 2px, black 0 0 4px, black 0 0 6px;

        &:before {
          content:"";
        }
      }
    }
  }
}
