.google-map {

  .popover-opened {

    @media (max-width: $iphone-landscape) {
      z-index: $nav-z-index - 1 !important;
    }
  }

  img {
    max-width: none;
  }

  label {
    width: auto;
    display: inline;
  }

  gmnoprint {
    display: none;
  }

  .gmnoprint {

    @media (max-width: $ipad-width) {
      display: none;
    }
  }

  .google-map-popover {
    background: $gray220;
    border: 1px solid $gray160;
    border-radius: $border-radius-default;
    color: $gray80;

    h4 {
      display: block;
      overflow: hidden;
      background: $theme_black;
      color: $theme_white;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 15px 20px 0px 20px

    }

    h4.location-title:first-child {
      position: relative;
      border-top-left-radius: $border-radius-default; border-top-right-radius: $border-radius-default;
      padding: 15px 42px 0px 20px
    }

    .close {
      position: absolute;
      top: 17px;
      right: 12px;
      font-size: 19px;;
      font-size: 19px;
      color: $theme_white;
    }

    &.with-scrollbar {

      h4.location-title:first-child {
        padding: 15px 44px 0px 20px
      }

      .close {
        right: 22px;
      }

    }

    em.arrow,
    em.arrow-border {
      position: absolute;
      top: 100%;
      left: 50%;
    }

    em.arrow {
      margin-left: -10px; // NB: Not -9px, as the map markers are not exactly symmetrical so it appears off-center
      @include css-arrow($width: 9px, $direction: down, $color: $gray220);
    }

    em.arrow-border {
      margin-left: -11px; // NB: Not -11px, as the map markers are not exactly symmetrical so it appears off-center
      @include css-arrow($width: 10px, $direction: down, $color: $gray120);
    }

    #address-popover {

      a {
        padding-left: 4px;
        color: $gray160;

        &:hover {
          color: $gray80;
        }
      }
    }
  }
}

.google-map-popover-content {
  overflow-y: auto;
  max-height: 292px;
  border-bottom-left-radius: $border-radius-default; border-bottom-right-radius: $border-radius-default;

  h4.location-title,
  .listing-sibling,
  .popover-image-container,
  .popover-image-container a,
  .popover-image-container img {
    height: 65px;
      box-sizing: border-box;
  }

  h4.location-title {
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    .location-name {
      font-size: 20px;;
      font-size: 20px;
    }

    .location-address {
      color: $gray100;
    }
  }

  .listing-sibling {
    position: relative;
    border-bottom: 1px solid $gray160;
    color: $gray100;

    &:last-child {
      border: none;
      border-bottom-left-radius: $border-radius-default; border-bottom-right-radius: $border-radius-default;
    }

    .popover-image-container {
      float: left;
      overflow: hidden;
      width: 65px;

      a {
        display: block;
        float: left;
      }

      img {
        max-width: 100%;
      }
    }

    .popover-info-container {
      box-sizing: border-box;
      float: left;
      width: 100%;
      margin-left: -65px;
      padding: 8px 10px 0px 75px;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: transparent;
      overflow: hidden;
      color: $gray100;

      h4 {
        display: block;
        padding: 0;
        background: transparent;
        white-space: nowrap;
        text-overflow: ellipsis;
      color: $gray100;
        font-size: 20px;;
        font-size: 20px;
      }

      span.label {
        float: right;
        margin: 5px 0 0 1px;
        padding: 2px 3px;
        font-size: 80%;
        letter-spacing: -1px;
      }
    }

    &:hover {
      background: $gray240;
    }
  }


  .popover-loading {
    text-align: center;
    padding-top: 20px;
    width: auto;
    span.label {
      float: right;
      margin: 5px 0 0 1px;
      padding: 2px 3px;
      font-size: 80%;
      letter-spacing: -1px;
    }
  }

  .popover-error {
    text-align: center;
    padding: 20px;

    span {
      font-size: 18px;
    }


  }

}
