.box {
  position: relative;
  margin-bottom: 20px;
  padding: $padding-default;
  box-sizing: border-box;
  border-radius: $border-radius-default;
  background-clip: border-box;
  background: $theme_white;
  line-height: 28px;

  header {
    display: block;
    margin-top: -$padding-default;
    margin-left: -$padding-default;
    margin-right: -$padding-default;
    margin-bottom: $padding-default;
    padding: $padding-default;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    background: $gray210;
    color: $gray80;

    h2 {
      color: $gray80;
    }
  }

  table.summary {
    tbody tr td, tfoot tr td {
      vertical-align: middle;
      padding: 5px 10px;
    }

    tfoot td, tbody td.total, thead th.total {
      text-align: right;
    }
    tfoot tr, tbody tr {
      border-bottom: 1px solid $line-gray;
    }
  }

  .text-label {
    font-weight: bolder;
    width: 200px;
    display: inline-block;
    float: left;
  }

  .summary-line {
    padding: $padding-default 0;
    width: 100%;
    border-top: 1px solid $line-gray;

    .summary-line-label {
      float: left;

    }
    .summary-line-value {
      float: right;
      width: 100px;
    }

  }

  // List Your Space Flow

  ul.space-wizard {
    display: block;
    overflow: hidden;
    margin-top: -$padding-default;
    margin-right: -$padding-default;
    margin-bottom: $padding-default;
    margin-left: -$padding-default;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    background: $gray230;

    li {
      float: left;
      padding: 0 $padding-default;
      box-sizing: border-box;
      width: 50%;
      border-right: 1px solid $gray210;
      color: $gray160;
      font-size: 22px;
      text-align: center;
      line-height: 60px;

      &:last-child {
        border: none;
      }

        @media (max-width: $iphone-landscape) {
          clear: both;
          width: 100%;
          border-right: none;
          border-bottom: 1px solid $gray240;
         }

      &.current {
        border: none;
        background: $gray60;
        color: $gray230;
      }
    }
  }

  hr,
  .hr {
    margin-right: -$padding-default;
    margin-left: -$padding-default;
    border-top: none;
    border-bottom: 2px solid $gray230;
    background: $line-gray;
    color: $gray210;
    text-align: center;

    > span {
      position: relative;
      top: -10px;
      display: inline-block;
      padding-left: $padding-small;
      padding-right:$padding-small;
      background: $white;
      color: $gray160;
      font-size: 18px;
    }

    &.thin {
      margin-right: 0;
      margin-left: 0;
      border-bottom: 0;
    }
  }

  .line-divider {
    margin: 25px 0px;
    border-bottom: 1px solid $gray220;
  }

  .inner-box {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid $gray210;
    border-radius: $border-radius-default;

    &.inner-box-filled {
      background: $gray240;
      border: none;
    }
  }

  &.gray {
    border: 1px solid $gray230;
    color: #b8b8b8;
    margin-top: 20px;
  }
}

.box.reservation-review {

  .reservation-properties {
    margin-top: 15px;
  }

}

.box-inverse {
  border: none;

  header {
    background: $gray80;
    color: $theme_white;
  }
}

.box-dim {
  border: none;

  header {
    background: $gray230;
  }
}


.box-nav {
  overflow: hidden;
  margin: -14px -14px 14px -14px;
  background: $gray220;

  li {
    float: left;

    a {
      display: block;
      padding: 0 10px;
      color: $gray230;
      line-height: 40px;

      &:hover {
        color: $theme_white;
      }
    }

    &.active {

      a,
      a:hover {
        background: $theme_black;
        color: $theme_white;
      }

      &:first-child,
      &:first-child a {
        border-left: none;
      }
    }

    &.right-link {
      float: right;

      a {
        padding-left: 22px;
        border-right: none;
        border-left: none;
        background: url("/assets/components/nav/box-return.png") left 10px no-repeat;
        font-weight: normal;
      }
    }
  }
}
