@import "../blog/common/variables";

body.user-blog {
  background-color: $white;

  .container-fluid.header-container {
    margin-top: 60px;

    span.blog-header {
      font-size: 26px;
      color: $white;
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1px solid $white;
    }
  }
}

.user-blog {
  .hero-image {
    margin-top: 20px;
  }

  #infinite-scrolling {
    text-align: center;
  }

  .dnm-page {
    padding-top: 60px;
  }

  .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
  }

  .main-menu {
    .nav-link.btn {
      display: block;
    }
  }

  .header {
    position: relative;
    display: block;
    overflow: hidden;
    margin: auto;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    height: 262px;
    background-color: $dark-gray;

    .container {
      margin: 0;
      padding: 0;
    }

    .heading {
      clear: both;
      height: 262px;
      line-height: 262px;
      text-align: center;

      a {
        color: $white;
        font-weight: bold;
        font-size: 24px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .header-image {
      margin: auto;
      width: 100%;
      text-align: center;
      max-width: 850px;
    }

    .logo {
      position: fixed;
      z-index: 10;
    }

    .header-text-and-logo {
      position: absolute;
      height: 100%;
      text-align: center;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      .v-wrap {
        height: 100%;
        white-space: nowrap;
        text-align: center;
      }

      .v-wrap:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        margin-right: -0.25em;
        height: 100%;
      }

      .v-box {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        width: 100%;
      }

      span.header-text {
        font-size: 24px;
        color: $white;

        &.with-border {
          margin-left: 15px;
          padding-left: 15px;
          border-left: 1px solid $white;
        }
      }

      p.header-motto {
        margin-bottom: 0;
        padding-top: 5px;
        color: $white;
      }

      img {
        vertical-align: bottom;

        &.blog-logo {
          @media (max-width: $ipad-width) {
            display: none;
          }
        }

        &.blog-icon {
          display: none;

          @media (max-width: $ipad-width) {
            display: inline;
          }
        }
      }
    }

    @media (max-width: $iphone-landscape) {
      height: auto;

      .header-text-and-logo {
        img {
          vertical-align: text-bottom;
        }
      }
    }
  }

  .blog-posts {
    margin-bottom: 20px;
  }

  .blog-post {
    margin-top: 20px;
    padding-bottom: 5px;

    h2 {
      font-size: 28px;

      a {
        font-weight: bold;
        color: $dark-gray;
      }
    }

    p {
      font-size: 14px;
      color: $gray;
      line-height: 28px;
    }

    b {
      font-weight: bold !important;
    }

    i {
      font-style: italic !important;
    }

    ol {
      list-style-type: decimal !important;
      padding-left: 20px;
    }

    ul {
      list-style-type: disc !important;
      padding-left: 20px;
    }

    .date {
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 16px;
      color: $light-gray;
      text-align: right;
      line-height: 20px;

      @media (max-width: $ipad-width) {
        text-align: left;
      }
    }

    .avatar {
      min-height: 76px;

      .avatar-container {
        overflow: hidden;
        margin: 13px 5px 20px 0;
        height: 56px;
        width: 56px;
        background-repeat: no-repeat;
        background-size: 56px 56px;
        background-position: center center;
        border-radius: 50%;
      }
    }
  }

  .blog-container {
    width: $blog-content-width;
    margin-left: auto;
    margin-right: auto;

    .blog-nav {
      padding-bottom: 45px;
      width: 100%;
      float: left;

      .rss-feed-button {
        float: right;
        color: #1a7fbb;
        padding-top: 12px;
      }
    }

    .blog-post-detail {
      h1 {
        margin: 40px 0 10px 0;
        font-weight: bold;
        font-size: 30px;
      }

      b {
        font-weight: bold !important;
      }

      i {
        font-style: italic !important;
      }

      ol {
        list-style-type: decimal !important;
        padding-left: 20px;
      }

      ul {
        list-style-type: disc !important;
        padding-left: 20px;
      }

      .social-buttons-wrapper {
        position: relative;
        display: inline-block;
        padding: 5px 0px 10px 100px;
        background-color: $very-light-gray;
        margin-bottom: 20px;

        &.without-facebook {
          padding-left: 10px;
        }

        .facebook {
          position: absolute;
          left: 10px;
          top: 2px;
        }

        .social-buttons {
          height: 25px;

          &:after {
            content: "";
            display: table;
            clear: both;
          }

          > div {
            float: left;
            margin-right: 10px;
            margin-top: 5px;
            height: 22px;

            &:last-child {
              margin-right: 0px;
            }
          }

          .twitter iframe {
            width: 81px !important;
          }

          .google-plus iframe,
          .google-plus > div {
            width: 66px !important;
          }
        }
      }

      .content {
        margin-bottom: 20px;
        font-size: 17px;
        color: $dark-gray;

        p {
          margin-bottom: 20px;
          line-height: 28px;
        }
      }

      .link {}

      .info {
        margin: 20px 0;

        .date {
          padding-left: 0;
          color: $light-gray;
          font-size: 16px;
          font-style: italic;
          line-height: 32px;
        }

        .social-buttons-wrapper {
          float: right;
          margin-bottom: 0px;

          .social {
            padding: 0;

            @media (max-width: $ipad-width) {
              padding-top: 10px;
            }

            a {
              display: block;
              float: right;
              margin-left: 5px;
              width: 32px;
              height: 32px;
              text-align: center;
              background: $light-gray;
              color: $white;
              font-size: 18px;
              line-height: 36px;

              &:hover {
                text-decoration: none;
              }

              &.ico-twitter {
                padding-left: 3px;
                font-size: 16px;
              }

              @media (max-width: $ipad-width) {
                float: left;
                margin-left: 0;
                margin-right: 5px;
                margin-top: 10px;
              }
            }
          }
        }
      }

      .author {
        margin: 20px 0 40px 0;
        padding: 20px 0;
        border-top: 1px solid $lighter-gray;
        border-bottom: 1px solid $lighter-gray;

        .avatar {
          padding-left: 0;

          .avatar-container {
            overflow: hidden;
            margin-right: 5px;
            height: 112px;
            width: 112px;
            background-repeat: no-repeat;
            background-size: 112px 112px;
            background-position: center center;
            border-radius: 50%;
            border: 1px solid $white;
          }
        }

        .biography {
          h3 {
            margin-top: 10px;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }

      .links {
        margin-bottom: 20px;

        .previous-blog-post, .next-blog-post {
          display: block;
          position: static;
          padding: 5px 20px;
          padding: 0;
          height: 35px;
          background: #1e2222;
          color: $white;
          font-weight: bold;
          line-height: 35px;
          font-size: 12px;
          text-align: center;

          @media (min-width: $screen-md) {
            position: fixed;
            top: 450px;
            padding: 8px 5px;
            width: 55px;
            height: 55px;
            line-height: 20px;
            text-align: left;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .previous-blog-post {
          @media (min-width: $ipad-width) {
            left: 0;
          }
        }

        .next-blog-post {
          @media (min-width: $ipad-width) {
            right: 0;
          }
        }
      }

      #fbcomments, .fb-comments, .fb-comments iframe[style], .fb-comments span {
        width: 100% !important;
      }
    }

    .spinner {
      margin-bottom: 20px;
      text-align: center;
    }

    @media (max-width: $blog-content-width) {
      width: 90%;
    }
  }

  .blog-posts {
    margin-bottom: 20px;

    p {
      line-height: 20px;
      margin-bottom: 0px;
    }

    .blog-post {
      .avatar {
        .span1 {
          width: 60px !important;
          float: left;
        }

        .span11 {
          float: left;

          &.with-avatar {
            max-width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .author-name {
          display: inline-block;
          padding-top: 17px;
          color: $light-gray;
        }

        .avatar-container {
          margin-top: 0;
        }
      }
    }
  }
}
