

// Space Page
@import "space/calendar";
@import "space/bookings";
@import "space/photos";


// Hero area

#space,
.space {

  h1 {
    margin-bottom: 30px;
    line-height: 40px;
  }

  .box.host,
  .box.share {
    position: relative;
    margin-top: 20px;

    i {
      position: absolute;
      top: -3px;
      right: -3px;
      width: 69px;
      height: 69px;
    }

    &.host i {
      background: url("/assets/sections/listings/host-flag.png");
    }

    &.share i {
      background: url("/assets/sections/listings/share-flag.png");
    }
  }

  .box.host {

    .avatar {
      float: left;
    }

    .name {
      margin-left: 60px;
      font-size: 22px;
      line-height: 50px;
    }
  }

  .box.share {

    .stButton {
      margin-right: 12px;
    }

    .first-row {
      margin-bottom: 6px;
    }
  }

  .amenities {
    font-size: 12px;
    line-height: 16px;

    $sprite-map: sprite-map("sections/listings/amenity-categories/*.png");

    dt {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 4px;

      i {
        background: $sprite-map;
        line-height: 20px;
        display: inline-block;
        height: 20px;
        width: 20px;
        vertical-align: top;
        margin-bottom: 0px;
      }
    }

    dl {
      margin-bottom: 8px;
    }

    i.icon-amenity-access {
      background-position: sprite-position($sprite-map, "access");
    }

    i.icon-amenity-other {
      background-position: sprite-position($sprite-map, "entertainment");
    }
  }

  .map {

    .map-wrapper {
      margin-bottom: 10px;
    }
  }

  .host-company {

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .activity-feed {

    li {
      background: url("/assets/sections/listings/event-feed-icon.png") top left no-repeat;
      padding-left: 24px;

      sup {
        font-size: 70%;
        line-height: 80%;
        vertical-align: top;
      }

      small {
        font-size: 12px;
        font-weight: bold;
        color: #6f7e85;
      }

      .user {
        font-weight: bold;
        color: #2091d0;
      }
    }
  }
}

