.footer-wrapper {
  background: $theme_black;
  padding: 40px 0;

  .container-fluid {
    background: $theme_black;
  }

  @media (max-width: $ipad-width) {
    padding: 30px 0 5px;
  }
}

#habla_window_div #habla_sizebutton_a.habla_button {
  display: none;
}

#habla_window_div #habla_oplink_a {
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

footer {
  @media (max-width: $ipad-width) {
    .mobile6 {
      width: 50% !important;
      float: left !important;
    }
  }

  .near-me-badge {
    background: url("/assets/near-me-badge.png") center center no-repeat;
    width: 100px;
    height: 100px;
    float: right;

    @media (max-width: $ipad-width) {
      position: static;
      margin: 20px auto;
      float: none;
      top: 0;
      width: 80px;
      height: 80px;
      background-size: cover;
    }
  }

  line-height: 35px;

  .social-wrapper {
    @media (max-width: $ipad-width) {
      position: relative;
      overflow: hidden;
      float: right;
      width: 100%;
    }

    ul.social-icons li a {
      .text {
        font-weight: 600;
      }

      .image {
        &.ico-twitter {
          font-size: 21px;
        }

        display: inline-block;
        font-size: 22px;
        width: 20px;
        margin-right: 20px;
      }
    }
  }

  ul {
    list-style: none !important;
    margin: 0 0 12px 0px !important;
  }

  a, a:visited {
    color: $gray120;
    text-decoration: none;
    text-align: center;
    font-weight: 600;

    &:hover {
      color: $gray210;
    }

    @media (max-width: $ipad-width) {
      font-size: 14px;
    }
  }

  &:first-child a {
    padding-left: 0;
  }

  &.last a {
    background: 0;
  }

  &.active a {
    color: $gray120;

    &:hover {
      color: lighten($gray120, 10%);
    }
  }

  .copyright {
    @media (max-width: $ipad-width) {
      text-align: center;
    }

    span {
      font-size: 12px;
      padding: 0 13px 0 0;
      margin: 0 auto;
      text-align: center;
      line-height: 30px;
      color: $gray120;

      @media (max-width: $ipad-width) {
        margin-top: -25px;
        padding-bottom: 20px;
      }

      a {
        font-size: 12px;
      }
    }
  }
}
