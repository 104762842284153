$map-z-index: $nav-z-index - 20;

/*** Map View ***/
section.search#content {
  margin-top: -30px;

  @media (max-width: $ipad-width) {
    margin-top: -55px;
  }

  .search-filter-box select.time-select option[disabled] {
    display: none;
  }

  .loading {
    position: fixed;
    top: 50%;
    right: 0;
    left: 0;
    z-index: $map-z-index + 2;
    margin: 0 auto;
    padding: 8px;
    width: 32px;
    height: 32px;
    background: $gray230;
    border-radius: $border-radius-default;
  }

  .screen-locker {
    position: fixed;
    background-color: black;
    opacity: 0.5;
    width: 100%;
    z-index: $map-z-index + 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .google-map-wrapper,
  .google-map .inner {
    z-index: $map-z-index;

    @media (max-width: $iphone-landscape) {
      height: 150px;
    }
  }

  .google-map-wrapper .cluster[title] {
    margin-top: -10px;
    margin-left: -2px;
    border: 2px solid rgb(107, 11, 13);
    border-radius: 42px;
    background: rgb(186, 0, 13) !important;
    color: $pure-white !important;
    font-size: 14px !important;
  }

  #results.no_results {
    z-index: $map-z-index - 1;
    padding: 40px 0;

    &.with_slider {
      margin-top: 40px;
    }

    &.map {
      position: absolute;
      top: 110px;
    }

    @media (max-width: $search-bar-breakpoint-desktop) {
      margin-top: 40px;
    }

    @media (max-width: $iphone-landscape) {
      margin-top: 30px;
    }

    @media (max-width: $iphone-portrait) {
      margin-top: 100px;
    }

    form {
      margin-top: 30px;
    }
  }

  div.google-map {
    background: $gray230;
    margin: 0;

    &.affix {
      z-index: $map-z-index;
      top: 48px;

      @media (min-width: 1400px) {
        .inner {
          width: 1360px;
        }
      }

      @media (max-width: 1399px) {
        left: 20px;
        right: 20px;
      }
    }

    .inner {
      height: 300px;

      @media (max-width: $iphone-landscape) {
        height: 150px;
      }
    }

    .search-map-redo-search-control {
      background: rgba(255, 255, 255, 0.7);
      padding: 2px 4px;
      margin-left: 12px;
      border-radius: $border-radius-default;

      label {
        font-size: 14px;
      }
    }

    @media (max-width: 960px) {
      .search-map-redo-search-control {
        display: none;
      }
    }
  }

  .location {
    .reserve-listing {
      margin-top: 37px;
    }

    .listing {
      .reserve-listing {
        margin-top: 0px;
      }
    }
  }

  .reserve-listing {
    text-align: right;

    a {
      border-radius: $border-radius-left;
      width: 100%;
      text-align: center;
      max-width: 100px;
    }

    @media (max-width: $ipad-width) {
      text-align: left;

      a {
        border-radius: $border-radius-default;
      }
    }

    @media (min-width: $ipad-width + 1) {
      width: 9.3333%;
      margin-left: 2%;
    }
  }
}

/*** End Map View ***/

#search_row,
.black-box {
  .btn-green {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }
}

#search_row {
  .tagline {
    float: none;
    text-align: center;
  }
}

/*** Home Page Search ***/
form.search-box {
  position: relative;

  .input-wrapper, .button-wrapper {
    position: relative;
    float: left;
    box-sizing: border-box;
  }

  input[type='submit'] {
    position: absolute;
    left: -999em;
    top: 0;
  }

  .input-wrapper {
    width: 100%;

    input {
      padding-left: 35px;
    }

    input.search_date {
      padding-left: 5px;
      opacity: 1.0;
    }

    .geolocation {
      position: absolute;
      top: 12px;
      left: 12px;
      color: $icon;
      width: 34px;

      &:hover {
        color: $icon-hover;
        cursor: pointer;
      }
    }

    .geo-loading {
      position: absolute;
      width: 16px;
    }
  }

  .select2-choice, .select2-container {
    border: solid #D4D4D4 1px;
    border-radius: 4px;
    background: #e6e6e6 url("/assets/themes/buy_sell/arrow.png") right no-repeat;
    width: 100%;
    font-size: 22px;
    box-sizing: border-box;

    .select2-arrow {
      display: none;
    }
  }

  .select2-container {
    padding: 10px 0 10px 5px;
  }

  .select2-choice {
    border: none;
    box-shadow: none;
  }

  .no-text-search {
    width: 100%;
    height: 50px;
  }

  .button-wrapper {
    width: 15%;

    a {
      display: block;
    }
  }

  .search-button {
    display: block;
  }

  @media (max-width: 767px) {
    .row-fluid [class*="span"] {
      margin-bottom: 5px;
    }

    .customSelect {
      width: 100%;
    }
  }

  @media (max-width: $iphone-landscape) {
    .input-wrapper, .button-wrapper {
      float: none;
      display: block;
      width: 100%;
    }

    .input-wrapper {
      padding-right: 0;
      margin-bottom: 8px;
    }
  }

  .search-transactable--radio {
    padding: 10px 0 0;

    label {
      margin: 0 20px 0 0;
      display: inline-block;
      white-space: nowrap;

      input {
        padding: 0;
      }
    }
  }
}

.select2-results {
  margin-right: 0;
  padding-left: 0;
}

.select2-results .select2-highlighted {
  background: #edf8fe;
  color: #aca8aa;
}

.select2-drop-active {
  margin-top: 5px;
  border-radius: 2px;
  border-color: transparent;
}

#ui-datepicker-div {
  background: #fff;
  box-shadow: 5px 6px 25px #727272;
  width: initial;
  text-align: center;

  .ui-datepicker-header {
    padding: 5px 10px;
  }

  table {
    background: #edeff0;
    padding: 0px 20px 10px 20px;
  }

  table th {
    padding-bottom: 5px;
  }

  table td {
    height: 30px;
    width: 30px;
    background: #fff;
    border: none;
  }

  tr:nth-child(1) td {
    padding-top: 0;
  }

  td a, td span, th span {
    color: #000;
    font-size: 12px;
    text-align: center;
    padding: 7px;
    font-weight: 200;
  }

  a.ui-corner-all:hover {
    cursor: pointer;
  }

  .ui-datepicker-next {
    float: right;
    font-size: 12px;
  }

  .ui-datepicker-prev {
    float: left;
    font-size: 12px;
  }

  .ui-datepicker-unselectable {
    background: #edeff0;
    color: #000;
  }

  .ui-state-active {
    background: #1c7bca;
    color: #fff;
  }
}

/*** End Home Page Search ***/

header#listing_search {
  position: fixed;
  top: 86px;
  right: 30px;
  left: 30px;
  z-index: $nav-z-index - 10;
  max-width: 1314px;
  padding: 0px 5px 5px 5px;
  margin: 0 auto;
  background: $gray230;
  border-radius: $border-radius-default;

  .saved-search-btn {
    float: right;
    margin-top: 10px;
    margin-right: 20px;
    background-color: $white;
  }

  @media (max-width: $ipad-width) {
    position: static !important;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 26px;
  }

  .header-inner {
    position: relative;
    max-width: 1360px;
    margin: -2px auto;
    background: $gray230;
  }

  .list-map-toggle {
    margin-top: 8px;
    height: 49px;
    background: transparent;
    border-radius: $border-radius-default;

    @media (max-width: $ipad-width) {
      top: 5px;
      height: 28px;
      border-radius: $border-radius-default;
    }

    a.map,
    a.list {
      float: left;
      z-index: 100;
      display: block;
      width: 50%;
      height: 45px;
      border-radius: $border-radius-default;
      background: rgba($dnm_blue, 0.5);
      color: $dnm_white;
      font-size: 24px;
      text-align: center;
      line-height: 45px;

      @media (max-width: $ipad-width) {
        height: 28px;
        left: 64px;
        font-size: 16px;
        line-height: 28px;
        border-radius: $border-radius-default;
      }

      &:hover {
        background: rgba($dnm_blue, 0.75);
      }

      &.active {
        background: $dnm_blue;
        color: $dnm_white;
      }

      &.active:hover {
        background: $dnm_blue;
      }
    }

    a.map {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .ico-marker:before {
        position: relative;
        left: -5px;
      }
    }

    a.list {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .ico-list:before {
        position: relative;
        left: -8px;
      }
    }
  }

  .geolocation-input, .keyword-input {
    position: relative;

    .geolocation {
      position: absolute;
      top: 14px;
      left: 13px;
      z-index: 1;
    }
  }

  .search-loop-icon {
    transition: all .1s ease-in;
    position: absolute;
    line-height: 35px;
    font-size: 20px;
    top: 14px;
    left: 13px;
    width: 35px;
    z-index: 1;

    @media (max-width: $ipad-width) {
      font-size: 18px;
      top: -2px;
      left: 7px;
    }
  }

  &.mixed {
    left: 0px;
    right: 0px;
    border-radius: 0;
    max-width: 100%;
    top: 60px;

    @media (max-width: $iphone-portrait) {
      top: 33px;
    }

    form {
      max-width: 100%;
    }

    .header-inner {
      max-width: 100%;
    }
  }

  nav.search-view {
    height: 49px;
    margin-top: 5px;

    a {
      border-radius: $border-radius-default;
      background: $gray230;
      color: $gray40;
      text-align: center;
      line-height: 49px;
    }

    a:hover {
      background: $gray;
      color: $pure-white;
    }

    a span {
      line-height: 49px;
      font-size: 24px;
    }
  }

  form {
    max-width: 1400px;
    margin: 0 auto;
    transition: margin .1s linear, padding .1s linear;

    input {
      margin-top: 6px;
      margin-bottom: 0px;

      @media (max-width: $ipad-width) {
        margin-top: 0px;
      }
    }

    input[type="text"].query {
      position: relative;
      display: block;
      float: left;
      padding: 8px 5px 8px 45px;
      border: 2px solid $gray230;
      background: $theme_white;

      &:focus {
        border: 2px solid $gray210;
      }

      @media (max-width: $ipad-width) {
        height: 28px;
        border-radius: $border-radius-default;
        border: none;
        font-size: 16px;
        line-height: 24px !important;
        margin-top: 0px;
        margin-bottom: 3px;
        padding: 0 0 0 29px;
      }
    }

    &.search_results {
      .geolocation,
      .search-icon {
        transition: all .1s ease-in;
        line-height: 35px;
        font-size: 20px;
        cursor: pointer;

        @media (max-width: $ipad-width) {
          font-size: 18px;
          top: -2px;
          left: 7px;
        }
      }

      .geolocation {
        color: $icon;
        width: 34px;

        &:hover {
          color: $icon-hover;
        }
      }

      .search-icon {
        box-sizing: border-box;
        margin-top: 7px;
        display: block;
        float: left;
        padding: 7px 0px 0 0;
        width: 45px;
        height: 45px;
        color: $theme_white;
        text-align: center;
        border-radius: $border-radius-default;

        span.text {
          display: none;
        }

        @media (max-width: $search-bar-breakpoint-desktop) {
          right: 0px;
        }

        @media (max-width: $ipad-width) {
          margin-top: 0;
          padding: 0 6px 0 0;
          line-height: 32px;
          width: 100%;
          height: 28px;

          span.text {
            display: inline;
          }
        }
      }
    }
  }

  @media (max-width: $iphone-landscape) {
    position: absolute;
  }

  @media (max-width: $iphone-portrait) {
    &.with-map {
      .search-filters {
        .search-filter {
          display: none;
        }
      }
    }
  }
}

.search-filters {
  margin-top: 8px;
  font-size: 0.9em;

  .search-filter {
    position: relative;
    float: left;
    padding: 8px 8px;
    border: 1px solid $theme_white;
    border-radius: $border-radius-default;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0px;
    }

    a {
      display: block;
    }

    ul {
      display: none;
      position: absolute;
      z-index: $map-z-index + 1;
      left: -1px;
      top: 30px;
      overflow-y: auto;
      padding: 7px 0px;
      max-height: 200px;
      min-width: 100%;
      border: 1px solid $theme_white;
      border-radius: $border-radius-default;
      background: $theme_white;

      li {
        overflow: hidden;
        padding: 0px 8px;
        white-space: nowrap;
      }

      label {
        float: left;
        margin: 0px;
        padding-left: 0px 0px 0px 39px;
      }

      @media (max-width: $ipad-width) {
        top: 25px;
      }
    }

    &.filter-margin {
      margin: 0px 5px;
    }

    &.active {
      background: $theme_white;
    }

    @media (max-width: $ipad-width) {
      padding: 0 8px;
      height: 28px;
    }
  }

  h3 span {
    font-size: 0.8em;
    padding-right: 8px;
  }
}

.search__filter-row {
  margin-top: 50px;
}

/*** Listing View ***/
section.search section#category-tree {
  &.list-view-category-tree {
    margin-top: 145px;

    &.with_slider {
      margin-top: 190px;
    }
  }

  .span2 & {
    margin-top: 0;
  }

  .nav-item a.active {
    color: #5dbded;
    font-weight: 400;
  }

  .nav-item ul {
    margin-left: 20px;
  }

  @media (max-width: $ipad-width) {
    &.list-view-category-tree {
      margin-top: 0;

      &.with_slider {
        margin-top: 0;
      }
    }
  }
}

.list-search-left {
  width: 220px;
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

.list-search-right {
  width: 665px;
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .list-search-right, .list-search-left {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .list-search-left {
    width: 166px;
  }

  .list-search-right {
    width: 507px;
  }
}

@media (min-width: 1200px) and (max-width: 1389px) {
  .list-search-left {
    width: 270px;
  }

  .list-search-right {
    width: 815px;
  }
}

@media (min-width: 1390px) {
  .list-search-left {
    width: 270px;
  }

  .list-search-right {
    width: 1000px;
  }
}

section.search section#results.list {
  margin-top: 120px;

  &.with_slider {
    margin-top: 190px;
  }

  article.listing {
    box-sizing: border-box;
    float: left;
    position: relative;
    margin-top: 25px;
    background: $gray230;
    border-radius: $border-radius-default;
    width: 22.9%;
    margin-left: 1%;
    margin-right: 1%;

    .tooltip-inner {
      text-align: left;
      max-width: none;
    }

    @media (max-width: $iphone-landscape) {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }

    @media (min-width: $iphone-landscape) and (max-width: $ipad-width) {
      width: 46.9%;
      margin-left: 1.5%;
      margin-right: 1.5%;
    }

    @media (min-width: $ipad-width) and (max-width: $default-width) {
      width: 29.9%;
      margin-left: 1.5%;
      margin-right: 1.5%;
    }

    .heading {
      box-sizing: border-box;
      padding-top: 8px;
      padding-left: 10px;
      height: 74px;

      .company {
        color: $gray160;
      }

      .company,
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .photo {
      box-sizing: border-box;
      z-index: -1;
      padding-right: 1px;

      img {
        width: 100%;
        display: block;
      }
    }

    .photo-container {
      position: relative;
    }

    .listing-google-map-wrapper {
      position: absolute;
      z-index: 5;
      display: none;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      a {
        position: absolute;
        z-index: 2;
        top: 5px;
        right: 10px;
      }

      .listing-google-map {
        width: 100%;
        height: 100%;
        padding-right: 1px;

        img {
          max-width: none;
        }

        .google-map-popover {
          .close {
            top: 0px;
            right: 10px;
            color: $theme_black;
          }
        }
      }
    }

    .info {
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      color: $theme_white;
      font-size: 13px;
      background-image: linear-gradient(rgba(250, 250, 250, 0), $black);

      .miles,
      .price {
        position: absolute;
        bottom: 0;
        padding-left: 5px;
        padding-right: 5px;
      }

      .miles {
        left: 0;
      }

      .price {
        right: 0;
      }
    }
  }

  article.listing.user {
    .heading {
      height: 43px;
    }
  }

  @media (max-width: $search-bar-breakpoint-desktop) {
    margin-top: 125px;
  }

  @media (max-width: $ipad-width) {
    margin-top: 0;

    &.with_slider {
      margin-top: 0;
    }
  }
}

/*** End Listing View ***/

section.search {
  header.products_list {
    .header-inner {
      @media (max-width: $ipad-width) {
        margin-top: 4px !important;
      }
    }
  }

  header#listing_search.products_list.no_taxons {
    @media (max-width: $ipad-width) {
      margin-top: 35px;
    }
  }

  section.products_list.no_taxons, section.no_products_list.no_taxons {
    margin-left: 0;
  }

  section#results.products_list {
    margin-top: 80px;
    margin-bottom: 20px;

    article.product {
      box-sizing: border-box;
      float: left;
      position: relative;
      margin-top: 25px;
      background: $gray230;
      border-radius: $border-radius-default;
      width: 22.9%;
      margin-left: 1%;
      margin-right: 1%;

      .tooltip-inner {
        text-align: left;
        max-width: none;
      }

      @media (max-width: $iphone-landscape) {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
      }

      @media (min-width: $iphone-landscape) and (max-width: $ipad-width) {
        width: 46.9%;
        margin-left: 1.5%;
        margin-right: 1.5%;
      }

      @media (min-width: $ipad-width) and (max-width: $default-width) {
        width: 29.9%;
        margin-left: 1.5%;
        margin-right: 1.5%;
      }

      .heading {
        box-sizing: border-box;
        padding-top: 8px;
        padding-left: 10px;
        height: 74px;

        .company {
          color: $gray160;
        }

        .company,
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .photo {
        box-sizing: border-box;
        z-index: -1;
        padding-right: 1px;

        img {
          width: 100%;
          display: block;
        }
      }

      .photo-container {
        position: relative;
      }

      .info {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        color: $theme_white;
        font-size: 13px;
        background-image: linear-gradient(rgba(250, 250, 250, 0), $black);

        .price {
          position: absolute;
          bottom: 0;
          padding-left: 5px;
          padding-right: 5px;
          right: 0;
        }
      }
    }

    @media (max-width: $search-bar-breakpoint-desktop) {
      margin-top: 125px;
    }

    @media (max-width: $ipad-width) {
      margin-top: 0;
    }
  }
}

/*** End Products List View ***/

/*** List Table View ***/

nav.taxons_tree {
  margin-top: 100px;
}

section.products_table {
  margin-top: 45px;
}

.products_table {
  .search-icon {
    margin-left: 0;
    width: 112px !important;
  }

  section#category-tree {
    margin-top: 45px !important;
  }

  table {
    margin: auto;
    min-width: 958px;

    @media (max-width: $search-bar-breakpoint-desktop) {
      min-width: 200px;
    }
  }

  table, th, td {
    background-color: white;
    border: 1px solid #D3D3D3;
  }

  th, td {
    padding: 5px;
  }

  table, th, td, p, a {
    font-size: 14px !important;
    font-weight: normal !important;
    color: $gray120 !important;
  }

  a.btn {
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 0 10px 0 10px !important;
  }

  th {
    text-align: center;
    text-decoration: underline;
    color: #545454;
    background: #D3D3D3;
  }

  td {
    border-bottom: 1px solid #D3D3D3;

    &.image {
      text-align: center;
    }

    &.number {
      text-align: right;
    }
  }
}

header#listing_search.products_table {
  background: none;
  padding: 0 !important;

  form .search-container {
    padding-left: 0px;

    input[type="text"].query {
      padding-left: 8px;
    }
  }
}

/*** End List Table View ***/

/*** Infinite Scroll ***/
.ias_loader {
  h1 {
    text-align: center;
    margin: 35px 0px;

    img {
      display: inline-block;
      margin: 0px 20px 4px 0px;
      vertical-align: middle;
    }
  }
}

/*** End Infinite Scroll ***/

/*** Products View ***/
section.search section#results.products {
  article.product {
    box-sizing: border-box;
    float: left;
    position: relative;
    margin-top: 0;
    margin-bottom: 25px;
    border-radius: $border-radius-default;
    width: 31%;
    margin-left: 2.3%;
    margin-right: 0;
    padding: 0 15px;

    .tooltip-inner {
      text-align: left;
      max-width: none;
    }

    @media (max-width: $iphone-landscape) {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }

    @media (min-width: $iphone-landscape) and (max-width: $ipad-width) {
      width: 46.9%;
      margin-left: 1.5%;
      margin-right: 1.5%;
    }

    @media (min-width: $ipad-width) and (max-width: $default-width) {
      width: 29.9%;
      margin-left: 1.5%;
      margin-right: 1.5%;
    }

    .heading {
      box-sizing: border-box;
      padding-top: 8px;
      padding-left: 0px;
      height: 64px;

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.9em;
      }

      .price {
        font-weight: bold;
      }
    }

    .photo {
      box-sizing: border-box;
      z-index: -1;
      padding-right: 1px;

      img {
        width: 100%;
        display: block;
      }
    }

    .photo-container {
      position: relative;
    }
  }
}

/*** End Products View ***/

form.search-notification-form {
  .control-group:not(.hidden) {
    margin: 0 auto;
    width: 285px;
    display: inline-block;
  }

  input[type=text], input[type=email] {
    font-size: 18px;
    font-weight: normal;
    height: 50px;
  }

  input[type=submit] {
    width: inherit;
    margin-left: 5px;
    vertical-align: top;
  }
}

section.search section#results.mixed {
  height: 100%;
  width: 100%;
  padding-top: 65px;

  .map {
    width: 33.33%;
    float: left;

    @media (max-width: 2 * $iphone-portrait) {
      display: none;
    }
  }

  .map-marker {
    color: $gray230;
    opacity: 1;
    pointer-events: none;
    text-align: center;
    width: 40px;
    white-space: nowrap;
  }

  .list {
    width: 66.66%;
    float: left;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .filters {
      padding-left: 10px;

      .search-mixed-filter {
        background-color: $gray230;
        padding: 10px;
        margin-bottom: 1px;

        .date-range {
          input {
            position: relative;
            display: block;
            float: left;
            margin-top: 5px 0 0;
            padding: 5px 5px 5px 10px;
            border: 2px solid $gray230;
            background: $theme_white;

            &:focus {
              border: 2px solid $gray210;
            }

            @media (max-width: $iphone-landscape) {
              height: 28px;
              border-radius: $border-radius-default;
              border: none;
              font-size: 16px;
              line-height: 24px !important;
              margin-top: 0px;
              margin-bottom: 3px;
              padding: 0 0 0 29px;
            }
          }

          #date-range-update {
            box-sizing: border-box;
            display: block;
            margin-top: 2px;
            padding: 5px 7px 0px 7px;
            height: 38px;
            color: $theme_white;
            cursor: pointer;
            border-radius: $border-radius-default;

            @media (max-width: $search-bar-breakpoint-desktop) {
              right: 0px;
            }

            @media (max-width: $iphone-landscape) {
              top: 5px;
              padding: 0px 8px 0px 8px;
              line-height: 32px;
              height: 28px;
              font-size: 18px;
            }
          }
        }

        h4 {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .container-fluid {
          padding-left: 0px;
        }

        ul {
          li {
            display: inline;
            padding-right: 15px;
          }

          li.filter-option {
            float: left;

            label.checkbox.small-checkbox {
              float: left;
              margin-bottom: 0px;

              span.filter-label-text {
                float: left;
                cursor: default;
              }
            }
          }
        }
      }

      @media (max-width: $iphone-portrait - 1) {
        display: none;
      }

      @media (max-width: 2 * $iphone-portrait) {
        margin-right: 10px;
      }
    }

    .sort {
      background-color: $gray60;
      line-height: 40px;
      height: 40px;
      padding: 10px;
      margin-left: 10px;
      font-size: 90%;

      h3 {
        display: inline-block;
        color: $gray245;
      }

      .results-count {
        float: left;
      }

      .sort-by {
        float: right;
        text-align: right;

        span.customSelect {
          color: $gray245;
        }

        span.customSelectInner {
          padding-right: 7px;
          width: 75px !important;
          text-align: right;

          @media (max-width: $iphone-landscape - 1) {
            width: 65px !important;
          }
        }

        #sort {
          width: 95px !important;
          cursor: pointer;
        }
      }

      .per-page {
        float: left;
        text-align: right;
        padding-left: 15px;

        span.customSelect {
          color: $gray245;
        }

        span.customSelectInner {
          padding-right: 7px;
          width: 30px !important;
          text-align: right;
        }

        #per_page {
          width: 50px !important;
          cursor: pointer;
        }
      }

      .saved-search-btn {
        float: right;
      }

      .custom-select-dropdown-icon {
        position: static;
        font-size: 80%;
        color: $gray245;
      }

      @media (max-width: $iphone-landscape - 1) {
        height: 120px;

        > div {
          float: none !important;
          text-align: left !important;
        }
      }

      @media (max-width: 2 * $iphone-portrait) {
        margin-right: 10px;
      }
    }

    .hidden-locations {
      display: none;
    }

    .locations {
      position: relative;
      padding-left: 10px;

      .location {
        position: relative;
        border-top: 4px solid $gray60;
        margin-top: 5px;

        .header {
          position: relative;
          min-height: 200px;
        }

        .location-counter {
          position: absolute;
          color: $gray245;
          background-color: $gray60;
          width: 35px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 80%;
          z-index: 1;
          border-bottom-right-radius: $border-radius-default;
        }

        .location-data {
          padding-top: 25px;

          .subheader {
            margin: 0 0 55px 0;
          }

          h2 {
            a {
              word-break: break-word;
            }
          }
        }

        .location-photos-container {
          .location-photos {
            width: 100%;

            .carousel {
              margin-bottom: 0;

              img {
                width: 100%;
              }
            }

            .carousel-control {
              left: 0;
              font-size: 160%;
              margin-top: 10px;
              text-shadow: none;

              &.right {
                left: auto;
                right: 0;
              }
            }
          }
        }

        .price-and-types {
          position: absolute;
          bottom: 0;
          width: 60%;

          @media (max-width: $ipad-width) {
            position: static;
            width: 100%;
          }
        }

        .location-price {
          text-align: right;
          padding-right: 50px;
          padding-bottom: 15px;

          span {
            font-size: 200%;
            color: $gray80;
          }

          @media (max-width: $ipad-width) {
            padding: 15px 0;
            padding-right: 15px;
          }
        }

        .nav {
          margin-bottom: 0;
        }

        ul.nav-tabs {
          li {
            a {
              color: $gray100;
              font-size: 90%;
              padding: 8px;
              line-height: 16px;

              &:hover {
                background-color: transparent;
                border-color: transparent;
              }

              &:focus {
                border-color: transparent;
              }
            }

            &.active {
              a {
                background-color: $gray220;
                border: 1px solid $gray220;
                border-top-left-radius: $border-radius-default;
                border-top-right-radius: $border-radius-default;
              }
            }
          }
        }

        .tabbable {
          .tab-content {
            overflow: hidden;
          }

          .title {
            background-color: $gray220;
            padding: 3px;
          }

          .row-fluid {
            background-color: $gray230;
            cursor: pointer;
            margin-bottom: 5px;

            .padding-top {
              padding-top: 15px;
            }

            .listing-price {
              span {
                font-size: 80%;
              }

              @media (min-width: $ipad-width + 1) {
                width: 9.3333%;
                margin-left: 2%;
              }
            }

            .listing-capacity {
              @media (min-width: $ipad-width + 1) {
                width: 9.3333%;
                margin-left: 2%;
              }
            }

            .listing-main-photo {
              @media (min-width: $ipad-width + 1) {
                width: 18%;
              }
            }

            .listing-name-description {
              padding-top: 15px;

              p {
                padding-top: 10px;
                line-height: 150%;
                font-size: 90%;
                margin-bottom: 0;
              }

              @media (min-width: $ipad-width + 1) {
                width: 46%;
                margin-left: 2%;

                &.without-capacity {
                  width: 57.3333%;
                }
              }
            }

            .photo {
              img {
                display: block;
              }
            }

            .bold {
              font-weight: bold;
            }

            @media (max-width: $ipad-width) {
              > div {
                padding: 5px;
              }
            }
          }

          @media (max-width: $ipad-width) {
            padding-right: 0;
          }
        }

        &.active {
          border-color: $dnm_orange;

          .location-counter {
            background-color: $dnm_orange;
          }
        }
      }

      .search-pagination {
        padding-top: 10px;
        padding-bottom: 15px;

        .page-entries-info {
          font-weight: bold;
          font-size: 0.9em;
        }

        .pagination {
          margin: 0;
          padding-top: 5px;

          a {
            color: $gray120;
          }

          span, em, a {
            display: inline-block;
            border: 1px solid $gray210;
            border-radius: 2px;
            min-width: 30px;
            text-align: center;
          }

          span.disabled {
            display: none;
          }

          a:hover, em.current {
            color: $gray60;
            border-color: $gray120;
          }
        }
      }

      @media (max-width: 2 * $iphone-portrait) {
        padding-right: 10px;
      }
    }

    @media (max-width: 2 * $iphone-portrait) {
      width: auto !important;
    }
  }

  @media (max-width: $ipad-width) {
    padding-top: 10px;
  }
}

#save-search-modal {
  display: none;
  width: 70%;
  margin-left: -35%;

  .content {
    margin: 0px 30px;

    h4 {
      margin-top: 25px;
      margin-bottom: 15px;
      font-size: 24px;
    }

    p {
      margin: 0;
    }
  }

  .controls {
    text-align: center;

    input {
      width: 70%;
      margin: 30px 0px;
      border: 1px solid $theme_blue;
      padding: 15px;
    }

    button.btn-saved-search-title {
      padding: 0px 70px;
      margin-bottom: 30px;
    }
  }
}

#save-search-status-modal {
  display: none;

  .content {
    padding: 40px;
    text-align: center;

    h4.modal-title {
      font-size: 24px;
    }

    a {
      color: $theme_blue;
    }
  }
}

div.search-view-listing {
  margin-bottom: 50px;

  .search-mixed-filter {
    .slider {
      margin: 70px 20px 30px;
    }
  }

  section#category-tree {
    margin-top: 20px;
  }

  .results-count {
    margin-bottom: 30px;
  }

  label {
    display: block;
  }

  select {
    width: 100%;
  }

  .search-input {
    margin-bottom: 10px;

    input {
      float: left;
      width: calc(100% - 40px);
      font-size: 16px;
      padding: 0 10px;
      border: none;
      background: #fff;
      box-sizing: border-box;
      height: 36px;
    }

    .btn {
      float: right;
      width: 36px;
      height: 36px;
      position: relative;
      padding: 0;

      img {
        display: block;
        vertical-align: top;
        float: left;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .search__table-responsive {
    overflow: auto;
    width: 100%;

    > .search__table {
      td, th {
        white-space: nowrap;
      }
    }
  }
}

.autocomplete-categories {
  margin: 20px 0;

  label {
    font-weight: bold;
    color: #000;
  }

  .select2-search-choice > div {
    line-height: 28px;
  }

  .select2-search-choice-close {
    top: 13px;
  }
}

.search-list-price-slider-container {
  display: block;
  clear: both;
}

.search-mixed-filter .price-slider-container {
  padding: 40px 20px 15px;
}

section.search {
  div.list {
    -webkit-overflow-scrolling: touch;
  }
}