@import "listings/documents";
@import "listings/search";

#content.search #results.map article.listing {
  display: none;
}

.row-fluid.reviews-summary.first-rating {
  .span9 {
    margin-left: 0;
  }
}

.row-fluid.row-rating-questions {
  .span3 {
    margin-left: 0;
  }
  .clear-row {
    clear: both;
  }
}

