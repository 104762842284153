section.listings {
    /*===== TABS AND TABBED CONTENT =====*/

    .tab-content-container {
        float: left;
        width: 100%;
        border-bottom: solid #D5D5D5 2px;
    }

    .nav {
        margin: 0;
    }

    .nav-tabs li a:hover {
        background: transparent;
        border: solid transparent 1px;
    }

    .nav-tabs > .active > a, .nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus {
        background: #fff !important;
        border: solid transparent 1px;
        color: #000;
    }

    .nav-tabs li a {
        color: #3a3a3a;
        padding: 22px 25px;
        font-weight: 600;
    }

    .tab-content > article {
        background: #fff;
        padding: 30px 40px;
    }

    .tab-content > article p {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 25px;
    }

    .padding-row {
        padding: $padding-medium 0;

        &.small {
            padding-top: 0;
        }
    }

    h2 {
        padding: $padding-default 0;
        font-size: 150%;
        padding-bottom: 30px;

        span {
            font-size: 90%;
            padding-left: 10px;
        }
    }

    p {
        font-size: 110%;
    }

    .ask-a-question {
        @media (max-width: $booking-module-breakpoint - 1) {
            float: none;
            width: 100%;
            text-align: center;
        }

        span {
            &.ico-mail {
                display: none;
            }

            @media (min-width: $booking-module-breakpoint) and (max-width: $ipad-width) {
                display: none;

                &.ico-mail {
                    display: inline;
                }
            }
        }
    }

    .span9 {
        @media (max-width: $booking-module-breakpoint - 1) {
            width: 100%;
        }
    }

    .amenities-block {
        .amenities-header {
            position: relative;

            a {
                display: none;
                color: $gray80;

                span {
                    position: absolute;
                    right: 0;
                    top: 0px;
                }

                @media (max-width: $booking-module-breakpoint) {
                    display: block;
                    width: 100%;
                }
            }

            > h3 {
                display: block;
            }
        }

        .amenities-container {
            padding-top: 15px;
            border-bottom: 1px solid $gray210;
            margin-bottom: 15px;
            display: block;

            .amenities-row {
                border-top: 1px solid $gray210;

                .span3 {
                    line-height: 40px;
                    font-size: 16px;
                    width: 100%;
                }

                @media (max-width: $booking-module-breakpoint-desktop) {
                    border-top: 0;

                    .span3 {
                        width: 100%;
                        margin-left: 0;
                        border-top: 1px solid $gray210;
                    }
                }

                @media (max-width: $ipad-width) {
                    .span3 {
                        border-top: 1px solid $gray210;
                        width: 100%;
                    }
                }
            }
        }

        @media (max-width: $booking-module-breakpoint) {
            &.amenities-shown {
                padding-bottom: 0;

                .amenities-container {
                    display: block;
                    margin-bottom: 0;
                    border-bottom: 0;
                }
            }
        }

        @media (max-width: $booking-module-breakpoint) {
            margin-bottom: 15px;
        }
    }

    .administrator {
        position: relative;
        background: $gray230;
        box-sizing: border-box;
        border-radius: $border-radius-default;

        img {
            float: left;
            width: 50%;
            min-height: 145px;
            border-top-left-radius: $border-radius-default;
            border-bottom-left-radius: $border-radius-default;
        }

        .info {
            float: left;
            padding: 15px;
            width: 50%;
            height: 50%;
            box-sizing: border-box;

            span.name {
                display: block;
                color: $gray80;
                font-size: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span.title {
                display: block;
                height: 2.4em;
                color: $gray120;
                font-size: 16px;
                line-height: 1.2em;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .buttons {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 50%;
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;

            a.btn {
                width: 100%;
                height: 42px;
                line-height: 42px;
                font-size: 18px;
                text-align: center;
                white-space: nowrap;
                border-radius: $border-radius-default;
            }

            a.btn.ask {
                margin-top: 8px;
            }

            a.btn.view {
                border: 1px solid $gray170 !important;
                color: $gray120;
                background: $gray230;
            }
        }

        @media (min-width: $host-card-breakpoint-tiny) and (max-width: $host-card-breakpoint-small), (min-width: $host-card-breakpoint-medium) and (max-width: $host-card-breakpoint-large) {
            .info {
                padding: 8px;

                span.name {
                    height: 22px;
                    font-size: 18px;
                    line-height: 22px;
                }

                span.title {
                    height: 2.4em;
                    font-size: 14px;
                    line-height: 1.2em;
                }
            }

            .buttons {
                margin-bottom: 8px;
                padding-left: 8px;
                padding-right: 8px;

                a.btn {
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                }
            }
        }

        @media (min-width: $booking-module-breakpoint + 1) {
            margin-left: 15px;
        }
    }

    article.listing, article.location, article.company, article.administrator {
        max-width: $space-full-image-width;
    }

    .availabilities {
        box-sizing: border-box;
        border-radius: $border-radius-default;
        background: $gray240;
        border: 1px solid $gray210;
        padding: 12px 20px;

        @media (min-width: $booking-module-breakpoint + 1) {
            margin-left: 15px;
        }

        @media (max-width: $ipad-width) {
            .span6 {
                width: 49%;
                display: inline-block;
                font-size: 80%;
            }
        }
    }

    #fullscreen-gallery {
        background: rgba(0, 0, 0, 1);

        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 11;
            width: 54px;
            height: 55px;
            margin-top: -27px;
            margin-left: -27px;
        }

        .carousel {
            .carousel-inner {
                height: 100%;
            }

            ol.carousel-indicators {
                width: auto !important;
            }

            .item {
                img {
                    max-height: 100%;

                    &.smaller-size {
                        max-height: 90% !important;
                    }
                }

                .info {
                    position: static;
                    background: none;

                    .caption {
                        position: static !important;
                        padding-top: 20px;
                    }
                }
            }
        }
    }

    .other-listings {
        box-sizing: border-box;
        border-radius: $border-radius-default;
        background: $gray230;

        @media (min-width: $booking-module-breakpoint + 1) {
            margin-left: 15px;
        }

        header {
            padding: 10px;
            display: block !important;

            p {
                font-size: 85%;
                margin-bottom: 0;

                a {
                    color: $gray80;
                }
            }
        }
    }

    .click-to-call {
        margin-left: 15px;

        a {
            text-align: center;
            line-height: 50px;
            width: 100%;
            height: 50px;
            border-radius: 5px;
            cursor: pointer;
            color: #fff;
        }

        .tooltip-inner {
            white-space: pre-wrap;
            text-align: left;
            max-width: 300px;
        }

        .tooltip {
            max-width: 300px;
        }
    }

    .favorite {
        margin-left: 15px;

        @media (max-width: $booking-module-breakpoint) {
            margin-left: 0;
        }

        a {
            text-align: center;
            width: 100%;
        }
    }

    .map {
        border-radius: $border-radius-default;
        background: $gray230;

        .map-container {
            width: auto;
            height: 250px;

            @media (max-width: $iphone-landscape + 1) {
                height: 200px;
            }
        }

        @media (min-width: $booking-module-breakpoint + 1) {
            margin-left: 15px;
        }

        .bottom-heading {
            position: relative;
            box-sizing: border-box;
            padding: 0 15px;
            min-height: 35px;

            a {
                display: block;
                color: $gray120;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 25px;
            }

            span {
                position: absolute;
                right: 15px;
                top: 0;
                font-size: 70%;
            }
        }
    }

    .listing-photos {
        #listing-siblings-container {
            img {
                border-radius: $border-radius-bottom;
            }
        }

        .carousel-nav {
            width: 100%;
            max-width: $space-full-image-width;
            position: absolute;
            top: 45%;

            .carousel-control {
                color: $gray60;
                text-shadow: none;
                font-size: 20px;
                top: 45%;
                opacity: 0.8;
                background: $gray230 !important;
                height: 48px;
                width: 33px;
                padding-top: 20px;

                &.right {
                    left: auto;
                    right: 0;
                    border-radius: $border-radius-left;
                }

                &.left {
                    left: 0;
                    right: auto;
                    border-radius: $border-radius-right;
                }
            }
        }

        .carousel {
            margin-bottom: 0;

            ol.carousel-indicators {
                bottom: 10px;
                left: 0px;
                top: auto;
                right: auto;
                width: 50%;

                li {
                    width: 22px;
                    height: 22px;
                    border-radius: 15px;
                    text-indent: 0px;
                    background: transparent;
                    color: $gray160;
                    font-size: 16px;
                    text-align: center;
                    line-height: 22px;
                    border: 1px solid $gray160;
                    margin-left: 8px;
                    margin-top: 5px;
                    cursor: pointer;

                    &.active {
                        color: $gray245;
                        border: 1px solid $gray245;
                    }
                }

                @media (max-width: $booking-module-breakpoint-desktop) {
                    left: 30px;
                    bottom: 40px;
                    right: 35px;
                    width: auto;

                    li {
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        font-size: 14px;
                    }
                }
            }

            .carousel-inner {
                width: 100%;
                max-width: $space-full-image-width;

                .item {
                    width: 100%;
                    height: 100%;

                    img {
                        border-radius: $border-radius-default;
                        height: auto;
                    }
                }
            }

            &#photos-container {
                max-width: $space-full-image-width;
            }

            &#photos-container-enlarged {
                ol.carousel-indicators {
                    position: fixed;
                }

                .carousel-inner {
                    max-width: 100%;

                    .item {
                        width: 100%;

                        img {
                            margin-left: auto;
                            margin-right: auto;
                        }

                        .info {
                            .caption {
                                position: fixed;
                                right: 0;
                                left: 0;
                            }
                        }
                    }
                }

                .carousel-nav {
                    max-width: 100%;
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                box-sizing: border-box;
                opacity: 0.8;
                color: $gray60;
                background: $gray230 !important;
                display: block;
                text-align: center;
                text-shadow: none;
                font-size: 20px;
                width: 40px;
                height: 36px;
                border-bottom-left-radius: $border-radius-default;
                cursor: pointer;

                &:focus {
                    outline: none !important;
                }

                &::-moz-focus-inner {
                    border: 0;
                }

                &.close {
                    position: fixed;
                    border-bottom-left-radius: $border-radius-default;
                }
            }

            .info {
                box-sizing: border-box;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 15%;
                color: $theme_white;
                font-size: 13px;
                background-image: linear-gradient(rgba(250, 250, 250, 0), $black);
                border-radius: $border-radius-bottom;

                .caption {
                    position: absolute;
                    bottom: 15px;
                    padding-left: 5px;
                    padding-right: 5px;
                    right: 5px;
                    font-weight: bold;
                }
            }
        }
    }

    .container-row {
        width: 100%;

        .one-third.column {
            margin: 0;
            width: 33.3333%;

            @media (max-width: $booking-module-breakpoint-desktop) {
                width: 50%;
            }

            @media (max-width: $booking-module-breakpoint) {
                width: 100%;
                clear: both;
            }
        }

        .two-thirds.column {
            margin: 0;
            width: 66.6666%;

            @media (max-width: $booking-module-breakpoint-desktop) {
                width: 50%;
            }

            @media (max-width: $booking-module-breakpoint) {
                width: 100%;
                clear: both;
            }
        }
    }

    h1 {
        margin: -10px 0 20px;
        float: left;
    }

    .btn {
        @media (max-width: $booking-module-breakpoint) {
            font-size: 15px;
        }
    }

    .span4.pull-left {
        margin-left: 0;

        @media (min-width: 768px) {
            width: 34%;
        }
    }

    ul.icons {
        float: right;

        li {
            font-size: 1em;
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-left: 10px;

            a {
                display: block;
                color: $gray160;

                &:hover, &.hover {
                    color: $gray80;
                    transition: none;
                }
            }
        }

        @media (max-width: $ipad-width) {
            float: left;
        }
    }

    .social-buttons-wrapper {
        opacity: 0;
        display: block;
        overflow: hidden;
        transition: opacity .5s ease-in;

        &.active {
            opacity: 1;
        }

        @media (min-width: $booking-module-breakpoint) {
            margin: 0 0 0 15px;
        }

        li {
            float: left;
            margin: 0 15px 0 0;

            div {
                text-align: center;

                span {
                    margin: 0 !important;
                }

                & > :first-child {
                    line-height: 0 !important;
                    vertical-align: bottom !important;
                }
            }
        }
    }
}

.tab-pane#details {
    background: $theme_white;
    box-sizing: border-box;
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;

    .row-fluid {
        padding: $padding-default;
        box-sizing: border-box;
        background: $theme_white;
    }
}

.availability-rules {
    .custom-availability-rules {
        .day {
            overflow: hidden;
            margin-top: 6px;
            padding: 6px 0;

            &.error {
                padding: 6px 0px 10px 0px;
            }

            &.control-group {
                margin-bottom: 0px;
            }

            .day-name {
                margin-top: 10px;
            }

            .closed-flag {
                margin-top: 13px;
                margin-left: 15px;
            }

            .open-time,
            .close-time {
                position: relative;
            }

            .day-name,
            .open-time,
            .close-time,
            .closed-flag {
                float: left;
                width: 90px;
                height: 28px;

                @media (max-width: $iphone-landscape) {
                    width: 110px;
                }
            }

            @media (max-width: $iphone-landscape) {
                .day-name {
                    clear: both;
                    width: 100%;
                }
            }
        }

        select {
            margin-top: -6px;
        }
    }
}
