.simple-schedule-form {

  .bootstrap-select {
    width: auto !important;
  }

  .row:first-child {
    margin-top: 0px;
  }

  .every_hours {
    input {
      width: auto;
    }
  }

  label.checkbox {
    clear: both;
    display: block;
  }
}

.exclude-rule-fields {
  .bootstrap-select {
    width: auto !important;
  }
}
