.box header {

  .button-links {
    width: 100%;
    text-align: center;

    ul {
      margin: 0px auto;

      li {
        display: inline;
        margin-right: 10px;
        text-align: center;

        a {
          width: 150px;
        }
      }

      @media (max-width: $ipad-width) {
        li {
          display: block;

          a {
            width: 100%;
          }

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
