$white:            #ffffff;
$red:              #ef4a53;
$dark-gray:        #333332;
$gray:             #666665;
$light-gray:       #b3b3b1;
$lighter-gray:     #dededc;
$very-light-gray:  #F9F9F9;


$iphone-landscape: 480px;
$ipad-width: 767px;
$screen-md: 992px;
$blog-content-width: 850px;
