.nm-new-credit-card-form, .payment-source-form {
  margin: 30px 0;

  .control-group {
    .control-label {
      display: block;
      float: none;
      width: 100%;
      text-align: left;
      margin-top: 0;
    }

    .controls {
      position: relative;

      .form-horizontal & {
        margin-left: 0;
      }

      select.hasCustomSelect {
        position: absolute;
        left: 0;
        top: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.nm-credit-card-fields, .nm-payment-subscription-fields {
  .control-group {
    &.nm-credit-card-option-select, &.payment-source-option-select {
      .controls {
        margin-left: 0;

        span.radio {
          display: block;
        }
      }
    }
  }
}

.nm-new-credit-card-form-header {
  &:after {
    display: table;
    content: "";
    clear: both;
  }

  h3:before {
    font-family: FontAwesome;
    content: "\f023";
    margin-right: 10px;
  }

  img {
    display: block;
    margin: 20px auto;
  }

  @media (min-width: 750px) {
    margin: 0 0 20px;

    h3 {
      float: left;
    }

    img {
      float: right;
      margin: 0;
    }
  }
}

.nm-new-credit-card-form-name-container {
  @media (min-width: 600px) {
    display: flex;
    justify-content: space-between;

    .form-group, .control-group {
      width: 49%;
    }
  }
}

.nm-new-credit-card-form-exp-date-container {
  @media (min-width: 600px) {
    display: flex;
    justify-content: space-between;

    .form-group, .control-group {
      width: 32%;

      label span {
        position: absolute;
        left: -999em;
      }
    }
  }
}

.accordion-body.collapse {
  border-bottom: 1px solid #E2E2E2;
}
