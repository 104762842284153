section.reservations{

  .first-br {
    display: none;
  }

  .export-icon{

    span {
      padding-left: 10px;
    }

    a {
      color: $gray80;
      font-size: 24px;

      &:hover {
        color: $gray120;
      }
    }
  }
}

section#reservations {

  ul {
    li {
      list-style: none outside none;
      overflow: hidden;

      a {
        width: 140px;
      }

      @media (max-width: $ipad-width) {
        a {
          width: 100%;
        }
      }
    }
  }

  .reservation-list {

    &.just-booked {
      background-color: $pure-white;
    }

    .no-reservations {
      border: 0;
      text-align: center;
      padding: 20px 0;
      margin: 0;
    }

    ul {

      li {
        overflow: visible;
        font-size: 16px;
        border-radius: $border-radius-default;
        border: 1px solid $light-gray;
        margin-top: 10px;
        color: $gray80;
        font-weight: 600;

        a {
          text-decoration: underline !important;
          color: $gray80;

          &.ico-mail {
            text-decoration: none !important;
            padding-left: 5px;
          }

          &:hover {
            color: $gray210;
          }
        }

        .reservation-details {

          .actions {

            .reservation-action {
              padding: 0px;
              text-align: center;

              &.request-payment {
                line-height: 13px;
                color: white;
                padding: 2px;
                font-size: 12px;
              }
            }

            input, a {
              border-radius: $border-radius-left;
              width: 100%;
              text-align: left;
              margin-top: 0px;
              margin-bottom: 0px !important;
            }

            input:not(.btn-green) {
              &:hover {
                background: $gray210;
              }
            }

            a {
              padding: 0 22px;
              text-decoration: none !important;

              &:hover {
                color: $gray80;
                background: $gray190;
              }
            }
          }
        }

        .reservation-status {
          background: $gray230;
          position: absolute;
          height: 100%;
          width: 50px;

          .tooltip-spacer {
            color: transparent;
            display: block;
            position: absolute;
            top: 0px;
            width: 20px;
          }

          .icon-container {
            position: absolute;
            margin-top: 48px;
            margin-left: 16px;

            a {
              text-decoration: none !important;

              &:hover {
                color: $gray80;
              }
            }
          }
        }

        .reservation-information {
          padding-left: 60px;
          padding-bottom: 15px;
          margin-left: 0;
          padding-top: 25px;

          & > div {
            padding-top: 15px;
          }

          @media (max-width: $ipad-width) {
            & > .split-2 {
              .actions {
                padding-top: 15px;
              }
            }
          }

          span.ico-download {
            margin-left: 3px;
          }

          .dates {
            color: $gray160;

            a.hours {
              &:hover {
                text-decoration: none !important;
              }
              color: $gray160 !important;
            }
          }

          span:not(.ico-user) {
            display: block;
            color: $gray160;
            overflow: hidden;
            font-size: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.ical {
              display: inline;
            }

            a {
              text-decoration: none !important;

              &:hover {
                color: $gray80;

                span {
                  color: $gray80;
                }
              }
            }
          }


          span.address {
            a {
              color: $gray160;
              text-decoration: underline !important;

              &:hover {
                text-decoration: none !important;
                color: $gray210;
              }
            }
          }

          .split-2 {
            padding-top: 0px;

            .reservation-action:nth-of-type(1) {
              margin-bottom: 5px !important;
            }
          }
        }

        .reservation-text-information {
          position: relative;
          width: 100%;
        }
      }
    }
  }
}
