section.pages {
  #hero {
    margin-top: -30px;
    max-height: 500px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }
  .hero-title {
    font-size: .7em;
  }

  .container-fluid {
    h2, h3 {
      margin-bottom: 20px;
    }

    ul {
      list-style-type: disc;
      margin: 0 0 12px 20px;
    }
  }
}
