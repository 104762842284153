.legend {
  border: 1px solid black;
  float: right;
  clear: both;

    .title {
      display: block;
      margin: 0.5em;
      border-style: solid;
      border-width: 0 0 0 1em;
      padding: 0 0.3em;
    }
}
