.chzn-container-multi ul.chzn-choices,
.chzn-container-single{
  @include form-field;
  padding-right: 40px;
}

.chzn-container-single {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  border: none !important;

  .chzn-search input {
    width: 100% !important;
    background-image: none !important;
  }

  a {
    box-sizing: border-box;
    height: 49px !important;
    padding: 14px 8px 8px 8px !important;
    border: none !important;
    background: none !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    span {
      font-size: 18px;
    }
  }

}


.chzn-drop {
  top: 49px !important;
  width: 100% !important;
  border: 0px !important;
  font-size: 18px;

}

.chzn-container-multi .chzn-choices .search-choice {
  margin: 4px 0 0 5px;
  padding: 2px 20px 6px 5px;
}

.chzn-container-multi .chzn-choices .search-field {
  margin-left: 5px;
}

ul.chzn-choices.chzn-choices-active{
  @include form-field-focus;
}

// overwrite default width - make its width dynamic instead of constant
.control-group .chzn-container-multi{
  width: auto !important;
  display: block !important;

  ul li {
    span, input {
      font-size: 18px;
    }

    &.search-field input {
      height: 100%;
      padding: 0px;
    }
  }
}
