body.buy-sell-theme {
    .alert.success {
        p {
            color: #ffffff;
        }

        background-color: #5dc988;
    }

    .alert.deleted {
        p {
            color: #ffffff;
        }

        background-color: #5dc988;
    }

    .alert.notice {
        p {
            color: #ffffff;
        }

        background-color: #5dc988;
    }

    .alert.error {
        p {
            color: #ffffff;
        }

        background-color: #e85c41;
    }

    .alert.warning {
        p {
            color: #ffffff;
        }

        background-color: #ffc840;
    }

    margin: 0;
    background: #f1f1f1;

    header {
        height: 60px;
        background: black;
    }

    /*===== GENERAL STYLES =====*/
    form {
        width: 100%;
    }

    p, td {
        color: #555555;
    }

    p, h1, h2, h3, h4, h5, a, label, td {
        font-weight: 600;
        margin: 0;
    }

    h1, h2, h3, h4, h5 {
        color: #3a3a3a;
        font-weight: 600;
    }

    h1 {
        font-size: 40px;
    }

    td {
        font-size: 16px;
        font-weight: 300;
    }

    td.country-price {
        padding-left: 20px;
    }

    section .container-fluid {
        width: 58%;
        margin: 0 auto;
        padding: 0;
    }

    .inline {
        display: inline-block;
    }

    .divider {
        border-top: solid #CACBD3 1px;
    }

    /*===== BUTTONS AND INPUTS =====*/

    .btn {
        width: 100%;
        padding: 7px 0;
        border-radius: 0;
        text-align: center;
        font-size: 16px;
        vertical-align: top;
        cursor: pointer;
    }

    .btn.blue {
        background: $blue;
        color: #fff;
    }

    .btn.gray {
        background: #f4f4f4;
        color: #555555;
    }

    .btn.green {
        background: #5dc998;
        color: #fff;
    }

    .btn.red {
        background: #e85c41;
        color: #fff;
    }

    .btn.disabled {
        background: #b6b6b6;
        color: #fff;
        cursor: default;
    }

    .btn.login {
        background: #001856;
        color: #fff;
        padding: 7px 150px;
        margin: 20px 0;
    }

    .customSelect {
        background: #fff url("/assets/themes/buy_sell/arrow.png") right no-repeat;
        border: solid #D4D4D4 1px;
        font-size: 14px;
        padding: 0 5px;
        width: 60px;
    }

    .padding11 {
        padding: 11px 0;
    }

    /*===== PRODUCT USER BADGE =====*/
    .product-profile-badge {
        .vendor-profile {
            padding: 30px 40px;
            background: #fff;
            border-bottom: solid #D5D5D5 2px;
            width: 29%;
            vertical-align: top;
            float: right;
            margin-top: 50px;
        }

        @media (max-width: 924px) {
            .vendor-profile {
                float: none;
                width: 80%;
                margin: 50px auto;
            }
        }

        @media (max-width: 585px) {
            .vendor-profile {
                width: 80%;
            }
        }
    }

    /*===== PRODUCT IMAGES =====*/

    .product-img-large img {
        width: 100%;
    }

    .product-thumbnail {
        width: 100%;
    }

    .thumbnail-row {
        margin-top: 20px;
    }

    .thumbnail-row img {
        margin-right: 17px;
        width: 100px;
    }

    /*===== PAGINATION =====*/

    .pagination {
        text-align: center;
    }

    .pagination ul {
        box-shadow: none;
    }

    .pagination ul li a {
        background: transparent;
        border: none;
        float: inherit;
    }

    .pagination img {
        float: inherit;
    }

    .pagination a {
        color: #5dbded;
    }

    .pagination .page-link {
        padding-top: 10px;
        vertical-align: super;
    }

    .pagination .page-link.active {
        color: #000;
    }

    /*===== PRODUCT PRICE AND RATING =====*/

    .product-rating {
        margin-top: 35px;
    }

    .rating p {
        display: inline-block;
        margin-right: 25px;
        font-weight: 300;
    }

    .product-head label {
        font-weight: 300;
        margin-right: 10px;
    }

    .product-head {
        background-color: #ffffff;
        margin-top: -30px;
        padding-top: 30px;

        .btn.blue {
            margin-top: 30px;
        }

        .btn.disabled {
            margin-top: 30px;
        }
    }

    /*===== SOCIAL =====*/

    .product-social-wrapper {
        border: solid #f4f4f4 1px;
        padding: 20px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .product-social-wrapper .btn.gray {
        margin-bottom: 15px;
    }

    .product-social-wrapper .social-icon {
        margin-right: 3px;
    }

    /*===== TABS AND TABBED CONTENT =====*/
    .product-details {
        background: #f4f4f4;
        padding: 40px 0;
    }

    .tab-content-container {
        float: left;
        width: 60%;
        border-bottom: solid #D5D5D5 2px;
    }

    .nav {
        margin: 0;
    }

    .nav-tabs li a:hover {
        background: transparent;
        border: solid transparent 1px;
    }

    .nav-tabs > .active > a, .nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus {
        background: #fff !important;
        border: solid transparent 1px;
        color: #000;
    }

    .nav-tabs li a {
        color: #3a3a3a;
        padding: 22px 25px;
        font-weight: 600;
    }

    .tab-content article {
        background: #fff;
        padding: 30px 40px;
    }

    .tab-content article p {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 25px;
    }

    /*===== VENDOR PROFILE =====*/

    .vendor-info {
        vertical-align: top;
        padding: 25px 10px;
    }

    .vendor-info p {
        font-size: 14px;
        font-weight: 300;
    }

    .vendor-pic {
        width: 100px;
        display: inline-block;
    }

    .verifications .row-fluid {
        margin: 15px 0;
    }

    .verifications p {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        margin: 5px 5px;
        width: 45%;
    }

    .verifications img {
        padding-right: 10px;
        vertical-align: text-top;
    }

    .vendor-profile .tn {
        margin: 15px 0 30px 0;
    }

    .vendor-rating {
        margin: 15px 0 30px 0;
    }

    .vendor-rating a {
        color: #5dbded;
        font-size: 14px;
        vertical-align: top;
        padding-left: 5px;
    }

    .profile {
        padding-bottom: 30px;

        .page-wrapper {
            width: 1200px;
        }

        .profile-content {
            box-sizing: border-box;
            float: right;
            width: 65%;
        }

        /*===== VENDOR PROFILE =====*/

        .user-badge {
            float: left;
            width: 25%;
        }

        .user-badge a {
            color: #5dbded;
            font-size: 14px;
            vertical-align: top;
        }

        .vendor-profile {
            box-sizing: border-box;
            padding: 30px 40px;
            background: #fff;
            border-bottom: solid #D5D5D5 2px;
            width: 100%;
            margin-bottom: 40px;
            overflow-wrap: break-word;
        }

        .shop-info p {
            font-size: 16px;
            margin-bottom: 20px;
        }

        .verifications li {
            font-size: 12px;
        }

        .edit-link {
            float: right;
        }

        .submit-bar {
            text-align: center;
        }

        .submit-bar .btn {
            width: 45%;
            margin-left: 20px;
        }

        /*===== VENDOR LISTINGS ===== */

        .profile-listings {
            box-sizing: border-box;
            background: #fff;
            padding: 30px;
            border-bottom: solid #D5D5D5 2px;
        }

        .profile-listings .row-fluid {
            margin-bottom: 40px;

            &.compact {
                margin-bottom: 10px;
            }
        }

        .item-img {
            max-height: 186px;
            overflow: hidden;
        }

        .item img {
            width: 100%;
            min-height: 186px;
        }

        .item .details {
            background: #fff;
            margin-top: -8px;
            padding: 5px 15px;
        }

        .item .details p {
            font-size: 14px;
        }

        .details .name {
            float: left;
            color: #858585;
            font-weight: 300;
        }

        .details .price {
            float: right;
            color: #5dc988;
            font-weight: 300;
        }

        .btn.cta {
            width: 240px;
        }

        article.post {
            border-bottom: 1px solid #D5D5D5;
            padding: 0;
            margin-bottom: 20px;

            .post-head {
                margin-bottom: 20px;
            }

            .post-body {
                clear: both;
            }

            p {
                font-size: 14px;
            }

            p.date {
                margin-bottom: 0;
            }
        }

        @media (max-width: 1555px) {
            .page-wrapper {
                width: 90%;
            }
        }

        @media (max-width: 1166px) {
            .page-wrapper {
                width: 100%;
            }

            .user-badge {
                float: none;
                width: 80%;
                margin: 0 auto;
                padding: 30px;
                margin-bottom: 20px;
            }

            .profile-content {
                float: none;
                width: 80%;
                margin: 0 auto;
            }

            .profile-listings {
                width: 100%;
            }
        }
    }

    /*===== REVIEWS =====*/
    .reviews-summary {
        margin-bottom: 20px;
    }

    .rating-averages {
        margin-bottom: 35px;
    }

    .see-more {
        text-align: center;
        padding: 30px 0;
        border-top: solid #cacbd3 1px;
    }

    .see-more a {
        color: #5dbded;
        font-size: 14px;
    }

    .review-date {
        float: right;
        font-size: 12px;
        color: #858585;
    }

    .reviews-dropdown {
        display: inline-block;

        .title {
            font-size: 14px;
            color: #5dbded;
        }

        .ico-chevron-down {
            font-size: 14px;
            margin-left: 5px;
            color: gray;
        }

        .dropdown-menu {
            min-width: 200px;

            li {
                padding: 0 15px;
                cursor: pointer;

                &:hover {
                    background-color: #f1f1f1;
                }
            }
        }
    }

    .cart-view {
        margin-top: -30px;
        padding-top: 30px;
        background: #ffffff;

        /*===== BUTTONS AND INPUTS =====*/
        .button-row {
            margin-bottom: 40px;
        }

        .btn {
            width: inherit;
            padding: 7px 25px;
            font-size: 14px;
            font-weight: 300;
        }

        .checkout {
            text-align: right;
        }

        .checkout .btn.blue {
            margin: 20px 0 80px 0;
            padding: 7px 50px;
        }

        .checkout img {
            margin-right: 10px;
        }

        .checkout .express-checkout img {
            margin-top: 20px;
        }

        /*===== CART LAYOUT =====*/
        .cart-head h3 {
            margin-bottom: 40px;
        }

        .cart-head .btn.green {
            float: right;
        }

        /*===== ITEM LISTS =====*/
        .cart-item-list {
            border: solid #f0f0f0 1px;
        }

        .list-body {
            padding: 20px 30px 0px 30px;
        }

        .list-body-header p {
            font-size: 16px;
        }

        .list-head, .list-footer {
            background: #efefef;
            padding: 15px 35px;
        }

        .clear-all {
            float: right;
        }

        .clear-all p {
            margin-right: 15px;
        }

        .list-footer {
            text-align: right;
        }

        .list-footer h3 {
            color: #5dbded;
            font-weight: 300;
            margin: 0;
        }

        .list-head p {
            display: inline-block;
        }

        .list-head a {
            font-size: 16px;
        }

        .list-item {
            padding: 10px 0;
        }

        .list-item p {
            font-size: 16px;
        }

        .list-item.additional-item {
            border-top: solid #f0f0f0 1px;
        }

        .list-item .heading {
            margin-bottom: 35px;
        }

        .item img {
            float: left;
            margin-right: 15px;
        }

        .value {
            margin-top: 35px;
        }

        /*===== MEDIA QUERIES =====*/
        @media (max-width: 767px) {
            .value {
                margin: 0;
            }

            .list-item .span2 {
                margin: 20px 0;
            }

            .list-item .span2 p:nth-child(1) {
                color: #000;
                font-weight: 600;
            }

            .page-wrapper {
                width: 90%;
            }

            .checkout {
                text-align: center;
            }

            .item img {
                float: none;
            }
        }
    }

    .checkout-wizard {
        &:after {
            content: "";
            clear: both;
            display: table;
        }

        td {
            font-weight: 300;
            font-size: 15px;
        }

        thead th {
            font-weight: 400;
            font-size: 15px;
            padding-bottom: 20px;
        }

        .checkout-wizard-main {
            box-sizing: border-box;
        }

        .checkout-wizard-aside {
            box-sizing: border-box;
            background: #fff;
            padding: 20px;
            border-bottom: solid #d5d5d5 2px;
            margin-bottom: 25px;
        }

        .checkout-wizard-steps {
            margin: 30px 0;
            padding: 0;
            text-align: center;

            li {
                display: inline-block;
                list-style: none;
                color: $blue;
                font-size: 1.5em;
                line-height: 1.4;
                font-weight: 300;
            }

            li + li {
                &:before {
                    content: "»";
                    margin: 0 0.5em;
                    font-weight: normal;
                }
            }

            .current {
                font-weight: 600;
            }

            .current ~ li {
                color: $gray;
            }
        }

        .checkout-wizard-actions {
            margin: 30px 0;
        }

        @media (min-width: 792px) {
            .checkout-wizard-aside {
                float: right;
                width: 300px;
            }

            .checkout-wizard-aside + .checkout-wizard-main {
                float: left;
                width: calc(100% - 320px);
            }
        }

        .delivery-options {
            .radio {
                display: block;
                max-width: 845px;
            }

            .controls {
                margin-left: 0px !important;
            }
        }

        .inline {
            display: inline-block;
        }

        .page-wrapper {
            padding: 60px 0;
            margin: 0 auto;
        }

        section.checkout-form {
            background: #fff;
            padding: 40px;
            border-bottom: solid #d5d5d5 2px;
            margin-bottom: 25px;
        }

        section.checkout-form h2 {
            color: #5dbded;
            font-weight: 300;
            margin-bottom: 40px;
        }

        section.checkout-form #options {
            padding-top: 10px;
            padding-bottom: 5px;
        }

        section.checkout-form#checkout-extra-fields {
            .control-fileupload .btn-blue {
                background: $blue none repeat scroll 0 0;
                color: $white;
            }

            .control-fileupload .fileupload .fileinput-button {
                cursor: pointer;
                margin-bottom: 8px;
                text-align: center;
                height: 35px;
            }

            .control-fileupload .btn {
                background-image: none;
                border: 1px solid transparent;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.42857;
                margin-bottom: 0;
                padding: 6px 12px;
                text-align: center;
                vertical-align: middle;
                white-space: nowrap;
                border-radius: 0;
            }
        }

        h2.page-heading {
            margin-bottom: 30px;
        }

        .divider {
            border: 1px solid #d5d5d5;
            margin: 40px 0;
        }

        .whats-this p {
            font-size: 14px;
        }

        .whats-this-link {
            color: #5dbded;
        }

        #whats-this-target.in {
            background: #f2f2f2;
            padding: 10px;
        }

        .order-complete {
            background: #5dbded;
            padding: 10px;
        }

        .order-complete p {
            color: #fff;
            font-weight: 300;
        }

        .shipping-details {
            border: solid #fafafa 2px;
            margin-bottom: 15px;
        }

        .shipping-details .heading {
            margin-bottom: 20px;
            color: #858585;
            font-weight: 300;
        }

        .shipping-options {
            padding: 10px 10px;
        }

        .item-list {
            background: #f2f2f2;
            padding: 10px 40px;
        }

        .item-list .row-fluid {
            margin-bottom: 20px;
        }

        .item-list p {
            font-weight: 300;
            font-size: 16px;
        }

        /*===== BUTTONS =====*/
        .button-row {
            margin-bottom: 40px;
            text-align: center;
        }

        .button-row .btn {
            float: right;
        }

        .button-row.complete {
            text-align: center;
        }

        .button-row.complete .btn {
            float: none;
        }

        .btn {
            padding: 7px 25px;
            font-size: 14px;
            font-weight: 300;
            width: inherit;
        }

        .btn.blue {
            background: #5dbded;
            color: #fff;
        }

        .btn.gray {
            background: #909090;
            color: #fff;
        }

        .btn.back {
            padding: 7px 125px;
        }

        .btn.next {
            padding: 12px 0px;
            cursor: pointer;
        }

        .btn.back {
            margin-right: 50px;
        }

        .action-row {
            text-align: right;
            margin-bottom: 15px;
        }

        .action-row .btn {
            margin-left: 15px;
        }

        /*===== INPUTS AND FIELDS =====*/

        .customSelect {
            border: solid #D4D4D4 1px;
            border-radius: 0;
            background: #fff url("/assets/themes/buy_sell/arrow.png") right no-repeat;
            width: 100%;
            padding: 5px 0 5px 5px;
            font-size: 14px;
        }

        .hasCustomSelect {
            background: #fff url("/assets/themes/buy_sell/arrow-down.png") right no-repeat;
            border: solid #D4D4D4 1px;
            font-size: 14px;
            padding: 0 5px;
            width: 60px;
            display: inline-block;
        }

        label, .input-container {
            display: inline-block;
        }

        label {
            vertical-align: top;
            font-weight: 600;
            color: #555555;
            font-size: 16px;
        }

        form input[type="text"] {
            background: #fff;
            border: solid #D4D4D4 1px;
            border-radius: 0;
            width: 100%;
            padding: 5px 10px;
            font-size: 14px;
        }

        .radio-select p {
            display: inline-block;
            font-size: 14px;
        }

        .radio-select span {
            color: #5dbded;
            font-size: 14px;
        }

        .radio-select img {
            vertical-align: middle;
            margin-left: 20px;
        }

        form .row-fluid {
            margin-bottom: 20px;
        }

        ::-webkit-input-placeholder {
            color: #cccccc;
        }

        :-moz-placeholder {
            /* Firefox 18- */
            color: #cccccc;
        }

        ::-moz-placeholder {
            /* Firefox 19+ */
            color: #cccccc;
        }

        :-ms-input-placeholder {
            color: #cccccc;
        }

        /*===== TABLES ===== */
        table, table.payment-summary {
            width: 100%;
        }

        .payment-summary tbody td {
            padding: 5px 0;
        }

        .payment-summary thead th,
        .payment-summary thead td {
            padding: 10px 0;
            font-weight: bold;
            color: #333;
        }

        .payment-summary tfoot td,
        .payment-summary tfoot th {
            font-weight: bold;
            padding: 10px 0;
            border-top: 1px solid #ccc;
            font-size: 0.85em;
            color: $blue;
        }

        table.payment-totals {
            width: 55%;
        }

        .payment-totals td {
            text-align: right;
        }

        .payment-totals .total {
            font-size: 18px;
            font-weight: 400;
            color: #3a3a3a;
        }

        .document-requirements {
            margin-top: 30px;

            .title {
                font-weight: bold;
            }

            .upload {
                .btn-upload-payment-document {
                    color: black;
                    background-color: white;
                    border: 1px solid #eee;
                    width: 30%;
                    padding: 10px 20px;
                }

                span.file-name {
                    padding-left: 20px;
                }
            }
        }

        /*===== BREADCRUMBS =====*/
        .breadcrumbs {
            background: #d4effc;
        }

        .breadcrumbs ul {
            list-style-type: none;
            width: 100%;
        }

        .breadcrumbs li {
            display: inline-block;
            text-align: center;
            padding: 20px 0;
            width: 33%;
            position: relative;
        }

        .breadcrumbs li.active {
            background: #5dbded;
            color: #fff;
        }

        .breadcrumbs li.last {
            float: right;
        }

        .breadcrumbs li.active.first:after {
            content: "";
            border-top: 34px solid transparent;
            border-bottom: 34px solid transparent;
            border-left: 20px solid #5dbded;
            position: absolute;
            right: -20px;
            top: 0;
        }

        .breadcrumbs li.active.middle:after {
            content: "";
            border-top: 34px solid transparent;
            border-bottom: 34px solid transparent;
            border-left: 20px solid #5dbded;
            position: absolute;
            right: -20px;
            top: 0;
        }

        .breadcrumbs li.active.middle:before {
            content: "";
            border-top: 34px solid transparent;
            border-bottom: 34px solid transparent;
            border-left: 20px solid #d4effc;
            position: absolute;
            left: 0;
            top: 0;
        }

        .breadcrumbs li.active.last:before {
            content: "";
            border-top: 34px solid transparent;
            border-bottom: 34px solid transparent;
            border-left: 20px solid #d4effc;
            position: absolute;
            left: 0;
            top: 0;
        }

        /*===== MEDIA QUERIES =====*/
        @media (max-width: 792px) {
            .page-wrapper {
                width: 90%;
            }

            .button-row .btn {
                margin: 20px 0;
                width: 40%;
                padding: 7px 0;
                float: none;
            }

            .btn.next {
                padding: 12px 0px;
                cursor: pointer;
            }

            .checkout-form .container-fluid {
                width: 90%;
            }

            .btn.login {
                width: 100%;
                padding: 7px 0;
            }
        }

        @media (max-width: 400px) {
            .breadcrumbs li {
                width: 30%;
            }

            .breadcrumbs li.active:before, .breadcrumbs li.active:after {
                border-left: 0 !important;
            }
        }
    }

    /*===== MEDIA QUERIES =====*/
    @media (max-width: 1594px) {
        section .container-fluid {
            width: 95%;
        }

        .product-social-wrapper {
            text-align: center;
        }
    }

    @media (max-width: 924px) {
        .user-badge {
            float: none;
            width: 50%;
            margin: 50px auto;
        }

        .tab-content-container {
            width: 100%;
            float: none;
        }
    }

    @media (max-width: 585px) {
        body.buy-sell-theme .profile .user-badge {
            width: 90%;
            padding: 0;
        }

        .nav-tabs li a {
            padding: 15px 7px;
            font-size: 12px;
        }

        .verifications .row-fluid {
            margin: 0;
        }

        .date {
            float: left;
        }
    }

    /*===== SEARCH BAR =====*/
    .search-view {
        .crumbs a {
            font-size: 14px;
            color: #858585;
            font-weight: 300;
        }

        .page-wrapper {
            padding: 60px 0;
            margin: 0 auto;
            margin-top: 60;
        }

        .slider-float-right {
            float: right;
        }

        .search-bar form input, .search-bar form input:focus {
            width: 50%;
            padding: 2px 10px;
            border-radius: 0;
            background: #fff;
            font-size: 14px;
            border: none;
            margin: 0;
            height: 36px;
            font-weight: 300;
        }

        .search-icon {
            vertical-align: middle;
            margin-left: -5px;
            margin-top: 1px;
        }

        .btn.search {
            width: initial;
            height: 36px;
            padding: initial;
        }

        &.search-view-listing .btn.search {
            padding: 0;
            width: 36px;
        }

        .search-bar.mobile form input {
            width: 70%;
            margin-bottom: 20px;
        }

        .search-bar.mobile form {
            display: none;
            width: 100%;
        }

        /*===== NAV =====*/
        nav ul {
            margin-bottom: 30px;
        }

        .categories {
            margin-bottom: 40px;
        }

        .nav-heading {
            font-weight: 300;
            color: #000;
        }

        .nav-item a {
            font-size: 14px;
            color: #858585;
            font-weight: 300;
            padding-left: 20px;
        }

        .nav-item a.active {
            color: #5dbded;
            font-weight: 400;
        }

        .categories-select {
            display: block;
        }

        .categories-select img {
            display: none;
        }

        /*===== SORTING =====*/
        .sort-wrapper {
            float: right;
        }

        .sort-wrapper * {
            font-size: 14px;
            font-weight: 300;
        }

        .sort label {
            margin-left: 30px;
        }

        .customSelect {
            margin: 0;
            border: inherit;
        }

        .customSelect:hover {
            cursor: pointer;
        }

        .customSelect {
            color: #5dbded;
            background: url("/assets/themes/buy_sell/arrow-down.png") right no-repeat;
        }

        .sort-price .customSelect {
            width: 120px;
        }

        /*===== RESULTS =====*/
        .search-breadcrumbs {
            float: left;
        }

        .search-results .row-fluid {
            margin-bottom: 40px;
        }

        .item-img {
            overflow: hidden;
            max-height: 186px;
        }

        .item img {
            width: 100%;
            min-height: 186px;
        }

        .item .details {
            background: #fff;
            padding: 5px 15px;
        }

        .item .details p {
            font-size: 14px;
        }

        .details .name {
            float: left;
            color: #858585;
            font-weight: 300;
            width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .details .price {
            float: right;
            color: #5dc988;
            font-weight: 300;
            width: 25%;
            text-align: right;
        }

        /*===== PAGINATION =====*/

        .pagination {
            text-align: center;
        }

        .pagination ul {
            box-shadow: none;
        }

        .pagination ul li a {
            background: transparent;
            border: none;
            float: inherit;
        }

        .pagination img {
            float: inherit;
        }

        .pagination a {
            color: #5dbded;
        }

        .pagination .page-link {
            padding-top: 10px;
            vertical-align: super;
        }

        .pagination .page-link.active {
            color: #000;
        }

        /*===== MEDIA QUERIES=====*/
        @media (max-width: 767px) {
            .page-wrapper {
                width: 80%;
            }

            .search-results .row-fluid {
                margin: 0;
            }

            .search-results .span4 {
                margin-bottom: 30px;
            }

            .search-breadcrumbs, .sort-wrapper {
                float: none;
            }

            .sort-wrapper {
                margin-bottom: 15px;
            }

            .sort {
                display: block;
            }

            .sort label {
                margin: 0;
            }

            .search-bar.header {
                display: none;
            }

            .search-bar.mobile {
                display: block;
            }

            .search-bar.mobile form {
                display: inline-block;
            }

            .categories {
                margin-bottom: 30px;
                display: inline-block;
            }

            .categories-select img {
                display: inline-block;
            }
        }
    }

    .checkout .button-row {
        .next {
            width: 100%;

            button {
                width: 100% !important;
            }
        }

        .go-back a, .go-back span {
            width: 100%;
        }
    }

    .edit-product-inline {
        h2 {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .all-inline {
            overflow: hidden;
        }

        .form-horizontal .control-group {
            clear: none;
            float: left;

            .control-label {
                width: auto;
            }

            .controls {
                float: left;
                margin-left: 0px;

                input {
                    margin-left: 0px;
                }
            }
        }

        .btn.medium {
            height: 43px;
            padding-top: 0px;
            margin-top: 0px;
        }

        .currency {
            font-size: 1.5em;
            float: left;
            margin-top: 11px;
        }
    }

    .modal-content .btn[type="submit"] {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 50px;
        height: auto;
    }

    .user-profile.container-fluid {
        margin-bottom: 40px;

        header {
            background: none;
            height: auto;
            position: relative;
        }

        .user-profile__header {
            background: #dfdfdf;
            padding: 15px;
        }

        .tab-content article {
            background: #efefef;
        }
    }
}
