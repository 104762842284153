html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.dnm-page-body {
  position: relative;
  min-height: 100%;
  height: auto;
}

.dnm-page-body.modal-body-wrapper {
    position: fixed;
}

.content-wrapper {
  min-height: 100%;
  height: auto;

  .dnm-page {
    padding-top: 90px;

    &.with-sub-navbar {
      padding-top: 59px;
    }

    &.no-navbar {
      padding-top: 0;
    }
  }

  .footer-push {
    height: $footer-height+60;

    &.white-label-footer-push {
      height: $footer-height;
    }
  }
}

.footer-wrapper {
  margin-top: -$footer-height;
  box-sizing: border-box;
  min-height: $footer-height;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .container-fluid {
    padding-top: 25px;
  }

  @media (max-width: $iphone-landscape) {
    min-height: $footer-height + 50;
  }

  @media (max-width: $ipad-width) {
    min-height: $footer-height + 50;
  }
}

@media (max-width: $ipad-width) {
  .mobile-clearfix {
    *zoom: 1;
    &:before,
    &:after {
      display: table;
      content: "";
      // Fixes Opera/contenteditable bug:
      // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
      line-height: 0;
    }
    &:after {
      clear: both;
    }
  }
}
