body.careers {
  font-size: 20px;;
  background: $gray220;
  color: $gray60 !important;
  line-height: 28px;

  h1 {
      font-size: 50px;;
      margin: 10px 0 20px;
      color: $gray80;
      font-weight: 900;
    }

  h2 {
    font-size: 40px;;
    margin: 10px 0 30px;
    color: $gray80;
  }

  h3 {
    font-size: 30px;;
    color: $gray80;
  }

  img {
    margin: 0 auto 20px;
    display: block;
  }

  .btn.btn-large {
    width: 100%;
    margin: 2em auto;
    text-align: center;
  }
}
