.multiselect {
  position: relative;
  display: inline-block;
  height: 26px;
  text-align: left;

  .collapsed {
    padding: 4px 8px;
    border-radius: $border-radius-default;
    background: $gray230 url("/assets/ui/arrow-down.png") 95% center no-repeat;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
    opacity: .8;
    transition: opacity .15s ease-in;

    &:hover {
      background: darken($gray230, 10%) url("/assets/ui/arrow-down.png") 95% center no-repeat;
      opacity: 1;
    }
  }

  .expanded {
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    z-index: $absolute-top-z-index + 999;
    border-radius: $border-radius-default;
    background: lighten($dark-gray, 60%);

    .summary,
    .item {
      padding: 0 8px;
      color: $black;
      font-size: 12px;
      cursor: pointer;
    }

    .summary {
      border-bottom: 1px solid $gray160;
      background: $gray220 url("/assets/ui/arrow-down.png") 95% center no-repeat;
      font-size: 16px;
      line-height: 26px;
      padding: 4px 8px;
      border-radius: $border-radius-default;
    }

    .items {
      overflow: visible;

      &.long {
        overflow-y: scroll;
        height: 200px;
      }
    }

    .item {
      padding: 6px 8px;
      line-height: 14px;
      overflow: hidden;

      input {
        float: left;
      }

      label {
        float: none !important;
        margin: 0;
        margin-left: 22px;
        font-size: 12px !important;
        line-height: 18px;
        margin-right: 0 !important;
      }
    }
  }
}
