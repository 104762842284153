.navbar {

  .navbar-inner {
    background: $black;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 0 0 20px;
    height: 60px;

    #header_links {
      float: none;
      position: absolute;
      right: 0px;
      background-color: $theme_black;
    }

    #logo {
      float: left;
      width: $theme_logo_width;
      height: $theme_logo_height;
      margin-top: $theme_logo_max_height - $theme_logo_height/2;
      background: url($theme_logo) no-repeat;
      background-size: auto 100%;
      text-indent: -9999px;

      @media (-webkit-min-device-pixel-ratio: 1.5),
      (min-resolution: 144dpi) {
        width: $theme_logo_retina_width;
        height: $theme_logo_retina_height;
        margin-top: $theme_logo_max_height - $theme_logo_retina_height/2;
        background: url($theme_logo2x) no-repeat;
        background-size: auto 100%;
      }
    }

    @media (max-width: $ipad-width) {

      #logo {
        width: $theme_icon_width;
        height: $theme_icon_height;
        margin-top: $theme_logo_max_height - $theme_icon_height/2;
        background: url($theme_icon) no-repeat;
        background-size: auto 100%;

        @media (-webkit-min-device-pixel-ratio: 1.5),
        (min-resolution: 144dpi) {
          width: $theme_icon_retina_width;
          height: $theme_icon_retina_height;
          margin-top: $theme_logo_max_height - $theme_icon_retina_height/2;
          background: url($theme_icon2x) no-repeat;
          background-position: left;
          background-size: auto 100%;
        }
      }
    }
  }

  .divider-vertical {
    margin: 0px;
    height: 60px;
    border: 0;
    width: 1px;
    background: $theme_lighter_black;
  }

  .nav-links {
    position: relative;
    line-height: 60px;

    li {
      height: 60px;

      a.nav-link {
        position: relative;
        display: inline-block;
        padding: 18px 20px;
        color: $gray160;
        font-size: 16px;
        line-height: 22px;
        font-size: 20px;

        span.icon {
          display: none;
        }

        span.ico-plus {
          margin-top: -1px;
          font-size: 25px;
        }

        span.ico-user {
          margin-top: -4px;
          vertical-align: -3px;
          font-size: 30px;
        }

        span.ico-mail {
          margin-top: -5px;
          vertical-align: -9px;
          font-size: 35px;
        }

        @media (max-width: $ipad-width) {
            span.text { display: none; }
            span.icon { display: inherit; }
        }

        @media (max-width: $navbar-mobile-narrow-links) {
          padding: 18px 15px;
        }

        span.count {
          margin-left: 8px;
          background: $gray160;
          border-radius: 0.7em;
          color: $theme_black;
          display: inline-block;
          line-height: 1.3em;
          font-size: 14px;
          text-align: center;
          width: 1.3em;
        }

        .ico-chevron-down {
            padding-left: 8px;
            font-size: 14px;
        }

        &.btn-blue {
          height: 60px;
          margin: 0px;
          color: $pure-white;
          border-radius: 0;

          &:hover {
            background: lighten($theme_blue, 10%);
          }
        }
      }

      .dropdown-menu {
        min-width: 130px;
        margin: 0 0 0 -1px;
        padding-top: 0px;
        border-radius: 0px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius-default;
        border-bottom-right-radius: $border-radius-default;

        li {
          height: 40px;
          line-height: 40px;

          a.nav-link {
            padding: 8px 20px;
          }
        }

        .truncate {
          display: block;
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: $ipad-width){
            width: 165px;
          }
        }

        /* Remove bootstrap dropdown arrow */
        &:before,
        &:after {
          border: none !important;
          content: none !important;
        }
      }

    }
  }
}

/* Below is only for applying colors and borders to the inverted theme.
   Don't put other styles in here, use the navbar block above this one. */

.navbar-inverse {
  background: $black;

  .navbar-inner {
    background: $black;
  }

  ul.nav {
    li {

      ul.dropdown-menu {
        background: $theme_black;
        border-top: 0;
        border-color: $theme_lighter_black;
        box-shadow: none;

        li {
          width: 100%;
          background:$theme_black;

          a:hover{
              background:$theme_black;
              color: $gray220;
          }
        }

        &::after{
          border-bottom-color:$theme_black;
        }
      }

      &.active {
        background: $theme_black;

        a {
          color: $theme_white;
        }
      }

      a {
        color: $gray80;

        &:active {
          color: $gray220;
        }

        &:hover {
          color: $gray210;
        }
      }

      &.dropdown.open {
        background: $theme_black;

        .dropdown-toggle {
          background: $theme_black;
        }
      }
    }
  }
}
/* end */

.manage-nav {

  /* Customize the navbar links to be fill the entire space of the .navbar */
  .navbar {
    background: $gray240;
    border-radius: $border-radius-bottom;
    border: 1px solid $gray220;
    margin-bottom: 25px;

    .navbar-inner {
      height: 50px;
      padding: 0;
      background: $gray240;
      line-height: 40px;
      filter: none;

      @media (max-width: $ipad-width) {
        height: auto;
      }
    }

    .nav {
      margin: 0;
      display: table;
      width: 100%;

      @media (max-width: $ipad-width) {
        display: block;
      }

      li {
        display: table-cell;
        width: 1%;
        float: none;

        @media (max-width: $ipad-width) {
          display: block;
          padding-left: 20px;

          &:last-child {
            padding-bottom: 10px;
          }
        }

        a {
          text-align: center;
          text-shadow: none;
          padding: 5px 0 0;
          color: $gray160;
          font-size: 18px;
          white-space: nowrap;

          &:hover, &.active {
            color: $gray80;
          }
        }
      }
    }
  }
}
