

// Generic styles to apply to everything

body {
  background: $gray240;
}

::selection, ::-moz-selection {
  background-color: $theme_blue;
  color: white;
}

input[type=text],
textarea {
  -webkit-appearance: none;
}

a,
a:visited,
a:hover {
  text-decoration: none !important;
  transition: opacity .07s ease-in, color .07s ease-in, border .07s ease-in;
}

p a,
p a:visited {
  color: $theme_blue;
  text-decoration: none;
  transition: opacity .07s ease-in, color .07s ease-in, border .07s ease-in;

  &:hover {
    color: darken( $theme_blue, 10%);
    text-decoration: none;
  }
}

img {
  max-width:100%;
}


// fix for google maps zoom controls

.map-wrapper .map-container img {
  max-width: none;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

strong {
  font-weight: bold;
}

.truncated-ellipsis {
  display: inline-block;
  margin: 0 4px;
  padding: 0 4px 10px;
  height:6px;
  border-radius: $border-radius-default;
  background: $gray220;
  color: $theme_black;
  vertical-align: middle;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 6px;
}


// An introduction paragraph at the top of the page content

.intro {
  margin: 20px 10px;
  line-height: 150%;
  font-size: 16px;
}

hr,
.hr {
  padding: 0;
  border: 0;
  height: 1px;
  max-height: 1px;
  background: #a8a9a9;
  color: #a8a9a9;
  border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid rgba(255,255,255,0.8);
  margin: 10px -12px 10px -12px;
}


// Inline rule to separate items
//
// i.e.
//  Something  -- or --  Something else
//
// Usage:
//  <span class="inline-rule"><span>or</span></span>
.inline-rule {
  display: inline-block;
  height: 162px;
  background: #b4b5b5;
  text-align: center;
  vertical-align: middle;
  line-height: 14px;
  margin: 0;
  width: 1px;

  @media (max-width: $iphone-portrait) {
    display: none;
  }
}

.or-part {
  position: relative;
  bottom: 0px;
  left: -16px;
  display: inline-block;
  margin: 0 0;
  padding: 0 5px;
  background: $gray240;
  color: $gray160;
  font-size: 14px;

  @media (max-width: $iphone-portrait) {
    left: 0;
  }
}

.divider {
  margin: 20px 0;

  &.small {
    margin: 20px 0;
  }
}

.close-button {
  position: absolute;
  top: 6px;
  right: 6px;
  color:black;
  font-size: 1.2em;
  cursor: pointer;
  opacity: .6;

  &:hover {
    opacity: 1;
  }
}

.margin-bottom {
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.multiline {
  white-space: pre-wrap;
}
