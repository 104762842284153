.review {
  border-top: solid #CACBD3 1px;
  padding: 15px;

  p.comment {
    color: #555555 !important;
    font-size: 1em !important;
    margin-bottom: 10px !important;
    font-weight: bold !important;
  }

  .review-date {
    float: right;
    font-size: 12px;
    color: #858585;
  }

  .review-details-questions {
    margin-top: 10px;

    span.question {
      font-size: 0.8em;
    }
  }
  .name-info {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .review-details-questions .row-fluid.compact {
    margin-bottom: 10px;
  }
}

