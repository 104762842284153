#new_user_message #textarea {
    height: 150px;
}

section.user_messages {
  p.no-data {
    text-align: center;
    margin: 40px 0 25px 0;
  }

  #new_user_message {
    textarea {
      height: 52px;
    }

    .span10, .span12 {
      margin-left: 0;
      width: 73%;
    }

    .span2 {
      margin-left: $padding-default;
      width: 20%;

      input.btn {
        padding: 0;
      }
    }
  }

  .listing-message, .listing-message-for-show {

    a {
      text-decoration: underline!important;
    }

    p {
      margin-bottom: 0;
    }
  }

  .listing-message {
    margin-left: 0;
    margin-bottom: $padding-default;
    padding: 2 * $padding-small 0;
    border: 1px solid $light-gray;
    border-radius: $border-radius-default;

    &.unread {
      background: $pure-white;
    }

    .name {
      margin-left: $padding-default;

      a {
        color: $gray80;
      }
    }

    .date {
      margin-left: $padding-default;
      color: $gray160;
      font-size: 14px;
    }

    .body {
      a {
        color: $gray80;
      }
    }

    .link-to-listing {
      font-size: 14px;

      a {
        color: $gray160;
        font-size: 14px;
      }
    }

    .actions {

      padding-left: $padding-default;
      margin-top: $padding-small;
      margin-bottom: $padding-small;

      a {
        padding: $padding-small 22px;
        width: 100%;
        text-decoration: none !important;
        text-align: left;
        border-radius: $border-radius-left;

        &:hover {
          background: $gray190;
        }
      }
    }
  }
}

.listing-deleted {
  .body {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid $light-gray;
    color: $gray80;
    background-color: $gray240;
    border-radius: $border-radius-default;
    text-align: center;
  }
}

.listing-message-for-show {

  margin-bottom: $padding-default;

  a {
    text-decoration: underline!important;
  }

  .name {
    a {
      color: $gray80;
    }
  }

  .date {
    color: $gray160;
    font-size: 14px;
  }

  .body {
    padding: 20px;
    border: 1px solid $light-gray;
    color: $gray80;
    border-radius: $border-radius-default;
  }

  &.foreign-message {
    .body {
      background: $pure-white;
    }
    .info {
      text-align:right;
    }
  }

}

#missing-mobile-number {

  .input-group {
    display: table;
  }

  .input-group-addon {
      display: table-cell;
      padding: 6px 12px;
      background-color: #eeeeee;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 1%;
      white-space: nowrap;
      vertical-align: middle;
  }

  .selectize-input {
    input {
      font-size: 16px;
    }
  }

  [data-mobile-number] {
    margin: 0 !important;
  }

  input[type=submit], a {
    height: 50px;
    line-height: 50px;
    margin: 10px 0 0;
  }

  a {
    text-align: center;
    display: inline-block;

    &.btn-grey {
      background-color: #C0C0C0;
    }
  }

  a.btn {
    padding: 0;
  }
}

@media (max-width: $ipad-width) {
  .user_messages {
    .listing-message {
      p {
        margin-left: $padding-default;
      }
    }
  }
}
