.form-horizontal {
  .control-group {
    &.control-fileupload {
      margin-bottom: 25px;
    }
  }
}

.photo-action {
  background-color: $blue;
  position: absolute;
  cursor: pointer;
  color: white;

  &:hover {
    background: lighten($theme_blue, 10%);
  }
}

.delete-photo {
  top: 6px;
  right: 10px;
}

.resize-photo {
  top: 30px;
  right: 10px;
}

#crop-form {
  position: relative;
}

.rotate-photo {
  z-index: 602;
  right: 0px;
}

.single-photo {

  .fileupload {

    .uploaded {
      overflow: hidden;
    }

    .photo-item {
      float: left;
    }

    .thumbnail-processing {
      width: 144px;
      height: 44px;
      padding-top: 100px;
    }

    img {
      float: left;
    }

    .action {
      clear: both;
    }
  }
}

.fileupload {

  .attachment {
    vertical-align: middle;
    margin-left: 10px;

    img {
      vertical-align: text-top;
    }

    a {
      color: $theme_blue;
    }
  }

  .progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);

    img.spinner {
      float: none;
      margin-left: 6px;
      margin-bottom: 5px;
    }

    .filename {
      margin-left: 5px;
      text-align: left;
      color: $gray;
    }
  }

  .progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #428bca;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    transition: width .6s ease;
    margin-top: -23px;
  }

  .ui-sortable {

    img {
      margin: 0 auto;
      width: 180px;
      height: 113px;
    }

  }

  .fileinput-button {
    margin-bottom: 8px;
    text-align: center;
    cursor: pointer;
  }

  .progress {
    clear: both;
  }

  .animated-progress-bar {
    height: 16px;
    background: url("/assets/progress-bar.gif") 50% 0% no-repeat;
  }

  .photo-placeholder{
    height: 110px;
    width: 182px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 4px;
    display: inline-block;
  }

  .photo-item {
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
    overflow: hidden;
    display: inline-block;
    padding: 4px;
    border: 1px dashed $black;

    .photo-position {
      margin: 6px 0 0 -174px;
      position: absolute;
    }

    img {
      margin: 0 auto;
    }

    input {
      margin: 0;
      width: 180px;
      display: block;
    }

  }

}

.thumbnail-processing {
  width: 180px;
  height: 56px;
  text-align: center;
  padding-top: 109px;
  // hack only for chrome...
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    padding-top: 114px;
  }

  .loading-icon {
    width: 100%;
    height: 16px;
    background: url("/assets/ajax-loader.gif") no-repeat center;
  }

  .loading-text {
    padding-top: 2px;
    position: relative;

    .progress {
      position: absolute;
      top: 8px;
      left: 0px;
      width: 100%;
    }

  }
}

