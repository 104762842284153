section.marketplace_sessions {

  #hero {
    position: absolute;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-color: $gray60;

    > * {
      position: relative;
      z-index: 2;
    }

    @media (max-width: $ipad-width) {
      height: 420px;
    }

    @media (max-width: $iphone-landscape) {
      height: 380px;
    }
  }

  section#hero + .container {
    width: 100%;
    padding: 0;
  }

  #marketplace-brand {
    margin-top: 110px;
  }

  #marketplace-password {
    margin-top: 100px;

    @media (max-width: $ipad-width) {
      margin-top: 210px;
    }

    @media (max-width: $iphone-landscape) {
      margin-top: 140px;
    }

    h1 {
      display: block;
      margin-bottom: 30px;
      color: $light-gray;
      font-size: 28px;
      line-height: 34px;
      text-align: center;

      @media (max-width: $iphone-portrait) {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
      }
    }

    form input[type="text"] {
      margin-bottom: 0;
    }

    form {
      clear: both;

      label {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

}

.footer-push {

  &.footer_marketplace_sessions {
    background-color: $gray60;
  }
}
