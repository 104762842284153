.video-wrapper {
  position:relative;
  padding-bottom:75%;
  height:0;
  overflow:hidden;

  iframe, object, embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  &.youtube {
    padding-top:30px;
  }
}

