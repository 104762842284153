.accordion-group {
  border-radius: $border-radius-default;
  background: $theme_white;

  a.accordion-toggle {
    &:after {
      content: "\46";
      float: right;
    }

    &.collapsed:after {
      content: "\44";
    }
  }
}

.accordion-dim {
  .accordion-group {
    border: none;
    background: $gray210;

    a.accordion-toggle {
      color: $theme_white;
    }
  }
}
