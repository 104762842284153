@charset "UTF-8";
@font-face {
  font-family: 'icon-font';
  src: url("/assets/icon-font.eot");
  src: url("/assets/icon-font.eot#iefix") format("embedded-opentype"), url("/assets/icon-font.woff") format("woff"), url("/assets/icon-font.ttf") format("truetype"), url("/assets/icon-font.svg#icon-font") format("svg");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'icomoon';
  src: url("/assets/icomoon/fonts/icomoon.eot?u1pn6e");
  src: url("/assets/icomoon/fonts/icomoon.eot?u1pn6e#iefix") format("embedded-opentype"), url("/assets/icomoon/fonts/icomoon.ttf?u1pn6e") format("truetype"), url("/assets/icomoon/fonts/icomoon.woff?u1pn6e") format("woff"), url("/assets/icomoon/fonts/icomoon.svg?u1pn6e#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

span.padding:before {
  padding-right: .4em;
}

span.padding-left {
  padding-left: .4em;
}

.ico-phone:before {
  font-size: 75%;
  padding-right: 2px;
}

.ico-twitter:before {
  font-size: 125%;
}

.ico-lock:before,
.ico-calender-start {
  padding-right: 4px;
}

.ico-filter:before,
.ico-calender-start:before,
.ico-calender-end:before,
.ico-user:before,
.ico-plus:before,
.ico-info:before,
.ico-facebook:before,
.ico-check:before,
.checkbox-icon-inner:before,
.switch-left:before,
.ico-bookings:before,
.ico-arrow-up:before,
.ico-arrow-right:before,
.ico-arrow-left:before,
.ico-arrow-down:before,
.ico-twitter:before,
.ico-sign-up:before,
.ico-search:before,
.ico-phone:before,
.ico-marker:before,
.ico-mail:before,
.ico-logout:before,
.ico-login:before,
.ico-lock:before,
.ico-location:before,
.ico-linkedin:before,
.ico-instagram:before,
.ico-edit:before,
.ico-drawer:before,
.ico-dashboard:before,
.ico-crosshairs:before,
.ico-close:before,
.switch-right:before,
.ico-clock:before,
.ico-calender:before,
.ico-add-location:before,
.ico-manage-guests:before,
.ico-link:before,
.ico-app-store:before,
.ico-credit-card:before,
.ico-trash:before,
.ico-settings:before,
.ico-upload-photos:before,
.ico-desk:before,
.ico-add-desk:before,
.ico-save:before,
.ico-trash-open:before,
.ico-profile:before,
.ico-warning:before,
.ico-chevron-up:before,
.ico-chevron-right:before,
.ico-chevron-down:before,
.booking-module-container .order_dates .select2-container .select2-choice .select2-arrow:before,
.ico-chevron-left:before,
.ico-list:before,
.ico-open-link:before,
.ico-rss:before,
.ico-facebook-full:before,
.ico-eye:before,
.ico-transfers:before,
.ico-bar-chart:before,
.ico-download:before,
.ico-pending:before,
.ico-menu:before,
.ico-enlarge:before,
.ico-time:before,
.ico-google-plus:before,
.ico-group:before,
.ico-blog:before,
.ico-bullet:before,
.ico-linkedin-other:before,
.ico-google:before,
.ico-google-plus-other:before {
  font-family: 'icon-font' !important;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}

.ico-github:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-upload-photos:before {
  content: "\21";
}

.ico-twitter:before {
  content: "\22";
}

.ico-trash:before {
  content: "\23";
}

.ico-trash-open:before {
  content: "\24";
}

.ico-sign-up:before {
  content: "\25";
}

.ico-settings:before {
  content: "\26";
}

.ico-search:before {
  content: "\27";
}

.ico-save:before {
  content: "\28";
}

.ico-profile:before {
  content: "\29";
}

.ico-plus:before {
  content: "\2a";
}

.ico-phone:before {
  content: "\2b";
}

.ico-open-link:before {
  content: "\2c";
}

.ico-marker:before {
  content: "\2d";
}

.ico-manage-guests:before {
  content: "\2e";
}

.ico-mail:before {
  content: "\2f";
}

.ico-logout:before {
  content: "\30";
}

.ico-login:before {
  content: "\31";
}

.ico-lock:before {
  content: "\32";
}

.ico-location:before {
  content: "\33";
}

.ico-list:before {
  content: "\34";
}

.ico-linkedin:before {
  content: "\35";
}

.ico-link:before {
  content: "\36";
}

.ico-instagram:before {
  content: "\37";
}

.ico-info:before {
  content: "\38";
}

.ico-filter:before {
  content: "\39";
}

.ico-facebook:before {
  content: "\3a";
}

.ico-github:before {
  content: "\e61a";
}

.ico-edit:before {
  content: "\3b";
}

.ico-drawer:before {
  content: "\3c";
}

.ico-desk:before {
  content: "\3d";
}

.ico-dashboard:before {
  content: "\3e";
}

.ico-crosshairs:before {
  content: "\3f";
}

.ico-credit-card:before {
  content: "\40";
}

.ico-close:before, .switch-right:before {
  content: "\41";
}

.ico-clock:before {
  content: "\42";
}

.ico-chevron-up:before {
  content: "\43";
}

.ico-chevron-right:before {
  content: "\44";
}

.ico-chevron-left:before {
  content: "\45";
}

.ico-chevron-down:before, .booking-module-container .order_dates .select2-container .select2-choice .select2-arrow:before {
  content: "\46";
}

.ico-check:before, .checkbox-icon-inner:before, .switch-left:before {
  content: "\47";
}

.ico-calender:before {
  content: "\48";
}

.ico-calender-start:before {
  content: "\49";
}

.ico-calender-end:before {
  content: "\4a";
}

.ico-bookings:before {
  content: "\4b";
}

.ico-arrow-up:before {
  content: "\4c";
}

.ico-arrow-right:before {
  content: "\4d";
}

.ico-arrow-left:before {
  content: "\4e";
}

.ico-arrow-down:before {
  content: "\4f";
}

.ico-app-store:before {
  content: "\50";
}

.ico-add-location:before {
  content: "\51";
}

.ico-add-desk:before {
  content: "\52";
}

.ico-user:before {
  content: "\53";
}

.ico-warning:before {
  content: "\54";
}

.ico-bar-chart:before {
  content: "\55";
}

.ico-rss:before {
  content: "\56";
}

.ico-transfers:before {
  content: "\57";
}

.ico-eye:before {
  content: "\58";
}

.ico-pending:before {
  content: "\59";
}

.ico-facebook-full:before {
  content: "\5a";
}

.ico-menu:before {
  content: "\5b";
}

.ico-download:before {
  content: "\5c";
}

.ico-enlarge:before {
  content: "\5d";
}

.ico-time:before {
  content: "\5e";
}

.ico-group:before {
  content: "\60";
}

.ico-bullet:before {
  content: "\61";
}

.ico-google-plus:before {
  content: "\62";
}

.ico-blog:before {
  content: "\63";
}

.ico-google-plus-other:before {
  content: "\64";
}

.ico-google:before {
  content: "\64";
}

.ico-linkedin-other:before {
  content: "\65";
}

body {
  padding: 90px 0 0;
}

.padding-row {
  padding: 1.5em 0;
}

.padding-bottom {
  padding: 0 0 1.5em;
}

@media (max-width: 767px) {
  .container-fluid {
    padding: 0 20px;
  }
}

@media (max-width: 1400px) {
  .container-fluid.flush {
    margin: 0 -22px;
  }
}

@media (max-width: 480px) {
  .container-fluid {
    padding: 0 .75em;
  }
}

.navbar-fixed-top,
.navbar-inner {
  border-width: 0 !important;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 998;
  margin: 0 !important;
}

.navbar-inner,
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  border: 0 !important;
  -webkit-box-shadow: 0 0 0 transparent !important;
  -moz-box-shadow: 0 0 0 transparent !important;
  box-shadow: 0 0 0 transparent !important;
}

a[rel="tooltip"] {
  color: #353535;
}

.tooltip {
  font-size: 16px;
  color: gainsboro;
}

abbr[title], .required > abbr {
  cursor: auto;
  border: none;
  color: #e85c41;
  text-decoration: none;
}

.accordion-group {
  background: #FFFFFF;
  border-radius: 5px;
}

.accordion-group a.accordion-toggle {
  color: black;
  opacity: .8;
}

.accordion-group a.accordion-toggle:after {
  transition: opacity .1s ease-in;
  color: black;
  font-family: icon-font !important;
  font-weight: 400;
  font-style: normal;
  speak: none;
  -webkit-font-smoothing: antialiased;
}

.accordion-group a.accordion-toggle:hover {
  opacity: 1;
}

.accordion-inner {
  border-top: none;
}

abbr[title],
abbr[data-original-title] {
  cursor: auto;
  border: none;
  color: #e85c41;
}

.btn, form:not(.no_inheritance) input[type='submit'], section.dashboard ul.locations li.location .links a {
  text-shadow: none;
  box-shadow: none;
}

.nav-tabs {
  border: none;
}

.carousel-control {
  background: none !important;
  border: none !important;
  text-shadow: black 0 0 5px;
}

@media (min-width: 1400px) {
  .container-fluid {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.tooltip-inner {
  max-width: 140px;
}

.tooltip {
  font-size: 16px;
}

.nav-tabs > li > a {
  border-top-left-radius: 2.5px;
  border-top-right-radius: 2.5px;
}

@media (max-width: 767px) {
  [class*="span"]:not(.nested),
  .uneditable-input[class*="span"],
  .row-fluid [class*="span"]:not(.nested) {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
}

@media (max-width: 480px) {
  .hidden-iphone {
    display: none !important;
  }
}

.btn, form:not(.no_inheritance) input[type='submit'], section.dashboard ul.locations li.location .links a,
a.btn,
section.dashboard ul.locations li.location .links a {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 10px;
  border: none !important;
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
  transition: background .1s ease-in;
}

.btn.full-width, form:not(.no_inheritance) input.full-width[type='submit'], section.dashboard ul.locations li.location .links a.full-width,
a.btn.full-width,
section.dashboard ul.locations li.location .links a.full-width {
  box-sizing: border-box;
  width: 100%;
}

.btn.margin-bottom, form:not(.no_inheritance) input.margin-bottom[type='submit'], section.dashboard ul.locations li.location .links a.margin-bottom,
a.btn.margin-bottom,
section.dashboard ul.locations li.location .links a.margin-bottom {
  margin-bottom: 1em;
}

.btn .icon, form:not(.no_inheritance) input[type='submit'] .icon, section.dashboard ul.locations li.location .links a .icon,
a.btn .icon, section.dashboard ul.locations li.location .links a .icon {
  vertical-align: middle;
}

.btn:visited, form:not(.no_inheritance) input[type='submit']:visited, section.dashboard ul.locations li.location .links a:visited, .btn:hover, form:not(.no_inheritance) input[type='submit']:hover, section.dashboard ul.locations li.location .links a:hover,
a.btn:visited,
section.dashboard ul.locations li.location .links a:visited,
a.btn:hover,
section.dashboard ul.locations li.location .links a:hover {
  color: #fff;
}

.btn.btn-small, form:not(.no_inheritance) input.btn-small[type='submit'], section.dashboard ul.locations li.location .links a.btn-small,
a.btn.btn-small,
section.dashboard ul.locations li.location .links a.btn-small {
  height: 30px;
  border-radius: 5px;
  line-height: 30px;
  font-size: 14px;
}

.btn.btn-medium, form:not(.no_inheritance) input.btn-medium[type='submit'], section.dashboard ul.locations li.location .links a,
a.btn.btn-medium,
section.dashboard ul.locations li.location .links a {
  height: 38px;
  border-radius: 5px;
  line-height: 38px;
  font-size: 18px;
}

.btn.btn-large, form:not(.no_inheritance) input[type='submit'], section.dashboard ul.locations li.location .links a.btn-large,
a.btn.btn-large,
section.dashboard ul.locations li.location .links a.btn-large {
  padding: 0 20px;
  height: 50px;
  border-radius: 5px;
  font-size: 22px;
  line-height: 50px;
}

.btn.btn-full, form:not(.no_inheritance) input.btn-full[type='submit'], section.dashboard ul.locations li.location .links a.btn-full,
a.btn.btn-full,
section.dashboard ul.locations li.location .links a.btn-full {
  width: 100%;
  height: 38px;
  border-radius: 8px;
  line-height: 38px;
  font-size: 18px;
}

.btn.btn-gray, form:not(.no_inheritance) input.btn-gray[type='submit'], section.dashboard ul.locations li.location .links a.btn-gray,
a.btn.btn-gray,
section.dashboard ul.locations li.location .links a.btn-gray {
  background: #E2E2E2;
  color: black;
}

.btn.btn-gray:hover, form:not(.no_inheritance) input.btn-gray[type='submit']:hover, section.dashboard ul.locations li.location .links a.btn-gray:hover,
a.btn.btn-gray:hover,
section.dashboard ul.locations li.location .links a.btn-gray:hover {
  background: #fff;
}

.btn.btn-gray:active, form:not(.no_inheritance) input.btn-gray[type='submit']:active, section.dashboard ul.locations li.location .links a.btn-gray:active,
a.btn.btn-gray:active,
section.dashboard ul.locations li.location .links a.btn-gray:active {
  background: #fff;
}

.btn.btn-white, form:not(.no_inheritance) input.btn-white[type='submit'], section.dashboard ul.locations li.location .links a.btn-white,
a.btn.btn-white,
section.dashboard ul.locations li.location .links a.btn-white {
  border: 2px solid #E2E2E2 !important;
  background: #FFFFFF;
  color: black;
}

.btn.btn-white:hover, form:not(.no_inheritance) input.btn-white[type='submit']:hover, section.dashboard ul.locations li.location .links a.btn-white:hover,
a.btn.btn-white:hover,
section.dashboard ul.locations li.location .links a.btn-white:hover {
  background: #fff;
}

.btn.btn-white:active, form:not(.no_inheritance) input.btn-white[type='submit']:active, section.dashboard ul.locations li.location .links a.btn-white:active,
a.btn.btn-white:active,
section.dashboard ul.locations li.location .links a.btn-white:active {
  background: #fff;
}

.btn.btn-gray-dark, form:not(.no_inheritance) input.btn-gray-dark[type='submit'], section.dashboard ul.locations li.location .links a.btn-gray-dark,
a.btn.btn-gray-dark,
section.dashboard ul.locations li.location .links a.btn-gray-dark {
  background: #3c3c3c;
  color: #787878;
  border: 1px solid #787878 !important;
}

.btn.btn-gray-dark span, form:not(.no_inheritance) input.btn-gray-dark[type='submit'] span, section.dashboard ul.locations li.location .links a.btn-gray-dark span,
a.btn.btn-gray-dark span, section.dashboard ul.locations li.location .links a.btn-gray-dark span {
  color: #787878;
}

.btn.btn-gray-dark:hover, form:not(.no_inheritance) input.btn-gray-dark[type='submit']:hover, section.dashboard ul.locations li.location .links a.btn-gray-dark:hover,
a.btn.btn-gray-dark:hover,
section.dashboard ul.locations li.location .links a.btn-gray-dark:hover {
  background: #565555;
}

.btn.btn-gray-dark:active, form:not(.no_inheritance) input.btn-gray-dark[type='submit']:active, section.dashboard ul.locations li.location .links a.btn-gray-dark:active,
a.btn.btn-gray-dark:active,
section.dashboard ul.locations li.location .links a.btn-gray-dark:active {
  background: #232222;
}

.btn.btn-gray-darker, form:not(.no_inheritance) input.btn-gray-darker[type='submit'], section.dashboard ul.locations li.location .links a.btn-gray-darker,
a.btn.btn-gray-darker,
section.dashboard ul.locations li.location .links a.btn-gray-darker {
  background: #d2d2d2;
  color: #505050;
  border: 1px solid #E2E2E2 !important;
}

.btn.btn-gray-darker span, form:not(.no_inheritance) input.btn-gray-darker[type='submit'] span, section.dashboard ul.locations li.location .links a.btn-gray-darker span,
a.btn.btn-gray-darker span, section.dashboard ul.locations li.location .links a.btn-gray-darker span {
  color: #505050;
}

.btn.btn-gray-darker:hover, form:not(.no_inheritance) input.btn-gray-darker[type='submit']:hover, section.dashboard ul.locations li.location .links a.btn-gray-darker:hover,
a.btn.btn-gray-darker:hover,
section.dashboard ul.locations li.location .links a.btn-gray-darker:hover {
  background: #ecebeb;
}

.btn.btn-gray-darker:active, form:not(.no_inheritance) input.btn-gray-darker[type='submit']:active, section.dashboard ul.locations li.location .links a.btn-gray-darker:active,
a.btn.btn-gray-darker:active,
section.dashboard ul.locations li.location .links a.btn-gray-darker:active {
  background: #b9b8b8;
}

.btn.btn-gray-light, form:not(.no_inheritance) input.btn-gray-light[type='submit'], section.dashboard ul.locations li.location .links a.btn-gray-light,
a.btn.btn-gray-light,
section.dashboard ul.locations li.location .links a.btn-gray-light {
  background: #787878;
  color: #fff;
  border: 1px solid #787878 !important;
}

.btn.btn-gray-light span, form:not(.no_inheritance) input.btn-gray-light[type='submit'] span, section.dashboard ul.locations li.location .links a.btn-gray-light span,
a.btn.btn-gray-light span, section.dashboard ul.locations li.location .links a.btn-gray-light span {
  color: #fff;
}

.btn.btn-gray-light:hover, form:not(.no_inheritance) input.btn-gray-light[type='submit']:hover, section.dashboard ul.locations li.location .links a.btn-gray-light:hover,
a.btn.btn-gray-light:hover,
section.dashboard ul.locations li.location .links a.btn-gray-light:hover {
  background: #929191;
}

.btn.btn-gray-light:active, form:not(.no_inheritance) input.btn-gray-light[type='submit']:active, section.dashboard ul.locations li.location .links a.btn-gray-light:active,
a.btn.btn-gray-light:active,
section.dashboard ul.locations li.location .links a.btn-gray-light:active {
  background: #5f5e5e;
}

.btn.twitter.connected, form:not(.no_inheritance) input.twitter.connected[type='submit'], section.dashboard ul.locations li.location .links a.twitter.connected, .btn.facebook.connected, form:not(.no_inheritance) input.facebook.connected[type='submit'], section.dashboard ul.locations li.location .links a.facebook.connected, .btn.linkedin.connected, form:not(.no_inheritance) input.linkedin.connected[type='submit'], section.dashboard ul.locations li.location .links a.linkedin.connected, .btn.instagram.connected, form:not(.no_inheritance) input.instagram.connected[type='submit'], section.dashboard ul.locations li.location .links a.instagram.connected, .btn.google.connected, form:not(.no_inheritance) input.google.connected[type='submit'], section.dashboard ul.locations li.location .links a.google.connected,
a.btn.twitter.connected,
section.dashboard ul.locations li.location .links a.twitter.connected,
a.btn.facebook.connected,
section.dashboard ul.locations li.location .links a.facebook.connected,
a.btn.linkedin.connected,
section.dashboard ul.locations li.location .links a.linkedin.connected,
a.btn.instagram.connected,
section.dashboard ul.locations li.location .links a.instagram.connected,
a.btn.google.connected,
section.dashboard ul.locations li.location .links a.google.connected {
  background: #e85c41;
}

.btn.twitter.connected:hover, form:not(.no_inheritance) input.twitter.connected[type='submit']:hover, section.dashboard ul.locations li.location .links a.twitter.connected:hover, .btn.facebook.connected:hover, form:not(.no_inheritance) input.facebook.connected[type='submit']:hover, section.dashboard ul.locations li.location .links a.facebook.connected:hover, .btn.linkedin.connected:hover, form:not(.no_inheritance) input.linkedin.connected[type='submit']:hover, section.dashboard ul.locations li.location .links a.linkedin.connected:hover, .btn.instagram.connected:hover, form:not(.no_inheritance) input.instagram.connected[type='submit']:hover, section.dashboard ul.locations li.location .links a.instagram.connected:hover, .btn.google.connected:hover, form:not(.no_inheritance) input.google.connected[type='submit']:hover, section.dashboard ul.locations li.location .links a.google.connected:hover,
a.btn.twitter.connected:hover,
section.dashboard ul.locations li.location .links a.twitter.connected:hover,
a.btn.facebook.connected:hover,
section.dashboard ul.locations li.location .links a.facebook.connected:hover,
a.btn.linkedin.connected:hover,
section.dashboard ul.locations li.location .links a.linkedin.connected:hover,
a.btn.instagram.connected:hover,
section.dashboard ul.locations li.location .links a.instagram.connected:hover,
a.btn.google.connected:hover,
section.dashboard ul.locations li.location .links a.google.connected:hover {
  background: #db3a1b;
}

.btn.twitter, form:not(.no_inheritance) input.twitter[type='submit'], section.dashboard ul.locations li.location .links a.twitter,
a.btn.twitter,
section.dashboard ul.locations li.location .links a.twitter {
  background: #33CCFF;
}

.btn.twitter:hover, form:not(.no_inheritance) input.twitter[type='submit']:hover, section.dashboard ul.locations li.location .links a.twitter:hover,
a.btn.twitter:hover,
section.dashboard ul.locations li.location .links a.twitter:hover {
  background: #00ace6;
}

.btn.google, form:not(.no_inheritance) input.google[type='submit'], section.dashboard ul.locations li.location .links a.google,
a.btn.google,
section.dashboard ul.locations li.location .links a.google {
  background: #DC4E41;
}

.btn.google:hover, form:not(.no_inheritance) input.google[type='submit']:hover, section.dashboard ul.locations li.location .links a.google:hover,
a.btn.google:hover,
section.dashboard ul.locations li.location .links a.google:hover {
  background: #b02c20;
}

.btn.facebook, form:not(.no_inheritance) input.facebook[type='submit'], section.dashboard ul.locations li.location .links a.facebook,
a.btn.facebook,
section.dashboard ul.locations li.location .links a.facebook {
  background: #3B5998;
}

.btn.facebook:hover, form:not(.no_inheritance) input.facebook[type='submit']:hover, section.dashboard ul.locations li.location .links a.facebook:hover,
a.btn.facebook:hover,
section.dashboard ul.locations li.location .links a.facebook:hover {
  background: #263961;
}

.btn.github, form:not(.no_inheritance) input.github[type='submit'], section.dashboard ul.locations li.location .links a.github,
a.btn.github,
section.dashboard ul.locations li.location .links a.github {
  background: #24292e;
}

.btn.github:hover, form:not(.no_inheritance) input.github[type='submit']:hover, section.dashboard ul.locations li.location .links a.github:hover,
a.btn.github:hover,
section.dashboard ul.locations li.location .links a.github:hover {
  background: #020303;
}

.btn.linkedin, form:not(.no_inheritance) input.linkedin[type='submit'], section.dashboard ul.locations li.location .links a.linkedin,
a.btn.linkedin,
section.dashboard ul.locations li.location .links a.linkedin {
  background: #4875B4;
}

.btn.linkedin:hover, form:not(.no_inheritance) input.linkedin[type='submit']:hover, section.dashboard ul.locations li.location .links a.linkedin:hover,
a.btn.linkedin:hover,
section.dashboard ul.locations li.location .links a.linkedin:hover {
  background: #32517d;
}

.btn.instagram, form:not(.no_inheritance) input.instagram[type='submit'], section.dashboard ul.locations li.location .links a.instagram,
a.btn.instagram,
section.dashboard ul.locations li.location .links a.instagram {
  background: #905f4c;
}

.btn.instagram:hover, form:not(.no_inheritance) input.instagram[type='submit']:hover, section.dashboard ul.locations li.location .links a.instagram:hover,
a.btn.instagram:hover,
section.dashboard ul.locations li.location .links a.instagram:hover {
  background: #5e3e32;
}

.btn-group {
  display: inline-block;
  overflow: hidden;
}

.btn-group .btn, .btn-group form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) .btn-group input[type='submit'], .btn-group section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .btn-group a {
  float: left;
}

.btn-group .btn:first-child:not(:last-child), .btn-group form:not(.no_inheritance) input[type='submit']:first-child:not(:last-child), form:not(.no_inheritance) .btn-group input[type='submit']:first-child:not(:last-child), .btn-group section.dashboard ul.locations li.location .links a:first-child:not(:last-child), section.dashboard ul.locations li.location .links .btn-group a:first-child:not(:last-child) {
  border-right-radius: 0;
  border-left: none;
}

.btn-group .btn:not(:first-child):not(:last-child), .btn-group form:not(.no_inheritance) input[type='submit']:not(:first-child):not(:last-child), form:not(.no_inheritance) .btn-group input[type='submit']:not(:first-child):not(:last-child), .btn-group section.dashboard ul.locations li.location .links a:not(:first-child):not(:last-child), section.dashboard ul.locations li.location .links .btn-group a:not(:first-child):not(:last-child) {
  border-left-radius: 0;
  border-right-radius: 0;
}

.btn-group .btn:last-child:not(:first-child), .btn-group form:not(.no_inheritance) input[type='submit']:last-child:not(:first-child), form:not(.no_inheritance) .btn-group input[type='submit']:last-child:not(:first-child), .btn-group section.dashboard ul.locations li.location .links a:last-child:not(:first-child), section.dashboard ul.locations li.location .links .btn-group a:last-child:not(:first-child) {
  border-left-radius: 0;
  border-right: none;
}

.chzn-container-multi ul.chzn-choices,
.chzn-container-single {
  margin: 0;
  margin-bottom: 8px;
  padding: 8px 8px 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  background: #e6e6e6;
  color: #3c3c3c;
  font-size: 22px;
  line-height: 29px;
  transition: border .2s ease-in, opacity .2s ease-in, color .2s ease-in;
  padding-right: 40px;
}

@media (max-width: 320px) {
  .chzn-container-multi ul.chzn-choices,
  .chzn-container-single {
    font-size: 16px;
  }
}

.chzn-container-multi ul.chzn-choices:focus,
.chzn-container-single:focus {
  outline: none;
  border: 2px solid #505050;
  background: #f0f0f0;
  color: #282828;
}

@media (max-width: 480px) {
  .chzn-container-multi ul.chzn-choices,
  .chzn-container-single {
    font-size: 18px;
  }
}

.chzn-container-single {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  border: none !important;
}

.chzn-container-single .chzn-search input {
  width: 100% !important;
  background-image: none !important;
}

.chzn-container-single a {
  box-sizing: border-box;
  height: 49px !important;
  padding: 14px 8px 8px 8px !important;
  border: none !important;
  background: none !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.chzn-container-single a span {
  font-size: 18px;
}

.chzn-drop {
  top: 49px !important;
  width: 100% !important;
  border: 0px !important;
  font-size: 18px;
}

.chzn-container-multi .chzn-choices .search-choice {
  margin: 4px 0 0 5px;
  padding: 2px 20px 6px 5px;
}

.chzn-container-multi .chzn-choices .search-field {
  margin-left: 5px;
}

ul.chzn-choices.chzn-choices-active {
  outline: none;
  border: 2px solid #505050;
  background: #f0f0f0;
  color: #282828;
}

.control-group .chzn-container-multi {
  width: auto !important;
  display: block !important;
}

.control-group .chzn-container-multi ul li span, .control-group .chzn-container-multi ul li input {
  font-size: 18px;
}

.control-group .chzn-container-multi ul li.search-field input {
  height: 100%;
  padding: 0px;
}

.chosen-container {
  width: 100% !important;
}

.jcrop-keymgr {
  display: none !important;
}

.jcrop-holder {
  margin-bottom: 30px;
  overflow: hidden;
}

input[type=color] {
  margin-top: 10px;
}

/* Always have the calendar above */
div#ui-datepicker-div.ui-datepicker {
  z-index: 2000 !important;
}

/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("/assets/font-awesome//fontawesome-webfont.eot?v=4.7.0");
  src: url("/assets/font-awesome//fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("/assets/font-awesome//fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("/assets/font-awesome//fontawesome-webfont.woff?v=4.7.0") format("woff"), url("/assets/font-awesome//fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("/assets/font-awesome//fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.2857142857em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.1428571429em;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.1428571429em;
  width: 2.1428571429em;
  top: 0.1428571429em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.8571428571em;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-gear:before,
.fa-cog:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-rotate-right:before,
.fa-repeat:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before,
.fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before,
.fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-gears:before,
.fa-cogs:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before,
.fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before,
.fa-users:before {
  content: "";
}

.fa-chain:before,
.fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before,
.fa-scissors:before {
  content: "";
}

.fa-copy:before,
.fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-save:before,
.fa-floppy-o:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-unsorted:before,
.fa-sort:before {
  content: "";
}

.fa-sort-down:before,
.fa-sort-desc:before {
  content: "";
}

.fa-sort-up:before,
.fa-sort-asc:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before,
.fa-undo:before {
  content: "";
}

.fa-legal:before,
.fa-gavel:before {
  content: "";
}

.fa-dashboard:before,
.fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-flash:before,
.fa-bolt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-paste:before,
.fa-clipboard:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before,
.fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-unlink:before,
.fa-chain-broken:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "";
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "";
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "";
}

.fa-euro:before,
.fa-eur:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before,
.fa-usd:before {
  content: "";
}

.fa-rupee:before,
.fa-inr:before {
  content: "";
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "";
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "";
}

.fa-won:before,
.fa-krw:before {
  content: "";
}

.fa-bitcoin:before,
.fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before,
.fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-turkish-lira:before,
.fa-try:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "";
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before,
.fa-car:before {
  content: "";
}

.fa-cab:before,
.fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "";
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "";
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "";
}

.fa-ge:before,
.fa-empire:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before,
.fa-weixin:before {
  content: "";
}

.fa-send:before,
.fa-paper-plane:before {
  content: "";
}

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before,
.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-hotel:before,
.fa-bed:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-yc:before,
.fa-y-combinator:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: "";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before,
.fa-battery-half:before {
  content: "";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before,
.fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "";
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-tv:before,
.fa-television:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-signing:before,
.fa-sign-language:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "";
}

.fa-fa:before,
.fa-font-awesome:before {
  content: "";
}

.fa-handshake-o:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-o:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-book-o:before {
  content: "";
}

.fa-vcard:before,
.fa-address-card:before {
  content: "";
}

.fa-vcard-o:before,
.fa-address-card-o:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-circle-o:before {
  content: "";
}

.fa-user-o:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-drivers-license:before,
.fa-id-card:before {
  content: "";
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-times-rectangle:before,
.fa-window-close:before {
  content: "";
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-eercast:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-snowflake-o:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.container.fluid,
.locations .container {
  box-sizing: border-box;
  position: relative;
  max-width: 960px;
  width: 100%;
}

.container.fluid .one-third.column,
.locations .container .one-third.column {
  box-sizing: border-box;
  margin: 0;
  width: 33.3333%;
}

.container.fluid .one-third.column.padding,
.locations .container .one-third.column.padding {
  padding: 0 20px;
}

.container.fluid .one-third.column.right,
.locations .container .one-third.column.right {
  float: right;
}

@media (max-width: 767px) {
  .container.fluid .one-third.column,
  .locations .container .one-third.column {
    width: 100%;
    clear: both;
  }
}

.container.fluid .two-thirds.column,
.locations .container .two-thirds.column {
  box-sizing: border-box;
  margin: 0;
  width: 66.6666%;
}

.container.fluid .two-thirds.column.right,
.locations .container .two-thirds.column.right {
  float: right;
}

@media (max-width: 767px) {
  .container.fluid .two-thirds.column,
  .locations .container .two-thirds.column {
    width: 100%;
    clear: both;
  }
}

.footer-wrapper {
  background: #353535;
  padding: 40px 0;
}

.footer-wrapper .container-fluid {
  background: #353535;
}

@media (max-width: 767px) {
  .footer-wrapper {
    padding: 30px 0 5px;
  }
}

#habla_window_div #habla_sizebutton_a.habla_button {
  display: none;
}

#habla_window_div #habla_oplink_a {
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

footer {
  line-height: 35px;
}

@media (max-width: 767px) {
  footer .mobile6 {
    width: 50% !important;
    float: left !important;
  }
}

footer .near-me-badge {
  background: url("/assets/near-me-badge.png") center center no-repeat;
  width: 100px;
  height: 100px;
  float: right;
}

@media (max-width: 767px) {
  footer .near-me-badge {
    position: static;
    margin: 20px auto;
    float: none;
    top: 0;
    width: 80px;
    height: 80px;
    background-size: cover;
  }
}

@media (max-width: 767px) {
  footer .social-wrapper {
    position: relative;
    overflow: hidden;
    float: right;
    width: 100%;
  }
}

footer .social-wrapper ul.social-icons li a .text {
  font-weight: 600;
}

footer .social-wrapper ul.social-icons li a .image {
  display: inline-block;
  font-size: 22px;
  width: 20px;
  margin-right: 20px;
}

footer .social-wrapper ul.social-icons li a .image.ico-twitter {
  font-size: 21px;
}

footer ul {
  list-style: none !important;
  margin: 0 0 12px 0px !important;
}

footer a, footer a:visited {
  color: #787878;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
}

footer a:hover, footer a:visited:hover {
  color: #d2d2d2;
}

@media (max-width: 767px) {
  footer a, footer a:visited {
    font-size: 14px;
  }
}

footer:first-child a {
  padding-left: 0;
}

footer.last a {
  background: 0;
}

footer.active a {
  color: #787878;
}

footer.active a:hover {
  color: #929191;
}

@media (max-width: 767px) {
  footer .copyright {
    text-align: center;
  }
}

footer .copyright span {
  font-size: 12px;
  padding: 0 13px 0 0;
  margin: 0 auto;
  text-align: center;
  line-height: 30px;
  color: #787878;
}

@media (max-width: 767px) {
  footer .copyright span {
    margin-top: -25px;
    padding-bottom: 20px;
  }
}

footer .copyright span a {
  font-size: 12px;
}

select {
  margin: 16px 0;
}

.controls .switch {
  margin-top: 9px;
}

.custom-availability-rules select {
  margin: 16px 2px;
}

input[type=file] {
  margin: 12px 0;
}

input[type=radio] {
  vertical-align: inherit;
  padding-right: 4px;
}

input[type='checkbox'],
input[type='radio'] {
  width: auto;
}

input[type=submit],
input[type=text],
input[type=email],
input[type=password],
input[type=url],
input[type=tel] {
  -webkit-appearance: none;
  cursor: pointer;
}

body.home-index input.query, body.search-index input.query {
  cursor: text;
}

label.radio {
  margin-right: 1em;
}

.radio_buttons.required {
  margin-top: 17px;
}

#space_wizard .periodicity {
  margin-right: 10px;
}

#space_wizard .chzn-container {
  margin: 16px 0;
}

form:not(.no_inheritance) div.input,
form:not(.no_inheritance) div.field {
  margin-bottom: 20px;
}

form:not(.no_inheritance) div.input.check_boxes,
form:not(.no_inheritance) div.field.check_boxes,
form:not(.no_inheritance) div.input.boolean,
form:not(.no_inheritance) div.input.radio_buttons {
  overflow: hidden;
}

form:not(.no_inheritance) div.input.check_boxes input,
form:not(.no_inheritance) div.field.check_boxes input,
form:not(.no_inheritance) div.input.boolean input,
form:not(.no_inheritance) div.input.radio_buttons input {
  float: left;
}

form:not(.no_inheritance) div.input.check_boxes label,
form:not(.no_inheritance) div.field.check_boxes label,
form:not(.no_inheritance) div.input.boolean label,
form:not(.no_inheritance) div.input.radio_buttons label {
  margin-left: 24px;
  font-size: 14px;
  line-height: 22px;
}

form:not(.no_inheritance) div.input.check_boxes .hint,
form:not(.no_inheritance) div.field.check_boxes .hint,
form:not(.no_inheritance) div.input.boolean .hint,
form:not(.no_inheritance) div.input.radio_buttons .hint {
  clear: left;
}

form:not(.no_inheritance) .control-group.check_boxes {
  overflow: hidden;
}

form:not(.no_inheritance) .control-group.check_boxes .controls {
  overflow: hidden;
}

form:not(.no_inheritance) .control-group.check_boxes .controls label.checkbox {
  padding-right: 10px;
}

form:not(.no_inheritance) .control-group.check_boxes .controls label.checkbox:last {
  padding-right: 0px;
}

form:not(.no_inheritance) div.radio_buttons {
  overflow: hidden;
  margin-bottom: 8px;
}

form:not(.no_inheritance) .hint,
form:not(.no_inheritance) .help-block,
form:not(.no_inheritance) .preamble,
form:not(.no_inheritance) .error-block {
  display: block;
  margin-bottom: 0;
  padding-bottom: 6px;
  font-size: 12px;
  line-height: 16px;
}

form:not(.no_inheritance) .preamble,
form:not(.no_inheritance) .error-block {
  color: #e85c41;
}

form:not(.no_inheritance) .hint,
form:not(.no_inheritance) .help-block,
form:not(.no_inheritance) .error-block {
  padding-left: 20px;
}

form:not(.no_inheritance) .hint.no-icon,
form:not(.no_inheritance) .help-block.no-icon,
form:not(.no_inheritance) .error-block.no-icon {
  background: none;
  padding-left: 0;
}

form:not(.no_inheritance) .hint-block {
  font-size: 12px;
}

form:not(.no_inheritance) .error-block {
  color: #e85c41;
  text-align: left;
  background: url("/assets/components/form/error.png") left top no-repeat;
}

form:not(.no_inheritance) .preamble {
  color: #1e2222;
}

form:not(.no_inheritance) .hint,
form:not(.no_inheritance) .help-block {
  background: url("/assets/components/form/hint.png") left top no-repeat;
  margin-top: 8px;
}

form:not(.no_inheritance) .card-info.error .controls input,
form:not(.no_inheritance) .card-info.error .controls textarea,
form:not(.no_inheritance) .card-info.error .controls ul,
form:not(.no_inheritance) .card-info.error .controls select, form:not(.no_inheritance) .row-fluid.error .controls input,
form:not(.no_inheritance) .row-fluid.error .controls textarea,
form:not(.no_inheritance) .row-fluid.error .controls ul,
form:not(.no_inheritance) .row-fluid.error .controls select, form:not(.no_inheritance) .control-group.error .controls input,
form:not(.no_inheritance) .control-group.error .controls textarea,
form:not(.no_inheritance) .control-group.error .controls ul,
form:not(.no_inheritance) .control-group.error .controls select {
  border-color: #ee836e;
}

form:not(.no_inheritance) label a[rel=tooltip] {
  color: #353535;
}

form:not(.no_inheritance) label a[rel=tooltip]:hover {
  color: #b6b6b6;
}

form:not(.no_inheritance) label.control-label {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
}

form:not(.no_inheritance) label.control-label.inline {
  display: inline-block;
  margin-bottom: auto;
  font-size: 14px;
}

form:not(.no_inheritance) label.control-label.hidden {
  display: none;
}

form:not(.no_inheritance) fieldset .control-group:last-child,
form:not(.no_inheritance) .fieldset .control-group:last-child {
  margin-bottom: 0;
}

form:not(.no_inheritance) input[type="text"],
form:not(.no_inheritance) input[type="password"],
form:not(.no_inheritance) input[type="email"],
form:not(.no_inheritance) input[type="tel"],
form:not(.no_inheritance) input[type="url"],
form:not(.no_inheritance) input[type="number"],
form:not(.no_inheritance) textarea {
  margin: 0;
  margin-bottom: 8px;
  padding: 8px 8px 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  background: #e6e6e6;
  color: #3c3c3c;
  font-size: 22px;
  line-height: 29px;
  transition: border .2s ease-in, opacity .2s ease-in, color .2s ease-in;
}

@media (max-width: 320px) {
  form:not(.no_inheritance) input[type="text"],
  form:not(.no_inheritance) input[type="password"],
  form:not(.no_inheritance) input[type="email"],
  form:not(.no_inheritance) input[type="tel"],
  form:not(.no_inheritance) input[type="url"],
  form:not(.no_inheritance) input[type="number"],
  form:not(.no_inheritance) textarea {
    font-size: 16px;
  }
}

form:not(.no_inheritance) input[type="text"]:focus,
form:not(.no_inheritance) input[type="password"]:focus,
form:not(.no_inheritance) input[type="email"]:focus,
form:not(.no_inheritance) input[type="tel"]:focus,
form:not(.no_inheritance) input[type="url"]:focus,
form:not(.no_inheritance) input[type="number"]:focus,
form:not(.no_inheritance) textarea:focus {
  outline: none;
  border: 2px solid #505050;
  background: #f0f0f0;
  color: #282828;
}

@media (max-width: 480px) {
  form:not(.no_inheritance) input[type="text"],
  form:not(.no_inheritance) input[type="password"],
  form:not(.no_inheritance) input[type="email"],
  form:not(.no_inheritance) input[type="tel"],
  form:not(.no_inheritance) input[type="url"],
  form:not(.no_inheritance) input[type="number"],
  form:not(.no_inheritance) textarea {
    font-size: 18px;
  }
}

form:not(.no_inheritance) input[type="text"].small,
form:not(.no_inheritance) input[type="password"].small,
form:not(.no_inheritance) input[type="email"].small,
form:not(.no_inheritance) input[type="tel"].small,
form:not(.no_inheritance) input[type="url"].small,
form:not(.no_inheritance) input[type="number"].small,
form:not(.no_inheritance) textarea.small {
  padding: 6px 8px;
}

form:not(.no_inheritance) input[type="text"].mini,
form:not(.no_inheritance) input[type="password"].mini,
form:not(.no_inheritance) input[type="email"].mini,
form:not(.no_inheritance) input[type="tel"].mini,
form:not(.no_inheritance) input[type="url"].mini,
form:not(.no_inheritance) input[type="number"].mini,
form:not(.no_inheritance) textarea.mini {
  width: 80px;
}

form:not(.no_inheritance) input[type="text"].price-mini,
form:not(.no_inheritance) input[type="password"].price-mini,
form:not(.no_inheritance) input[type="email"].price-mini,
form:not(.no_inheritance) input[type="tel"].price-mini,
form:not(.no_inheritance) input[type="url"].price-mini,
form:not(.no_inheritance) input[type="number"].price-mini,
form:not(.no_inheritance) textarea.price-mini {
  width: 100px;
}

form:not(.no_inheritance) input[type="text"]:disabled,
form:not(.no_inheritance) input[type="password"]:disabled,
form:not(.no_inheritance) input[type="email"]:disabled,
form:not(.no_inheritance) input[type="tel"]:disabled,
form:not(.no_inheritance) input[type="url"]:disabled,
form:not(.no_inheritance) input[type="number"]:disabled,
form:not(.no_inheritance) textarea:disabled {
  opacity: 0.5;
}

form:not(.no_inheritance) input[type="text"][readonly="readonly"], form:not(.no_inheritance) input[type="text"][readonly],
form:not(.no_inheritance) input[type="password"][readonly="readonly"],
form:not(.no_inheritance) input[type="password"][readonly],
form:not(.no_inheritance) input[type="email"][readonly="readonly"],
form:not(.no_inheritance) input[type="email"][readonly],
form:not(.no_inheritance) input[type="tel"][readonly="readonly"],
form:not(.no_inheritance) input[type="tel"][readonly],
form:not(.no_inheritance) input[type="url"][readonly="readonly"],
form:not(.no_inheritance) input[type="url"][readonly],
form:not(.no_inheritance) input[type="number"][readonly="readonly"],
form:not(.no_inheritance) input[type="number"][readonly],
form:not(.no_inheritance) textarea[readonly="readonly"],
form:not(.no_inheritance) textarea[readonly] {
  opacity: 0.5;
}

form:not(.no_inheritance) textarea {
  height: 88px;
}

form:not(.no_inheritance) textarea.short {
  height: 44px;
}

form:not(.no_inheritance) textarea.high {
  height: 176px;
}

form:not(.no_inheritance) fieldset,
form:not(.no_inheritance) .fieldset {
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 14px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
}

form:not(.no_inheritance) fieldset > legend,
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .fieldset > .legend {
  border-radius: 5px 5px 0 0;
}

form:not(.no_inheritance) fieldset > .border-legend,
form:not(.no_inheritance) .fieldset > .border-legend {
  position: absolute;
  top: -14px;
  background: #fafafa;
  display: inline-block;
  padding: 0px 10px;
}

form:not(.no_inheritance) .input-icon-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
}

form:not(.no_inheritance) .input-icon-wrapper input {
  padding-left: 35px;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

form:not(.no_inheritance) .input-icon-wrapper .icon {
  position: absolute;
  top: 50%;
  left: 8px;
}

form:not(.no_inheritance) .input-icon-wrapper .icon.icon-loading {
  margin-top: -12px;
  width: 16px;
  height: 16px;
  background: url("/assets/components/form/input-loader.gif");
}

form:not(.no_inheritance) .input-icon-wrapper .icon.ico-crosshairs {
  margin-top: -17px;
  width: 22px;
  height: 23px;
  font-size: 26px;
  opacity: .6;
}

form:not(.no_inheritance) input[type='submit'] {
  width: 100%;
  border: none;
  font-size: 18px;
}

form:not(.no_inheritance) h2.with-margin, form:not(.no_inheritance) fieldset > legend.with-margin, form:not(.no_inheritance) fieldset > .with-margin.legend, form:not(.no_inheritance) .fieldset > legend.with-margin, form:not(.no_inheritance) .fieldset > .with-margin.legend {
  padding-bottom: 15px;
}

.form-horizontal .control-group {
  clear: both;
  margin-bottom: 10px;
}

.form-horizontal .control-group.indent {
  margin-left: 200px;
}

.form-horizontal .control-group.indent .control-label {
  display: block;
  float: none;
  margin: 0;
  margin-bottom: 5px;
  width: auto;
  text-align: left;
}

.form-horizontal .control-group.indent .controls {
  margin-left: 0;
}

.form-horizontal .control-group.social-provider {
  padding-bottom: 10px;
}

.form-horizontal .control-group.social-provider a.btn, .form-horizontal .control-group.social-provider section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .form-horizontal .control-group.social-provider a {
  margin-left: 20px;
}

@media (max-width: 668px) {
  .form-horizontal .control-group.social-provider a.btn, .form-horizontal .control-group.social-provider section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .form-horizontal .control-group.social-provider a {
    margin-left: 0px;
  }
}

.form-horizontal .control-group .control-label {
  float: left;
  margin-top: 11px;
  width: 180px;
  color: #3c3c3c;
  text-align: right;
}

.form-horizontal .control-group .control-label.social-networks {
  margin-top: 1px;
  width: 110px;
}

.form-horizontal .control-group .control-label.flush-top, .form-horizontal .control-group .control-label.boolean.optional {
  margin: 0 0 20px;
}

@media (max-width: 480px) {
  .form-horizontal .control-group .control-label.flush-top, .form-horizontal .control-group .control-label.boolean.optional {
    margin: 7px 0 0;
  }
}

.form-horizontal .control-group .control-label.check_boxes {
  margin-top: 0px;
}

.form-horizontal .control-group .controls {
  margin-left: 200px;
}

.form-horizontal .control-group .controls input,
.form-horizontal .control-group .controls textarea {
  font-size: 18px;
  font-weight: normal;
}

.form-horizontal .control-group .chosen-container {
  width: 100% !important;
}

.two-thirds.column form.form-horizontal input[type="text"],
.two-thirds.column form.form-horizontal input[type="password"],
.two-thirds.column form.form-horizontal input[type="email"],
.two-thirds.column form.form-horizontal input[type="url"],
.two-thirds.column form.form-horizontal input[type="tel"],
.two-thirds.column form.form-horizontal textarea,
.eleven.columns form.form-horizontal input[type="text"],
.eleven.columns form.form-horizontal input[type="password"],
.eleven.columns form.form-horizontal input[type="email"],
.eleven.columns form.form-horizontal input[type="url"],
.eleven.columns form.form-horizontal input[type="tel"],
.eleven.columns form.form-horizontal textarea {
  width: 367px;
}

.two-thirds.column form.form-horizontal .input-icon-wrapper input[type="text"],
.eleven.columns form.form-horizontal .input-icon-wrapper input[type="text"] {
  width: 340px;
}

.two-thirds.column form.form-horizontal .controls.two-up input,
.eleven.columns form.form-horizontal .controls.two-up input {
  width: 169px;
}

.two-thirds.column form.form-horizontal .controls.two-up input:first-child,
.eleven.columns form.form-horizontal .controls.two-up input:first-child {
  margin-right: 4px;
}

.two-thirds.column form.form-horizontal .actions,
.eleven.columns form.form-horizontal .actions {
  margin-left: 196px;
  margin-bottom: 20px;
}

.two-thirds.column form.form-horizontal .fieldset input[type="text"],
.two-thirds.column form.form-horizontal .fieldset input[type="password"],
.two-thirds.column form.form-horizontal .fieldset input[type="email"],
.two-thirds.column form.form-horizontal .fieldset input[type="url"],
.two-thirds.column form.form-horizontal .fieldset input[type="tel"],
.two-thirds.column form.form-horizontal .fieldset textarea,
.two-thirds.column form.form-horizontal fieldset input[type="text"],
.two-thirds.column form.form-horizontal fieldset input[type="password"],
.two-thirds.column form.form-horizontal fieldset input[type="email"],
.two-thirds.column form.form-horizontal fieldset input[type="url"],
.two-thirds.column form.form-horizontal fieldset input[type="tel"],
.two-thirds.column form.form-horizontal fieldset textarea,
.eleven.columns form.form-horizontal .fieldset input[type="text"],
.eleven.columns form.form-horizontal .fieldset input[type="password"],
.eleven.columns form.form-horizontal .fieldset input[type="email"],
.eleven.columns form.form-horizontal .fieldset input[type="url"],
.eleven.columns form.form-horizontal .fieldset input[type="tel"],
.eleven.columns form.form-horizontal .fieldset textarea,
.eleven.columns form.form-horizontal fieldset input[type="text"],
.eleven.columns form.form-horizontal fieldset input[type="password"],
.eleven.columns form.form-horizontal fieldset input[type="email"],
.eleven.columns form.form-horizontal fieldset input[type="url"],
.eleven.columns form.form-horizontal fieldset input[type="tel"],
.eleven.columns form.form-horizontal fieldset textarea {
  width: 328px;
}

@media screen and (max-width: 959px) {
  .two-thirds.column form.form-horizontal input[type="text"],
  .two-thirds.column form.form-horizontal input[type="password"],
  .two-thirds.column form.form-horizontal input[type="email"],
  .two-thirds.column form.form-horizontal input[type="url"],
  .two-thirds.column form.form-horizontal input[type="tel"],
  .two-thirds.column form.form-horizontal textarea,
  .two-thirds.column form.form-horizontal .controls.two-up input,
  form .hint,
  form .help-block,
  .two-thirds.column form.form-horizontal .fieldset input[type="text"],
  .two-thirds.column form.form-horizontal .fieldset textarea {
    box-sizing: border-box;
    position: relative;
    float: left;
    clear: both;
    width: 100%;
  }
  form .controls .input-icon-wrapper,
  .two-thirds.column form.form-horizontal .input-icon-wrapper input[type="text"] {
    display: block;
    float: left;
    width: 100%;
  }
  .form-horizontal .control-group .control-label {
    text-align: left;
  }
  .form-horizontal .control-group .controls {
    margin: 10px 0;
    clear: both;
  }
  .two-thirds.column form.form-horizontal .actions,
  .form-horizontal .control-group.indent {
    margin-left: 0;
  }
  .actions input[type='submit'] {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  select.currency {
    width: 100%;
  }
}

.input-icon-holder {
  position: relative;
  width: 100%;
}

.input-icon-holder input {
  padding-left: 32px !important;
}

.input-icon-holder input:focus + span {
  color: #505050 !important;
}

.input-icon-holder span {
  position: absolute;
  top: 11px;
  left: 8px;
  color: #a0a0a0;
  line-height: inherit;
}

.input-icon-holder-right {
  position: relative;
  width: 100%;
}

.input-icon-holder-right input {
  padding-right: 32px !important;
}

.input-icon-holder-right input:focus + span {
  color: #505050 !important;
}

.input-icon-holder-right span {
  position: absolute;
  top: 11px;
  right: 8px;
  color: #a0a0a0;
}

.mobile-fixed-position-fix .navbar, .mobile-fixed-position-fix #space_wizard .actions {
  position: absolute !important;
}

.mobile-fixed-position-fix .navbar {
  top: 0;
}

.select2-container {
  width: 100%;
}

.select2-container input.select2-input:focus {
  border: none !important;
}

.error .select2-container, .error .category_tree a {
  border: solid #ec8473 1px !important;
}

.error .select2-container input, .error .select2-container textarea, .error .category_tree a input, .error .category_tree a textarea {
  border: none !important;
}

body {
  background: #f0f0f0;
}

::selection, ::-moz-selection {
  background-color: #5dbded;
  color: white;
}

input[type=text],
textarea {
  -webkit-appearance: none;
}

a,
a:visited,
a:hover {
  text-decoration: none !important;
  transition: opacity .07s ease-in, color .07s ease-in, border .07s ease-in;
}

p a,
p a:visited {
  color: #5dbded;
  text-decoration: none;
  transition: opacity .07s ease-in, color .07s ease-in, border .07s ease-in;
}

p a:hover,
p a:visited:hover {
  color: #2faae8;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.map-wrapper .map-container img {
  max-width: none;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

strong {
  font-weight: bold;
}

.truncated-ellipsis {
  display: inline-block;
  margin: 0 4px;
  padding: 0 4px 10px;
  height: 6px;
  border-radius: 5px;
  background: gainsboro;
  color: #353535;
  vertical-align: middle;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 6px;
}

.intro {
  margin: 20px 10px;
  line-height: 150%;
  font-size: 16px;
}

hr,
.hr {
  padding: 0;
  border: 0;
  height: 1px;
  max-height: 1px;
  background: #a8a9a9;
  color: #a8a9a9;
  border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  margin: 10px -12px 10px -12px;
}

.inline-rule {
  display: inline-block;
  height: 162px;
  background: #b4b5b5;
  text-align: center;
  vertical-align: middle;
  line-height: 14px;
  margin: 0;
  width: 1px;
}

@media (max-width: 320px) {
  .inline-rule {
    display: none;
  }
}

.or-part {
  position: relative;
  bottom: 0px;
  left: -16px;
  display: inline-block;
  margin: 0 0;
  padding: 0 5px;
  background: #f0f0f0;
  color: #a0a0a0;
  font-size: 14px;
}

@media (max-width: 320px) {
  .or-part {
    left: 0;
  }
}

.divider {
  margin: 20px 0;
}

.divider.small {
  margin: 20px 0;
}

.close-button {
  position: absolute;
  top: 6px;
  right: 6px;
  color: black;
  font-size: 1.2em;
  cursor: pointer;
  opacity: .6;
}

.close-button:hover {
  opacity: 1;
}

.margin-bottom {
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.multiline {
  white-space: pre-wrap;
}

html {
  font-size: 18px;
  line-height: 1.5555555556;
}

body,
input,
textarea,
select,
p,
ul,
ol,
em {
  font-size: 18px;
  line-height: 28px;
  color: #787878;
}

@media (max-width: 480px) {
  body,
  input,
  textarea,
  select,
  p,
  ul,
  ol,
  em {
    font-size: 16px;
  }
}

h1,
h1 a,
h2,
form:not(.no_inheritance) fieldset > legend,
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .fieldset > .legend,
h2 a, form:not(.no_inheritance) fieldset > legend a,
form:not(.no_inheritance) fieldset > .legend a,
form:not(.no_inheritance) .fieldset > legend a,
form:not(.no_inheritance) .fieldset > .legend a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a {
  color: #505050;
  font-weight: 600;
}

h1:hover,
h1 a:hover,
h2:hover,
form:not(.no_inheritance) fieldset > legend:hover,
form:not(.no_inheritance) fieldset > .legend:hover,
form:not(.no_inheritance) .fieldset > legend:hover,
form:not(.no_inheritance) .fieldset > .legend:hover,
h2 a:hover, form:not(.no_inheritance) fieldset > legend a:hover,
form:not(.no_inheritance) fieldset > .legend a:hover,
form:not(.no_inheritance) .fieldset > legend a:hover,
form:not(.no_inheritance) .fieldset > .legend a:hover,
h3:hover,
h3 a:hover,
h4:hover,
h4 a:hover,
h5:hover,
h5 a:hover {
  color: #505050;
}

h1 {
  margin-bottom: 20px;
  font-size: 1.77778em;
  line-height: 1.3125em;
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.33333em;
    line-height: 1.16667em;
  }
}

h2, form:not(.no_inheritance) fieldset > legend,
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .fieldset > .legend {
  font-size: 1.33333em;
  line-height: 1.16667em;
}

@media (max-width: 480px) {
  h2, form:not(.no_inheritance) fieldset > legend,
  form:not(.no_inheritance) fieldset > .legend,
  form:not(.no_inheritance) .fieldset > legend,
  form:not(.no_inheritance) .fieldset > .legend {
    font-size: 1.11111em;
    line-height: 1.4em;
  }
}

h3 {
  font-size: 1.11111em;
  line-height: 1.4em;
}

h4 {
  font-size: 1em;
  line-height: 1.55556em;
}

p {
  margin-bottom: 20px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

address {
  font-size: 0.77778em;
  line-height: 2em;
}

address .ico-marker {
  opacity: .7;
}

p.notice,
div.pnotice {
  margin: .5em 0;
  color: #505050;
  font-weight: 600;
  font-size: 12px;
  filter: inherit;
  opacity: .7;
  transition: all .1s ease-in;
}

p.notice label,
div.pnotice label {
  display: block;
  line-height: 1.6;
}

p.notice:hover,
div.pnotice:hover {
  opacity: 1;
  filter: inherit;
}

body.careers {
  font-size: 20px;
  background: gainsboro;
  color: #3c3c3c !important;
  line-height: 28px;
}

body.careers h1 {
  font-size: 50px;
  margin: 10px 0 20px;
  color: #505050;
  font-weight: 900;
}

body.careers h2, body.careers form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) body.careers fieldset > legend, body.careers
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) body.careers fieldset > .legend, body.careers
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) body.careers .fieldset > legend, body.careers
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) body.careers .fieldset > .legend {
  font-size: 40px;
  margin: 10px 0 30px;
  color: #505050;
}

body.careers h3 {
  font-size: 30px;
  color: #505050;
}

body.careers img {
  margin: 0 auto 20px;
  display: block;
}

body.careers .btn.btn-large, body.careers form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.careers input[type='submit'], body.careers section.dashboard ul.locations li.location .links a.btn-large, section.dashboard ul.locations li.location .links body.careers a.btn-large {
  width: 100%;
  margin: 2em auto;
  text-align: center;
}

section#landing-page h1 {
  width: 60%;
  margin: 0 20%;
  color: #FFFFFF;
  font-size: 45px;
  text-align: center;
}

@media (max-width: 1024px) {
  section#landing-page h1 {
    font-size: 34px;
  }
}

@media (max-width: 767px) {
  section#landing-page h1 {
    width: 90%;
    margin: 0 5%;
    font-size: 30px;
  }
}

section#landing-page h2, section#landing-page form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) section#landing-page fieldset > legend, section#landing-page
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) section#landing-page fieldset > .legend, section#landing-page
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) section#landing-page .fieldset > legend, section#landing-page
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) section#landing-page .fieldset > .legend {
  width: 80%;
  margin: 20px 10%;
  color: #505050;
  font-size: 24px;
  text-align: center;
}

@media (max-width: 1024px) {
  section#landing-page h2, section#landing-page form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) section#landing-page fieldset > legend, section#landing-page
  form:not(.no_inheritance) fieldset > .legend,
  form:not(.no_inheritance) section#landing-page fieldset > .legend, section#landing-page
  form:not(.no_inheritance) .fieldset > legend,
  form:not(.no_inheritance) section#landing-page .fieldset > legend, section#landing-page
  form:not(.no_inheritance) .fieldset > .legend,
  form:not(.no_inheritance) section#landing-page .fieldset > .legend {
    margin: 10px 10%;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  section#landing-page h2, section#landing-page form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) section#landing-page fieldset > legend, section#landing-page
  form:not(.no_inheritance) fieldset > .legend,
  form:not(.no_inheritance) section#landing-page fieldset > .legend, section#landing-page
  form:not(.no_inheritance) .fieldset > legend,
  form:not(.no_inheritance) section#landing-page .fieldset > legend, section#landing-page
  form:not(.no_inheritance) .fieldset > .legend,
  form:not(.no_inheritance) section#landing-page .fieldset > .legend {
    width: 90%;
    margin: 10px 5%;
    font-size: 18px;
  }
}

section#landing-page h3 {
  width: 80%;
  margin: 20px 10% 20px 10%;
  color: #505050;
  font-size: 24px;
  text-align: center;
}

@media (max-width: 1024px) {
  section#landing-page h3 {
    margin: 15px 10% 10px 10%;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  section#landing-page h3 {
    width: 90%;
    margin: 10px 5% 10px 5%;
    font-size: 18px;
  }
}

section#landing-page .landing-page-search-link {
  margin-top: 14px;
}

section#landing-page .landing-page-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1;
}

section#landing-page .landing-page-background .header-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
}

section#landing-page .landing-page-background .header-image#header-accounting {
  background: url("/assets/landing-page/hero-accounting.jpg") no-repeat center center;
  background-size: cover;
}

section#landing-page .landing-page-background .header-image#header-law {
  background: url("/assets/landing-page/hero-law.jpg") no-repeat center center;
  background-size: cover;
}

section#landing-page .landing-page-background .header-image#header-hairdressing {
  background: url("/assets/landing-page/hero-hairdressing.jpg") no-repeat center center;
  background-size: cover;
}

section#landing-page .landing-page-background .header-image#header-design {
  background: url("/assets/landing-page/hero-agency.jpg") no-repeat center center;
  background-size: cover;
}

@media (max-width: 480px) {
  section#landing-page .landing-page-background .header-image {
    min-height: auto;
  }
}

section#landing-page .landing-page-background .header-image .hero-color {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

section#landing-page .landing-page-background .header-image .hero-color#color-accounting {
  background: rgba(93, 201, 136, 0.75);
}

section#landing-page .landing-page-background .header-image .hero-color#color-law {
  background: rgba(93, 189, 237, 0.75);
}

section#landing-page .landing-page-background .header-image .hero-color#color-hairdressing {
  background: rgba(232, 92, 65, 0.75);
}

section#landing-page .landing-page-background .header-image .hero-color#color-design {
  background: rgba(255, 193, 39, 0.75);
}

section#landing-page .calltoaction {
  padding: 100px 0 50px 0;
  max-width: 1360px;
  z-index: 2;
}

section#landing-page .calltoaction img {
  position: relative;
  left: 50%;
  margin: 0 0 30px -100px;
  width: 200px;
  height: auto;
  text-align: center;
}

@media (max-width: 1024px) {
  section#landing-page .calltoaction img {
    margin: 0 0 10px -80px;
    width: 160px;
  }
}

section#landing-page .calltoaction .row-fluid {
  width: 50%;
  margin: 30px 25% 20px 25%;
  padding: 0 0 20px 0;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
}

@media (max-width: 767px) {
  section#landing-page .calltoaction .row-fluid {
    width: 90%;
    margin: 30px 5% 20px 5%;
  }
}

@media (max-width: 767px) {
  section#landing-page .calltoaction {
    padding: 50px 0 30px 0;
  }
}

section#w-hotels .header-image {
  margin-top: -60px;
}

section#w-hotels .carousel {
  margin: 0 auto;
  max-width: 1600px;
}

section#w-hotels .span4 {
  position: relative;
}

@media (max-width: 925px) {
  section#w-hotels .span4.listing,
  section#w-hotels .span8.listing {
    clear: both;
    margin: 0;
    width: 100%;
  }
  section#w-hotels .span4.listing h1,
  section#w-hotels .span8.listing h1 {
    margin-top: 20px;
  }
  section#w-hotels .span4.listing .booking-module-container .booking-module,
  section#w-hotels .span8.listing .booking-module-container .booking-module {
    margin: 0;
    border-radius: 5px;
  }
}

section#w-hotels .booking-module-container {
  position: relative !important;
  width: 100% !important;
  margin-left: 0;
}

.accordion-group {
  border-radius: 5px;
  background: #FFFFFF;
}

.accordion-group a.accordion-toggle:after {
  content: "\46";
  float: right;
}

.accordion-group a.accordion-toggle.collapsed:after {
  content: "\44";
}

.accordion-dim .accordion-group {
  border: none;
  background: #d2d2d2;
}

.accordion-dim .accordion-group a.accordion-toggle {
  color: #FFFFFF;
}

.booking-module-container {
  position: static;
  width: auto;
}

@media (min-width: 611px) {
  .booking-module-container {
    margin-left: 15px;
  }
}

.booking-module-container .booking-module .form-horizontal .control-group .controls {
  margin-left: 0px;
}

.booking-module-container .book {
  width: 100%;
}

.booking-module-container .book .btn.disabled, .booking-module-container .book form:not(.no_inheritance) input.disabled[type='submit'], form:not(.no_inheritance) .booking-module-container .book input.disabled[type='submit'], .booking-module-container .book section.dashboard ul.locations li.location .links a.disabled, section.dashboard ul.locations li.location .links .booking-module-container .book a.disabled, .booking-module-container .book input:disabled {
  opacity: 0.5;
}

.booking-module-container .book .btn.click-disabled, .booking-module-container .book form:not(.no_inheritance) input.click-disabled[type='submit'], form:not(.no_inheritance) .booking-module-container .book input.click-disabled[type='submit'], .booking-module-container .book section.dashboard ul.locations li.location .links a.click-disabled, section.dashboard ul.locations li.location .links .booking-module-container .book a.click-disabled {
  opacity: 0.75;
}

.booking-module-container .book .btn, .booking-module-container .book form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) .booking-module-container .book input[type='submit'], .booking-module-container .book section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .booking-module-container .book a {
  height: 50px !important;
  line-height: 50px !important;
  font-size: 30px !important;
}

.booking-module-container .book .suggest-date {
  width: 100%;
  text-align: center;
}

.booking-module-container .booking-price-info {
  font-size: 0.7em;
  display: none;
}

.booking-module-container .booking-body {
  display: block;
}

.booking-module-container .booking-body .booking-message {
  padding: 20px;
}

.booking-module-container .booking-body .booking-message p {
  color: #000;
  font-size: 13pt;
  text-align: center;
  margin: 0;
}

.booking-module-container .ico-chevron-right {
  display: none;
}

.booking-module-container.collapsed .booking-body {
  display: none;
}

.booking-module-container.collapsed .booking-price-info {
  display: block;
}

.booking-module-container.collapsed .ico-chevron-down, .booking-module-container.collapsed .order_dates .select2-container .select2-choice .select2-arrow {
  display: none;
}

.booking-module-container.collapsed .ico-chevron-right {
  display: block;
}

.booking-module-container.collapsed span {
  font-size: 0.7em;
}

.booking-module-container.collapsed span.booking-listing-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.booking-module-container .booking-module {
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  padding: 22px;
  background: #3c3c3c;
  color: #d2d2d2;
  font-size: 32px;
  line-height: 36px;
}

@media (max-width: 1200px) {
  .booking-module-container .booking-module {
    padding: 12px;
    font-size: 26px;
    line-height: 30px;
  }
}

@media (max-width: 319px) {
  .booking-module-container .booking-module {
    font-size: 22px;
    line-height: 28px;
  }
}

.booking-module-container .booking-module .booking-header {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #505050;
}

.booking-module-container .booking-module .booking-header a {
  color: #d2d2d2;
  display: block;
}

.booking-module-container .booking-module .booking-header span {
  font-size: 0.7em;
}

.booking-module-container .booking-module .booking-header span.booking-listing-name {
  width: 90%;
  display: block;
  font-size: 1em;
  line-height: 1.4em;
}

.booking-module-container .booking-module form textarea {
  height: inherit;
  resize: none;
}

.booking-module-container .booking-module .booking-price {
  overflow: hidden;
  padding-bottom: 15px;
  border-bottom: 1px solid #505050;
}

.booking-module-container .booking-module .rfq-explanation {
  overflow: hidden;
  padding-top: 15px;
  border-top: 1px solid #505050;
  padding-bottom: 10px;
}

.booking-module-container .booking-module .rfq-explanation span {
  font-size: 0.65em;
}

.booking-module-container .booking-module .booking-description, .booking-module-container .booking-module .booking-opening-hours {
  margin: 15px 0px 0px;
  font-size: 0.50em;
}

.booking-module-container .booking-module .booking-description h3,
.booking-module-container .booking-module .booking-description span, .booking-module-container .booking-module .booking-opening-hours h3,
.booking-module-container .booking-module .booking-opening-hours span {
  color: #a0a0a0 !important;
}

.booking-module-container .booking-module .booking-description h3, .booking-module-container .booking-module .booking-opening-hours h3 {
  font-size: 1.1em;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .booking-module-container .booking-module .booking-description, .booking-module-container .booking-module .booking-opening-hours {
    font-size: 0.6em;
  }
}

.booking-module-container .booking-module .availability-week-container .enabled {
  color: #d2d2d2 !important;
}

.booking-module-container .booking-module .availability-detailed-container .day-of-week {
  float: left;
  width: 45px;
}

.booking-module-container .booking-module .availability-detailed-container .times-in-day {
  float: left;
}

.booking-module-container .booking-module .availability-detailed-container .times-in-day span {
  display: block;
}

.booking-module-container .booking-module .seller-attachments {
  font-size: 0.50em;
}

.booking-module-container .booking-module .seller-attachments h3 {
  color: #a0a0a0 !important;
}

.booking-module-container .booking-module .seller-attachments a {
  color: #a0a0a0 !important;
}

.booking-module-container .booking-module .seller-attachments a:hover {
  text-decoration: underline !important;
}

.booking-module-container .booking-module .seller-attachments h3 {
  font-size: 1.1em;
}

@media (max-width: 1200px) {
  .booking-module-container .booking-module .seller-attachments {
    font-size: 0.6em;
  }
}

.booking-module-container .booking-module .label {
  display: block;
  float: left;
  width: 30%;
  background: transparent;
  color: #d2d2d2;
  font-size: 30px;
  text-align: center;
  line-height: normal;
}

@media (max-width: 1200px) {
  .booking-module-container .booking-module .label {
    font-size: 100%;
  }
}

.booking-module-container .booking-module .label.unavailable {
  opacity: .1;
}

.booking-module-container .booking-module .label .period {
  display: block;
  clear: both;
  margin-top: 4px;
  color: #a0a0a0;
  font-weight: bold;
  font-size: 40%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 319px) {
  .booking-module-container .booking-module .label .period {
    font-size: 45%;
  }
}

.booking-module-container .booking-module .label.label-hourly-price {
  margin-left: 33%;
}

.booking-module-container .booking-module .label.label-free-price {
  width: 100%;
  text-align: center;
}

@media (max-width: 319px) {
  .booking-module-container .booking-module .label {
    padding: 0;
    margin: 0;
  }
}

.booking-module-container .booking-module .book {
  width: 100%;
}

.booking-module-container .booking-module .book .btn.disabled, .booking-module-container .booking-module .book form:not(.no_inheritance) input.disabled[type='submit'], form:not(.no_inheritance) .booking-module-container .booking-module .book input.disabled[type='submit'], .booking-module-container .booking-module .book section.dashboard ul.locations li.location .links a.disabled, section.dashboard ul.locations li.location .links .booking-module-container .booking-module .book a.disabled {
  opacity: 0.5;
}

.booking-module-container .booking-module .book .btn.click-disabled, .booking-module-container .booking-module .book form:not(.no_inheritance) input.click-disabled[type='submit'], form:not(.no_inheritance) .booking-module-container .booking-module .book input.click-disabled[type='submit'], .booking-module-container .booking-module .book section.dashboard ul.locations li.location .links a.click-disabled, section.dashboard ul.locations li.location .links .booking-module-container .booking-module .book a.click-disabled {
  opacity: 0.75;
}

.booking-module-container .booking-module .calendar-wrapper {
  border-radius: 5px;
  float: left;
  box-sizing: border-box;
  margin: 15px 0px;
  width: 48%;
  background: #e6e6e6;
  color: #505050;
  height: 50px;
  padding-left: 10px;
  white-space: nowrap;
  cursor: pointer;
}

.booking-module-container .booking-module .calendar-wrapper.date-end, .booking-module-container .booking-module .calendar-wrapper.time-picker {
  float: right;
  margin-right: 0;
}

.booking-module-container .booking-module .calendar-wrapper.date-end {
  padding-left: 18px;
}

.booking-module-container .booking-module .calendar-wrapper .time {
  font-size: 90%;
  margin-top: 8px;
}

@media (max-width: 767px) {
  .booking-module-container .booking-module .calendar-wrapper {
    padding-left: 10px !important;
  }
  .booking-module-container .booking-module .calendar-wrapper.time-picker {
    font-size: 85%;
  }
}

@media (max-width: 610px) {
  .booking-module-container .booking-module .calendar-wrapper.time-picker {
    font-size: 100%;
  }
}

@media (max-width: 319px) {
  .booking-module-container .booking-module .calendar-wrapper {
    padding-left: 0px !important;
  }
}

@media (min-width: 610px) and (max-width: 650px) {
  .booking-module-container .booking-module .calendar-wrapper {
    padding-left: 0px !important;
  }
}

.booking-module-container .booking-module .calendar-wrapper .calendar {
  position: relative;
  display: inline-block;
  margin-top: 8px;
}

.booking-module-container .booking-module .calendar-wrapper .calendar .dates,
.booking-module-container .booking-module .calendar-wrapper .calendar input {
  display: inline-block;
  width: 30px;
  height: 38px;
  border: 0;
  border: none;
  background: transparent;
  color: transparent;
  color: #787878;
  vertical-align: middle;
}

.booking-module-container .booking-module .calendar-wrapper .calendar .dates:focus,
.booking-module-container .booking-module .calendar-wrapper .calendar input:focus {
  outline: none !important;
}

@media (max-width: 319px) {
  .booking-module-container .booking-module .calendar-wrapper .calendar {
    display: block;
  }
  .booking-module-container .booking-module .calendar-wrapper .calendar .ico-calender-end, .booking-module-container .booking-module .calendar-wrapper .calendar .ico-calender-start {
    display: none;
  }
  .booking-module-container .booking-module .calendar-wrapper .calendar .calendar-text {
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media (min-width: 610px) and (max-width: 650px) {
  .booking-module-container .booking-module .calendar-wrapper .calendar {
    display: block;
  }
  .booking-module-container .booking-module .calendar-wrapper .calendar .ico-calender-end, .booking-module-container .booking-module .calendar-wrapper .calendar .ico-calender-start {
    display: none;
  }
  .booking-module-container .booking-module .calendar-wrapper .calendar .calendar-text {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.booking-module-container .booking-module .recurring-booking {
  margin-bottom: 30px;
  font-size: 17px;
}

.booking-module-container .booking-module .recurring-booking .or {
  font-size: 15px;
}

.booking-module-container .booking-module .recurring-booking .recurring_select {
  position: absolute;
  top: -99999px;
  left: -999999px;
}

.booking-module-container .booking-module .number-of-units input {
  clear: none;
  max-width: 88px;
  float: right;
  text-align: center;
}

.booking-module-container .booking-module .number-of-units span {
  float: left;
  margin: 15px 0px;
}

.booking-module-container .booking-module .quantity {
  position: relative;
  display: block;
  clear: both;
  margin: 15px 0px;
}

.booking-module-container .booking-module .quantity .resource {
  text-transform: capitalize;
}

.booking-module-container .booking-module .quantity select {
  display: inline-block;
  right: 0;
  width: 70px;
}

.booking-module-container .booking-module .quantity span.customSelect {
  float: right;
  border-radius: 5px;
  min-width: 32px;
  margin: 0px;
  border: none;
  background: transparent;
}

.booking-module-container .booking-module .quantity span.customSelect i {
  top: 0;
}

.booking-module-container .booking-module .price, .booking-module-container .booking-module .discount_price {
  clear: both;
  margin: 15px 0px;
  background-clip: border-box;
  text-align: left;
}

.booking-module-container .booking-module .price span, .booking-module-container .booking-module .discount_price span {
  float: right;
}

.booking-module-container .booking-module .book-it-out, .booking-module-container .booking-module .exclusive-price {
  width: 100%;
}

.booking-module-container .booking-module .book-it-out input, .booking-module-container .booking-module .exclusive-price input {
  width: 100%;
  height: 19px;
}

.booking-module-container .booking-module .book-it-out .discount_price, .booking-module-container .booking-module .exclusive-price .discount_price {
  margin-top: 0px;
  margin-bottom: 50px;
}

.booking-module-container .booking-module .book-it-out {
  display: none;
}

.booking-module-container .booking-module .recurring_select {
  width: 100%;
}

.booking-module-container .booking-module .pricing-tabs li.active span {
  color: #505050;
}

.booking-module-container .booking-module .pricing-tabs li a {
  padding-right: 6px;
  padding-left: 6px;
  display: inline-block;
}

.booking-module-container .booking-submit-row > * {
  margin-left: 0;
}

.booking-module-container .booking-submit-row .offer-button {
  margin-top: 8px;
}

.booking-module-container div[data-exclusive-price-content=true] {
  display: none;
}

.booking-module-container .order_dates .select2-container {
  background: #e6e6e6;
  transition: border 0.2s ease-in, opacity 0.2s ease-in, color 0.2s ease-in;
  margin-bottom: 8px;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
  height: 49px;
}

.booking-module-container .order_dates .select2-container .select2-offscreen {
  clip: none;
}

.booking-module-container .order_dates .select2-container .select2-choice {
  background-color: #e6e6e6;
  background-image: none;
  border: none;
  line-height: 34px;
  height: auto;
  box-shadow: none;
}

.booking-module-container .order_dates .select2-container .select2-choice .select2-arrow {
  width: 40px;
  background-image: none;
  background-color: #e6e6e6;
  border: none;
  color: #999999;
}

.box {
  position: relative;
  margin-bottom: 20px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  background-clip: border-box;
  background: #FFFFFF;
  line-height: 28px;
}

.box header {
  display: block;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #d2d2d2;
  color: #505050;
}

.box header h2, .box header form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .box header fieldset > legend, .box header
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .box header fieldset > .legend, .box header
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .box header .fieldset > legend, .box header
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .box header .fieldset > .legend {
  color: #505050;
}

.box table.summary tbody tr td, .box table.summary tfoot tr td {
  vertical-align: middle;
  padding: 5px 10px;
}

.box table.summary tfoot td, .box table.summary tbody td.total, .box table.summary thead th.total {
  text-align: right;
}

.box table.summary tfoot tr, .box table.summary tbody tr {
  border-bottom: 1px solid #cecfcf;
}

.box .text-label {
  font-weight: bolder;
  width: 200px;
  display: inline-block;
  float: left;
}

.box .summary-line {
  padding: 15px 0;
  width: 100%;
  border-top: 1px solid #cecfcf;
}

.box .summary-line .summary-line-label {
  float: left;
}

.box .summary-line .summary-line-value {
  float: right;
  width: 100px;
}

.box ul.space-wizard {
  display: block;
  overflow: hidden;
  margin-top: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
  margin-left: -15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e6e6e6;
}

.box ul.space-wizard li {
  float: left;
  padding: 0 15px;
  box-sizing: border-box;
  width: 50%;
  border-right: 1px solid #d2d2d2;
  color: #a0a0a0;
  font-size: 22px;
  text-align: center;
  line-height: 60px;
}

.box ul.space-wizard li:last-child {
  border: none;
}

@media (max-width: 480px) {
  .box ul.space-wizard li {
    clear: both;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #f0f0f0;
  }
}

.box ul.space-wizard li.current {
  border: none;
  background: #3c3c3c;
  color: #e6e6e6;
}

.box hr,
.box .hr {
  margin-right: -15px;
  margin-left: -15px;
  border-top: none;
  border-bottom: 2px solid #e6e6e6;
  background: #cecfcf;
  color: #d2d2d2;
  text-align: center;
}

.box hr > span,
.box .hr > span {
  position: relative;
  top: -10px;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  background: #FFFFFF;
  color: #a0a0a0;
  font-size: 18px;
}

.box hr.thin,
.box .hr.thin {
  margin-right: 0;
  margin-left: 0;
  border-bottom: 0;
}

.box .line-divider {
  margin: 25px 0px;
  border-bottom: 1px solid gainsboro;
}

.box .inner-box {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}

.box .inner-box.inner-box-filled {
  background: #f0f0f0;
  border: none;
}

.box.gray {
  border: 1px solid #e6e6e6;
  color: #b8b8b8;
  margin-top: 20px;
}

.box.reservation-review .reservation-properties {
  margin-top: 15px;
}

.box-inverse {
  border: none;
}

.box-inverse header {
  background: #505050;
  color: #FFFFFF;
}

.box-dim {
  border: none;
}

.box-dim header {
  background: #e6e6e6;
}

.box-nav {
  overflow: hidden;
  margin: -14px -14px 14px -14px;
  background: gainsboro;
}

.box-nav li {
  float: left;
}

.box-nav li a {
  display: block;
  padding: 0 10px;
  color: #e6e6e6;
  line-height: 40px;
}

.box-nav li a:hover {
  color: #FFFFFF;
}

.box-nav li.active a,
.box-nav li.active a:hover {
  background: #353535;
  color: #FFFFFF;
}

.box-nav li.active:first-child,
.box-nav li.active:first-child a {
  border-left: none;
}

.box-nav li.right-link {
  float: right;
}

.box-nav li.right-link a {
  padding-left: 22px;
  border-right: none;
  border-left: none;
  background: url("/assets/components/nav/box-return.png") left 10px no-repeat;
  font-weight: normal;
}

.legend {
  border: 1px solid black;
  float: right;
  clear: both;
}

.legend .title {
  display: block;
  margin: 0.5em;
  border-style: solid;
  border-width: 0 0 0 1em;
  padding: 0 0.3em;
}

.chzn-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.submit-secure-payment .text {
  line-height: 40px;
}

.submit-secure-payment .credit-card-images {
  text-align: right;
  line-height: 18px;
}

@media (max-width: 767px) {
  .submit-secure-payment .text {
    line-height: inherit;
    margin-bottom: 15px;
  }
  .submit-secure-payment .credit-card-images {
    text-align: left;
  }
}

.credit-card-fields .control-group .controls {
  margin-left: 0;
}

.credit-card-fields input {
  font-size: 18px;
  height: 49px;
}

.credit-card-fields .radio input, .credit-card-fields .checkbox input {
  height: auto;
}

.credit-card-fields .credit-card {
  margin-top: 15px;
}

.credit-card-fields .credit-card .card-number #reservation_request_card_number {
  padding-right: 40px !important;
}

.credit-card-fields .credit-card .card-number ::-webkit-input-placeholder {
  /* WebKit browsers */
  padding-top: 3px;
  color: #bebebe;
  font-family: 'icon-font';
  font-size: 10px;
  letter-spacing: 3px;
}

.credit-card-fields .credit-card .card-number :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  padding-top: 3px;
  color: #bebebe;
  font-family: 'icon-font';
  font-size: 10px;
  letter-spacing: 3px;
}

.credit-card-fields .credit-card .card-number ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  padding-top: 3px;
  color: #bebebe;
  font-family: 'icon-font';
  font-size: 10px;
  letter-spacing: 3px;
}

.credit-card-fields .credit-card .card-number :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  padding-top: 3px;
  color: #bebebe;
  font-family: 'icon-font';
  font-size: 10px;
  letter-spacing: 3px;
  line-height: 46px;
  height: 49px;
  vertical-align: middle;
}

.credit-card-fields .credit-card .ico-credit-card:before {
  font-size: 30px;
}

@media (min-width: 767px) and (max-width: 860px) {
  .credit-card-fields .card-info.expiry label {
    text-overflow: clip;
    white-space: nowrap;
    width: 70px;
    overflow: hidden;
  }
}

.credit-card-fields .select2-container {
  border: 2px solid #e6e6e6;
  border-radius: 4px;
  background: #e6e6e6 url("/assets/themes/buy_sell/arrow.png") right no-repeat;
  width: 100%;
  font-size: 22px;
  box-sizing: border-box;
  padding: 10px 0 10px 5px;
}

.credit-card-fields .select2-container .select2-choice {
  border: none;
  background: none;
  box-shadow: none;
}

.credit-card-fields .select2-container .select2-choice .select2-arrow {
  display: none;
}

.checkbox,
.radio {
  margin-bottom: 12px;
  padding-left: 28px;
  position: relative;
}

.checkbox:hover,
.radio:hover {
  color: #5dbded;
}

.checkbox:hover a,
.radio:hover a {
  color: #5dbded;
}

.checkbox input,
.radio input {
  outline: none !important;
  opacity: 0;
}

.checkbox input[type=radio],
.checkbox input[type=checkbox],
.radio input[type=radio],
.radio input[type=checkbox] {
  position: absolute;
  left: 0px;
  top: 0px;
}

.checkbox .checkbox-icon-outer {
  border-radius: 5px;
  height: 22px;
  width: 22px;
  left: 0;
  position: absolute;
  top: 0px;
  background: gainsboro;
  transition: all 0.1s ease-in-out;
}

.checkbox .checkbox-icon-outer .checkbox-icon-inner {
  font-size: 18px;
  position: absolute;
  top: -4px;
  left: 2px;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.checkbox.small-checkbox .checkbox-icon-outer {
  height: 16px;
  width: 16px;
  top: 5px;
}

.checkbox.small-checkbox .checkbox-icon-outer .checkbox-icon-inner {
  font-size: 12px;
  top: -6px;
}

.checkbox.checked .checkbox-icon-outer {
  color: #FFFFFF;
  background: #5dbded;
  transition: all 0.1s ease-in-out;
}

.checkbox.checked .checkbox-icon-outer .checkbox-icon-inner {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

.radio .radio-icon-outer {
  position: absolute;
  top: 3px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: gainsboro;
  transition: all 0.1s ease-in-out;
}

.radio .radio-icon-outer .radio-icon-inner {
  width: 4px;
  height: 4px;
  position: absolute;
  left: 50%;
  margin-left: -2px;
  display: block;
  border-radius: 50%;
  top: 50%;
  margin-top: -2px;
  transition: all 0.1s ease-in-out;
}

.radio.checked .radio-icon-outer {
  background: #5dbded;
  transition: all 0.1s ease-in-out;
}

.radio.checked .radio-icon-outer .radio-icon-inner {
  background: #FFFFFF;
  transition: all 0.1s ease-in-out;
}

.error .radio .radio-icon-outer {
  background: #e85c41;
}

.control-group .controls span.customSelect {
  margin: 0;
  margin-bottom: 8px;
  padding: 8px 8px 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  background: #e6e6e6;
  color: #3c3c3c;
  font-size: 22px;
  line-height: 29px;
  transition: border .2s ease-in, opacity .2s ease-in, color .2s ease-in;
  padding-right: 40px;
  font-size: 18px;
}

@media (max-width: 320px) {
  .control-group .controls span.customSelect {
    font-size: 16px;
  }
}

.control-group .controls span.customSelect:focus {
  outline: none;
  border: 2px solid #505050;
  background: #f0f0f0;
  color: #282828;
}

@media (max-width: 480px) {
  .control-group .controls span.customSelect {
    font-size: 18px;
  }
}

.control-group .controls span.customSelect.disabled {
  opacity: 0.5;
}

.control-group .controls select[disabled] ~ span.customSelect {
  opacity: 0.5;
}

.control-group .controls .customSelectInner {
  width: 100% !important;
}

.control-group.error .controls span.customSelect {
  border-color: #e58984;
}

.control-group.error .open-time span.customSelect,
.control-group.error .close-time span.customSelect {
  border-color: #e58984;
}

.customSelect {
  position: relative;
}

.cart-item-list .customSelect i {
  display: none;
}

.custom-select-dropdown-icon {
  position: absolute;
  z-index: 0;
  right: 8px;
  top: 10px;
  font-size: 26px;
  color: #a0a0a0;
}

@keyframes opacity-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dnm-datepicker {
  position: absolute;
  top: 300px;
  left: 300px;
  z-index: 9000;
  width: 280px;
  background: #353535;
  border-radius: 5px;
  box-shadow: 0 0 40px black;
  background-clip: border-box;
  overflow: hidden;
}

@media only screen and (max-width: 480px) {
  .dnm-datepicker {
    position: fixed;
    top: 20px !important;
    left: 20px !important;
    right: 20px !important;
  }
}

.dnm-datepicker .datepicker-prev,
.dnm-datepicker .datepicker-next {
  float: left;
  padding: 10px;
  width: 16px;
  height: 20px;
  color: gainsboro;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  opacity: .4;
}

.dnm-datepicker .datepicker-prev:hover,
.dnm-datepicker .datepicker-next:hover {
  opacity: 1;
}

.dnm-datepicker .datepicker-next {
  float: right;
}

.dnm-datepicker .datepicker-header {
  margin: 0 40px;
  padding-bottom: 4px;
  color: #f0f0f0;
  text-align: center;
}

.dnm-datepicker .datepicker-header .datepicker-month {
  font-size: 20px;
  line-height: 30px;
}

.dnm-datepicker .datepicker-header .datepicker-year {
  font-size: 10px;
  line-height: 10px;
}

.dnm-datepicker .datepicker-week-header,
.dnm-datepicker .datepicker-days {
  overflow: hidden;
  background: #f0f0f0;
}

.dnm-datepicker .datepicker-week-header .datepicker-week-heading,
.dnm-datepicker .datepicker-week-header .datepicker-day,
.dnm-datepicker .datepicker-days .datepicker-week-heading,
.dnm-datepicker .datepicker-days .datepicker-day {
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.dnm-datepicker .datepicker-days {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-clip: border-box;
}

.dnm-datepicker .datepicker-text {
  border-bottom: 1px solid #d2d2d2;
  background-color: #353535;
  color: #353535;
  text-align: center;
  line-height: 32px;
}

.dnm-datepicker .datepicker-text-fadein {
  background-color: #f0f0f0;
  padding: 0 5px;
  -webkit-animation-name: opacity-fadein;
  -moz-animation-name: opacity-fadein;
  animation-name: opacity-fadein;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

.dnm-datepicker .datepicker-days-wrapper, .dnm-datepicker .time-wrapper {
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-clip: border-box;
  background: #f0f0f0;
}

.dnm-datepicker .datepicker-days-wrapper .datepicker-loading, .dnm-datepicker .time-wrapper .datepicker-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0.9;
}

.dnm-datepicker .datepicker-days .datepicker-day {
  color: #505050;
  font-weight: bold;
  cursor: pointer;
}

.dnm-datepicker .datepicker-days .datepicker-day.datepicker-day-dow-0 {
  clear: left;
}

.dnm-datepicker .datepicker-days .datepicker-day:hover {
  background: #e6e6e6;
}

.dnm-datepicker .datepicker-days .datepicker-day.datepicker-day-other-month {
  color: #b6b6b6;
}

.dnm-datepicker .datepicker-days .datepicker-day.active, .dnm-datepicker .datepicker-days .datepicker-day.datepicker-day-other-month.active {
  background: #157A49;
  color: #fff;
}

.dnm-datepicker .datepicker-days .datepicker-day.active.implicit {
  opacity: 0.75;
}

.dnm-datepicker .datepicker-days .datepicker-day.closed, .dnm-datepicker .datepicker-days .datepicker-day.not-available, .dnm-datepicker .datepicker-days .datepicker-day.datepicker-day-other-month.not-available, .dnm-datepicker .datepicker-days .datepicker-day.before-start-date, .dnm-datepicker .datepicker-days .datepicker-day.datepicker-day-other-month.before-start-date, .dnm-datepicker .datepicker-days .datepicker-day.disabled, .dnm-datepicker .datepicker-days .datepicker-day.disabled.closed, .dnm-datepicker .datepicker-days .datepicker-day.datepicker-day-past, .dnm-datepicker .datepicker-days .datepicker-day.datepicker-day-past.disabled {
  color: #a0a0a0;
}

.dnm-datepicker .datepicker-days .datepicker-day.datepicker-booking-end-only {
  background: linear-gradient(135deg, rgba(204, 204, 204, 0) 0%, rgba(204, 204, 204, 0) 50%, #cccccc 50%, #cccccc 100%);
}

.dnm-datepicker .datepicker-days .datepicker-day.datepicker-booking-end-only.active {
  background: linear-gradient(225deg, rgba(204, 204, 204, 0) 0%, rgba(204, 204, 204, 0) 50%, #157a49 50%, #157a49 100%);
  text-shadow: 1px 1px #157a49;
}

.dnm-datepicker .datepicker-days .datepicker-day.datepicker-booking-end-only.disabled, .dnm-datepicker .datepicker-days .datepicker-day.datepicker-booking-end-only.not-available, .dnm-datepicker .datepicker-days .datepicker-day.datepicker-booking-end-only.before-start-date {
  background: none;
}

.time-wrapper {
  height: 160px;
  padding-top: 70px;
}

.time-wrapper .time-start,
.time-wrapper .ico-arrow-right,
.time-wrapper .time-end {
  float: left;
  margin-left: 15px;
  line-height: 40px;
}

.time-wrapper .time-start,
.time-wrapper .time-end {
  background: #e6e6e6;
  color: #3c3c3c;
  border-radius: 5px;
  border: 1px solid #767676;
  height: 40px;
  width: 100px;
  position: relative;
}

.time-wrapper .time-start span,
.time-wrapper .time-end span {
  display: block;
  padding: 0 6px;
}

.time-wrapper .time-start span i,
.time-wrapper .time-end span i {
  float: right;
  font-size: 12px;
  color: #969696;
}

.time-wrapper .time-start select,
.time-wrapper .time-end select {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid transparent;
  height: 40px;
  margin: 0;
  opacity: 0;
}

.time-wrapper .time-start select option[disabled],
.time-wrapper .time-end select option[disabled] {
  color: #d2d2d2;
}

.form-horizontal .control-group.control-fileupload {
  margin-bottom: 25px;
}

.photo-action {
  background-color: #5dbded;
  position: absolute;
  cursor: pointer;
  color: white;
}

.photo-action:hover {
  background: #8bd0f2;
}

.delete-photo {
  top: 6px;
  right: 10px;
}

.resize-photo {
  top: 30px;
  right: 10px;
}

#crop-form {
  position: relative;
}

.rotate-photo {
  z-index: 602;
  right: 0px;
}

.single-photo .fileupload .uploaded {
  overflow: hidden;
}

.single-photo .fileupload .photo-item {
  float: left;
}

.single-photo .fileupload .thumbnail-processing {
  width: 144px;
  height: 44px;
  padding-top: 100px;
}

.single-photo .fileupload img {
  float: left;
}

.single-photo .fileupload .action {
  clear: both;
}

.fileupload .attachment {
  vertical-align: middle;
  margin-left: 10px;
}

.fileupload .attachment img {
  vertical-align: text-top;
}

.fileupload .attachment a {
  color: #5dbded;
}

.fileupload .progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.fileupload .progress img.spinner {
  float: none;
  margin-left: 6px;
  margin-bottom: 5px;
}

.fileupload .progress .filename {
  margin-left: 5px;
  text-align: left;
  color: #b6b6b6;
}

.fileupload .progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width .6s ease;
  margin-top: -23px;
}

.fileupload .ui-sortable img {
  margin: 0 auto;
  width: 180px;
  height: 113px;
}

.fileupload .fileinput-button {
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
}

.fileupload .progress {
  clear: both;
}

.fileupload .animated-progress-bar {
  height: 16px;
  background: url("/assets/progress-bar.gif") 50% 0% no-repeat;
}

.fileupload .photo-placeholder {
  height: 110px;
  width: 182px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 4px;
  display: inline-block;
}

.fileupload .photo-item {
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  overflow: hidden;
  display: inline-block;
  padding: 4px;
  border: 1px dashed #353535;
}

.fileupload .photo-item .photo-position {
  margin: 6px 0 0 -174px;
  position: absolute;
}

.fileupload .photo-item img {
  margin: 0 auto;
}

.fileupload .photo-item input {
  margin: 0;
  width: 180px;
  display: block;
}

.thumbnail-processing {
  width: 180px;
  height: 56px;
  text-align: center;
  padding-top: 109px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .thumbnail-processing {
    padding-top: 114px;
  }
}

.thumbnail-processing .loading-icon {
  width: 100%;
  height: 16px;
  background: url("/assets/ajax-loader.gif") no-repeat center;
}

.thumbnail-processing .loading-text {
  padding-top: 2px;
  position: relative;
}

.thumbnail-processing .loading-text .progress {
  position: absolute;
  top: 8px;
  left: 0px;
  width: 100%;
}

.featured-items {
  margin-bottom: 40px;
}

.featured-items .item {
  background: #fff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.featured-items .item span {
  display: block;
}

.featured-items .item-title {
  font-size: .9em;
  line-height: 1.6em;
  padding-bottom: 5px;
  font-weight: bold;
}

.featured-items .item-description {
  font-size: .8em;
  line-height: 1.4em;
}

.featured-items .item-price {
  font-size: .7em;
  color: #27ae60;
  text-transform: uppercase;
}

.alert {
  cursor: pointer;
  position: absolute;
  z-index: 997;
  border: none;
  border-radius: 0px;
  top: 60px;
  text-align: center;
  color: white;
  text-shadow: none;
  padding: 0px;
  width: 100%;
}

.alert.static {
  position: static;
}

.alert p {
  margin: 0px;
  padding: 0px 49px 0px 0px;
  color: white;
  font-size: 24px;
  line-height: 60px;
}

.alert p span.left-icon {
  margin-right: 15px;
}

.alert p span.right-icon {
  margin-left: 5px;
}

.alert a.close {
  position: absolute;
  top: 0;
  right: 20px;
  color: white;
  font-size: 24px;
  line-height: 60px;
  opacity: .6;
}

.alert a.close:hover {
  color: white;
  opacity: .8;
}

.modal-content .alert {
  position: relative;
  top: auto;
  left: auto;
}

@media (max-width: 480px) {
  .google-map .popover-opened {
    z-index: 997 !important;
  }
}

.google-map img {
  max-width: none;
}

.google-map label {
  width: auto;
  display: inline;
}

.google-map gmnoprint {
  display: none;
}

@media (max-width: 767px) {
  .google-map .gmnoprint {
    display: none;
  }
}

.google-map .google-map-popover {
  background: gainsboro;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  color: #505050;
}

.google-map .google-map-popover h4 {
  display: block;
  overflow: hidden;
  background: #353535;
  color: #FFFFFF;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 15px 20px 0px 20px;
}

.google-map .google-map-popover h4.location-title:first-child {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px 42px 0px 20px;
}

.google-map .google-map-popover .close {
  position: absolute;
  top: 17px;
  right: 12px;
  font-size: 19px;
  font-size: 19px;
  color: #FFFFFF;
}

.google-map .google-map-popover.with-scrollbar h4.location-title:first-child {
  padding: 15px 44px 0px 20px;
}

.google-map .google-map-popover.with-scrollbar .close {
  right: 22px;
}

.google-map .google-map-popover em.arrow,
.google-map .google-map-popover em.arrow-border {
  position: absolute;
  top: 100%;
  left: 50%;
}

.google-map .google-map-popover em.arrow {
  margin-left: -10px;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: gainsboro;
}

.google-map .google-map-popover em.arrow-border {
  margin-left: -11px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #787878;
}

.google-map .google-map-popover #address-popover a {
  padding-left: 4px;
  color: #a0a0a0;
}

.google-map .google-map-popover #address-popover a:hover {
  color: #505050;
}

.google-map-popover-content {
  overflow-y: auto;
  max-height: 292px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.google-map-popover-content h4.location-title,
.google-map-popover-content .listing-sibling,
.google-map-popover-content .popover-image-container,
.google-map-popover-content .popover-image-container a,
.google-map-popover-content .popover-image-container img {
  height: 65px;
  box-sizing: border-box;
}

.google-map-popover-content h4.location-title {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.google-map-popover-content h4.location-title .location-name {
  font-size: 20px;
  font-size: 20px;
}

.google-map-popover-content h4.location-title .location-address {
  color: #646464;
}

.google-map-popover-content .listing-sibling {
  position: relative;
  border-bottom: 1px solid #a0a0a0;
  color: #646464;
}

.google-map-popover-content .listing-sibling:last-child {
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.google-map-popover-content .listing-sibling .popover-image-container {
  float: left;
  overflow: hidden;
  width: 65px;
}

.google-map-popover-content .listing-sibling .popover-image-container a {
  display: block;
  float: left;
}

.google-map-popover-content .listing-sibling .popover-image-container img {
  max-width: 100%;
}

.google-map-popover-content .listing-sibling .popover-info-container {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin-left: -65px;
  padding: 8px 10px 0px 75px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: transparent;
  overflow: hidden;
  color: #646464;
}

.google-map-popover-content .listing-sibling .popover-info-container h4 {
  display: block;
  padding: 0;
  background: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #646464;
  font-size: 20px;
  font-size: 20px;
}

.google-map-popover-content .listing-sibling .popover-info-container span.label {
  float: right;
  margin: 5px 0 0 1px;
  padding: 2px 3px;
  font-size: 80%;
  letter-spacing: -1px;
}

.google-map-popover-content .listing-sibling:hover {
  background: #f0f0f0;
}

.google-map-popover-content .popover-loading {
  text-align: center;
  padding-top: 20px;
  width: auto;
}

.google-map-popover-content .popover-loading span.label {
  float: right;
  margin: 5px 0 0 1px;
  padding: 2px 3px;
  font-size: 80%;
  letter-spacing: -1px;
}

.google-map-popover-content .popover-error {
  text-align: center;
  padding: 20px;
}

.google-map-popover-content .popover-error span {
  font-size: 18px;
}

.box header .button-links {
  width: 100%;
  text-align: center;
}

.box header .button-links ul {
  margin: 0px auto;
}

.box header .button-links ul li {
  display: inline;
  margin-right: 10px;
  text-align: center;
}

.box header .button-links ul li a {
  width: 150px;
}

@media (max-width: 767px) {
  .box header .button-links ul li {
    display: block;
  }
  .box header .button-links ul li a {
    width: 100%;
  }
  .box header .button-links ul li:not(:last-child) {
    margin-bottom: 5px;
  }
}

.hint-toggler {
  margin-left: 5px;
  border: none;
  background: none;
  padding: 0;
  font-size: 0;
  height: 20px;
}

.hint-toggler:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: normal;
  content: "";
  color: #239ed9;
}

.inset-box {
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), 0 1px 0 0 rgba(255, 255, 255, 0.3);
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}

.modal-overlay.visible {
  opacity: 1;
}

html.no-rgba .modal-overlay {
  background: #000;
  opacity: 0.6;
}

.modal-wrapper, .modal-loading, .modal-content.visible {
  background: #f0f0f0;
  border-radius: 5px;
}

.modal-container {
  z-index: 1000;
  padding-bottom: 50px;
  width: 600px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.modal-container.visible {
  opacity: 1;
}

.modal-container.loading .modal-close {
  display: none;
}

@media (max-width: 767px) {
  .modal-container {
    position: fixed;
    top: 20px;
    right: 20px;
    left: 20px;
    margin: 0;
    width: 100%;
    width: auto;
  }
}

.modal-container.sign-up-modal .modal-close {
  top: 12px;
  right: 20px;
}

.modal-container.sign-up-modal .modal-content {
  background: transparent;
}

.modal-container.sign-up-modal .modal-content h2, .modal-container.sign-up-modal .modal-content form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .modal-container.sign-up-modal .modal-content fieldset > legend, .modal-container.sign-up-modal .modal-content
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .modal-container.sign-up-modal .modal-content fieldset > .legend, .modal-container.sign-up-modal .modal-content
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .modal-container.sign-up-modal .modal-content .fieldset > legend, .modal-container.sign-up-modal .modal-content
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .modal-container.sign-up-modal .modal-content .fieldset > .legend {
  margin-left: -10px;
  color: #e6e6e6;
}

.modal-container.sign-up-modal .modal-content .hr {
  background: transparent;
}

.modal-container.sign-up-modal .modal-content .hr span {
  color: gainsboro;
}

.modal-container.sign-up-modal .modal-content header,
.modal-container.sign-up-modal .modal-content #service_buttons a,
.modal-container.sign-up-modal .modal-content .control-group,
.modal-container.sign-up-modal .modal-content .pnotice,
.modal-container.sign-up-modal .modal-content .actions {
  opacity: 1;
}

.modal-container.sign-up-modal .modal-content .pnotice a {
  color: #FFFFFF;
  opacity: 0.7;
}

.modal-container.sign-up-modal .modal-content .pnotice a:hover {
  opacity: 1;
}

.modal-container.sign-up-modal .modal-loading {
  background: transparent;
}

.modal-loading {
  height: 200px;
  background: gainsboro url("/assets/components/modal/loader.gif") center center no-repeat;
}

.modal-content.visible {
  padding: 15px;
  z-index: 1001;
  transition: opacity .2s, transform .4s;
  opacity: 0;
  transform: translateY(-200px);
}

.modal-content.visible.visible {
  opacity: 1;
  transform: translateY(0);
}

.modal-content.visible header {
  display: block;
  margin-top: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
  margin-left: -15px;
  padding: 15px;
  background: #d2d2d2;
  color: #505050;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: auto;
  text-shadow: none;
}

.modal-content.visible header h2, .modal-content.visible header form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .modal-content.visible header fieldset > legend, .modal-content.visible header
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .modal-content.visible header fieldset > .legend, .modal-content.visible header
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .modal-content.visible header .fieldset > legend, .modal-content.visible header
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .modal-content.visible header .fieldset > .legend {
  color: #505050;
  font-weight: 400;
}

.modal-content.visible hr,
.modal-content.visible .hr {
  border-bottom: 0;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.modal-content.visible .hr span {
  position: relative;
  top: -10px;
  display: inline-block;
  padding: 0 8px;
  background: transparent;
  color: #787878;
  font-weight: bold;
}

.modal-content.visible .intro {
  font-weight: bold;
  color: #FFFFFF;
}

.modal-close {
  position: absolute;
  top: 6px;
  right: 8px;
  z-index: 1003;
  width: 18px;
  height: 18px;
  color: #a0a0a0;
  font-size: 18px;
}

.modal-close:hover {
  color: #505050;
}

.modal-body-wrapper {
  position: fixed;
  width: 100%;
}

.multiselect {
  position: relative;
  display: inline-block;
  height: 26px;
  text-align: left;
}

.multiselect .collapsed {
  padding: 4px 8px;
  border-radius: 5px;
  background: #e6e6e6 url("/assets/ui/arrow-down.png") 95% center no-repeat;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  opacity: .8;
  transition: opacity .15s ease-in;
}

.multiselect .collapsed:hover {
  background: #cdcccc url("/assets/ui/arrow-down.png") 95% center no-repeat;
  opacity: 1;
}

.multiselect .expanded {
  position: absolute;
  top: -2px;
  right: -2px;
  left: -2px;
  z-index: 9999;
  border-radius: 5px;
  background: #e9e9e9;
}

.multiselect .expanded .summary,
.multiselect .expanded .item {
  padding: 0 8px;
  color: #353535;
  font-size: 12px;
  cursor: pointer;
}

.multiselect .expanded .summary {
  border-bottom: 1px solid #a0a0a0;
  background: gainsboro url("/assets/ui/arrow-down.png") 95% center no-repeat;
  font-size: 16px;
  line-height: 26px;
  padding: 4px 8px;
  border-radius: 5px;
}

.multiselect .expanded .items {
  overflow: visible;
}

.multiselect .expanded .items.long {
  overflow-y: scroll;
  height: 200px;
}

.multiselect .expanded .item {
  padding: 6px 8px;
  line-height: 14px;
  overflow: hidden;
}

.multiselect .expanded .item input {
  float: left;
}

.multiselect .expanded .item label {
  float: none !important;
  margin: 0;
  margin-left: 22px;
  font-size: 12px !important;
  line-height: 18px;
  margin-right: 0 !important;
}

.navbar .navbar-inner {
  background: #353535;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 0 0 20px;
  height: 60px;
}

.navbar .navbar-inner #header_links {
  float: none;
  position: absolute;
  right: 0px;
  background-color: #353535;
}

.navbar .navbar-inner #logo {
  float: left;
  width: 240px;
  height: 30px;
  margin-top: 15px;
  background: url("/assets/theme/desksnearme/logo/logo.png") no-repeat;
  background-size: auto 100%;
  text-indent: -9999px;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  .navbar .navbar-inner #logo {
    width: 240px;
    height: 30px;
    margin-top: 15px;
    background: url("/assets/theme/desksnearme/logo/logo@2x.png") no-repeat;
    background-size: auto 100%;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-inner #logo {
    width: 60px;
    height: 30px;
    margin-top: 15px;
    background: url("/assets/theme/desksnearme/logo/icon.png") no-repeat;
    background-size: auto 100%;
  }
}

@media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 1.5), (max-width: 767px) and (min-resolution: 144dpi) {
  .navbar .navbar-inner #logo {
    width: 60px;
    height: 30px;
    margin-top: 15px;
    background: url("/assets/theme/desksnearme/logo/icon@2x.png") no-repeat;
    background-position: left;
    background-size: auto 100%;
  }
}

.navbar .divider-vertical {
  margin: 0px;
  height: 60px;
  border: 0;
  width: 1px;
  background: #424242;
}

.navbar .nav-links {
  position: relative;
  line-height: 60px;
}

.navbar .nav-links li {
  height: 60px;
}

.navbar .nav-links li a.nav-link {
  position: relative;
  display: inline-block;
  padding: 18px 20px;
  color: #a0a0a0;
  font-size: 16px;
  line-height: 22px;
  font-size: 20px;
}

.navbar .nav-links li a.nav-link span.icon {
  display: none;
}

.navbar .nav-links li a.nav-link span.ico-plus {
  margin-top: -1px;
  font-size: 25px;
}

.navbar .nav-links li a.nav-link span.ico-user {
  margin-top: -4px;
  vertical-align: -3px;
  font-size: 30px;
}

.navbar .nav-links li a.nav-link span.ico-mail {
  margin-top: -5px;
  vertical-align: -9px;
  font-size: 35px;
}

@media (max-width: 767px) {
  .navbar .nav-links li a.nav-link span.text {
    display: none;
  }
  .navbar .nav-links li a.nav-link span.icon {
    display: inherit;
  }
}

@media (max-width: 340px) {
  .navbar .nav-links li a.nav-link {
    padding: 18px 15px;
  }
}

.navbar .nav-links li a.nav-link span.count {
  margin-left: 8px;
  background: #a0a0a0;
  border-radius: 0.7em;
  color: #353535;
  display: inline-block;
  line-height: 1.3em;
  font-size: 14px;
  text-align: center;
  width: 1.3em;
}

.navbar .nav-links li a.nav-link .ico-chevron-down, .navbar .nav-links li a.nav-link .booking-module-container .order_dates .select2-container .select2-choice .select2-arrow, .booking-module-container .order_dates .select2-container .select2-choice .navbar .nav-links li a.nav-link .select2-arrow {
  padding-left: 8px;
  font-size: 14px;
}

.navbar .nav-links li a.nav-link.btn-blue, .navbar .nav-links section.dashboard ul.locations li.location .links a.nav-link, section.dashboard ul.locations .navbar .nav-links li.location .links a.nav-link {
  height: 60px;
  margin: 0px;
  color: #fff;
  border-radius: 0;
}

.navbar .nav-links li a.nav-link.btn-blue:hover, .navbar .nav-links section.dashboard ul.locations li.location .links a.nav-link:hover, section.dashboard ul.locations .navbar .nav-links li.location .links a.nav-link:hover {
  background: #8bd0f2;
}

.navbar .nav-links li .dropdown-menu {
  min-width: 130px;
  margin: 0 0 0 -1px;
  padding-top: 0px;
  border-radius: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* Remove bootstrap dropdown arrow */
}

.navbar .nav-links li .dropdown-menu li {
  height: 40px;
  line-height: 40px;
}

.navbar .nav-links li .dropdown-menu li a.nav-link {
  padding: 8px 20px;
}

.navbar .nav-links li .dropdown-menu .truncate {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .navbar .nav-links li .dropdown-menu .truncate {
    width: 165px;
  }
}

.navbar .nav-links li .dropdown-menu:before, .navbar .nav-links li .dropdown-menu:after {
  border: none !important;
  content: none !important;
}

/* Below is only for applying colors and borders to the inverted theme.
   Don't put other styles in here, use the navbar block above this one. */
.navbar-inverse {
  background: #353535;
}

.navbar-inverse .navbar-inner {
  background: #353535;
}

.navbar-inverse ul.nav li ul.dropdown-menu {
  background: #353535;
  border-top: 0;
  border-color: #424242;
  box-shadow: none;
}

.navbar-inverse ul.nav li ul.dropdown-menu li {
  width: 100%;
  background: #353535;
}

.navbar-inverse ul.nav li ul.dropdown-menu li a:hover {
  background: #353535;
  color: gainsboro;
}

.navbar-inverse ul.nav li ul.dropdown-menu::after {
  border-bottom-color: #353535;
}

.navbar-inverse ul.nav li.active {
  background: #353535;
}

.navbar-inverse ul.nav li.active a {
  color: #FFFFFF;
}

.navbar-inverse ul.nav li a {
  color: #505050;
}

.navbar-inverse ul.nav li a:active {
  color: gainsboro;
}

.navbar-inverse ul.nav li a:hover {
  color: #d2d2d2;
}

.navbar-inverse ul.nav li.dropdown.open {
  background: #353535;
}

.navbar-inverse ul.nav li.dropdown.open .dropdown-toggle {
  background: #353535;
}

/* end */
.manage-nav {
  /* Customize the navbar links to be fill the entire space of the .navbar */
}

.manage-nav .navbar {
  background: #f0f0f0;
  border-radius: 0 0 5px 5px;
  border: 1px solid gainsboro;
  margin-bottom: 25px;
}

.manage-nav .navbar .navbar-inner {
  height: 50px;
  padding: 0;
  background: #f0f0f0;
  line-height: 40px;
  filter: none;
}

@media (max-width: 767px) {
  .manage-nav .navbar .navbar-inner {
    height: auto;
  }
}

.manage-nav .navbar .nav {
  margin: 0;
  display: table;
  width: 100%;
}

@media (max-width: 767px) {
  .manage-nav .navbar .nav {
    display: block;
  }
}

.manage-nav .navbar .nav li {
  display: table-cell;
  width: 1%;
  float: none;
}

@media (max-width: 767px) {
  .manage-nav .navbar .nav li {
    display: block;
    padding-left: 20px;
  }
  .manage-nav .navbar .nav li:last-child {
    padding-bottom: 10px;
  }
}

.manage-nav .navbar .nav li a {
  text-align: center;
  text-shadow: none;
  padding: 5px 0 0;
  color: #a0a0a0;
  font-size: 18px;
  white-space: nowrap;
}

.manage-nav .navbar .nav li a:hover, .manage-nav .navbar .nav li a.active {
  color: #505050;
}

.pagination {
  text-align: center;
  display: inherit;
}

.pagination ul {
  box-shadow: none;
  list-style: none;
}

.pagination li {
  display: inline;
}

.pagination ul li a {
  background: transparent;
  border: none;
  font-size: 18px;
  padding: 4px 12px;
}

.pagination img {
  float: inherit;
}

.pagination a {
  color: #5dbded;
}

.pagination .page-link {
  padding-top: 10px;
}

.pagination .page-link.active {
  color: #000;
}

.popover {
  position: absolute;
  display: none;
  margin-top: -4px;
  padding: 8px;
  border: 1px solid #939798;
  background: #f5f5f5;
  color: #e6e6e6;
  font-size: 10px;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25), inset 0 1px 0 0 #fff;
}

.popover .arrow,
.popover .top-arrow {
  position: absolute;
  top: 0;
  left: 50%;
  margin: -8px 0 -8px;
  width: 14px;
  height: 8px;
  background: url("/assets/components/popover/top-arrow.png") no-repeat;
}

.popover hr {
  margin: 0px;
  color: #cbd1d3;
  background: #cbd1d3;
  border-bottom: 1px solid #fff;
}

.form-horizontal .control-group .free-price {
  float: left;
}

.form-horizontal .control-group .price-input-options {
  overflow: hidden;
  padding-left: 30px;
}

.form-horizontal .control-group .price-inputs-wrapper {
  padding-right: 20px;
}

.form-horizontal .control-group .price-inputs-wrapper label {
  float: left;
  top: 16px;
}

.form-horizontal .control-group .currency-holder {
  position: relative;
  z-index: 2;
  margin-left: 11px;
  width: 30px;
  color: #505050;
  text-align: right;
}

.form-horizontal .control-group .price-input {
  position: relative;
  z-index: 1;
  margin-left: -25px;
  padding-left: 30px;
  width: 120px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrapper.youtube {
  padding-top: 30px;
}

.review {
  border-top: solid #CACBD3 1px;
  padding: 15px;
}

.review p.comment {
  color: #555555 !important;
  font-size: 1em !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
}

.review .review-date {
  float: right;
  font-size: 12px;
  color: #858585;
}

.review .review-details-questions {
  margin-top: 10px;
}

.review .review-details-questions span.question {
  font-size: 0.8em;
}

.review .name-info {
  text-overflow: ellipsis;
  overflow: hidden;
}

.review .review-details-questions .row-fluid.compact {
  margin-bottom: 10px;
}

.ui-slider {
  position: relative;
  margin-top: 6px;
  height: 11px;
  border-radius: 5px;
  background: #9d9c9c;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  display: block;
  margin-top: -8px;
  margin-left: -4px;
  width: 24px;
  height: 24px;
  outline: none;
  border: none !important;
  border-radius: 5px;
  background: url("https://s3.amazonaws.com/desksnearme.assets/img/ui/knob-green.png") center center no-repeat;
  transition: all 0s false false;
}

.ui-slider .ui-slider-range {
  position: absolute;
  margin-top: -1px;
  height: 12px;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.dnm-page-body {
  position: relative;
  min-height: 100%;
  height: auto;
}

.dnm-page-body.modal-body-wrapper {
  position: fixed;
}

.content-wrapper {
  min-height: 100%;
  height: auto;
}

.content-wrapper .dnm-page {
  padding-top: 90px;
}

.content-wrapper .dnm-page.with-sub-navbar {
  padding-top: 59px;
}

.content-wrapper .dnm-page.no-navbar {
  padding-top: 0;
}

.content-wrapper .footer-push {
  height: 252px;
}

.content-wrapper .footer-push.white-label-footer-push {
  height: 192px;
}

.footer-wrapper {
  margin-top: -192px;
  box-sizing: border-box;
  min-height: 192px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-wrapper .container-fluid {
  padding-top: 25px;
}

@media (max-width: 480px) {
  .footer-wrapper {
    min-height: 242px;
  }
}

@media (max-width: 767px) {
  .footer-wrapper {
    min-height: 242px;
  }
}

@media (max-width: 767px) {
  .mobile-clearfix {
    *zoom: 1;
  }
  .mobile-clearfix:before, .mobile-clearfix:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .mobile-clearfix:after {
    clear: both;
  }
}

.table-simple {
  width: 100%;
  color: #787878;
}

.table-simple th, .table-simple td {
  padding: 8px 0;
}

.table-simple th {
  font-weight: bold;
}

.table-simple tbody tr:hover td {
  background: #E2E2E2;
}

.table-simple tbody td {
  border-top: 1px solid gainsboro;
}

.table-simple tbody .total-row td {
  padding-top: 15px;
  font-weight: bold;
}

.token-input-list {
  overflow: hidden;
  height: auto !important;
  list-style-type: none;
  clear: left;
  height: 1%;
  cursor: text;
  z-index: 999;
  margin: 0;
  padding: 3px 12px;
  border: 1px solid #ccc;
  line-height: 1;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.token-input-list li {
  list-style-type: none;
}

.token-input-list li input {
  border: 0;
  width: 100%;
  padding: 3px 8px;
  background-color: transparent;
  -webkit-appearance: caret;
  border: 0 none !important;
  box-shadow: 0 0 0 transparent !important;
}

.token-input-list li.token-input-token {
  overflow: hidden;
  height: auto !important;
  height: 1%;
  margin: 3px;
  padding: 0 5px 0 0;
  border-radius: 4px;
  background-color: #1a7fbb;
  color: #fff;
  cursor: default;
  display: inline-block;
  text-shadow: 1px -1px rgba(0, 0, 0, 0.4);
  text-transform: lowercase;
}

.token-input-list li.token-input-token p {
  float: left;
  padding: 5px 0 !important;
  line-height: 1 !important;
  margin: 0 0 0 10px;
  color: #fff;
}

.token-input-list li.token-input-token span {
  float: right;
  color: #fff;
  cursor: pointer;
  padding-left: 5px;
}

.token-input-list li.token-input-selected-token {
  background-color: #81c5ed;
  color: #fff;
}

.token-input-dropdown {
  position: absolute;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.6);
  cursor: default;
  font-size: 13px;
  z-index: 1;
}

.token-input-dropdown p {
  margin: 0;
  padding: 5px;
  color: #777;
}

.token-input-dropdown ul {
  margin: 0;
  padding: 0;
}

.token-input-dropdown ul li {
  background-color: #fff;
  padding: 3px 8px;
  list-style-type: none;
}

.token-input-dropdown ul li.token-input-dropdown-item {
  background-color: #fafafa;
}

.token-input-dropdown ul li.token-input-dropdown-item2 {
  background-color: #fff;
}

.token-input-dropdown ul li.token-input-selected-dropdown-item {
  background-color: #81c5ed;
  color: #fff;
}

.token-input-dropdown ul li em {
  font-weight: bold;
  font-style: normal;
}

#main-container .token-input-list {
  border-radius: 4px;
  line-height: 1.4;
}

.tags-filters {
  padding-top: 12px;
  list-style: none;
  width: 80%;
  float: left;
}

.tags-filters li.tag-filter-link {
  display: inline-block;
  position: relative;
}

.tags-filters li.tag-filter-link a {
  color: #bbb;
  text-align: center;
}

.tags-filters li.tag-filter-link:after {
  content: "|";
  margin: 0 10px;
  font-weight: 300;
  color: #ddd;
}

.tags-filters li.tag-filter-link:last-child:after {
  display: none;
}

.tags-filters li.tag-filter-link .selected {
  color: #1a7fbb;
  position: relative;
}

.tags-filters li.tag-filter-link .selected:after {
  height: 16px;
  width: 16px;
  content: "\00d7";
  background: #1a7fbb;
  text-shadow: none;
  display: inline-block;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
  color: #fff;
  margin-left: 5px;
}

.favorite .icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 5px;
}

.favorite .heart {
  background: url("/assets/wish_lists/heart/unselected.png") no-repeat;
  background-size: contain;
}

.favorite .heart.selected {
  background: url("/assets/wish_lists/heart/selected.png") no-repeat;
  background-size: contain;
}

.favorite .tick {
  background: url("/assets/wish_lists/tick/unselected.png") no-repeat;
  background-size: contain;
}

.favorite .tick.selected {
  background: url("/assets/wish_lists/tick/selected.png") no-repeat;
  background-size: contain;
}

.favorite .thumbs_up {
  background: url("/assets/wish_lists/thumbs_up/unselected.png") no-repeat;
  background-size: contain;
}

.favorite .thumbs_up.selected {
  background: url("/assets/wish_lists/thumbs_up/selected.png") no-repeat;
  background-size: contain;
}

.favorite:hover .heart {
  background: url("/assets/wish_lists/heart/hover.png") no-repeat;
  background-size: contain;
}

.favorite:hover .thumbs_up {
  background: url("/assets/wish_lists/thumbs_up/hover.png") no-repeat;
  background-size: contain;
}

.favorite:hover .tick {
  background: url("/assets/wish_lists/tick/hover.png") no-repeat;
  background-size: contain;
}

.products-show .favorite .icon {
  width: 22px;
  height: 22px;
}

div.availability-rules .custom-availability-rules .day .open-time,
div.availability-rules .custom-availability-rules .day .close-time {
  width: auto;
}

.dashboard-home-link:hover {
  opacity: .7;
}

.availability-rules {
  padding-left: 2px;
}

.availability-rules .radio .icon,
.availability-rules .radio .icon-to-fade {
  margin-top: 4px;
}

.availability-rules .open-time,
.availability-rules .close-time {
  margin-right: 10px;
  padding-right: 0px;
}

.gallery li.photo-item {
  margin-bottom: 20px;
  clear: both;
}

.gallery li.photo-item img {
  float: left;
}

.gallery li.photo-item .delete-photo {
  float: left;
  margin: 0px 0 0 -45px;
  font-size: 24px;
  text-shadow: black 0 0 5px;
  color: white;
  opacity: .5;
}

.gallery li.photo-item .delete-photo:hover {
  opacity: 1;
}

.gallery li.photo-item .caption-photo {
  margin-top: 10px;
}

.gallery li.photo-item .loading {
  background: url("/assets/ajax-loader.gif") no-repeat center;
  min-width: 20px;
  min-height: 24px;
}

.gallery li.photo-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 668px) {
  .btn.facebook, form:not(.no_inheritance) input.facebook[type='submit'], section.dashboard ul.locations li.location .links a.facebook,
  .btn.linkedin,
  form:not(.no_inheritance) input.linkedin[type='submit'],
  section.dashboard ul.locations li.location .links a.linkedin,
  .btn.twitter,
  form:not(.no_inheritance) input.twitter[type='submit'],
  section.dashboard ul.locations li.location .links a.twitter,
  .btn.instagram,
  form:not(.no_inheritance) input.instagram[type='submit'],
  section.dashboard ul.locations li.location .links a.instagram {
    margin-bottom: .25em;
    width: 100%;
    text-align: center;
  }
}

.avatar-container {
  display: inline-block;
}

.avatar-container img {
  margin-right: 1em;
}

section.dashboard ul {
  list-style: none;
}

section.dashboard ul li {
  list-style: none;
  overflow: hidden;
}

section.dashboard form {
  margin-top: 30px;
}

section.dashboard p.no_data {
  text-align: center;
  margin-top: 20px;
}

section.dashboard .limited_text .limiter {
  margin-top: 0;
}

section.dashboard .help-block {
  color: #505050;
  font-size: 12px;
}

section.dashboard .dashboard-save {
  margin-bottom: 30px;
  text-align: center;
}

section.dashboard .loading {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 160;
  margin: 0 auto;
  padding: 8px;
  width: 300px;
  height: 70px;
  background: #e6e6e6;
  border-radius: 5px;
  text-align: center;
}

section.dashboard .loading .span {
  padding: 0 !important;
  margin: 0 !important;
}

section.dashboard .screen-locker {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  width: 100%;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

section.dashboard ul.locations ul.location-and-listings {
  margin: 0px;
}

section.dashboard ul.locations > li {
  padding-bottom: 15px;
}

section.dashboard ul.locations form .control-group {
  margin-bottom: 25px;
}

section.dashboard ul.locations li.location {
  overflow: hidden;
  font-size: 30px;
  color: #505050;
}

section.dashboard ul.locations li.location .name {
  float: left;
  margin-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

section.dashboard ul.locations li.location .name a {
  font-size: 18px;
  color: #505050;
  position: relative;
  top: -3px;
}

section.dashboard ul.locations li.location a {
  overflow: auto;
}

section.dashboard ul.locations li.location .links {
  float: right;
  height: 38px;
}

section.dashboard ul.locations li.location .links span {
  color: #fff;
}

section.dashboard ul.locations li.location .links a {
  margin-right: 5px;
  overflow: hidden;
}

section.dashboard ul.locations li.location .links a:last-child {
  margin-right: 0px;
}

section.dashboard ul.locations li.location .links span.icon {
  vertical-align: top;
}

@media (max-width: 768px) {
  section.dashboard ul.locations li.location .links span.text {
    display: none;
  }
}

@media (min-width: 767px) {
  section.dashboard ul.locations li.location .links span.icon {
    display: none;
  }
}

section.dashboard ul.locations li.listing {
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #E2E2E2;
  margin-top: 10px;
  height: 38px;
}

section.dashboard ul.locations li.listing.edit-location {
  border: none;
}

section.dashboard ul.locations li.listing .name {
  float: left;
  color: #3c3c3c;
}

section.dashboard ul.locations li.listing a {
  color: #3c3c3c;
  font-size: 18px;
  display: block;
  padding: 5px 15px 5px 20px;
}

section.dashboard ul.locations li.listing a .no-hide {
  display: inline;
}

section.dashboard ul.locations li.listing a:hover {
  background-color: #E2E2E2;
}

section.dashboard ul.locations li.listing .links {
  float: right;
  margin-left: 12px;
}

section.dashboard ul.locations li.listing .bar-text {
  float: left;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  section.dashboard ul.locations li.listing .links span.text {
    display: none;
  }
}

@media (min-width: 767px) {
  section.dashboard ul.locations li.listing .links span.icon {
    display: none;
  }
}

section.dashboard ul.locations li.new-location {
  padding: 0px;
  margin: 0 0 25px 0;
  border: none;
}

section.dashboard ul.locations li.new-location .links .icon {
  vertical-align: top;
}

section.dashboard ul.locations li.new-location a {
  color: #fff;
  padding: 0 10px;
  background: #5dbded;
}

section.dashboard ul.locations li.new-location a:hover {
  background: #8bd0f2;
}

section.dashboard .spacer {
  margin-bottom: 20px;
}

section.dashboard .delete {
  float: left;
  padding-top: 10px;
  color: #a0a0a0;
  font-size: 80%;
}

section.dashboard .delete:hover {
  color: #505050;
}

section.dashboard .actions a {
  width: 100%;
}

section.dashboard .action-buttons {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E2E2E2;
}

section.dashboard .action-buttons .btn-white {
  border-width: 1px !important;
  margin-right: 10px;
}

section.dashboard .action-buttons .btn-white:last-child {
  margin-right: 0px;
}

h1.manage-locations-home-link {
  line-height: 1.7em;
}

.bank_account_fields .flash.error {
  color: #e85c41;
  font-size: 1.1em;
  margin: 20px 0px;
}

.bank_account_fields .flash.error ul li {
  color: #e85c41;
  font-size: 1.1em;
  margin: 20px 0px;
}

ul.location-and-listings .filter span {
  padding: 0px 10px;
}

#data-uploads .data-upload .span2 {
  font-size: 0.90em;
}

#data-uploads .data-upload .state .importing-text {
  font-style: italic;
  margin-right: 10px;
  color: #0d90d1;
}

#data-uploads .data-upload .state .progress {
  height: 10px;
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

#data-uploads .data-upload .state.waiting {
  color: #ffc840;
  font-style: italic;
}

#data-uploads .data-upload .state.waiting a {
  color: #ffc840;
  font-style: italic;
}

#data-uploads .data-upload .state.waiting .text {
  padding-right: 5px;
}

.control-fileupload .btn-blue, .control-fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .control-fileupload a {
  color: #fff;
}

section.home #hero {
  position: relative;
  margin-top: -32px;
  width: auto;
  height: 580px;
  background-size: cover;
  background-image: url("/assets/theme/desksnearme/hero.jpg");
  background-position: center center;
  background-repeat: no-repeat;
}

section.home #hero > * {
  position: relative;
  z-index: 2;
}

section.home #hero > .hero-shadow {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to left, rgba(30, 30, 34, 0), rgba(30, 30, 34, 0.4) 60%, rgba(30, 30, 34, 0.6));
}

section.home #hero .call-to-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  text-align: center;
}

section.home #hero .call-to-action a {
  display: inline-block;
  color: #E2E2E2;
  font-size: 24px;
  line-height: 1;
  line-height: 36px;
  text-shadow: black 0 0 1px, black 0 0 1px, black 0 0 1px, black 0 0 1px, black 0 0 1px;
}

section.home #hero .call-to-action a span.ico-chevron-down, section.home #hero .call-to-action a .booking-module-container .order_dates .select2-container .select2-choice span.select2-arrow, .booking-module-container .order_dates .select2-container .select2-choice section.home #hero .call-to-action a span.select2-arrow {
  cursor: pointer;
}

@media (max-width: 767px) {
  section.home #hero {
    height: 420px;
  }
}

@media (max-width: 480px) {
  section.home #hero {
    height: 380px;
  }
}

section.home .caption a {
  position: absolute;
  top: 580px;
  right: 20px;
  color: rgba(240, 240, 240, 0.7);
}

section.home .caption a:hover {
  color: white;
}

@media (max-width: 767px) {
  section.home .caption a {
    top: 430px;
  }
}

@media (max-width: 480px) {
  section.home .caption a {
    display: none;
  }
}

section.home section#hero + .container {
  width: 100%;
  padding: 0;
}

#search_row {
  margin-top: 180px;
}

@media (max-width: 767px) {
  #search_row {
    margin-top: 100px;
  }
}

@media (max-width: 480px) {
  #search_row {
    margin-top: 70px;
  }
}

#search_row h1 {
  display: block;
  float: left;
  margin-bottom: 10px;
  color: #E2E2E2;
  font-size: 48px;
  line-height: 58px;
  text-shadow: black 0 0 5px, black 0 0 8px, black 0 0 10px, black 0 0 20px, black 0 0 30px;
  filter: glow(color="#FF000000", strength=3);
  -ms-filter: glow(color="#FF000000", strength=3);
}

@media (max-width: 320px) {
  #search_row h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }
}

#search_row .black-box {
  position: relative;
  padding: 8px;
  border-radius: 5px;
  background: #1e1e1e;
  box-sizing: border-box;
}

#search_row h2, #search_row form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) #search_row fieldset > legend, #search_row
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) #search_row fieldset > .legend, #search_row
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) #search_row .fieldset > legend, #search_row
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) #search_row .fieldset > .legend {
  float: left;
  margin: 10px 0 10px 10px;
  margin-top: 17px;
  color: #787878;
  font-weight: normal;
  font-size: 16px;
  margin-left: 10px;
}

@media (max-width: 480px) {
  #search_row h2, #search_row form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) #search_row fieldset > legend, #search_row
  form:not(.no_inheritance) fieldset > .legend,
  form:not(.no_inheritance) #search_row fieldset > .legend, #search_row
  form:not(.no_inheritance) .fieldset > legend,
  form:not(.no_inheritance) #search_row .fieldset > legend, #search_row
  form:not(.no_inheritance) .fieldset > .legend,
  form:not(.no_inheritance) #search_row .fieldset > .legend {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}

#search_row form input[type="text"] {
  margin-bottom: 0;
}

#search_row form {
  clear: both;
}

#search_row form label {
  cursor: pointer;
  margin-bottom: 8px;
}

#search_row .price-range {
  width: 370px;
  margin-right: 0;
  margin-left: 0px;
}

form.search-box-liquid-tag a.search-button {
  background-color: #e6e6e6;
  padding: 0 50px;
}

.main-page {
  height: 100%;
  min-height: 100%;
}

.main-page .content-wrapper {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

.main-page .dnm-page {
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding-top: 0px;
  box-sizing: border-box;
}

.main-page .dnm-page > .container-fluid:before, .main-page .dnm-page > .container-fluid:after {
  display: block !important;
}

.main-page .dnm-page #content {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.main-page .dnm-page #content #hero {
  margin: 0px;
  height: 100% !important;
  width: 100%;
}

.main-page .footer-push {
  display: none;
}

.main-page .footer-wrapper {
  position: relative;
  top: 0px;
  left: 0px;
  margin-top: 0px;
}

section.projects .project-header .collaborators {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #FAFBFB;
  margin-bottom: 15px;
}

section.projects .project-header .number {
  color: #353535;
  font-weight: bold;
  font-size: 24px;
}

section.projects .project-header .desc {
  font-size: 14px;
}

section.projects .project-header .project-button {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
}

section.projects .project-header .project-button.follow-button {
  background-color: #66707E;
}

section.projects .project-header .project-button.follow-button:hover {
  background: #7f8998;
}

section.projects .project-header .project-button.follow-button:active {
  background: #4f5762;
}

section.projects .project-header .project-button.join-button {
  margin-top: 10px;
  background-color: #5EBDEB;
}

section.projects .project-header .project-button.join-button:hover {
  background: #8bd0f1;
}

section.projects .project-header .project-button.join-button:active {
  background: #31aae5;
}

section.projects .project-content {
  margin: 0px -20px;
  padding: 60px 20px 40px 20px;
  background: #FAFBFB;
}

section.projects .project-content .nav-tabs {
  margin-bottom: 0px;
  border-color: #E3E4E8;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
}

section.projects .project-content .nav-tabs li {
  border-color: #E3E4E8;
  border-style: solid;
  padding: 20px 15px;
  text-align: center;
  background: #FAFBFB;
}

section.projects .project-content .nav-tabs li.active {
  border-width: 2px 2px 0px 2px;
  margin-bottom: -2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: white;
  border-bottom: none;
}

section.projects .project-content .nav-tabs li a, section.projects .project-content .nav-tabs li a:hover, section.projects .project-content .nav-tabs li a:visited {
  color: #34383B;
  border: none;
}

section.projects .project-content .tab-content {
  background: white;
  border-color: #E3E4E8;
  border-style: solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-width: 0px 2px 2px 2px;
  padding: 20px 15px 20px 25px;
}

.documents .row-fluid {
  margin-bottom: 5px;
}

.documents .hidden-field {
  display: none;
}

.documents .upload .btn-upload-payment-document {
  color: black;
  background-color: white;
  border: 1px solid #eee;
  width: 30%;
  padding: 10px 20px;
}

.documents .upload span.file-name {
  padding-left: 20px;
}

/*** Map View ***/
section.search#content {
  margin-top: -30px;
}

@media (max-width: 767px) {
  section.search#content {
    margin-top: -55px;
  }
}

section.search#content .search-filter-box select.time-select option[disabled] {
  display: none;
}

section.search#content .loading {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 980;
  margin: 0 auto;
  padding: 8px;
  width: 32px;
  height: 32px;
  background: #e6e6e6;
  border-radius: 5px;
}

section.search#content .screen-locker {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  width: 100%;
  z-index: 979;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

section.search#content .google-map-wrapper,
section.search#content .google-map .inner {
  z-index: 978;
}

@media (max-width: 480px) {
  section.search#content .google-map-wrapper,
  section.search#content .google-map .inner {
    height: 150px;
  }
}

section.search#content .google-map-wrapper .cluster[title] {
  margin-top: -10px;
  margin-left: -2px;
  border: 2px solid #6b0b0d;
  border-radius: 42px;
  background: #ba000d !important;
  color: #fff !important;
  font-size: 14px !important;
}

section.search#content #results.no_results {
  z-index: 977;
  padding: 40px 0;
}

section.search#content #results.no_results.with_slider {
  margin-top: 40px;
}

section.search#content #results.no_results.map {
  position: absolute;
  top: 110px;
}

@media (max-width: 1150px) {
  section.search#content #results.no_results {
    margin-top: 40px;
  }
}

@media (max-width: 480px) {
  section.search#content #results.no_results {
    margin-top: 30px;
  }
}

@media (max-width: 320px) {
  section.search#content #results.no_results {
    margin-top: 100px;
  }
}

section.search#content #results.no_results form {
  margin-top: 30px;
}

section.search#content div.google-map {
  background: #e6e6e6;
  margin: 0;
}

section.search#content div.google-map.affix {
  z-index: 978;
  top: 48px;
}

@media (min-width: 1400px) {
  section.search#content div.google-map.affix .inner {
    width: 1360px;
  }
}

@media (max-width: 1399px) {
  section.search#content div.google-map.affix {
    left: 20px;
    right: 20px;
  }
}

section.search#content div.google-map .inner {
  height: 300px;
}

@media (max-width: 480px) {
  section.search#content div.google-map .inner {
    height: 150px;
  }
}

section.search#content div.google-map .search-map-redo-search-control {
  background: rgba(255, 255, 255, 0.7);
  padding: 2px 4px;
  margin-left: 12px;
  border-radius: 5px;
}

section.search#content div.google-map .search-map-redo-search-control label {
  font-size: 14px;
}

@media (max-width: 960px) {
  section.search#content div.google-map .search-map-redo-search-control {
    display: none;
  }
}

section.search#content .location .reserve-listing {
  margin-top: 37px;
}

section.search#content .location .listing .reserve-listing {
  margin-top: 0px;
}

section.search#content .reserve-listing {
  text-align: right;
}

section.search#content .reserve-listing a {
  border-radius: 5px 0 0 5px;
  width: 100%;
  text-align: center;
  max-width: 100px;
}

@media (max-width: 767px) {
  section.search#content .reserve-listing {
    text-align: left;
  }
  section.search#content .reserve-listing a {
    border-radius: 5px;
  }
}

@media (min-width: 768px) {
  section.search#content .reserve-listing {
    width: 9.3333%;
    margin-left: 2%;
  }
}

/*** End Map View ***/
#search_row .btn-green, #search_row form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) #search_row input[type='submit'],
.black-box .btn-green,
.black-box form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance)
.black-box input[type='submit'] {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

#search_row .tagline {
  float: none;
  text-align: center;
}

/*** Home Page Search ***/
form.search-box {
  position: relative;
}

form.search-box .input-wrapper, form.search-box .button-wrapper {
  position: relative;
  float: left;
  box-sizing: border-box;
}

form.search-box input[type='submit'] {
  position: absolute;
  left: -999em;
  top: 0;
}

form.search-box .input-wrapper {
  width: 100%;
}

form.search-box .input-wrapper input {
  padding-left: 35px;
}

form.search-box .input-wrapper input.search_date {
  padding-left: 5px;
  opacity: 1.0;
}

form.search-box .input-wrapper .geolocation {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #a0a0a0;
  width: 34px;
}

form.search-box .input-wrapper .geolocation:hover {
  color: #505050;
  cursor: pointer;
}

form.search-box .input-wrapper .geo-loading {
  position: absolute;
  width: 16px;
}

form.search-box .select2-choice, form.search-box .select2-container {
  border: solid #D4D4D4 1px;
  border-radius: 4px;
  background: #e6e6e6 url("/assets/themes/buy_sell/arrow.png") right no-repeat;
  width: 100%;
  font-size: 22px;
  box-sizing: border-box;
}

form.search-box .select2-choice .select2-arrow, form.search-box .select2-container .select2-arrow {
  display: none;
}

form.search-box .select2-container {
  padding: 10px 0 10px 5px;
}

form.search-box .select2-choice {
  border: none;
  box-shadow: none;
}

form.search-box .no-text-search {
  width: 100%;
  height: 50px;
}

form.search-box .button-wrapper {
  width: 15%;
}

form.search-box .button-wrapper a {
  display: block;
}

form.search-box .search-button {
  display: block;
}

@media (max-width: 767px) {
  form.search-box .row-fluid [class*="span"] {
    margin-bottom: 5px;
  }
  form.search-box .customSelect {
    width: 100%;
  }
}

@media (max-width: 480px) {
  form.search-box .input-wrapper, form.search-box .button-wrapper {
    float: none;
    display: block;
    width: 100%;
  }
  form.search-box .input-wrapper {
    padding-right: 0;
    margin-bottom: 8px;
  }
}

form.search-box .search-transactable--radio {
  padding: 10px 0 0;
}

form.search-box .search-transactable--radio label {
  margin: 0 20px 0 0;
  display: inline-block;
  white-space: nowrap;
}

form.search-box .search-transactable--radio label input {
  padding: 0;
}

.select2-results {
  margin-right: 0;
  padding-left: 0;
}

.select2-results .select2-highlighted {
  background: #edf8fe;
  color: #aca8aa;
}

.select2-drop-active {
  margin-top: 5px;
  border-radius: 2px;
  border-color: transparent;
}

#ui-datepicker-div {
  background: #fff;
  box-shadow: 5px 6px 25px #727272;
  width: initial;
  text-align: center;
}

#ui-datepicker-div .ui-datepicker-header {
  padding: 5px 10px;
}

#ui-datepicker-div table {
  background: #edeff0;
  padding: 0px 20px 10px 20px;
}

#ui-datepicker-div table th {
  padding-bottom: 5px;
}

#ui-datepicker-div table td {
  height: 30px;
  width: 30px;
  background: #fff;
  border: none;
}

#ui-datepicker-div tr:nth-child(1) td {
  padding-top: 0;
}

#ui-datepicker-div td a, #ui-datepicker-div td span, #ui-datepicker-div th span {
  color: #000;
  font-size: 12px;
  text-align: center;
  padding: 7px;
  font-weight: 200;
}

#ui-datepicker-div a.ui-corner-all:hover {
  cursor: pointer;
}

#ui-datepicker-div .ui-datepicker-next {
  float: right;
  font-size: 12px;
}

#ui-datepicker-div .ui-datepicker-prev {
  float: left;
  font-size: 12px;
}

#ui-datepicker-div .ui-datepicker-unselectable {
  background: #edeff0;
  color: #000;
}

#ui-datepicker-div .ui-state-active {
  background: #1c7bca;
  color: #fff;
}

/*** End Home Page Search ***/
header#listing_search {
  position: fixed;
  top: 86px;
  right: 30px;
  left: 30px;
  z-index: 988;
  max-width: 1314px;
  padding: 0px 5px 5px 5px;
  margin: 0 auto;
  background: #e6e6e6;
  border-radius: 5px;
}

header#listing_search .saved-search-btn {
  float: right;
  margin-top: 10px;
  margin-right: 20px;
  background-color: #FFFFFF;
}

@media (max-width: 767px) {
  header#listing_search {
    position: static !important;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 26px;
  }
}

header#listing_search .header-inner {
  position: relative;
  max-width: 1360px;
  margin: -2px auto;
  background: #e6e6e6;
}

header#listing_search .list-map-toggle {
  margin-top: 8px;
  height: 49px;
  background: transparent;
  border-radius: 5px;
}

@media (max-width: 767px) {
  header#listing_search .list-map-toggle {
    top: 5px;
    height: 28px;
    border-radius: 5px;
  }
}

header#listing_search .list-map-toggle a.map,
header#listing_search .list-map-toggle a.list {
  float: left;
  z-index: 100;
  display: block;
  width: 50%;
  height: 45px;
  border-radius: 5px;
  background: rgba(93, 189, 237, 0.5);
  color: #FFFFFF;
  font-size: 24px;
  text-align: center;
  line-height: 45px;
}

@media (max-width: 767px) {
  header#listing_search .list-map-toggle a.map,
  header#listing_search .list-map-toggle a.list {
    height: 28px;
    left: 64px;
    font-size: 16px;
    line-height: 28px;
    border-radius: 5px;
  }
}

header#listing_search .list-map-toggle a.map:hover,
header#listing_search .list-map-toggle a.list:hover {
  background: rgba(93, 189, 237, 0.75);
}

header#listing_search .list-map-toggle a.map.active,
header#listing_search .list-map-toggle a.list.active {
  background: #5dbded;
  color: #FFFFFF;
}

header#listing_search .list-map-toggle a.map.active:hover,
header#listing_search .list-map-toggle a.list.active:hover {
  background: #5dbded;
}

header#listing_search .list-map-toggle a.map {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

header#listing_search .list-map-toggle a.map .ico-marker:before {
  position: relative;
  left: -5px;
}

header#listing_search .list-map-toggle a.list {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

header#listing_search .list-map-toggle a.list .ico-list:before {
  position: relative;
  left: -8px;
}

header#listing_search .geolocation-input, header#listing_search .keyword-input {
  position: relative;
}

header#listing_search .geolocation-input .geolocation, header#listing_search .keyword-input .geolocation {
  position: absolute;
  top: 14px;
  left: 13px;
  z-index: 1;
}

header#listing_search .search-loop-icon {
  transition: all .1s ease-in;
  position: absolute;
  line-height: 35px;
  font-size: 20px;
  top: 14px;
  left: 13px;
  width: 35px;
  z-index: 1;
}

@media (max-width: 767px) {
  header#listing_search .search-loop-icon {
    font-size: 18px;
    top: -2px;
    left: 7px;
  }
}

header#listing_search.mixed {
  left: 0px;
  right: 0px;
  border-radius: 0;
  max-width: 100%;
  top: 60px;
}

@media (max-width: 320px) {
  header#listing_search.mixed {
    top: 33px;
  }
}

header#listing_search.mixed form {
  max-width: 100%;
}

header#listing_search.mixed .header-inner {
  max-width: 100%;
}

header#listing_search nav.search-view {
  height: 49px;
  margin-top: 5px;
}

header#listing_search nav.search-view a {
  border-radius: 5px;
  background: #e6e6e6;
  color: #282828;
  text-align: center;
  line-height: 49px;
}

header#listing_search nav.search-view a:hover {
  background: #b6b6b6;
  color: #fff;
}

header#listing_search nav.search-view a span {
  line-height: 49px;
  font-size: 24px;
}

header#listing_search form {
  max-width: 1400px;
  margin: 0 auto;
  transition: margin .1s linear, padding .1s linear;
}

header#listing_search form input {
  margin-top: 6px;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  header#listing_search form input {
    margin-top: 0px;
  }
}

header#listing_search form input[type="text"].query {
  position: relative;
  display: block;
  float: left;
  padding: 8px 5px 8px 45px;
  border: 2px solid #e6e6e6;
  background: #FFFFFF;
}

header#listing_search form input[type="text"].query:focus {
  border: 2px solid #d2d2d2;
}

@media (max-width: 767px) {
  header#listing_search form input[type="text"].query {
    height: 28px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    line-height: 24px !important;
    margin-top: 0px;
    margin-bottom: 3px;
    padding: 0 0 0 29px;
  }
}

header#listing_search form.search_results .geolocation,
header#listing_search form.search_results .search-icon {
  transition: all .1s ease-in;
  line-height: 35px;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 767px) {
  header#listing_search form.search_results .geolocation,
  header#listing_search form.search_results .search-icon {
    font-size: 18px;
    top: -2px;
    left: 7px;
  }
}

header#listing_search form.search_results .geolocation {
  color: #a0a0a0;
  width: 34px;
}

header#listing_search form.search_results .geolocation:hover {
  color: #505050;
}

header#listing_search form.search_results .search-icon {
  box-sizing: border-box;
  margin-top: 7px;
  display: block;
  float: left;
  padding: 7px 0px 0 0;
  width: 45px;
  height: 45px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 5px;
}

header#listing_search form.search_results .search-icon span.text {
  display: none;
}

@media (max-width: 1150px) {
  header#listing_search form.search_results .search-icon {
    right: 0px;
  }
}

@media (max-width: 767px) {
  header#listing_search form.search_results .search-icon {
    margin-top: 0;
    padding: 0 6px 0 0;
    line-height: 32px;
    width: 100%;
    height: 28px;
  }
  header#listing_search form.search_results .search-icon span.text {
    display: inline;
  }
}

@media (max-width: 480px) {
  header#listing_search {
    position: absolute;
  }
}

@media (max-width: 320px) {
  header#listing_search.with-map .search-filters .search-filter {
    display: none;
  }
}

.search-filters {
  margin-top: 8px;
  font-size: 0.9em;
}

.search-filters .search-filter {
  position: relative;
  float: left;
  padding: 8px 8px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  box-sizing: border-box;
}

.search-filters .search-filter:last-child {
  margin-right: 0px;
}

.search-filters .search-filter a {
  display: block;
}

.search-filters .search-filter ul {
  display: none;
  position: absolute;
  z-index: 979;
  left: -1px;
  top: 30px;
  overflow-y: auto;
  padding: 7px 0px;
  max-height: 200px;
  min-width: 100%;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background: #FFFFFF;
}

.search-filters .search-filter ul li {
  overflow: hidden;
  padding: 0px 8px;
  white-space: nowrap;
}

.search-filters .search-filter ul label {
  float: left;
  margin: 0px;
  padding-left: 0px 0px 0px 39px;
}

@media (max-width: 767px) {
  .search-filters .search-filter ul {
    top: 25px;
  }
}

.search-filters .search-filter.filter-margin {
  margin: 0px 5px;
}

.search-filters .search-filter.active {
  background: #FFFFFF;
}

@media (max-width: 767px) {
  .search-filters .search-filter {
    padding: 0 8px;
    height: 28px;
  }
}

.search-filters h3 span {
  font-size: 0.8em;
  padding-right: 8px;
}

.search__filter-row {
  margin-top: 50px;
}

/*** Listing View ***/
section.search section#category-tree.list-view-category-tree {
  margin-top: 145px;
}

section.search section#category-tree.list-view-category-tree.with_slider {
  margin-top: 190px;
}

.span2 section.search section#category-tree {
  margin-top: 0;
}

section.search section#category-tree .nav-item a.active {
  color: #5dbded;
  font-weight: 400;
}

section.search section#category-tree .nav-item ul {
  margin-left: 20px;
}

@media (max-width: 767px) {
  section.search section#category-tree.list-view-category-tree {
    margin-top: 0;
  }
  section.search section#category-tree.list-view-category-tree.with_slider {
    margin-top: 0;
  }
}

.list-search-left {
  width: 220px;
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

.list-search-right {
  width: 665px;
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .list-search-right, .list-search-left {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .list-search-left {
    width: 166px;
  }
  .list-search-right {
    width: 507px;
  }
}

@media (min-width: 1200px) and (max-width: 1389px) {
  .list-search-left {
    width: 270px;
  }
  .list-search-right {
    width: 815px;
  }
}

@media (min-width: 1390px) {
  .list-search-left {
    width: 270px;
  }
  .list-search-right {
    width: 1000px;
  }
}

section.search section#results.list {
  margin-top: 120px;
}

section.search section#results.list.with_slider {
  margin-top: 190px;
}

section.search section#results.list article.listing {
  box-sizing: border-box;
  float: left;
  position: relative;
  margin-top: 25px;
  background: #e6e6e6;
  border-radius: 5px;
  width: 22.9%;
  margin-left: 1%;
  margin-right: 1%;
}

section.search section#results.list article.listing .tooltip-inner {
  text-align: left;
  max-width: none;
}

@media (max-width: 480px) {
  section.search section#results.list article.listing {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  section.search section#results.list article.listing {
    width: 46.9%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}

@media (min-width: 767px) and (max-width: 980px) {
  section.search section#results.list article.listing {
    width: 29.9%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}

section.search section#results.list article.listing .heading {
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 10px;
  height: 74px;
}

section.search section#results.list article.listing .heading .company {
  color: #a0a0a0;
}

section.search section#results.list article.listing .heading .company,
section.search section#results.list article.listing .heading .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

section.search section#results.list article.listing .photo {
  box-sizing: border-box;
  z-index: -1;
  padding-right: 1px;
}

section.search section#results.list article.listing .photo img {
  width: 100%;
  display: block;
}

section.search section#results.list article.listing .photo-container {
  position: relative;
}

section.search section#results.list article.listing .listing-google-map-wrapper {
  position: absolute;
  z-index: 5;
  display: none;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

section.search section#results.list article.listing .listing-google-map-wrapper a {
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 10px;
}

section.search section#results.list article.listing .listing-google-map-wrapper .listing-google-map {
  width: 100%;
  height: 100%;
  padding-right: 1px;
}

section.search section#results.list article.listing .listing-google-map-wrapper .listing-google-map img {
  max-width: none;
}

section.search section#results.list article.listing .listing-google-map-wrapper .listing-google-map .google-map-popover .close {
  top: 0px;
  right: 10px;
  color: #353535;
}

section.search section#results.list article.listing .info {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  color: #FFFFFF;
  font-size: 13px;
  background-image: linear-gradient(rgba(250, 250, 250, 0), #353535);
}

section.search section#results.list article.listing .info .miles,
section.search section#results.list article.listing .info .price {
  position: absolute;
  bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
}

section.search section#results.list article.listing .info .miles {
  left: 0;
}

section.search section#results.list article.listing .info .price {
  right: 0;
}

section.search section#results.list article.listing.user .heading {
  height: 43px;
}

@media (max-width: 1150px) {
  section.search section#results.list {
    margin-top: 125px;
  }
}

@media (max-width: 767px) {
  section.search section#results.list {
    margin-top: 0;
  }
  section.search section#results.list.with_slider {
    margin-top: 0;
  }
}

/*** End Listing View ***/
@media (max-width: 767px) {
  section.search header.products_list .header-inner {
    margin-top: 4px !important;
  }
}

@media (max-width: 767px) {
  section.search header#listing_search.products_list.no_taxons {
    margin-top: 35px;
  }
}

section.search section.products_list.no_taxons, section.search section.no_products_list.no_taxons {
  margin-left: 0;
}

section.search section#results.products_list {
  margin-top: 80px;
  margin-bottom: 20px;
}

section.search section#results.products_list article.product {
  box-sizing: border-box;
  float: left;
  position: relative;
  margin-top: 25px;
  background: #e6e6e6;
  border-radius: 5px;
  width: 22.9%;
  margin-left: 1%;
  margin-right: 1%;
}

section.search section#results.products_list article.product .tooltip-inner {
  text-align: left;
  max-width: none;
}

@media (max-width: 480px) {
  section.search section#results.products_list article.product {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  section.search section#results.products_list article.product {
    width: 46.9%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}

@media (min-width: 767px) and (max-width: 980px) {
  section.search section#results.products_list article.product {
    width: 29.9%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}

section.search section#results.products_list article.product .heading {
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 10px;
  height: 74px;
}

section.search section#results.products_list article.product .heading .company {
  color: #a0a0a0;
}

section.search section#results.products_list article.product .heading .company,
section.search section#results.products_list article.product .heading .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

section.search section#results.products_list article.product .photo {
  box-sizing: border-box;
  z-index: -1;
  padding-right: 1px;
}

section.search section#results.products_list article.product .photo img {
  width: 100%;
  display: block;
}

section.search section#results.products_list article.product .photo-container {
  position: relative;
}

section.search section#results.products_list article.product .info {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  color: #FFFFFF;
  font-size: 13px;
  background-image: linear-gradient(rgba(250, 250, 250, 0), #353535);
}

section.search section#results.products_list article.product .info .price {
  position: absolute;
  bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  right: 0;
}

@media (max-width: 1150px) {
  section.search section#results.products_list {
    margin-top: 125px;
  }
}

@media (max-width: 767px) {
  section.search section#results.products_list {
    margin-top: 0;
  }
}

/*** End Products List View ***/
/*** List Table View ***/
nav.taxons_tree {
  margin-top: 100px;
}

section.products_table {
  margin-top: 45px;
}

.products_table .search-icon {
  margin-left: 0;
  width: 112px !important;
}

.products_table section#category-tree {
  margin-top: 45px !important;
}

.products_table table {
  margin: auto;
  min-width: 958px;
}

@media (max-width: 1150px) {
  .products_table table {
    min-width: 200px;
  }
}

.products_table table, .products_table th, .products_table td {
  background-color: white;
  border: 1px solid #D3D3D3;
}

.products_table th, .products_table td {
  padding: 5px;
}

.products_table table, .products_table th, .products_table td, .products_table p, .products_table a {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #787878 !important;
}

.products_table a.btn, .products_table section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .products_table a {
  font-size: 14px !important;
  font-weight: normal !important;
  padding: 0 10px 0 10px !important;
}

.products_table th {
  text-align: center;
  text-decoration: underline;
  color: #545454;
  background: #D3D3D3;
}

.products_table td {
  border-bottom: 1px solid #D3D3D3;
}

.products_table td.image {
  text-align: center;
}

.products_table td.number {
  text-align: right;
}

header#listing_search.products_table {
  background: none;
  padding: 0 !important;
}

header#listing_search.products_table form .search-container {
  padding-left: 0px;
}

header#listing_search.products_table form .search-container input[type="text"].query {
  padding-left: 8px;
}

/*** End List Table View ***/
/*** Infinite Scroll ***/
.ias_loader h1 {
  text-align: center;
  margin: 35px 0px;
}

.ias_loader h1 img {
  display: inline-block;
  margin: 0px 20px 4px 0px;
  vertical-align: middle;
}

/*** End Infinite Scroll ***/
/*** Products View ***/
section.search section#results.products article.product {
  box-sizing: border-box;
  float: left;
  position: relative;
  margin-top: 0;
  margin-bottom: 25px;
  border-radius: 5px;
  width: 31%;
  margin-left: 2.3%;
  margin-right: 0;
  padding: 0 15px;
}

section.search section#results.products article.product .tooltip-inner {
  text-align: left;
  max-width: none;
}

@media (max-width: 480px) {
  section.search section#results.products article.product {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  section.search section#results.products article.product {
    width: 46.9%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}

@media (min-width: 767px) and (max-width: 980px) {
  section.search section#results.products article.product {
    width: 29.9%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}

section.search section#results.products article.product .heading {
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 0px;
  height: 64px;
}

section.search section#results.products article.product .heading .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em;
}

section.search section#results.products article.product .heading .price {
  font-weight: bold;
}

section.search section#results.products article.product .photo {
  box-sizing: border-box;
  z-index: -1;
  padding-right: 1px;
}

section.search section#results.products article.product .photo img {
  width: 100%;
  display: block;
}

section.search section#results.products article.product .photo-container {
  position: relative;
}

/*** End Products View ***/
form.search-notification-form .control-group:not(.hidden) {
  margin: 0 auto;
  width: 285px;
  display: inline-block;
}

form.search-notification-form input[type=text], form.search-notification-form input[type=email] {
  font-size: 18px;
  font-weight: normal;
  height: 50px;
}

form.search-notification-form input[type=submit] {
  width: inherit;
  margin-left: 5px;
  vertical-align: top;
}

section.search section#results.mixed {
  height: 100%;
  width: 100%;
  padding-top: 65px;
}

section.search section#results.mixed .map {
  width: 33.33%;
  float: left;
}

@media (max-width: 640px) {
  section.search section#results.mixed .map {
    display: none;
  }
}

section.search section#results.mixed .map-marker {
  color: #e6e6e6;
  opacity: 1;
  pointer-events: none;
  text-align: center;
  width: 40px;
  white-space: nowrap;
}

section.search section#results.mixed .list {
  width: 66.66%;
  float: left;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

section.search section#results.mixed .list .filters {
  padding-left: 10px;
}

section.search section#results.mixed .list .filters .search-mixed-filter {
  background-color: #e6e6e6;
  padding: 10px;
  margin-bottom: 1px;
}

section.search section#results.mixed .list .filters .search-mixed-filter .date-range input {
  position: relative;
  display: block;
  float: left;
  margin-top: 5px 0 0;
  padding: 5px 5px 5px 10px;
  border: 2px solid #e6e6e6;
  background: #FFFFFF;
}

section.search section#results.mixed .list .filters .search-mixed-filter .date-range input:focus {
  border: 2px solid #d2d2d2;
}

@media (max-width: 480px) {
  section.search section#results.mixed .list .filters .search-mixed-filter .date-range input {
    height: 28px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    line-height: 24px !important;
    margin-top: 0px;
    margin-bottom: 3px;
    padding: 0 0 0 29px;
  }
}

section.search section#results.mixed .list .filters .search-mixed-filter .date-range #date-range-update {
  box-sizing: border-box;
  display: block;
  margin-top: 2px;
  padding: 5px 7px 0px 7px;
  height: 38px;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 1150px) {
  section.search section#results.mixed .list .filters .search-mixed-filter .date-range #date-range-update {
    right: 0px;
  }
}

@media (max-width: 480px) {
  section.search section#results.mixed .list .filters .search-mixed-filter .date-range #date-range-update {
    top: 5px;
    padding: 0px 8px 0px 8px;
    line-height: 32px;
    height: 28px;
    font-size: 18px;
  }
}

section.search section#results.mixed .list .filters .search-mixed-filter h4 {
  overflow: hidden;
  text-overflow: ellipsis;
}

section.search section#results.mixed .list .filters .search-mixed-filter .container-fluid {
  padding-left: 0px;
}

section.search section#results.mixed .list .filters .search-mixed-filter ul li {
  display: inline;
  padding-right: 15px;
}

section.search section#results.mixed .list .filters .search-mixed-filter ul li.filter-option {
  float: left;
}

section.search section#results.mixed .list .filters .search-mixed-filter ul li.filter-option label.checkbox.small-checkbox {
  float: left;
  margin-bottom: 0px;
}

section.search section#results.mixed .list .filters .search-mixed-filter ul li.filter-option label.checkbox.small-checkbox span.filter-label-text {
  float: left;
  cursor: default;
}

@media (max-width: 319px) {
  section.search section#results.mixed .list .filters {
    display: none;
  }
}

@media (max-width: 640px) {
  section.search section#results.mixed .list .filters {
    margin-right: 10px;
  }
}

section.search section#results.mixed .list .sort {
  background-color: #3c3c3c;
  line-height: 40px;
  height: 40px;
  padding: 10px;
  margin-left: 10px;
  font-size: 90%;
}

section.search section#results.mixed .list .sort h3 {
  display: inline-block;
  color: whitesmoke;
}

section.search section#results.mixed .list .sort .results-count {
  float: left;
}

section.search section#results.mixed .list .sort .sort-by {
  float: right;
  text-align: right;
}

section.search section#results.mixed .list .sort .sort-by span.customSelect {
  color: whitesmoke;
}

section.search section#results.mixed .list .sort .sort-by span.customSelectInner {
  padding-right: 7px;
  width: 75px !important;
  text-align: right;
}

@media (max-width: 479px) {
  section.search section#results.mixed .list .sort .sort-by span.customSelectInner {
    width: 65px !important;
  }
}

section.search section#results.mixed .list .sort .sort-by #sort {
  width: 95px !important;
  cursor: pointer;
}

section.search section#results.mixed .list .sort .per-page {
  float: left;
  text-align: right;
  padding-left: 15px;
}

section.search section#results.mixed .list .sort .per-page span.customSelect {
  color: whitesmoke;
}

section.search section#results.mixed .list .sort .per-page span.customSelectInner {
  padding-right: 7px;
  width: 30px !important;
  text-align: right;
}

section.search section#results.mixed .list .sort .per-page #per_page {
  width: 50px !important;
  cursor: pointer;
}

section.search section#results.mixed .list .sort .saved-search-btn {
  float: right;
}

section.search section#results.mixed .list .sort .custom-select-dropdown-icon {
  position: static;
  font-size: 80%;
  color: whitesmoke;
}

@media (max-width: 479px) {
  section.search section#results.mixed .list .sort {
    height: 120px;
  }
  section.search section#results.mixed .list .sort > div {
    float: none !important;
    text-align: left !important;
  }
}

@media (max-width: 640px) {
  section.search section#results.mixed .list .sort {
    margin-right: 10px;
  }
}

section.search section#results.mixed .list .hidden-locations {
  display: none;
}

section.search section#results.mixed .list .locations {
  position: relative;
  padding-left: 10px;
}

section.search section#results.mixed .list .locations .location {
  position: relative;
  border-top: 4px solid #3c3c3c;
  margin-top: 5px;
}

section.search section#results.mixed .list .locations .location .header {
  position: relative;
  min-height: 200px;
}

section.search section#results.mixed .list .locations .location .location-counter {
  position: absolute;
  color: whitesmoke;
  background-color: #3c3c3c;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 80%;
  z-index: 1;
  border-bottom-right-radius: 5px;
}

section.search section#results.mixed .list .locations .location .location-data {
  padding-top: 25px;
}

section.search section#results.mixed .list .locations .location .location-data .subheader {
  margin: 0 0 55px 0;
}

section.search section#results.mixed .list .locations .location .location-data h2 a, section.search section#results.mixed .list .locations .location .location-data form:not(.no_inheritance) fieldset > legend a, form:not(.no_inheritance) section.search section#results.mixed .list .locations .location .location-data fieldset > legend a, section.search section#results.mixed .list .locations .location .location-data
form:not(.no_inheritance) fieldset > .legend a,
form:not(.no_inheritance) section.search section#results.mixed .list .locations .location .location-data fieldset > .legend a, section.search section#results.mixed .list .locations .location .location-data
form:not(.no_inheritance) .fieldset > legend a,
form:not(.no_inheritance) section.search section#results.mixed .list .locations .location .location-data .fieldset > legend a, section.search section#results.mixed .list .locations .location .location-data
form:not(.no_inheritance) .fieldset > .legend a,
form:not(.no_inheritance) section.search section#results.mixed .list .locations .location .location-data .fieldset > .legend a {
  word-break: break-word;
}

section.search section#results.mixed .list .locations .location .location-photos-container .location-photos {
  width: 100%;
}

section.search section#results.mixed .list .locations .location .location-photos-container .location-photos .carousel {
  margin-bottom: 0;
}

section.search section#results.mixed .list .locations .location .location-photos-container .location-photos .carousel img {
  width: 100%;
}

section.search section#results.mixed .list .locations .location .location-photos-container .location-photos .carousel-control {
  left: 0;
  font-size: 160%;
  margin-top: 10px;
  text-shadow: none;
}

section.search section#results.mixed .list .locations .location .location-photos-container .location-photos .carousel-control.right {
  left: auto;
  right: 0;
}

section.search section#results.mixed .list .locations .location .price-and-types {
  position: absolute;
  bottom: 0;
  width: 60%;
}

@media (max-width: 767px) {
  section.search section#results.mixed .list .locations .location .price-and-types {
    position: static;
    width: 100%;
  }
}

section.search section#results.mixed .list .locations .location .location-price {
  text-align: right;
  padding-right: 50px;
  padding-bottom: 15px;
}

section.search section#results.mixed .list .locations .location .location-price span {
  font-size: 200%;
  color: #505050;
}

@media (max-width: 767px) {
  section.search section#results.mixed .list .locations .location .location-price {
    padding: 15px 0;
    padding-right: 15px;
  }
}

section.search section#results.mixed .list .locations .location .nav {
  margin-bottom: 0;
}

section.search section#results.mixed .list .locations .location ul.nav-tabs li a {
  color: #646464;
  font-size: 90%;
  padding: 8px;
  line-height: 16px;
}

section.search section#results.mixed .list .locations .location ul.nav-tabs li a:hover {
  background-color: transparent;
  border-color: transparent;
}

section.search section#results.mixed .list .locations .location ul.nav-tabs li a:focus {
  border-color: transparent;
}

section.search section#results.mixed .list .locations .location ul.nav-tabs li.active a {
  background-color: gainsboro;
  border: 1px solid gainsboro;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

section.search section#results.mixed .list .locations .location .tabbable .tab-content {
  overflow: hidden;
}

section.search section#results.mixed .list .locations .location .tabbable .title {
  background-color: gainsboro;
  padding: 3px;
}

section.search section#results.mixed .list .locations .location .tabbable .row-fluid {
  background-color: #e6e6e6;
  cursor: pointer;
  margin-bottom: 5px;
}

section.search section#results.mixed .list .locations .location .tabbable .row-fluid .padding-top {
  padding-top: 15px;
}

section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-price span {
  font-size: 80%;
}

@media (min-width: 768px) {
  section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-price {
    width: 9.3333%;
    margin-left: 2%;
  }
}

@media (min-width: 768px) {
  section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-capacity {
    width: 9.3333%;
    margin-left: 2%;
  }
}

@media (min-width: 768px) {
  section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-main-photo {
    width: 18%;
  }
}

section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-name-description {
  padding-top: 15px;
}

section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-name-description p {
  padding-top: 10px;
  line-height: 150%;
  font-size: 90%;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-name-description {
    width: 46%;
    margin-left: 2%;
  }
  section.search section#results.mixed .list .locations .location .tabbable .row-fluid .listing-name-description.without-capacity {
    width: 57.3333%;
  }
}

section.search section#results.mixed .list .locations .location .tabbable .row-fluid .photo img {
  display: block;
}

section.search section#results.mixed .list .locations .location .tabbable .row-fluid .bold {
  font-weight: bold;
}

@media (max-width: 767px) {
  section.search section#results.mixed .list .locations .location .tabbable .row-fluid > div {
    padding: 5px;
  }
}

@media (max-width: 767px) {
  section.search section#results.mixed .list .locations .location .tabbable {
    padding-right: 0;
  }
}

section.search section#results.mixed .list .locations .location.active {
  border-color: #ffc840;
}

section.search section#results.mixed .list .locations .location.active .location-counter {
  background-color: #ffc840;
}

section.search section#results.mixed .list .locations .search-pagination {
  padding-top: 10px;
  padding-bottom: 15px;
}

section.search section#results.mixed .list .locations .search-pagination .page-entries-info {
  font-weight: bold;
  font-size: 0.9em;
}

section.search section#results.mixed .list .locations .search-pagination .pagination {
  margin: 0;
  padding-top: 5px;
}

section.search section#results.mixed .list .locations .search-pagination .pagination a {
  color: #787878;
}

section.search section#results.mixed .list .locations .search-pagination .pagination span, section.search section#results.mixed .list .locations .search-pagination .pagination em, section.search section#results.mixed .list .locations .search-pagination .pagination a {
  display: inline-block;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  min-width: 30px;
  text-align: center;
}

section.search section#results.mixed .list .locations .search-pagination .pagination span.disabled {
  display: none;
}

section.search section#results.mixed .list .locations .search-pagination .pagination a:hover, section.search section#results.mixed .list .locations .search-pagination .pagination em.current {
  color: #3c3c3c;
  border-color: #787878;
}

@media (max-width: 640px) {
  section.search section#results.mixed .list .locations {
    padding-right: 10px;
  }
}

@media (max-width: 640px) {
  section.search section#results.mixed .list {
    width: auto !important;
  }
}

@media (max-width: 767px) {
  section.search section#results.mixed {
    padding-top: 10px;
  }
}

#save-search-modal {
  display: none;
  width: 70%;
  margin-left: -35%;
}

#save-search-modal .content {
  margin: 0px 30px;
}

#save-search-modal .content h4 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 24px;
}

#save-search-modal .content p {
  margin: 0;
}

#save-search-modal .controls {
  text-align: center;
}

#save-search-modal .controls input {
  width: 70%;
  margin: 30px 0px;
  border: 1px solid #5dbded;
  padding: 15px;
}

#save-search-modal .controls button.btn-saved-search-title {
  padding: 0px 70px;
  margin-bottom: 30px;
}

#save-search-status-modal {
  display: none;
}

#save-search-status-modal .content {
  padding: 40px;
  text-align: center;
}

#save-search-status-modal .content h4.modal-title {
  font-size: 24px;
}

#save-search-status-modal .content a {
  color: #5dbded;
}

div.search-view-listing {
  margin-bottom: 50px;
}

div.search-view-listing .search-mixed-filter .slider {
  margin: 70px 20px 30px;
}

div.search-view-listing section#category-tree {
  margin-top: 20px;
}

div.search-view-listing .results-count {
  margin-bottom: 30px;
}

div.search-view-listing label {
  display: block;
}

div.search-view-listing select {
  width: 100%;
}

div.search-view-listing .search-input {
  margin-bottom: 10px;
}

div.search-view-listing .search-input input {
  float: left;
  width: calc(100% - 40px);
  font-size: 16px;
  padding: 0 10px;
  border: none;
  background: #fff;
  box-sizing: border-box;
  height: 36px;
}

div.search-view-listing .search-input .btn, div.search-view-listing .search-input form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) div.search-view-listing .search-input input[type='submit'], div.search-view-listing .search-input section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links div.search-view-listing .search-input a {
  float: right;
  width: 36px;
  height: 36px;
  position: relative;
  padding: 0;
}

div.search-view-listing .search-input .btn img, div.search-view-listing .search-input form:not(.no_inheritance) input[type='submit'] img, form:not(.no_inheritance) div.search-view-listing .search-input input[type='submit'] img, div.search-view-listing .search-input section.dashboard ul.locations li.location .links a img, section.dashboard ul.locations li.location .links div.search-view-listing .search-input a img {
  display: block;
  vertical-align: top;
  float: left;
}

@media screen and (max-width: 768px) {
  .search__table-responsive {
    overflow: auto;
    width: 100%;
  }
  .search__table-responsive > .search__table td, .search__table-responsive > .search__table th {
    white-space: nowrap;
  }
}

.autocomplete-categories {
  margin: 20px 0;
}

.autocomplete-categories label {
  font-weight: bold;
  color: #000;
}

.autocomplete-categories .select2-search-choice > div {
  line-height: 28px;
}

.autocomplete-categories .select2-search-choice-close {
  top: 13px;
}

.search-list-price-slider-container {
  display: block;
  clear: both;
}

.search-mixed-filter .price-slider-container {
  padding: 40px 20px 15px;
}

section.search div.list {
  -webkit-overflow-scrolling: touch;
}

#content.search #results.map article.listing {
  display: none;
}

.row-fluid.reviews-summary.first-rating .span9 {
  margin-left: 0;
}

.row-fluid.row-rating-questions .span3 {
  margin-left: 0;
}

.row-fluid.row-rating-questions .clear-row {
  clear: both;
}

section.listings {
  /*===== TABS AND TABBED CONTENT =====*/
}

section.listings .tab-content-container {
  float: left;
  width: 100%;
  border-bottom: solid #D5D5D5 2px;
}

section.listings .nav {
  margin: 0;
}

section.listings .nav-tabs li a:hover {
  background: transparent;
  border: solid transparent 1px;
}

section.listings .nav-tabs > .active > a, section.listings .nav-tabs > .active > a:hover, section.listings .nav-tabs > .active > a:focus {
  background: #fff !important;
  border: solid transparent 1px;
  color: #000;
}

section.listings .nav-tabs li a {
  color: #3a3a3a;
  padding: 22px 25px;
  font-weight: 600;
}

section.listings .tab-content > article {
  background: #fff;
  padding: 30px 40px;
}

section.listings .tab-content > article p {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 25px;
}

section.listings .padding-row {
  padding: 12px 0;
}

section.listings .padding-row.small {
  padding-top: 0;
}

section.listings h2, section.listings form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) section.listings fieldset > legend, section.listings
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) section.listings fieldset > .legend, section.listings
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) section.listings .fieldset > legend, section.listings
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) section.listings .fieldset > .legend {
  padding: 15px 0;
  font-size: 150%;
  padding-bottom: 30px;
}

section.listings h2 span, section.listings form:not(.no_inheritance) fieldset > legend span, form:not(.no_inheritance) section.listings fieldset > legend span, section.listings
form:not(.no_inheritance) fieldset > .legend span,
form:not(.no_inheritance) section.listings fieldset > .legend span, section.listings
form:not(.no_inheritance) .fieldset > legend span,
form:not(.no_inheritance) section.listings .fieldset > legend span, section.listings
form:not(.no_inheritance) .fieldset > .legend span,
form:not(.no_inheritance) section.listings .fieldset > .legend span {
  font-size: 90%;
  padding-left: 10px;
}

section.listings p {
  font-size: 110%;
}

@media (max-width: 609px) {
  section.listings .ask-a-question {
    float: none;
    width: 100%;
    text-align: center;
  }
}

section.listings .ask-a-question span.ico-mail {
  display: none;
}

@media (min-width: 610px) and (max-width: 767px) {
  section.listings .ask-a-question span {
    display: none;
  }
  section.listings .ask-a-question span.ico-mail {
    display: inline;
  }
}

@media (max-width: 609px) {
  section.listings .span9 {
    width: 100%;
  }
}

section.listings .amenities-block .amenities-header {
  position: relative;
}

section.listings .amenities-block .amenities-header a {
  display: none;
  color: #505050;
}

section.listings .amenities-block .amenities-header a span {
  position: absolute;
  right: 0;
  top: 0px;
}

@media (max-width: 610px) {
  section.listings .amenities-block .amenities-header a {
    display: block;
    width: 100%;
  }
}

section.listings .amenities-block .amenities-header > h3 {
  display: block;
}

section.listings .amenities-block .amenities-container {
  padding-top: 15px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 15px;
  display: block;
}

section.listings .amenities-block .amenities-container .amenities-row {
  border-top: 1px solid #d2d2d2;
}

section.listings .amenities-block .amenities-container .amenities-row .span3 {
  line-height: 40px;
  font-size: 16px;
  width: 100%;
}

@media (max-width: 1000px) {
  section.listings .amenities-block .amenities-container .amenities-row {
    border-top: 0;
  }
  section.listings .amenities-block .amenities-container .amenities-row .span3 {
    width: 100%;
    margin-left: 0;
    border-top: 1px solid #d2d2d2;
  }
}

@media (max-width: 767px) {
  section.listings .amenities-block .amenities-container .amenities-row .span3 {
    border-top: 1px solid #d2d2d2;
    width: 100%;
  }
}

@media (max-width: 610px) {
  section.listings .amenities-block.amenities-shown {
    padding-bottom: 0;
  }
  section.listings .amenities-block.amenities-shown .amenities-container {
    display: block;
    margin-bottom: 0;
    border-bottom: 0;
  }
}

@media (max-width: 610px) {
  section.listings .amenities-block {
    margin-bottom: 15px;
  }
}

section.listings .administrator {
  position: relative;
  background: #e6e6e6;
  box-sizing: border-box;
  border-radius: 5px;
}

section.listings .administrator img {
  float: left;
  width: 50%;
  min-height: 145px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

section.listings .administrator .info {
  float: left;
  padding: 15px;
  width: 50%;
  height: 50%;
  box-sizing: border-box;
}

section.listings .administrator .info span.name {
  display: block;
  color: #505050;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

section.listings .administrator .info span.title {
  display: block;
  height: 2.4em;
  color: #787878;
  font-size: 16px;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

section.listings .administrator .buttons {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 50%;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

section.listings .administrator .buttons a.btn, section.listings .administrator .buttons section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section.listings .administrator .buttons a {
  width: 100%;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
}

section.listings .administrator .buttons a.btn.ask, section.listings .administrator .buttons section.dashboard ul.locations li.location .links a.ask, section.dashboard ul.locations li.location .links section.listings .administrator .buttons a.ask {
  margin-top: 8px;
}

section.listings .administrator .buttons a.btn.view, section.listings .administrator .buttons section.dashboard ul.locations li.location .links a.view, section.dashboard ul.locations li.location .links section.listings .administrator .buttons a.view {
  border: 1px solid #aaaaaa !important;
  color: #787878;
  background: #e6e6e6;
}

@media (min-width: 611px) and (max-width: 860px), (min-width: 1001px) and (max-width: 1260px) {
  section.listings .administrator .info {
    padding: 8px;
  }
  section.listings .administrator .info span.name {
    height: 22px;
    font-size: 18px;
    line-height: 22px;
  }
  section.listings .administrator .info span.title {
    height: 2.4em;
    font-size: 14px;
    line-height: 1.2em;
  }
  section.listings .administrator .buttons {
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  section.listings .administrator .buttons a.btn, section.listings .administrator .buttons section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section.listings .administrator .buttons a {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
}

@media (min-width: 611px) {
  section.listings .administrator {
    margin-left: 15px;
  }
}

section.listings article.listing, section.listings article.location, section.listings article.company, section.listings article.administrator {
  max-width: 895px;
}

section.listings .availabilities {
  box-sizing: border-box;
  border-radius: 5px;
  background: #f0f0f0;
  border: 1px solid #d2d2d2;
  padding: 12px 20px;
}

@media (min-width: 611px) {
  section.listings .availabilities {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  section.listings .availabilities .span6 {
    width: 49%;
    display: inline-block;
    font-size: 80%;
  }
}

section.listings #fullscreen-gallery {
  background: black;
}

section.listings #fullscreen-gallery .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  width: 54px;
  height: 55px;
  margin-top: -27px;
  margin-left: -27px;
}

section.listings #fullscreen-gallery .carousel .carousel-inner {
  height: 100%;
}

section.listings #fullscreen-gallery .carousel ol.carousel-indicators {
  width: auto !important;
}

section.listings #fullscreen-gallery .carousel .item img {
  max-height: 100%;
}

section.listings #fullscreen-gallery .carousel .item img.smaller-size {
  max-height: 90% !important;
}

section.listings #fullscreen-gallery .carousel .item .info {
  position: static;
  background: none;
}

section.listings #fullscreen-gallery .carousel .item .info .caption {
  position: static !important;
  padding-top: 20px;
}

section.listings .other-listings {
  box-sizing: border-box;
  border-radius: 5px;
  background: #e6e6e6;
}

@media (min-width: 611px) {
  section.listings .other-listings {
    margin-left: 15px;
  }
}

section.listings .other-listings header {
  padding: 10px;
  display: block !important;
}

section.listings .other-listings header p {
  font-size: 85%;
  margin-bottom: 0;
}

section.listings .other-listings header p a {
  color: #505050;
}

section.listings .click-to-call {
  margin-left: 15px;
}

section.listings .click-to-call a {
  text-align: center;
  line-height: 50px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

section.listings .click-to-call .tooltip-inner {
  white-space: pre-wrap;
  text-align: left;
  max-width: 300px;
}

section.listings .click-to-call .tooltip {
  max-width: 300px;
}

section.listings .favorite {
  margin-left: 15px;
}

@media (max-width: 610px) {
  section.listings .favorite {
    margin-left: 0;
  }
}

section.listings .favorite a {
  text-align: center;
  width: 100%;
}

section.listings .map {
  border-radius: 5px;
  background: #e6e6e6;
}

section.listings .map .map-container {
  width: auto;
  height: 250px;
}

@media (max-width: 481px) {
  section.listings .map .map-container {
    height: 200px;
  }
}

@media (min-width: 611px) {
  section.listings .map {
    margin-left: 15px;
  }
}

section.listings .map .bottom-heading {
  position: relative;
  box-sizing: border-box;
  padding: 0 15px;
  min-height: 35px;
}

section.listings .map .bottom-heading a {
  display: block;
  color: #787878;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 25px;
}

section.listings .map .bottom-heading span {
  position: absolute;
  right: 15px;
  top: 0;
  font-size: 70%;
}

section.listings .listing-photos #listing-siblings-container img {
  border-radius: 0 0 5px 5px;
}

section.listings .listing-photos .carousel-nav {
  width: 100%;
  max-width: 895px;
  position: absolute;
  top: 45%;
}

section.listings .listing-photos .carousel-nav .carousel-control {
  color: #3c3c3c;
  text-shadow: none;
  font-size: 20px;
  top: 45%;
  opacity: 0.8;
  background: #e6e6e6 !important;
  height: 48px;
  width: 33px;
  padding-top: 20px;
}

section.listings .listing-photos .carousel-nav .carousel-control.right {
  left: auto;
  right: 0;
  border-radius: 5px 0 0 5px;
}

section.listings .listing-photos .carousel-nav .carousel-control.left {
  left: 0;
  right: auto;
  border-radius: 0 5px 5px 0;
}

section.listings .listing-photos .carousel {
  margin-bottom: 0;
}

section.listings .listing-photos .carousel ol.carousel-indicators {
  bottom: 10px;
  left: 0px;
  top: auto;
  right: auto;
  width: 50%;
}

section.listings .listing-photos .carousel ol.carousel-indicators li {
  width: 22px;
  height: 22px;
  border-radius: 15px;
  text-indent: 0px;
  background: transparent;
  color: #a0a0a0;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  border: 1px solid #a0a0a0;
  margin-left: 8px;
  margin-top: 5px;
  cursor: pointer;
}

section.listings .listing-photos .carousel ol.carousel-indicators li.active {
  color: whitesmoke;
  border: 1px solid whitesmoke;
}

@media (max-width: 1000px) {
  section.listings .listing-photos .carousel ol.carousel-indicators {
    left: 30px;
    bottom: 40px;
    right: 35px;
    width: auto;
  }
  section.listings .listing-photos .carousel ol.carousel-indicators li {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 14px;
  }
}

section.listings .listing-photos .carousel .carousel-inner {
  width: 100%;
  max-width: 895px;
}

section.listings .listing-photos .carousel .carousel-inner .item {
  width: 100%;
  height: 100%;
}

section.listings .listing-photos .carousel .carousel-inner .item img {
  border-radius: 5px;
  height: auto;
}

section.listings .listing-photos .carousel#photos-container {
  max-width: 895px;
}

section.listings .listing-photos .carousel#photos-container-enlarged ol.carousel-indicators {
  position: fixed;
}

section.listings .listing-photos .carousel#photos-container-enlarged .carousel-inner {
  max-width: 100%;
}

section.listings .listing-photos .carousel#photos-container-enlarged .carousel-inner .item {
  width: 100%;
}

section.listings .listing-photos .carousel#photos-container-enlarged .carousel-inner .item img {
  margin-left: auto;
  margin-right: auto;
}

section.listings .listing-photos .carousel#photos-container-enlarged .carousel-inner .item .info .caption {
  position: fixed;
  right: 0;
  left: 0;
}

section.listings .listing-photos .carousel#photos-container-enlarged .carousel-nav {
  max-width: 100%;
}

section.listings .listing-photos .carousel button {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  opacity: 0.8;
  color: #3c3c3c;
  background: #e6e6e6 !important;
  display: block;
  text-align: center;
  text-shadow: none;
  font-size: 20px;
  width: 40px;
  height: 36px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

section.listings .listing-photos .carousel button:focus {
  outline: none !important;
}

section.listings .listing-photos .carousel button::-moz-focus-inner {
  border: 0;
}

section.listings .listing-photos .carousel button.close {
  position: fixed;
  border-bottom-left-radius: 5px;
}

section.listings .listing-photos .carousel .info {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%;
  color: #FFFFFF;
  font-size: 13px;
  background-image: linear-gradient(rgba(250, 250, 250, 0), #353535);
  border-radius: 0 0 5px 5px;
}

section.listings .listing-photos .carousel .info .caption {
  position: absolute;
  bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
  right: 5px;
  font-weight: bold;
}

section.listings .container-row {
  width: 100%;
}

section.listings .container-row .one-third.column {
  margin: 0;
  width: 33.3333%;
}

@media (max-width: 1000px) {
  section.listings .container-row .one-third.column {
    width: 50%;
  }
}

@media (max-width: 610px) {
  section.listings .container-row .one-third.column {
    width: 100%;
    clear: both;
  }
}

section.listings .container-row .two-thirds.column {
  margin: 0;
  width: 66.6666%;
}

@media (max-width: 1000px) {
  section.listings .container-row .two-thirds.column {
    width: 50%;
  }
}

@media (max-width: 610px) {
  section.listings .container-row .two-thirds.column {
    width: 100%;
    clear: both;
  }
}

section.listings h1 {
  margin: -10px 0 20px;
  float: left;
}

@media (max-width: 610px) {
  section.listings .btn, section.listings form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) section.listings input[type='submit'], section.listings section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section.listings a {
    font-size: 15px;
  }
}

section.listings .span4.pull-left {
  margin-left: 0;
}

@media (min-width: 768px) {
  section.listings .span4.pull-left {
    width: 34%;
  }
}

section.listings ul.icons {
  float: right;
}

section.listings ul.icons li {
  font-size: 1em;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

section.listings ul.icons li a {
  display: block;
  color: #a0a0a0;
}

section.listings ul.icons li a:hover, section.listings ul.icons li a.hover {
  color: #505050;
  transition: none;
}

@media (max-width: 767px) {
  section.listings ul.icons {
    float: left;
  }
}

section.listings .social-buttons-wrapper {
  opacity: 0;
  display: block;
  overflow: hidden;
  transition: opacity .5s ease-in;
}

section.listings .social-buttons-wrapper.active {
  opacity: 1;
}

@media (min-width: 610px) {
  section.listings .social-buttons-wrapper {
    margin: 0 0 0 15px;
  }
}

section.listings .social-buttons-wrapper li {
  float: left;
  margin: 0 15px 0 0;
}

section.listings .social-buttons-wrapper li div {
  text-align: center;
}

section.listings .social-buttons-wrapper li div span {
  margin: 0 !important;
}

section.listings .social-buttons-wrapper li div > :first-child {
  line-height: 0 !important;
  vertical-align: bottom !important;
}

.tab-pane#details {
  background: #FFFFFF;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tab-pane#details .row-fluid {
  padding: 15px;
  box-sizing: border-box;
  background: #FFFFFF;
}

.availability-rules .custom-availability-rules .day {
  overflow: hidden;
  margin-top: 6px;
  padding: 6px 0;
}

.availability-rules .custom-availability-rules .day.error {
  padding: 6px 0px 10px 0px;
}

.availability-rules .custom-availability-rules .day.control-group {
  margin-bottom: 0px;
}

.availability-rules .custom-availability-rules .day .day-name {
  margin-top: 10px;
}

.availability-rules .custom-availability-rules .day .closed-flag {
  margin-top: 13px;
  margin-left: 15px;
}

.availability-rules .custom-availability-rules .day .open-time,
.availability-rules .custom-availability-rules .day .close-time {
  position: relative;
}

.availability-rules .custom-availability-rules .day .day-name,
.availability-rules .custom-availability-rules .day .open-time,
.availability-rules .custom-availability-rules .day .close-time,
.availability-rules .custom-availability-rules .day .closed-flag {
  float: left;
  width: 90px;
  height: 28px;
}

@media (max-width: 480px) {
  .availability-rules .custom-availability-rules .day .day-name,
  .availability-rules .custom-availability-rules .day .open-time,
  .availability-rules .custom-availability-rules .day .close-time,
  .availability-rules .custom-availability-rules .day .closed-flag {
    width: 110px;
  }
}

@media (max-width: 480px) {
  .availability-rules .custom-availability-rules .day .day-name {
    clear: both;
    width: 100%;
  }
}

.availability-rules .custom-availability-rules select {
  margin-top: -6px;
}

section.marketplace_sessions #hero {
  position: absolute;
  min-height: 100%;
  width: 100%;
  height: auto;
  background-color: #3c3c3c;
}

section.marketplace_sessions #hero > * {
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  section.marketplace_sessions #hero {
    height: 420px;
  }
}

@media (max-width: 480px) {
  section.marketplace_sessions #hero {
    height: 380px;
  }
}

section.marketplace_sessions section#hero + .container {
  width: 100%;
  padding: 0;
}

section.marketplace_sessions #marketplace-brand {
  margin-top: 110px;
}

section.marketplace_sessions #marketplace-password {
  margin-top: 100px;
}

@media (max-width: 767px) {
  section.marketplace_sessions #marketplace-password {
    margin-top: 210px;
  }
}

@media (max-width: 480px) {
  section.marketplace_sessions #marketplace-password {
    margin-top: 140px;
  }
}

section.marketplace_sessions #marketplace-password h1 {
  display: block;
  margin-bottom: 30px;
  color: #E2E2E2;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

@media (max-width: 320px) {
  section.marketplace_sessions #marketplace-password h1 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }
}

section.marketplace_sessions #marketplace-password form input[type="text"] {
  margin-bottom: 0;
}

section.marketplace_sessions #marketplace-password form {
  clear: both;
}

section.marketplace_sessions #marketplace-password form label {
  display: block;
  margin-bottom: 8px;
}

.footer-push.footer_marketplace_sessions {
  background-color: #3c3c3c;
}

section.reservations .first-br {
  display: none;
}

section.reservations .export-icon span {
  padding-left: 10px;
}

section.reservations .export-icon a {
  color: #505050;
  font-size: 24px;
}

section.reservations .export-icon a:hover {
  color: #787878;
}

section#reservations ul li {
  list-style: none outside none;
  overflow: hidden;
}

section#reservations ul li a {
  width: 140px;
}

@media (max-width: 767px) {
  section#reservations ul li a {
    width: 100%;
  }
}

section#reservations .reservation-list.just-booked {
  background-color: #fff;
}

section#reservations .reservation-list .no-reservations {
  border: 0;
  text-align: center;
  padding: 20px 0;
  margin: 0;
}

section#reservations .reservation-list ul li {
  overflow: visible;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #E2E2E2;
  margin-top: 10px;
  color: #505050;
  font-weight: 600;
}

section#reservations .reservation-list ul li a {
  text-decoration: underline !important;
  color: #505050;
}

section#reservations .reservation-list ul li a.ico-mail {
  text-decoration: none !important;
  padding-left: 5px;
}

section#reservations .reservation-list ul li a:hover {
  color: #d2d2d2;
}

section#reservations .reservation-list ul li .reservation-details .actions .reservation-action {
  padding: 0px;
  text-align: center;
}

section#reservations .reservation-list ul li .reservation-details .actions .reservation-action.request-payment {
  line-height: 13px;
  color: white;
  padding: 2px;
  font-size: 12px;
}

section#reservations .reservation-list ul li .reservation-details .actions input, section#reservations .reservation-list ul li .reservation-details .actions a {
  border-radius: 5px 0 0 5px;
  width: 100%;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px !important;
}

section#reservations .reservation-list ul li .reservation-details .actions input:not(.btn-green):not(form:not(.no_inheritance) input[type='submit']):hover {
  background: #d2d2d2;
}

section#reservations .reservation-list ul li .reservation-details .actions a {
  padding: 0 22px;
  text-decoration: none !important;
}

section#reservations .reservation-list ul li .reservation-details .actions a:hover {
  color: #505050;
  background: #bebebe;
}

section#reservations .reservation-list ul li .reservation-status {
  background: #e6e6e6;
  position: absolute;
  height: 100%;
  width: 50px;
}

section#reservations .reservation-list ul li .reservation-status .tooltip-spacer {
  color: transparent;
  display: block;
  position: absolute;
  top: 0px;
  width: 20px;
}

section#reservations .reservation-list ul li .reservation-status .icon-container {
  position: absolute;
  margin-top: 48px;
  margin-left: 16px;
}

section#reservations .reservation-list ul li .reservation-status .icon-container a {
  text-decoration: none !important;
}

section#reservations .reservation-list ul li .reservation-status .icon-container a:hover {
  color: #505050;
}

section#reservations .reservation-list ul li .reservation-information {
  padding-left: 60px;
  padding-bottom: 15px;
  margin-left: 0;
  padding-top: 25px;
}

section#reservations .reservation-list ul li .reservation-information > div {
  padding-top: 15px;
}

@media (max-width: 767px) {
  section#reservations .reservation-list ul li .reservation-information > .split-2 .actions {
    padding-top: 15px;
  }
}

section#reservations .reservation-list ul li .reservation-information span.ico-download {
  margin-left: 3px;
}

section#reservations .reservation-list ul li .reservation-information .dates {
  color: #a0a0a0;
}

section#reservations .reservation-list ul li .reservation-information .dates a.hours {
  color: #a0a0a0 !important;
}

section#reservations .reservation-list ul li .reservation-information .dates a.hours:hover {
  text-decoration: none !important;
}

section#reservations .reservation-list ul li .reservation-information span:not(.ico-user) {
  display: block;
  color: #a0a0a0;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

section#reservations .reservation-list ul li .reservation-information span:not(.ico-user).ical {
  display: inline;
}

section#reservations .reservation-list ul li .reservation-information span:not(.ico-user) a {
  text-decoration: none !important;
}

section#reservations .reservation-list ul li .reservation-information span:not(.ico-user) a:hover {
  color: #505050;
}

section#reservations .reservation-list ul li .reservation-information span:not(.ico-user) a:hover span {
  color: #505050;
}

section#reservations .reservation-list ul li .reservation-information span.address a {
  color: #a0a0a0;
  text-decoration: underline !important;
}

section#reservations .reservation-list ul li .reservation-information span.address a:hover {
  text-decoration: none !important;
  color: #d2d2d2;
}

section#reservations .reservation-list ul li .reservation-information .split-2 {
  padding-top: 0px;
}

section#reservations .reservation-list ul li .reservation-information .split-2 .reservation-action:nth-of-type(1) {
  margin-bottom: 5px !important;
}

section#reservations .reservation-list ul li .reservation-text-information {
  position: relative;
  width: 100%;
}

section#orders .box {
  background: #fff;
}

section#orders .reviews-search {
  border: solid #E0E1E0 1px;
  background: #fff;
  padding: 0 10px;
  float: right;
}

section#orders .reviews-search input {
  outline: 0px;
  -webkit-appearance: none;
}

section#orders .reviews-search .ico-search {
  display: inline-block;
}

section#orders #search {
  border: none;
}

section#orders .page-tabs {
  margin-bottom: 40px;
  border-bottom: 3px solid #E0E1E0;
}

section#orders .page-tabs li {
  display: inline-block;
  margin-right: 30px;
  overflow: initial !important;
  padding-bottom: 10px;
}

section#orders .page-tabs li a {
  color: #6F6E6E;
  display: inline;
  padding-bottom: 13px;
}

section#orders .page-tabs li.active a {
  color: #1C51A2;
  border-bottom: 3px solid #1C51A2;
}

section#orders .select-label {
  display: inline-block;
  margin-right: 10px;
}

section#orders .select-container {
  border: solid #E0E1E0 1px;
  border-radius: 3px;
  font-size: 14px;
  padding: 0 10px;
  display: inline-block;
}

section#orders .order a {
  color: #1C51A2;
  cursor: pointer;
}

section#orders .order-info li {
  display: inline-block;
  margin-right: 35px;
}

section#orders .order-info p {
  margin: 0;
}

section#orders .order-info p:nth-child(1) {
  color: #000;
}

section#orders .order-details, section#orders .order-actions {
  display: inline-block;
}

section#orders .order-details {
  float: left;
}

section#orders .order-details h1, section#orders .order-details p {
  margin: 0;
}

section#orders .order-actions {
  float: right;
}

section#orders .order-actions .btn, section#orders .order-actions form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) section#orders .order-actions input[type='submit'], section#orders .order-actions section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section#orders .order-actions a {
  display: block;
  width: 100%;
  padding: 5px 40px;
  margin-bottom: 15px;
}

section#orders .order-number a {
  color: #1C51A2;
}

section#orders .order-seller {
  margin-top: 30px;
}

section#orders .order-img {
  float: left;
  margin-right: 30px;
}

section#orders .order.additional-order {
  border-top: solid #E0E1E0 1px;
}

section#orders .order.additional-order .order-actions {
  padding-top: 40px;
}

section#orders input.btn.update, section#orders form:not(.no_inheritance) input.update[type='submit'], form:not(.no_inheritance) section#orders input.update[type='submit'] {
  background: #FFD597;
}

section#orders input.btn.btn-gray:hover, section#orders form:not(.no_inheritance) input.btn-gray[type='submit']:hover, form:not(.no_inheritance) section#orders input.btn-gray[type='submit']:hover {
  background: #F0F0F0;
}

section#orders input.btn, section#orders form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) section#orders input[type='submit'] {
  border-radius: 3px;
}

@media (max-width: 400px) {
  section#orders .reviews-search {
    margin-bottom: 20px;
  }
  section#orders .page-tabs {
    clear: both;
  }
}

section.pages #hero {
  margin-top: -30px;
  max-height: 500px;
  overflow: hidden;
}

section.pages #hero img {
  width: 100%;
  height: auto;
}

section.pages .hero-title {
  font-size: .7em;
}

section.pages .container-fluid h2, section.pages .container-fluid form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) section.pages .container-fluid fieldset > legend, section.pages .container-fluid
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) section.pages .container-fluid fieldset > .legend, section.pages .container-fluid
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) section.pages .container-fluid .fieldset > legend, section.pages .container-fluid
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) section.pages .container-fluid .fieldset > .legend, section.pages .container-fluid h3 {
  margin-bottom: 20px;
}

section.pages .container-fluid ul {
  list-style-type: disc;
  margin: 0 0 12px 20px;
}

section#payments .chart, section#transfers .chart {
  margin-bottom: 20px;
  padding: 20px 0;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

section#payments .chart .total, section#transfers .chart .total {
  padding-right: 20px;
}

section#payments .total-number, section#transfers .total-number {
  padding: 0px 10px 0px 0px;
}

section#payments .total-text, section#transfers .total-text {
  color: #bebebe;
}

section#payments select.analytics-mode {
  width: auto !important;
}

section#transfers .warning {
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #e6e6e6;
  text-align: center;
  border-radius: 5px;
}

section#transfers .warning i {
  position: relative;
  top: 3px;
  padding-right: 8px;
}

.registrations-show .avatar img {
  border-radius: 5px;
}

.registrations-show .info {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 15px;
}

.registrations-show .info h2, .registrations-show .info form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .registrations-show .info fieldset > legend, .registrations-show .info
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .registrations-show .info fieldset > .legend, .registrations-show .info
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .registrations-show .info .fieldset > legend, .registrations-show .info
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .registrations-show .info .fieldset > .legend {
  margin-bottom: 15px;
}

.registrations-show .info h4 {
  color: #a0a0a0;
  margin-bottom: 15px;
}

.registrations-show .info p {
  color: #3c3c3c;
  margin-bottom: 15px;
}

.registrations-show .box .part {
  margin-top: 10px;
}

.registrations-show .box .part:first-child {
  margin-top: 0;
}

.registrations-show .social.info a {
  font-size: 27px;
  color: #505050;
}

.registrations-show .social.info a.ico-twitter {
  font-size: 120%;
}

.registrations-show .social.info a.ico-facebook-full:hover {
  color: #3b5998;
}

.registrations-show .social.info a.ico-linkedin {
  position: relative;
  left: -2px;
}

.registrations-show .social.info a.ico-linkedin:hover {
  color: #006699;
}

.registrations-show .social.info a.ico-twitter:hover {
  color: #55acee;
}

.registrations-show .social.info a.ico-instagram:hover {
  color: #905f4c;
}

.registrations-show .social.info a.ico-mail:hover {
  color: #dd4b39;
}

.registrations-show .social.info .connection {
  padding-bottom: 5px;
}

.registrations-show .social.info .connection:first-child {
  margin-top: 0;
}

.registrations-show .social.info .connection .text {
  color: #3c3c3c;
}

.registrations-show .social.info .connection .count {
  padding-left: 10px;
  color: #a0a0a0;
}

.registrations-show .icons li {
  float: right;
  margin-left: 24px;
}

.registrations-show .icons li a {
  font-size: 27px;
  color: #b6b6b6;
}

.registrations-show .icons li a.ico-facebook-full:hover {
  color: #3B5998;
}

.registrations-show .icons li a.ico-twitter:hover {
  color: #33CCFF;
}

.registrations-show .icons li a.ico-linkedin:hover {
  color: #4875B4;
}

.registrations-show .icons li a.ico-instagram:hover {
  color: #905f4c;
}

.phone-number-country-code-field {
  position: relative;
}

.phone-number-country-code-field .country-calling-code {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  line-height: 29px;
  width: 40px;
  text-align: right;
}

.phone-number-country-code-field input {
  padding-left: 60px !important;
}

.controls.switch-control {
  display: table-row;
}

.controls.switch-control .cell {
  display: table-cell;
}

.controls.switch-control .cell.with-label {
  vertical-align: bottom;
  padding-left: 30px;
}

.controls.switch-control .cell.with-label label {
  font-weight: bold;
}

.admin-profile-notice {
  background: #FEEFB3;
  padding: 20px;
  margin-bottom: 30px;
  text-align: center;
  border: 1px solid #fccf1c;
}

.admin-profile-notice p {
  font-size: 16px;
  font-weight: normal;
  color: #9F6000;
}

section#reservations .price,
section.reservations .price {
  color: #157A49;
}

section#reservations .reservation-dates,
section.reservations .reservation-dates {
  cursor: pointer;
  border-bottom: 1px dashed black;
}

section#reservations .guest_filter,
section.reservations .guest_filter {
  margin-bottom: 30px;
}

section#reservations .guest_filter .button-container,
section.reservations .guest_filter .button-container {
  padding: 5px 0px;
}

section#reservations .guest_filter .btn-gray.inactive,
section.reservations .guest_filter .btn-gray.inactive {
  border: 2px solid #E2E2E2 !important;
  background-color: #FFFFFF;
}

section#reservations .guest_filter .btn-gray.inactive .guest-count,
section.reservations .guest_filter .btn-gray.inactive .guest-count {
  color: #bebebe;
}

section#reservations .guest_filter .btn-gray.inactive:hover,
section.reservations .guest_filter .btn-gray.inactive:hover {
  background: #E2E2E2;
}

section#reservations .guest_filter .btn-gray.inactive:hover .guest-count,
section.reservations .guest_filter .btn-gray.inactive:hover .guest-count {
  color: black;
}

section#reservations .actions .btn, section#reservations .actions form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) section#reservations .actions input[type='submit'], section#reservations .actions section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section#reservations .actions a,
section.reservations .actions .btn,
section.reservations .actions form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance)
section.reservations .actions input[type='submit'],
section.reservations .actions section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links
section.reservations .actions a {
  width: 49%;
}

section#reservations .expiry-message,
section.reservations .expiry-message {
  font-size: 0.95em;
}

section#reservations .reservation-emphasis,
section.reservations .reservation-emphasis {
  margin: 0px 0px 20px 0px;
  font-size: 20px;
}

section#reservations p.no-data,
section.reservations p.no-data {
  text-align: center;
  margin: 40px 0 25px 0;
}

section#reservations .other-reservation-separator,
section.reservations .other-reservation-separator {
  margin: 20px 0px;
  font-size: 20px;
}

section#reservations .reservation-navigation,
section.reservations .reservation-navigation {
  margin-bottom: 30px;
}

section#reservations .reservation-navigation a,
section.reservations .reservation-navigation a {
  margin: 5px 0px;
  border: 2px solid gainsboro !important;
  background: #FFFFFF;
}

section#reservations .reservation-navigation a span,
section.reservations .reservation-navigation a span {
  padding-right: 10px;
  color: #d2d2d2;
}

section#reservations .reservation-navigation a.active,
section.reservations .reservation-navigation a.active {
  background: gainsboro;
}

section#reservations .reservation-navigation a.active span,
section.reservations .reservation-navigation a.active span {
  color: #353535;
}

@media (max-width: 767px) {
  section#reservations .reservation-navigation,
  section.reservations .reservation-navigation {
    margin-bottom: 15px;
  }
}

section#reservations .reservation-details h2, section#reservations .reservation-details form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) section#reservations .reservation-details fieldset > legend, section#reservations .reservation-details
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) section#reservations .reservation-details fieldset > .legend, section#reservations .reservation-details
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) section#reservations .reservation-details .fieldset > legend, section#reservations .reservation-details
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) section#reservations .reservation-details .fieldset > .legend,
section.reservations .reservation-details h2,
section.reservations .reservation-details form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance)
section.reservations .reservation-details fieldset > legend,
section.reservations .reservation-details
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance)
section.reservations .reservation-details fieldset > .legend,
section.reservations .reservation-details
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance)
section.reservations .reservation-details .fieldset > legend,
section.reservations .reservation-details
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance)
section.reservations .reservation-details .fieldset > .legend {
  margin-top: 15px;
}

section#reservations .reservation-details h2:first-child, section#reservations .reservation-details form:not(.no_inheritance) fieldset > legend:first-child, form:not(.no_inheritance) section#reservations .reservation-details fieldset > legend:first-child, section#reservations .reservation-details
form:not(.no_inheritance) fieldset > .legend:first-child,
form:not(.no_inheritance) section#reservations .reservation-details fieldset > .legend:first-child, section#reservations .reservation-details
form:not(.no_inheritance) .fieldset > legend:first-child,
form:not(.no_inheritance) section#reservations .reservation-details .fieldset > legend:first-child, section#reservations .reservation-details
form:not(.no_inheritance) .fieldset > .legend:first-child,
form:not(.no_inheritance) section#reservations .reservation-details .fieldset > .legend:first-child,
section.reservations .reservation-details h2:first-child,
section.reservations .reservation-details form:not(.no_inheritance) fieldset > legend:first-child, form:not(.no_inheritance)
section.reservations .reservation-details fieldset > legend:first-child,
section.reservations .reservation-details
form:not(.no_inheritance) fieldset > .legend:first-child,
form:not(.no_inheritance)
section.reservations .reservation-details fieldset > .legend:first-child,
section.reservations .reservation-details
form:not(.no_inheritance) .fieldset > legend:first-child,
form:not(.no_inheritance)
section.reservations .reservation-details .fieldset > legend:first-child,
section.reservations .reservation-details
form:not(.no_inheritance) .fieldset > .legend:first-child,
form:not(.no_inheritance)
section.reservations .reservation-details .fieldset > .legend:first-child {
  margin-top: 0px;
}

@media (max-width: 767px) {
  section#reservations .reservation-details h2:first-child, section#reservations .reservation-details form:not(.no_inheritance) fieldset > legend:first-child, form:not(.no_inheritance) section#reservations .reservation-details fieldset > legend:first-child, section#reservations .reservation-details
  form:not(.no_inheritance) fieldset > .legend:first-child,
  form:not(.no_inheritance) section#reservations .reservation-details fieldset > .legend:first-child, section#reservations .reservation-details
  form:not(.no_inheritance) .fieldset > legend:first-child,
  form:not(.no_inheritance) section#reservations .reservation-details .fieldset > legend:first-child, section#reservations .reservation-details
  form:not(.no_inheritance) .fieldset > .legend:first-child,
  form:not(.no_inheritance) section#reservations .reservation-details .fieldset > .legend:first-child,
  section.reservations .reservation-details h2:first-child,
  section.reservations .reservation-details form:not(.no_inheritance) fieldset > legend:first-child, form:not(.no_inheritance)
  section.reservations .reservation-details fieldset > legend:first-child,
  section.reservations .reservation-details
  form:not(.no_inheritance) fieldset > .legend:first-child,
  form:not(.no_inheritance)
  section.reservations .reservation-details fieldset > .legend:first-child,
  section.reservations .reservation-details
  form:not(.no_inheritance) .fieldset > legend:first-child,
  form:not(.no_inheritance)
  section.reservations .reservation-details .fieldset > legend:first-child,
  section.reservations .reservation-details
  form:not(.no_inheritance) .fieldset > .legend:first-child,
  form:not(.no_inheritance)
  section.reservations .reservation-details .fieldset > .legend:first-child {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  section#reservations .reservation-details h2.booking-header, section#reservations .reservation-details form:not(.no_inheritance) fieldset > legend.booking-header, form:not(.no_inheritance) section#reservations .reservation-details fieldset > legend.booking-header, section#reservations .reservation-details
  form:not(.no_inheritance) fieldset > .booking-header.legend,
  form:not(.no_inheritance) section#reservations .reservation-details fieldset > .booking-header.legend, section#reservations .reservation-details
  form:not(.no_inheritance) .fieldset > legend.booking-header,
  form:not(.no_inheritance) section#reservations .reservation-details .fieldset > legend.booking-header, section#reservations .reservation-details
  form:not(.no_inheritance) .fieldset > .booking-header.legend,
  form:not(.no_inheritance) section#reservations .reservation-details .fieldset > .booking-header.legend,
  section.reservations .reservation-details h2.booking-header,
  section.reservations .reservation-details form:not(.no_inheritance) fieldset > legend.booking-header, form:not(.no_inheritance)
  section.reservations .reservation-details fieldset > legend.booking-header,
  section.reservations .reservation-details
  form:not(.no_inheritance) fieldset > .booking-header.legend,
  form:not(.no_inheritance)
  section.reservations .reservation-details fieldset > .booking-header.legend,
  section.reservations .reservation-details
  form:not(.no_inheritance) .fieldset > legend.booking-header,
  form:not(.no_inheritance)
  section.reservations .reservation-details .fieldset > legend.booking-header,
  section.reservations .reservation-details
  form:not(.no_inheritance) .fieldset > .booking-header.legend,
  form:not(.no_inheritance)
  section.reservations .reservation-details .fieldset > .booking-header.legend {
    margin-top: 0px;
  }
}

section#reservations .reservation-details form,
section.reservations .reservation-details form {
  margin: 0px;
}

section#reservations .reservation-details .actions .btn, section#reservations .reservation-details .actions form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) section#reservations .reservation-details .actions input[type='submit'], section#reservations .reservation-details .actions section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section#reservations .reservation-details .actions a,
section.reservations .reservation-details .actions .btn,
section.reservations .reservation-details .actions form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance)
section.reservations .reservation-details .actions input[type='submit'],
section.reservations .reservation-details .actions section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links
section.reservations .reservation-details .actions a {
  margin-top: 15px;
  width: 140px;
}

@media (max-width: 767px) {
  section#reservations .reservation-details .actions .btn, section#reservations .reservation-details .actions form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) section#reservations .reservation-details .actions input[type='submit'], section#reservations .reservation-details .actions section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section#reservations .reservation-details .actions a,
  section.reservations .reservation-details .actions .btn,
  section.reservations .reservation-details .actions form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance)
  section.reservations .reservation-details .actions input[type='submit'],
  section.reservations .reservation-details .actions section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links
  section.reservations .reservation-details .actions a {
    margin-bottom: 15px;
  }
}

section#reservations .reservation-details .add-calendar a,
section.reservations .reservation-details .add-calendar a {
  color: #787878;
}

section#reservations .reservation-details .add-calendar a span,
section.reservations .reservation-details .add-calendar a span {
  padding-left: 5px;
}

section#reservations .reservation-details .reservation-status.confirmed i,
section.reservations .reservation-details .reservation-status.confirmed i {
  color: #157A49;
}

section#reservations .reservation-details .reservation-status.unconfirmed i,
section.reservations .reservation-details .reservation-status.unconfirmed i {
  color: #ffc840;
}

section#reservations .reservation-details .reservation-status.cancelled i,
section.reservations .reservation-details .reservation-status.cancelled i {
  color: #e85c41;
}

section#reservations .reservation-details .map-wrapper,
section#reservations .reservation-details .map-container,
section.reservations .reservation-details .map-wrapper,
section.reservations .reservation-details .map-container {
  width: 100%;
  height: 100%;
}

section#reservations .reservation-details .pending-confirmation,
section.reservations .reservation-details .pending-confirmation {
  color: #ffc840;
}

section#reservations .reservation-details .host-avatar,
section.reservations .reservation-details .host-avatar {
  margin-right: 10px;
}

section#reservations #payment-options,
section.reservations #payment-options {
  margin: 0px;
}

section#reservations #payment-options a,
section.reservations #payment-options a {
  color: #646464;
}

section#reservations #payment-options.active,
section.reservations #payment-options.active {
  margin-bottom: -1px;
}

.modal-content .reservations-booking_successful .icons, .modal-content .reservations-booking_failed .icons {
  padding: 15px;
}

.modal-content .reservations-booking_successful .icons h3, .modal-content .reservations-booking_failed .icons h3 {
  margin: 15px 0;
  text-align: center;
}

.modal-content .reservations-booking_successful .icons ul, .modal-content .reservations-booking_failed .icons ul {
  width: 100%;
  margin: 30px auto;
  padding: 0;
  text-align: center;
}

.modal-content .reservations-booking_successful .icons ul li, .modal-content .reservations-booking_failed .icons ul li {
  display: inline;
  margin: 0;
  padding: 0 15px;
}

.modal-content .reservations-booking_successful .icons ul li a, .modal-content .reservations-booking_failed .icons ul li a {
  font-size: 34px;
  color: #505050;
}

.modal-content .reservations-booking_successful .icons ul li a.ico-facebook-full:hover, .modal-content .reservations-booking_failed .icons ul li a.ico-facebook-full:hover {
  color: #3B5998;
}

.modal-content .reservations-booking_successful .icons ul li a.ico-twitter:hover, .modal-content .reservations-booking_failed .icons ul li a.ico-twitter:hover {
  color: #33CCFF;
}

.modal-content .reservations-booking_successful .icons ul li a.ico-linkedin:hover, .modal-content .reservations-booking_failed .icons ul li a.ico-linkedin:hover {
  color: #4875B4;
}

.modal-content .reservations-booking_successful .buttons a, .modal-content .reservations-booking_failed .buttons a {
  width: 48%;
  text-align: center;
  cursor: pointer;
}

.modal-content .reservations-booking_successful .buttons a.btn-white, .modal-content .reservations-booking_failed .buttons a.btn-white {
  border: 1px solid gainsboro !important;
  color: #b6b6b6;
}

.modal-content .reservations-booking_successful .buttons a.btn-green, .modal-content .reservations-booking_failed .buttons a.btn-green {
  margin-left: 3%;
}

@media (max-width: 610px) {
  .modal-content .reservations-booking_successful .buttons a, .modal-content .reservations-booking_failed .buttons a {
    width: 100%;
  }
  .modal-content .reservations-booking_successful .buttons a.btn-green, .modal-content .reservations-booking_failed .buttons a.btn-green {
    margin-left: 0;
    margin-top: 3%;
  }
}

.reservations-review #shipping-address .select2-container a.select2-choice, .reservations-create #shipping-address .select2-container a.select2-choice, .reservations-address #shipping-address .select2-container a.select2-choice {
  background: #e6e6e6;
  height: 45px;
  border: none;
}

.reservations-review #shipping-address .select2-container a.select2-choice .select2-chosen, .reservations-create #shipping-address .select2-container a.select2-choice .select2-chosen, .reservations-address #shipping-address .select2-container a.select2-choice .select2-chosen {
  line-height: 43px;
}

.reservations-review #shipping-address .select2-container a.select2-choice .select2-arrow, .reservations-create #shipping-address .select2-container a.select2-choice .select2-arrow, .reservations-address #shipping-address .select2-container a.select2-choice .select2-arrow {
  width: 23px;
}

.reservations-review #shipping-address .select2-container a.select2-choice .select2-arrow b, .reservations-create #shipping-address .select2-container a.select2-choice .select2-arrow b, .reservations-address #shipping-address .select2-container a.select2-choice .select2-arrow b {
  background-size: 77px 73px !important;
}

.reservations-review #shipping-address .hide, .reservations-create #shipping-address .hide, .reservations-address #shipping-address .hide {
  display: none;
}

.reservations-review .radio_buttons .radio, .reservations-create .radio_buttons .radio, .reservations-address .radio_buttons .radio {
  display: block;
}

.reservations-review .delivery-options .controls, .reservations-create .delivery-options .controls, .reservations-address .delivery-options .controls {
  margin-left: 15px !important;
}

.reservations-review .header h1, .reservations-create .header h1, .reservations-address .header h1 {
  margin-bottom: 10px;
}

.reservations-review .header p.location-name, .reservations-create .header p.location-name, .reservations-address .header p.location-name {
  margin-bottom: 5px;
}

.reservations-review .header p.location-address, .reservations-create .header p.location-address, .reservations-address .header p.location-address {
  color: #646464;
  margin-bottom: 25px;
}

.reservations-review .box h1, .reservations-create .box h1, .reservations-address .box h1 {
  margin-bottom: 8px;
}

.reservations-review .box h2, .reservations-review .box form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .reservations-review .box fieldset > legend, .reservations-review .box
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .reservations-review .box fieldset > .legend, .reservations-review .box
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .reservations-review .box .fieldset > legend, .reservations-review .box
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .reservations-review .box .fieldset > .legend, .reservations-create .box h2, .reservations-create .box form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .reservations-create .box fieldset > legend, .reservations-create .box
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .reservations-create .box fieldset > .legend, .reservations-create .box
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .reservations-create .box .fieldset > legend, .reservations-create .box
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .reservations-create .box .fieldset > .legend, .reservations-address .box h2, .reservations-address .box form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .reservations-address .box fieldset > legend, .reservations-address .box
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .reservations-address .box fieldset > .legend, .reservations-address .box
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .reservations-address .box .fieldset > legend, .reservations-address .box
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .reservations-address .box .fieldset > .legend {
  margin-top: 30px;
  margin-bottom: 15px;
}

.reservations-review .box p, .reservations-create .box p, .reservations-address .box p {
  color: #646464;
}

.reservations-review .box p.error-block, .reservations-create .box p.error-block, .reservations-address .box p.error-block {
  color: #e85c41;
}

.reservations-review .box hr, .reservations-create .box hr, .reservations-address .box hr {
  color: #e6e6e6;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.reservations-review .box .selected-dates-summary, .reservations-create .box .selected-dates-summary, .reservations-address .box .selected-dates-summary {
  margin: 15px 0;
}

.reservations-review .box .selected-dates-summary p, .reservations-create .box .selected-dates-summary p, .reservations-address .box .selected-dates-summary p {
  margin: 15px 0;
  color: #787878;
}

.reservations-review .box .order-summary, .reservations-create .box .order-summary, .reservations-address .box .order-summary {
  border-bottom: 1px solid #e6e6e6;
}

.reservations-review .box .order-summary .summary-line, .reservations-create .box .order-summary .summary-line, .reservations-address .box .order-summary .summary-line {
  border-color: #e6e6e6;
}

.reservations-review .box .tabs, .reservations-create .box .tabs, .reservations-address .box .tabs {
  width: auto;
  float: none;
  list-style: none;
  position: relative;
  margin: 10px 0px 0 0px;
  text-align: left;
}

.reservations-review .box .tabs li, .reservations-create .box .tabs li, .reservations-address .box .tabs li {
  float: left;
}

.reservations-review .box .tabs input[type="radio"], .reservations-create .box .tabs input[type="radio"], .reservations-address .box .tabs input[type="radio"] {
  display: none;
}

.reservations-review .box .tabs label.tab, .reservations-create .box .tabs label.tab, .reservations-address .box .tabs label.tab {
  display: inline-block;
  padding: 13px 21px !important;
  border-radius: 2px 2px 0 0;
  font-size: 20px;
  font-weight: normal;
  cursor: pointer;
  position: relative;
  top: 4px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}

.reservations-review .box .tabs .tab2 label, .reservations-create .box .tabs .tab2 label, .reservations-address .box .tabs .tab2 label {
  position: absolute;
  left: 130px;
  top: 0px;
}

.reservations-review .box .tabs .tab-content, .reservations-create .box .tabs .tab-content, .reservations-address .box .tabs .tab-content {
  z-index: 2;
  display: none;
  overflow: hidden;
  width: 100%;
  font-size: 17px;
  line-height: 25px;
}

.reservations-review .box .tabs .tab-content .confirm-reservations, .reservations-create .box .tabs .tab-content .confirm-reservations, .reservations-address .box .tabs .tab-content .confirm-reservations {
  background-color: #FFFFFF;
  color: #646464;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 5px 0 15px 0;
}

.reservations-review .box .tabs .tab-content #braintree-paypal-loggedin, .reservations-create .box .tabs .tab-content #braintree-paypal-loggedin, .reservations-address .box .tabs .tab-content #braintree-paypal-loggedin {
  max-width: 100% !important;
  border-bottom: 1px solid #e6e6e6 !important;
  border-top: 0px !important;
}

.reservations-review .box .tabs [id^="tab"]:checked + label, .reservations-create .box .tabs [id^="tab"]:checked + label, .reservations-address .box .tabs [id^="tab"]:checked + label {
  top: 1px;
  padding-top: 17px;
  border: 1px solid #e6e6e6;
  border-bottom: 0px;
  background-color: #FFFFFF;
}

.reservations-review .box .tabs [id^="tab"]:checked ~ [id^="tab-content"], .reservations-create .box .tabs [id^="tab"]:checked ~ [id^="tab-content"], .reservations-address .box .tabs [id^="tab"]:checked ~ [id^="tab-content"] {
  display: block;
}

.reservations-review .box .contact-information-fields .controls, .reservations-create .box .contact-information-fields .controls, .reservations-address .box .contact-information-fields .controls {
  margin-left: 0;
  margin: 0px;
}

.reservations-review .box .contact-information-fields .phone-number-country-code-field input, .reservations-create .box .contact-information-fields .phone-number-country-code-field input, .reservations-address .box .contact-information-fields .phone-number-country-code-field input {
  height: 49px;
}

.reservations-review .box .contact-information-fields input, .reservations-create .box .contact-information-fields input, .reservations-address .box .contact-information-fields input {
  font-size: 18px;
}

.reservations-review .box .confirm-reservations, .reservations-create .box .confirm-reservations, .reservations-address .box .confirm-reservations {
  margin: 15px 0 30px 0;
}

.reservations-review .box .btn, .reservations-review .box form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) .reservations-review .box input[type='submit'], .reservations-review .box section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .reservations-review .box a, .reservations-create .box .btn, .reservations-create .box form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) .reservations-create .box input[type='submit'], .reservations-create .box section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .reservations-create .box a, .reservations-address .box .btn, .reservations-address .box form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) .reservations-address .box input[type='submit'], .reservations-address .box section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links .reservations-address .box a {
  height: 60px;
  font-size: 24px;
}

.reservations-review .box .flash.error, .reservations-create .box .flash.error, .reservations-address .box .flash.error {
  color: #e85c41;
  padding-bottom: 0.5em;
}

.reservations-review .box .flash.error p, .reservations-create .box .flash.error p, .reservations-address .box .flash.error p {
  text-align: center;
  color: #e85c41;
  font-size: 1.1em;
}

.reservations-review .box .flash.error ul li, .reservations-create .box .flash.error ul li, .reservations-address .box .flash.error ul li {
  color: #e85c41;
  font-size: 1.1em;
  margin: 20px 0px;
}

body.reservations-review .control-fileupload .btn-blue, body.reservations-review .control-fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.reservations-review .control-fileupload a, body.reservations-create .control-fileupload .btn-blue, body.reservations-create .control-fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.reservations-create .control-fileupload a {
  background: #5dbded none repeat scroll 0 0;
  color: #FFFFFF;
}

body.reservations-review .control-fileupload .fileupload .fileinput-button, body.reservations-create .control-fileupload .fileupload .fileinput-button {
  cursor: pointer;
  margin-bottom: 8px;
  text-align: center;
  height: 35px;
}

body.reservations-review .control-fileupload .btn, body.reservations-review .control-fileupload form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.reservations-review .control-fileupload input[type='submit'], body.reservations-review .control-fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.reservations-review .control-fileupload a, body.reservations-create .control-fileupload .btn, body.reservations-create .control-fileupload form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.reservations-create .control-fileupload input[type='submit'], body.reservations-create .control-fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.reservations-create .control-fileupload a {
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0;
}

.reservation-wizard select option[disabled] {
  display: none;
}

.simple-schedule-form .bootstrap-select {
  width: auto !important;
}

.simple-schedule-form .row:first-child {
  margin-top: 0px;
}

.simple-schedule-form .every_hours input {
  width: auto;
}

.simple-schedule-form label.checkbox {
  clear: both;
  display: block;
}

.exclude-rule-fields .bootstrap-select {
  width: auto !important;
}

#signup .box {
  max-width: 570px;
  margin: 0px auto 60px;
}

#signup #service_buttons::after {
  /* Copied from community/_mixins.scss:15 */
  content: '';
  display: table;
  width: 100%;
  clear: both;
}

#signup #service_buttons .btn, #signup #service_buttons form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) #signup #service_buttons input[type='submit'], #signup #service_buttons section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links #signup #service_buttons a {
  border-radius: 0;
  margin: 0;
  width: 33.3%;
  font-size: 22px;
  text-align: center;
  float: left;
}

#signup #service_buttons .btn i.icon-twitter, #signup #service_buttons form:not(.no_inheritance) input[type='submit'] i.icon-twitter, form:not(.no_inheritance) #signup #service_buttons input[type='submit'] i.icon-twitter, #signup #service_buttons section.dashboard ul.locations li.location .links a i.icon-twitter, section.dashboard ul.locations li.location .links #signup #service_buttons a i.icon-twitter {
  display: inline-block;
  vertical-align: text-top;
}

#signup #service_buttons .btn i.icon-facebook, #signup #service_buttons form:not(.no_inheritance) input[type='submit'] i.icon-facebook, form:not(.no_inheritance) #signup #service_buttons input[type='submit'] i.icon-facebook, #signup #service_buttons section.dashboard ul.locations li.location .links a i.icon-facebook, section.dashboard ul.locations li.location .links #signup #service_buttons a i.icon-facebook {
  display: inline-block;
  vertical-align: text-top;
}

#signup #service_buttons .btn i.icon-linkedin, #signup #service_buttons form:not(.no_inheritance) input[type='submit'] i.icon-linkedin, form:not(.no_inheritance) #signup #service_buttons input[type='submit'] i.icon-linkedin, #signup #service_buttons section.dashboard ul.locations li.location .links a i.icon-linkedin, section.dashboard ul.locations li.location .links #signup #service_buttons a i.icon-linkedin {
  display: inline-block;
  vertical-align: text-top;
}

#signup .already-a-user {
  position: absolute;
  width: 100%;
}

#signup .already-a-user a {
  display: block;
  margin: 30px auto;
  font-size: 22px;
  color: #505050;
  opacity: .7;
  transition: opacity, ease-in, .1s;
}

#signup .already-a-user a:hover {
  opacity: 1;
}

#signup #space_wizard .already-a-user a {
  display: none;
}

#signup h3.legal {
  margin: 10px 0 0 0;
  color: gainsboro;
  text-align: center;
}

#signup h3.twitter-login {
  float: left;
  width: 180px;
  text-align: right;
  margin: 8px 20px 0 0;
}

@media (max-width: 1080px) {
  #signup h3.twitter-login {
    display: block;
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
    clear: both;
  }
}

@media (min-width: 767px) {
  #signup .btn.facebook, #signup form:not(.no_inheritance) input.facebook[type='submit'], form:not(.no_inheritance) #signup input.facebook[type='submit'], #signup section.dashboard ul.locations li.location .links a.facebook, section.dashboard ul.locations li.location .links #signup a.facebook,
  #signup .btn.linkedin,
  #signup form:not(.no_inheritance) input.linkedin[type='submit'], form:not(.no_inheritance)
  #signup input.linkedin[type='submit'],
  #signup section.dashboard ul.locations li.location .links a.linkedin, section.dashboard ul.locations li.location .links
  #signup a.linkedin {
    margin-right: 2%;
  }
}

#signup .btn.linkedin:hover, #signup form:not(.no_inheritance) input.linkedin[type='submit']:hover, form:not(.no_inheritance) #signup input.linkedin[type='submit']:hover, #signup section.dashboard ul.locations li.location .links a.linkedin:hover, section.dashboard ul.locations li.location .links #signup a.linkedin:hover {
  background: #32517d;
}

#signup .btn.twitter:hover, #signup form:not(.no_inheritance) input.twitter[type='submit']:hover, form:not(.no_inheritance) #signup input.twitter[type='submit']:hover, #signup section.dashboard ul.locations li.location .links a.twitter:hover, section.dashboard ul.locations li.location .links #signup a.twitter:hover {
  background: #00ace6;
}

#signup .btn.facebook:hover, #signup form:not(.no_inheritance) input.facebook[type='submit']:hover, form:not(.no_inheritance) #signup input.facebook[type='submit']:hover, #signup section.dashboard ul.locations li.location .links a.facebook:hover, section.dashboard ul.locations li.location .links #signup a.facebook:hover {
  background: #263961;
}

#signup.decline {
  margin: 100px 0 0 0;
}

#signup.decline .box h2, #signup.decline .box form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) #signup.decline .box fieldset > legend, #signup.decline .box
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) #signup.decline .box fieldset > .legend, #signup.decline .box
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) #signup.decline .box .fieldset > legend, #signup.decline .box
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) #signup.decline .box .fieldset > .legend {
  margin: 0 0 20px 0;
}

#signup #tos-error {
  margin-left: 200px;
}

.modal-content #signup .already-a-user a {
  color: #FFFFFF;
}

.sign-up-modal form {
  overflow: visible;
}

.sign-up-modal #signup {
  margin: 0;
}

.sign-up-modal #signup .span8.offset2 {
  margin: 0;
  width: 100%;
}

.sign-up-modal #signup .span8.offset2 .box {
  margin: 0;
  padding: 0;
  background: transparent;
}

.sign-up-modal #signup .span8.offset2 .box header {
  background: transparent;
  padding: 10px 30px;
}

.sign-up-modal #signup .span8.offset2 .box header p {
  margin: 10px -10px 0;
}

.sign-up-modal p.notice {
  font-size: 12px;
  color: #fff;
  margin: .5em 0;
  font-weight: 600;
}

.sign-up-modal .transaction-side-switcher {
  margin: 10px -10px 0;
}

.mobile-holder .country-calling-code {
  position: absolute;
  top: 0;
  left: 30px;
  line-height: 48px;
  color: #a0a0a0;
  font-size: 22px;
}

@media (max-width: 480px) {
  .mobile-holder .country-calling-code {
    font-size: 18px;
  }
}

.mobile-holder input[type="text"] {
  padding-left: 60px !important;
}

@media (max-width: 480px) {
  .mobile-holder input[type="text"] {
    padding-left: 55px !important;
  }
}

section.space_wizard .select2-container {
  padding-bottom: 8px;
}

section.space_wizard .select2-container a.select2-choice {
  background: #e6e6e6;
  height: 45px;
  border: none;
}

section.space_wizard .select2-container a.select2-choice .select2-chosen {
  line-height: 43px;
}

section.space_wizard .select2-container a.select2-choice .select2-arrow {
  width: 23px;
}

section.space_wizard .select2-container a.select2-choice .select2-arrow b {
  background-size: 77px 73px !important;
}

section.space_wizard #space_wizard {
  margin: 0 0 60px 0;
}

section.space_wizard #space_wizard form.new_desks .currency-type {
  display: inline-block;
  margin-left: 8px;
  font-size: 16px;
}

section.space_wizard #space_wizard form.new_desks input {
  z-index: 1;
  margin-left: -30px;
  padding-left: 30px;
  positon: relative;
}

section.space_wizard #space_wizard form.new_desks .fieldset.template {
  display: none;
}

section.space_wizard #space_wizard .already-a-user {
  display: none;
}

section.space_wizard #space_wizard .help-block {
  color: #505050;
}

section.space_wizard #space_wizard .actions {
  background: #353535;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  z-index: 2;
  text-align: center;
}

section.space_wizard #space_wizard .actions .centerbuttons {
  display: inline-block;
  width: 50%;
}

section.space_wizard #space_wizard .actions .centerbuttons .listmydesk, section.space_wizard #space_wizard .actions .centerbuttons .saveasdraft {
  float: left;
  margin: 0 1% 0 1%;
  width: 48%;
}

@media (max-width: 767px) {
  section.space_wizard #space_wizard .actions .centerbuttons {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  section.space_wizard #space_wizard .actions {
    padding: 10px 0 40px 0;
  }
}

section.space_wizard #space_wizard .resize-photo {
  display: none;
}

section.space_wizard #space_wizard div.booking-types-tab-menu {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

section.space_wizard #space_wizard ul li a {
  color: #5dbded;
}

section.space_wizard .input-disabled {
  opacity: .4;
  filter: inherit;
}

section.space_wizard .with-upload-button .fileupload {
  margin-left: 15px;
  margin-bottom: 10px;
}

section.space_wizard .with-upload-button .fileupload .btn-blue, section.space_wizard .with-upload-button .fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links section.space_wizard .with-upload-button .fileupload a {
  background: #5dbded;
  color: #fff;
}

section.space_wizard .with-upload-button .fileupload .uploaded .attachment {
  margin-left: 10px;
}

section.space_wizard .with-upload-button .fileupload .uploaded .attachment img {
  vertical-align: text-top;
}

section.space_wizard .with-upload-button .fileupload .hint-block {
  font-size: 12px;
}

section.space_wizard .availability-rules-wrapper .nested-fields-set .nested-fields {
  padding: 20px;
  border: 1px solid #efefef;
  border-radius: 10px;
  margin: 20px 0;
}

section.space_wizard .availability-rules-wrapper .nested-fields-set .nested-fields p:last-child {
  margin-bottom: 0;
}

section.space_wizard .availability-rules-wrapper .always-inline.check_boxes .controls > .checkbox {
  display: inline-block;
  margin-right: 10px;
}

#space .calendar,
.space .calendar {
  overflow: hidden;
  padding: 0px;
}

#space .calendar .slider-window,
.space .calendar .slider-window {
  margin: 0 45px;
  overflow: hidden;
}

#space .calendar .prev,
#space .calendar .next, #space .calendar li,
.space .calendar .prev,
.space .calendar .next,
.space .calendar li {
  height: 70px;
  cursor: pointer;
}

#space .calendar .prev:hover,
#space .calendar .next:hover, #space .calendar li:hover,
.space .calendar .prev:hover,
.space .calendar .next:hover,
.space .calendar li:hover {
  background-color: #e6e6e6;
}

#space .calendar .prev,
#space .calendar .next,
.space .calendar .prev,
.space .calendar .next {
  width: 45px;
}

#space .calendar .prev,
.space .calendar .prev {
  float: left;
  background: url("/assets/sections/listings/calendar-prev.png") center center no-repeat;
  border-right: 1px solid #d2d2d2;
}

#space .calendar .next,
.space .calendar .next {
  border-left: 1px solid #fff;
  float: right;
  background: url("/assets/sections/listings/calendar-next.png") center center no-repeat;
}

#space .calendar ul,
.space .calendar ul {
  width: 99999px;
  position: relative;
}

#space .calendar ul li,
.space .calendar ul li {
  float: left;
  width: 60px;
  border-right: 1px solid #d2d2d2;
  border-left: 1px solid #fff;
  position: relative;
}

#space .calendar ul li .date,
#space .calendar ul li .day,
.space .calendar ul li .date,
.space .calendar ul li .day {
  display: block;
  text-align: center;
}

#space .calendar ul li .date,
.space .calendar ul li .date {
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 22px;
}

#space .calendar ul li .date sup,
.space .calendar ul li .date sup {
  vertical-align: top;
  font-size: 12px;
  line-height: 12px;
}

#space .calendar ul li .day,
.space .calendar ul li .day {
  font-size: 12px;
  line-height: 12px;
}

#space .calendar ul li .loader,
.space .calendar ul li .loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("/assets/sections/listings/calendar-loader.gif") center center no-repeat;
}

#space .calendar ul li.today,
.space .calendar ul li.today {
  background-image: url("/assets/sections/listings/calendar-today-flag.png");
  background-position: left 1px;
  background-repeat: no-repeat;
}

#space .calendar ul li.weekend,
.space .calendar ul li.weekend {
  color: #787878;
}

#space .calendar ul li.loading *,
.space .calendar ul li.loading * {
  opacity: 0.3;
}

#space .calendar ul li.loading .loader,
.space .calendar ul li.loading .loader {
  opacity: 1;
  display: block;
}

#location-tabs {
  margin: 0px;
}

#address-popover {
  font-size: 14px;
  padding: 6px;
}

#location-tabs > li > a {
  border: none;
  background-color: #a0a0a0;
  color: #e6e6e6;
  margin: 30px 0 0 0;
}

#location-tabs > li > a:hover {
  background-color: #787878;
}

#location-tabs > li.active > a {
  background-color: #505050;
}

.other-listing-separator {
  margin: 40px 0px;
}

#space .listing.box,
.space .listing.box {
  overflow: hidden;
  height: 254px;
}

@media (min-width: 1000px) {
  #space .listing.box,
  .space .listing.box {
    height: 254px;
  }
}

@media (max-width: 1000px) {
  #space .listing.box,
  .space .listing.box {
    height: auto;
  }
}

@media (max-width: 610px) {
  #space .listing.box,
  .space .listing.box {
    height: auto;
  }
}

#space .listing.box .bottom-fade,
.space .listing.box .bottom-fade {
  position: absolute;
  bottom: 0;
  left: 410px;
  width: 100%;
  height: 40px;
  background-image: linear-gradient(rgba(250, 250, 250, 0), #FFFFFF);
}

@media (max-width: 1000px) {
  #space .listing.box .bottom-fade,
  .space .listing.box .bottom-fade {
    display: none;
  }
}

@media (min-width: 610px) and (max-width: 1000px) {
  #space .listing.box .booking-module-container,
  .space .listing.box .booking-module-container {
    top: 58px;
    float: right;
    clear: both;
    width: 50%;
  }
}

#space .book .btn, #space .book form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) #space .book input[type='submit'], #space .book section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links #space .book a,
.space .book .btn,
.space .book form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance)
.space .book input[type='submit'],
.space .book section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links
.space .book a {
  font-size: 40px;
  height: 80px;
  line-height: 80px;
}

#space header,
.space header {
  display: none;
}

@media (max-width: 1000px) {
  #space header,
  .space header {
    display: block;
  }
}

#space .listing-photo-container,
.space .listing-photo-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 410px;
  height: 254px;
  background: #bebebe;
}

@media (min-width: 610px) and (max-width: 1000px) {
  #space .listing-photo-container,
  .space .listing-photo-container {
    position: relative;
    float: left;
    clear: both;
    margin: -15px 0 0 -15px;
    width: 55%;
    height: 254px;
  }
  #space .listing-photo-container img,
  .space .listing-photo-container img {
    margin: 0 auto;
    max-width: 410px;
    width: 410px;
    height: 254px;
  }
}

@media (max-width: 610px) {
  #space .listing-photo-container,
  .space .listing-photo-container {
    position: relative;
    float: left;
    clear: both;
    margin: -15px 0 0 -15px;
    width: 110%;
    height: auto;
  }
}

#space .listing-name, #space .listing-description,
.space .listing-name,
.space .listing-description {
  float: left;
  padding: 0 300px 0 420px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  #space .listing-name, #space .listing-description,
  .space .listing-name,
  .space .listing-description {
    display: none;
  }
}

#space .listing-description,
.space .listing-description {
  margin: 15px 0 0;
}

@media (max-width: 1000px) {
  #space .listing-description,
  .space .listing-description {
    display: block;
    float: left;
    clear: both;
    margin: 15px 0 0;
    padding: 0;
  }
}

@media (max-width: 610px) {
  #space .listing-description,
  .space .listing-description {
    margin: 15px 0;
  }
}

#space .listing-description p,
.space .listing-description p {
  overflow: auto;
}

@media (min-width: 610px) {
  #space .listing-description p,
  .space .listing-description p {
    max-height: 160px;
  }
}

@media (max-width: 1000px) {
  #space .listing-description p,
  .space .listing-description p {
    margin-bottom: 0;
  }
}

#space .description .price,
.space .description .price {
  font-size: 22px;
}

#space .description .price .period,
.space .description .price .period {
  font-size: 16px;
  font-weight: normal;
}

.space-reservation-modal form {
  overflow: visible;
}

.space-reservation-modal .summary {
  overflow: hidden;
}

.space-reservation-modal .summary .date,
.space-reservation-modal .summary .subtotal-label,
.space-reservation-modal .summary .service-fee-label,
.space-reservation-modal .summary .total-label {
  float: left;
  width: 30%;
}

.space-reservation-modal .summary .bookings,
.space-reservation-modal .summary .subtotal-amount,
.space-reservation-modal .summary .service-fee-amount,
.space-reservation-modal .summary .total-amount {
  float: left;
  width: 70%;
  text-align: right;
}

.space-reservation-modal .subtotal-label,
.space-reservation-modal .subtotal-amount,
.space-reservation-modal .service-fee-label,
.space-reservation-modal .service-fee-amount,
.space-reservation-modal .total-label,
.space-reservation-modal .total-amount {
  font-weight: bold;
}

.space-reservation-modal p.notice {
  font-size: 12px;
  color: gray2;
  margin: .5em 0;
  font-weight: 600px;
  opacity: .7;
}

.space-reservation-modal p.notice:hover {
  opacity: 1;
}

.space-reservation-modal .flash.error {
  color: #e85c41;
  padding-bottom: 0.5em;
}

.space-reservation-modal .flash.error p {
  font-size: 1.1em;
}

.space-reservation-modal form.payment .control-group .select .required {
  display: none;
}

.space-reservation-modal form.payment .control-group .control-label {
  width: auto;
}

.space-reservation-modal form.payment .control-group .controls {
  margin-left: 30%;
}

.space-reservation-modal form.payment .control-group .controls.card-number input {
  width: 90%;
}

.space-reservation-modal form.payment .control-group .controls.card-expires input, .space-reservation-modal form.payment .control-group .controls.card-code input {
  width: 80px;
}

.space-reservation-modal #credit_card_fields .column, .space-reservation-modal .phone_fields .column {
  float: left;
  width: 49%;
}

.space-reservation-modal #credit_card_fields .column.left, .space-reservation-modal .phone_fields .column.left {
  margin-right: 1%;
}

.space-reservation-modal #credit_card_fields .column.right, .space-reservation-modal .phone_fields .column.right {
  margin-left: 1%;
}

.space-reservation-modal #credit_card_fields input#card_number {
  padding-left: 50px;
}

.space-reservation-modal .phone_fields .controls {
  margin-left: 0 !important;
}

.quick-book .calendar-wrapper {
  display: inline-block;
  padding: 2px 8px;
  border: 2px solid gainsboro;
  border-radius: 5px;
  background: #fff;
}

.quick-book .quantity {
  display: inline-block;
}

.quick-book .quantity select {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  vertical-align: middle;
  z-index: 997;
}

#new_reservation_request .small-inputs input, #new_reservation_request .small-inputs select, #new_reservation_request .small-inputs .customSelect {
  width: 180px;
}

#details {
  height: 554px;
}

@media (max-width: 767px) {
  #details {
    height: 160px;
  }
}

@media (max-width: 480px) {
  #details {
    height: 100px;
  }
}

.location-photos.row-fluid .span4.pull-right {
  width: 34%;
  margin-left: 0;
}

@media (max-width: 767px) {
  .location-photos.row-fluid .span4.pull-right {
    width: 100%;
  }
}

.location-photos.row-fluid .map .map-container {
  height: 554px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .location-photos.row-fluid .map .map-container {
    height: 160px !important;
  }
}

@media (max-width: 480px) {
  .location-photos.row-fluid .map .map-container {
    height: 100px !important;
  }
}

.location-photos.row-fluid .map address {
  position: relative;
  z-index: 100;
  margin-top: -65px;
  padding: 10px 20px;
  box-sizing: border-box;
  width: auto;
  height: 65px;
  background: rgba(0, 0, 0, 0.7);
  color: #f0f0f0;
  transition: background .2s ease-in;
}

.location-photos.row-fluid .map address:hover {
  background: rgba(0, 0, 0, 0.95);
}

.location-photos.row-fluid .map address strong {
  font-weight: 600;
}

@media (max-width: 768px) {
  .location-photos.row-fluid .map address {
    margin-top: -40px;
    max-width: none;
    width: 100%;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .location-photos.row-fluid .map address {
    margin-top: 0;
    padding: 4px 20px;
    height: auto;
    border: none;
    color: #f0f0f0;
    font-size: 14px;
  }
}

.location-photos.row-fluid .carousel {
  margin-bottom: 0;
}

.location-photos.row-fluid .carousel .carousel-control {
  top: 50%;
  width: 26px;
  height: auto;
  font-size: 22px;
}

.location-photos.row-fluid .carousel .carousel-caption {
  position: absolute;
  right: 20px !important;
  bottom: 20px;
  overflow: hidden;
  padding: 20px;
  width: 2px;
  height: 6px;
  background: transparent;
  color: white;
  font-weight: 600;
  white-space: nowrap;
  opacity: .5;
  transition: opacity .2s ease-in, text-shadow .1s ease-in;
}

.location-photos.row-fluid .carousel .carousel-caption:hover {
  width: auto;
  color: white;
  opacity: 1;
  text-shadow: black 0 0 2px, black 0 0 4px, black 0 0 6px;
}

.location-photos.row-fluid .carousel .carousel-caption:hover:before {
  content: "";
}

#space h1,
.space h1 {
  margin-bottom: 30px;
  line-height: 40px;
}

#space .box.host,
#space .box.share,
.space .box.host,
.space .box.share {
  position: relative;
  margin-top: 20px;
}

#space .box.host i,
#space .box.share i,
.space .box.host i,
.space .box.share i {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 69px;
  height: 69px;
}

#space .box.host.host i,
#space .box.share.host i,
.space .box.host.host i,
.space .box.share.host i {
  background: url("/assets/sections/listings/host-flag.png");
}

#space .box.host.share i,
#space .box.share.share i,
.space .box.host.share i,
.space .box.share.share i {
  background: url("/assets/sections/listings/share-flag.png");
}

#space .box.host .avatar,
.space .box.host .avatar {
  float: left;
}

#space .box.host .name,
.space .box.host .name {
  margin-left: 60px;
  font-size: 22px;
  line-height: 50px;
}

#space .box.share .stButton,
.space .box.share .stButton {
  margin-right: 12px;
}

#space .box.share .first-row,
.space .box.share .first-row {
  margin-bottom: 6px;
}

#space .amenities,
.space .amenities {
  font-size: 12px;
  line-height: 16px;
}

#space .amenities dt,
.space .amenities dt {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
}

#space .amenities dt i,
.space .amenities dt i {
  background: sprite-map("sections/listings/amenity-categories/*.png");
  line-height: 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: top;
  margin-bottom: 0px;
}

#space .amenities dl,
.space .amenities dl {
  margin-bottom: 8px;
}

#space .amenities i.icon-amenity-access,
.space .amenities i.icon-amenity-access {
  background-position: sprite-position(sprite-map("sections/listings/amenity-categories/*.png"), "access");
}

#space .amenities i.icon-amenity-other,
.space .amenities i.icon-amenity-other {
  background-position: sprite-position(sprite-map("sections/listings/amenity-categories/*.png"), "entertainment");
}

#space .map .map-wrapper,
.space .map .map-wrapper {
  margin-bottom: 10px;
}

#space .host-company h2, #space .host-company form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) #space .host-company fieldset > legend, #space .host-company
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) #space .host-company fieldset > .legend, #space .host-company
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) #space .host-company .fieldset > legend, #space .host-company
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) #space .host-company .fieldset > .legend,
.space .host-company h2,
.space .host-company form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance)
.space .host-company fieldset > legend,
.space .host-company
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance)
.space .host-company fieldset > .legend,
.space .host-company
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance)
.space .host-company .fieldset > legend,
.space .host-company
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance)
.space .host-company .fieldset > .legend {
  font-size: 18px;
  font-weight: bold;
}

#space .activity-feed li,
.space .activity-feed li {
  background: url("/assets/sections/listings/event-feed-icon.png") top left no-repeat;
  padding-left: 24px;
}

#space .activity-feed li sup,
.space .activity-feed li sup {
  font-size: 70%;
  line-height: 80%;
  vertical-align: top;
}

#space .activity-feed li small,
.space .activity-feed li small {
  font-size: 12px;
  font-weight: bold;
  color: #6f7e85;
}

#space .activity-feed li .user,
.space .activity-feed li .user {
  font-weight: bold;
  color: #2091d0;
}

.support hr {
  margin-left: 5px;
  margin-right: 5px;
}

.support .message {
  margin-top: 15px;
}

.support .message .profile {
  max-width: 180px;
}

.support.detail h1 a {
  color: #5dbded;
}

.support.detail .rfq-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .support.detail .rfq-header .header-wrapper {
    margin-top: 10px;
  }
  .support.detail .rfq-header .header-wrapper:first-child {
    margin-top: 0px;
  }
}

.support.detail .rfq-header .rfq-header-label {
  color: #a0a0a0;
  margin-bottom: 10px;
}

.support.detail .rfq-header .rfq-header-data .status.open {
  color: #5dc988;
}

.support.detail .rfq-header .status-wrapper,
.support.detail .rfq-header .status-wrapper.status {
  text-align: right;
}

@media (max-width: 768px) {
  .support.detail .rfq-header .status-wrapper,
  .support.detail .rfq-header .status-wrapper.status {
    text-align: left;
  }
}

.support.detail .header {
  margin: -15px -15px 30px -15px;
  padding: 15px;
  background-color: #a0a0a0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.support.detail #more-reservations {
  display: none;
}

.support.detail .form {
  margin-bottom: 30px;
}

.support.detail .name {
  color: #1e2222;
}

.support.detail .time abbr {
  color: #a0a0a0;
}

.support.detail .text {
  color: #a0a0a0;
}

.support.detail .text p {
  color: #a0a0a0;
}

.support .list.tickets .text {
  color: #1e2222;
}

.support .list.tickets .ticket {
  padding-top: 0px;
  padding-bottom: 5px;
}

.support .list.tickets .box {
  padding: 20px;
}

.support .list.tickets .link {
  display: inline-block;
}

.support .list.tickets .link a {
  display: inline-block;
}

.support .list.tickets .time {
  display: inline-block;
}

.support .form {
  color: #1e2222;
}

.support .form .columns .row-fluid {
  margin-top: 10px;
}

.support .faq {
  padding-bottom: 20px;
}

.support .faq.opened .question:before {
  content: "\25BC";
}

.support .faq.closed .question:before {
  content: "\25BA";
}

.support .faq.closed .answer {
  display: none;
}

.support .faq .question {
  cursor: pointer;
  color: #1e2222;
}

.support .faq .answer {
  padding-top: 10px;
}

.support .tickets {
  font-size: 18px;
  color: #b6b6b6;
}

.support .tickets .ticket {
  padding-top: 15px;
}

.support .tickets .ticket .question {
  color: #1e2222;
  padding-bottom: 8px;
}

.support .tickets .ticket .time abbr {
  color: #b6b6b6;
}

.support .tickets .ticket .link a {
  display: block;
  color: #b6b6b6;
  text-decoration: underline !important;
}

.support .tickets .ticket .link a:hover {
  text-decoration: none !important;
}

.support .view-all {
  display: block;
  padding-top: 15px;
  padding-bottom: 25px;
  color: #1e2222;
  text-decoration: underline !important;
}

.support .btn.btn-green, .support form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) .support input[type='submit'], .support section.dashboard ul.locations li.location .links a.btn-green, section.dashboard ul.locations li.location .links .support a.btn-green {
  margin-top: 20px;
}

.support .upload-attachment {
  border-radius: 5px;
  padding: 0 20px;
  margin-top: 20px;
  font-size: 18px;
  height: 50px;
  width: 100%;
  line-height: 50px;
  text-align: center;
}

.support .attachments {
  font-size: 16px;
}

.support .attachments .attachment .tag {
  color: #cccccc;
  margin-left: 10px;
}

.support .attachments .attachment .destroy {
  margin-left: 8px;
}

.support .attachments .attachment .edit a,
.support .attachments .attachment .destroy a {
  font-size: 17px;
  color: #787878;
}

.support textarea {
  font-size: 18px;
}

.support .time .timeago {
  font-size: 15px;
}

@media (max-width: 767px) {
  .support .message .span1,
  .support .message-form .span1 {
    float: left;
    width: 12%;
  }
  .support .message .span11,
  .support .message-form .span11 {
    float: left;
    margin-left: 2%;
    width: 86%;
  }
  .support .message input[type='submit'],
  .support .message-form input[type='submit'] {
    margin-top: 0px;
  }
  .support .message input[type='submit'].resolve,
  .support .message-form input[type='submit'].resolve {
    margin-top: 5px;
  }
}

.support .message form,
.support .message-form form {
  margin-top: 0px;
}

#new_user_message #textarea {
  height: 150px;
}

section.user_messages p.no-data {
  text-align: center;
  margin: 40px 0 25px 0;
}

section.user_messages #new_user_message textarea {
  height: 52px;
}

section.user_messages #new_user_message .span10, section.user_messages #new_user_message .span12 {
  margin-left: 0;
  width: 73%;
}

section.user_messages #new_user_message .span2 {
  margin-left: 15px;
  width: 20%;
}

section.user_messages #new_user_message .span2 input.btn, section.user_messages #new_user_message .span2 form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) section.user_messages #new_user_message .span2 input[type='submit'] {
  padding: 0;
}

section.user_messages .listing-message a, section.user_messages .listing-message-for-show a {
  text-decoration: underline !important;
}

section.user_messages .listing-message p, section.user_messages .listing-message-for-show p {
  margin-bottom: 0;
}

section.user_messages .listing-message {
  margin-left: 0;
  margin-bottom: 15px;
  padding: 16px 0;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
}

section.user_messages .listing-message.unread {
  background: #fff;
}

section.user_messages .listing-message .name {
  margin-left: 15px;
}

section.user_messages .listing-message .name a {
  color: #505050;
}

section.user_messages .listing-message .date {
  margin-left: 15px;
  color: #a0a0a0;
  font-size: 14px;
}

section.user_messages .listing-message .body a {
  color: #505050;
}

section.user_messages .listing-message .link-to-listing {
  font-size: 14px;
}

section.user_messages .listing-message .link-to-listing a {
  color: #a0a0a0;
  font-size: 14px;
}

section.user_messages .listing-message .actions {
  padding-left: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
}

section.user_messages .listing-message .actions a {
  padding: 8px 22px;
  width: 100%;
  text-decoration: none !important;
  text-align: left;
  border-radius: 5px 0 0 5px;
}

section.user_messages .listing-message .actions a:hover {
  background: #bebebe;
}

.listing-deleted .body {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #E2E2E2;
  color: #505050;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
}

.listing-message-for-show {
  margin-bottom: 15px;
}

.listing-message-for-show a {
  text-decoration: underline !important;
}

.listing-message-for-show .name a {
  color: #505050;
}

.listing-message-for-show .date {
  color: #a0a0a0;
  font-size: 14px;
}

.listing-message-for-show .body {
  padding: 20px;
  border: 1px solid #E2E2E2;
  color: #505050;
  border-radius: 5px;
}

.listing-message-for-show.foreign-message .body {
  background: #fff;
}

.listing-message-for-show.foreign-message .info {
  text-align: right;
}

#missing-mobile-number .input-group {
  display: table;
}

#missing-mobile-number .input-group-addon {
  display: table-cell;
  padding: 6px 12px;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

#missing-mobile-number .selectize-input input {
  font-size: 16px;
}

#missing-mobile-number [data-mobile-number] {
  margin: 0 !important;
}

#missing-mobile-number input[type=submit], #missing-mobile-number a {
  height: 50px;
  line-height: 50px;
  margin: 10px 0 0;
}

#missing-mobile-number a {
  text-align: center;
  display: inline-block;
}

#missing-mobile-number a.btn-grey {
  background-color: #C0C0C0;
}

#missing-mobile-number a.btn, #missing-mobile-number section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links #missing-mobile-number a {
  padding: 0;
}

@media (max-width: 767px) {
  .user_messages .listing-message p {
    margin-left: 15px;
  }
}

.user-profile {
  margin-bottom: 40px;
  background: #fff;
}

.user-profile * {
  box-sizing: border-box;
}

.user-profile header {
  background: none;
  height: auto;
  position: relative;
}

.user-profile .user-profile__header {
  background: #dfdfdf;
  padding: 15px;
}

.user-profile .profile-content {
  padding: 15px;
}

.user-profile .portrait {
  position: relative;
  display: block;
}

.user-profile .portrait img {
  margin: 0 auto 20px;
  max-width: 200px;
  display: block;
  background: #fff;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.user-profile .user-meta {
  margin-bottom: 10px;
}

.user-profile .user-meta b {
  font-weight: bold;
}

@media (min-width: 769px) {
  .user-profile .user-profile__header .span7 {
    float: left;
    margin-left: 20px;
    width: calc(100% - 380px);
    padding: 0;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .user-profile .user-profile__header .span3 {
    position: relative;
    float: left;
    width: 200px;
    padding: 0;
  }
  .user-profile .user-profile__header .span3 img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 200px;
    height: 200px;
    margin: 0;
  }
  .user-profile .user-profile__header .span2 {
    float: right;
    width: 160px;
    min-height: 80px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .user-profile .user-meta {
    margin-bottom: 0;
  }
  .user-profile .profile-content .span3 {
    float: left;
    width: 200px;
    padding-top: 100px;
    margin: 0;
  }
  .user-profile .profile-content .tab-content .span3 {
    padding-top: 0;
  }
  .user-profile .profile-content .span9 {
    float: right;
    width: calc(100% - 220px);
    margin: 0;
  }
}

.user-profile .follow {
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
  text-align: center;
}

@media (min-width: 769px) {
  .user-profile .follow {
    text-align: right;
  }
}

.user-profile .follow li {
  display: inline-block;
  margin-left: 8px;
}

.user-profile .follow span {
  font-size: 0;
}

.user-profile .follow span:before {
  font-size: 25px;
}

.user-profile .follow .twitter a {
  color: #55acee;
}

.user-profile .follow .facebook a {
  color: #3b5998;
}

.user-profile .follow .linkedin a {
  color: #0077b5;
}

.user-profile .follow .googleplus a {
  color: #dc4e41;
}

.user-profile .tab-content {
  padding: 15px 0 0;
}

.user-profile .section-header {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.user-profile .section-header h2, .user-profile .section-header form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .user-profile .section-header fieldset > legend, .user-profile .section-header
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .user-profile .section-header fieldset > .legend, .user-profile .section-header
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .user-profile .section-header .fieldset > legend, .user-profile .section-header
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .user-profile .section-header .fieldset > .legend {
  font-size: 20px;
}

.user-profile .section-header .edit-link {
  position: absolute;
  right: 0;
  top: 0;
}

.user-profile .section-header:first-child {
  margin-top: 0;
}

.user-profile dl:after {
  display: table;
  clear: both;
  width: 100%;
  content: "";
}

.user-profile dt {
  font-weight: bold;
  color: #2a2a2a;
}

.user-profile dd {
  margin-bottom: 1em;
}

@media (min-width: 769px) {
  .user-profile dt {
    clear: both;
    float: left;
    width: 20%;
    margin-bottom: 1em;
    text-align: right;
  }
  .user-profile dd {
    float: right;
    width: 75%;
  }
}

.user-profile .verifications .ico-check, .user-profile .verifications .checkbox-icon-inner, .user-profile .verifications .switch-left {
  color: #5dc998;
}

.user-profile .verifications .ico-check:before, .user-profile .verifications .checkbox-icon-inner:before, .user-profile .verifications .switch-left:before {
  vertical-align: middle;
  margin-right: 10px;
}

.product-details article p, .product-details article li,
.listing-details article p,
.listing-details article li,
.booking-module .booking-description p,
.booking-module .booking-description li {
  font-size: 16px;
}

.product-details article p *, .product-details article li *,
.listing-details article p *,
.listing-details article li *,
.booking-module .booking-description p *,
.booking-module .booking-description li * {
  font-size: 1em;
}

.product-details article p,
.listing-details article p,
.booking-module .booking-description p {
  margin: 0 0 .5em;
}

.product-details article strong,
.listing-details article strong,
.booking-module .booking-description strong {
  font-weight: bold;
}

.product-details article em,
.listing-details article em,
.booking-module .booking-description em {
  font-style: italic;
}

.product-details article u,
.listing-details article u,
.booking-module .booking-description u {
  text-decoration: underline;
}

.product-details article s,
.listing-details article s,
.booking-module .booking-description s {
  text-decoration: line-through;
}

.product-details article ol, .product-details article ul,
.listing-details article ol,
.listing-details article ul,
.booking-module .booking-description ol,
.booking-module .booking-description ul {
  margin: 0 0 1em 22px;
}

.product-details article ol ol, .product-details article ol ul, .product-details article ul ol, .product-details article ul ul,
.listing-details article ol ol,
.listing-details article ol ul,
.listing-details article ul ol,
.listing-details article ul ul,
.booking-module .booking-description ol ol,
.booking-module .booking-description ol ul,
.booking-module .booking-description ul ol,
.booking-module .booking-description ul ul {
  margin-bottom: 0;
}

.product-details article ul,
.listing-details article ul,
.booking-module .booking-description ul {
  list-style-type: disc;
}

.product-details article ol,
.listing-details article ol,
.booking-module .booking-description ol {
  list-style-type: decimal;
}

.product-details article blockquote,
.listing-details article blockquote,
.booking-module .booking-description blockquote {
  margin: 0 20px 1em;
  border-left: 5px solid #aaa;
  padding: 10px 20px;
}

.product-details article blockquote p,
.listing-details article blockquote p,
.booking-module .booking-description blockquote p {
  font-style: italic;
  font-size: 1.2em !important;
  margin: 0 !important;
}

body.buy-sell-theme {
  margin: 0;
  background: #f1f1f1;
  /*===== GENERAL STYLES =====*/
  /*===== BUTTONS AND INPUTS =====*/
  /*===== PRODUCT USER BADGE =====*/
  /*===== PRODUCT IMAGES =====*/
  /*===== PAGINATION =====*/
  /*===== PRODUCT PRICE AND RATING =====*/
  /*===== SOCIAL =====*/
  /*===== TABS AND TABBED CONTENT =====*/
  /*===== VENDOR PROFILE =====*/
  /*===== REVIEWS =====*/
  /*===== MEDIA QUERIES =====*/
  /*===== SEARCH BAR =====*/
}

body.buy-sell-theme .alert.success {
  background-color: #5dc988;
}

body.buy-sell-theme .alert.success p {
  color: #ffffff;
}

body.buy-sell-theme .alert.deleted {
  background-color: #5dc988;
}

body.buy-sell-theme .alert.deleted p {
  color: #ffffff;
}

body.buy-sell-theme .alert.notice {
  background-color: #5dc988;
}

body.buy-sell-theme .alert.notice p {
  color: #ffffff;
}

body.buy-sell-theme .alert.error {
  background-color: #e85c41;
}

body.buy-sell-theme .alert.error p {
  color: #ffffff;
}

body.buy-sell-theme .alert.warning {
  background-color: #ffc840;
}

body.buy-sell-theme .alert.warning p {
  color: #ffffff;
}

body.buy-sell-theme header {
  height: 60px;
  background: black;
}

body.buy-sell-theme form {
  width: 100%;
}

body.buy-sell-theme p, body.buy-sell-theme td {
  color: #555555;
}

body.buy-sell-theme p, body.buy-sell-theme h1, body.buy-sell-theme h2, body.buy-sell-theme form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) body.buy-sell-theme fieldset > legend, body.buy-sell-theme
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme fieldset > .legend, body.buy-sell-theme
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) body.buy-sell-theme .fieldset > legend, body.buy-sell-theme
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme .fieldset > .legend, body.buy-sell-theme h3, body.buy-sell-theme h4, body.buy-sell-theme h5, body.buy-sell-theme a, body.buy-sell-theme label, body.buy-sell-theme td {
  font-weight: 600;
  margin: 0;
}

body.buy-sell-theme h1, body.buy-sell-theme h2, body.buy-sell-theme form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) body.buy-sell-theme fieldset > legend, body.buy-sell-theme
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme fieldset > .legend, body.buy-sell-theme
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) body.buy-sell-theme .fieldset > legend, body.buy-sell-theme
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme .fieldset > .legend, body.buy-sell-theme h3, body.buy-sell-theme h4, body.buy-sell-theme h5 {
  color: #3a3a3a;
  font-weight: 600;
}

body.buy-sell-theme h1 {
  font-size: 40px;
}

body.buy-sell-theme td {
  font-size: 16px;
  font-weight: 300;
}

body.buy-sell-theme td.country-price {
  padding-left: 20px;
}

body.buy-sell-theme section .container-fluid {
  width: 58%;
  margin: 0 auto;
  padding: 0;
}

body.buy-sell-theme .inline {
  display: inline-block;
}

body.buy-sell-theme .divider {
  border-top: solid #CACBD3 1px;
}

body.buy-sell-theme .btn, body.buy-sell-theme form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme input[type='submit'], body.buy-sell-theme section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme a {
  width: 100%;
  padding: 7px 0;
  border-radius: 0;
  text-align: center;
  font-size: 16px;
  vertical-align: top;
  cursor: pointer;
}

body.buy-sell-theme .btn.blue, body.buy-sell-theme form:not(.no_inheritance) input.blue[type='submit'], form:not(.no_inheritance) body.buy-sell-theme input.blue[type='submit'], body.buy-sell-theme section.dashboard ul.locations li.location .links a.blue, section.dashboard ul.locations li.location .links body.buy-sell-theme a.blue {
  background: #5dbded;
  color: #fff;
}

body.buy-sell-theme .btn.gray, body.buy-sell-theme form:not(.no_inheritance) input.gray[type='submit'], form:not(.no_inheritance) body.buy-sell-theme input.gray[type='submit'], body.buy-sell-theme section.dashboard ul.locations li.location .links a.gray, section.dashboard ul.locations li.location .links body.buy-sell-theme a.gray {
  background: #f4f4f4;
  color: #555555;
}

body.buy-sell-theme .btn.green, body.buy-sell-theme form:not(.no_inheritance) input.green[type='submit'], form:not(.no_inheritance) body.buy-sell-theme input.green[type='submit'], body.buy-sell-theme section.dashboard ul.locations li.location .links a.green, section.dashboard ul.locations li.location .links body.buy-sell-theme a.green {
  background: #5dc998;
  color: #fff;
}

body.buy-sell-theme .btn.red, body.buy-sell-theme form:not(.no_inheritance) input.red[type='submit'], form:not(.no_inheritance) body.buy-sell-theme input.red[type='submit'], body.buy-sell-theme section.dashboard ul.locations li.location .links a.red, section.dashboard ul.locations li.location .links body.buy-sell-theme a.red {
  background: #e85c41;
  color: #fff;
}

body.buy-sell-theme .btn.disabled, body.buy-sell-theme form:not(.no_inheritance) input.disabled[type='submit'], form:not(.no_inheritance) body.buy-sell-theme input.disabled[type='submit'], body.buy-sell-theme section.dashboard ul.locations li.location .links a.disabled, section.dashboard ul.locations li.location .links body.buy-sell-theme a.disabled {
  background: #b6b6b6;
  color: #fff;
  cursor: default;
}

body.buy-sell-theme .btn.login, body.buy-sell-theme form:not(.no_inheritance) input.login[type='submit'], form:not(.no_inheritance) body.buy-sell-theme input.login[type='submit'], body.buy-sell-theme section.dashboard ul.locations li.location .links a.login, section.dashboard ul.locations li.location .links body.buy-sell-theme a.login {
  background: #001856;
  color: #fff;
  padding: 7px 150px;
  margin: 20px 0;
}

body.buy-sell-theme .customSelect {
  background: #fff url("/assets/themes/buy_sell/arrow.png") right no-repeat;
  border: solid #D4D4D4 1px;
  font-size: 14px;
  padding: 0 5px;
  width: 60px;
}

body.buy-sell-theme .padding11 {
  padding: 11px 0;
}

body.buy-sell-theme .product-profile-badge .vendor-profile {
  padding: 30px 40px;
  background: #fff;
  border-bottom: solid #D5D5D5 2px;
  width: 29%;
  vertical-align: top;
  float: right;
  margin-top: 50px;
}

@media (max-width: 924px) {
  body.buy-sell-theme .product-profile-badge .vendor-profile {
    float: none;
    width: 80%;
    margin: 50px auto;
  }
}

@media (max-width: 585px) {
  body.buy-sell-theme .product-profile-badge .vendor-profile {
    width: 80%;
  }
}

body.buy-sell-theme .product-img-large img {
  width: 100%;
}

body.buy-sell-theme .product-thumbnail {
  width: 100%;
}

body.buy-sell-theme .thumbnail-row {
  margin-top: 20px;
}

body.buy-sell-theme .thumbnail-row img {
  margin-right: 17px;
  width: 100px;
}

body.buy-sell-theme .pagination {
  text-align: center;
}

body.buy-sell-theme .pagination ul {
  box-shadow: none;
}

body.buy-sell-theme .pagination ul li a {
  background: transparent;
  border: none;
  float: inherit;
}

body.buy-sell-theme .pagination img {
  float: inherit;
}

body.buy-sell-theme .pagination a {
  color: #5dbded;
}

body.buy-sell-theme .pagination .page-link {
  padding-top: 10px;
  vertical-align: super;
}

body.buy-sell-theme .pagination .page-link.active {
  color: #000;
}

body.buy-sell-theme .product-rating {
  margin-top: 35px;
}

body.buy-sell-theme .rating p {
  display: inline-block;
  margin-right: 25px;
  font-weight: 300;
}

body.buy-sell-theme .product-head label {
  font-weight: 300;
  margin-right: 10px;
}

body.buy-sell-theme .product-head {
  background-color: #ffffff;
  margin-top: -30px;
  padding-top: 30px;
}

body.buy-sell-theme .product-head .btn.blue, body.buy-sell-theme .product-head form:not(.no_inheritance) input.blue[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .product-head input.blue[type='submit'], body.buy-sell-theme .product-head section.dashboard ul.locations li.location .links a.blue, section.dashboard ul.locations li.location .links body.buy-sell-theme .product-head a.blue {
  margin-top: 30px;
}

body.buy-sell-theme .product-head .btn.disabled, body.buy-sell-theme .product-head form:not(.no_inheritance) input.disabled[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .product-head input.disabled[type='submit'], body.buy-sell-theme .product-head section.dashboard ul.locations li.location .links a.disabled, section.dashboard ul.locations li.location .links body.buy-sell-theme .product-head a.disabled {
  margin-top: 30px;
}

body.buy-sell-theme .product-social-wrapper {
  border: solid #f4f4f4 1px;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}

body.buy-sell-theme .product-social-wrapper .btn.gray, body.buy-sell-theme .product-social-wrapper form:not(.no_inheritance) input.gray[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .product-social-wrapper input.gray[type='submit'], body.buy-sell-theme .product-social-wrapper section.dashboard ul.locations li.location .links a.gray, section.dashboard ul.locations li.location .links body.buy-sell-theme .product-social-wrapper a.gray {
  margin-bottom: 15px;
}

body.buy-sell-theme .product-social-wrapper .social-icon {
  margin-right: 3px;
}

body.buy-sell-theme .product-details {
  background: #f4f4f4;
  padding: 40px 0;
}

body.buy-sell-theme .tab-content-container {
  float: left;
  width: 60%;
  border-bottom: solid #D5D5D5 2px;
}

body.buy-sell-theme .nav {
  margin: 0;
}

body.buy-sell-theme .nav-tabs li a:hover {
  background: transparent;
  border: solid transparent 1px;
}

body.buy-sell-theme .nav-tabs > .active > a, body.buy-sell-theme .nav-tabs > .active > a:hover, body.buy-sell-theme .nav-tabs > .active > a:focus {
  background: #fff !important;
  border: solid transparent 1px;
  color: #000;
}

body.buy-sell-theme .nav-tabs li a {
  color: #3a3a3a;
  padding: 22px 25px;
  font-weight: 600;
}

body.buy-sell-theme .tab-content article {
  background: #fff;
  padding: 30px 40px;
}

body.buy-sell-theme .tab-content article p {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 25px;
}

body.buy-sell-theme .vendor-info {
  vertical-align: top;
  padding: 25px 10px;
}

body.buy-sell-theme .vendor-info p {
  font-size: 14px;
  font-weight: 300;
}

body.buy-sell-theme .vendor-pic {
  width: 100px;
  display: inline-block;
}

body.buy-sell-theme .verifications .row-fluid {
  margin: 15px 0;
}

body.buy-sell-theme .verifications p {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  margin: 5px 5px;
  width: 45%;
}

body.buy-sell-theme .verifications img {
  padding-right: 10px;
  vertical-align: text-top;
}

body.buy-sell-theme .vendor-profile .tn {
  margin: 15px 0 30px 0;
}

body.buy-sell-theme .vendor-rating {
  margin: 15px 0 30px 0;
}

body.buy-sell-theme .vendor-rating a {
  color: #5dbded;
  font-size: 14px;
  vertical-align: top;
  padding-left: 5px;
}

body.buy-sell-theme .profile {
  padding-bottom: 30px;
  /*===== VENDOR PROFILE =====*/
  /*===== VENDOR LISTINGS ===== */
}

body.buy-sell-theme .profile .page-wrapper {
  width: 1200px;
}

body.buy-sell-theme .profile .profile-content {
  box-sizing: border-box;
  float: right;
  width: 65%;
}

body.buy-sell-theme .profile .user-badge {
  float: left;
  width: 25%;
}

body.buy-sell-theme .profile .user-badge a {
  color: #5dbded;
  font-size: 14px;
  vertical-align: top;
}

body.buy-sell-theme .profile .vendor-profile {
  box-sizing: border-box;
  padding: 30px 40px;
  background: #fff;
  border-bottom: solid #D5D5D5 2px;
  width: 100%;
  margin-bottom: 40px;
  overflow-wrap: break-word;
}

body.buy-sell-theme .profile .shop-info p {
  font-size: 16px;
  margin-bottom: 20px;
}

body.buy-sell-theme .profile .verifications li {
  font-size: 12px;
}

body.buy-sell-theme .profile .edit-link {
  float: right;
}

body.buy-sell-theme .profile .submit-bar {
  text-align: center;
}

body.buy-sell-theme .profile .submit-bar .btn, body.buy-sell-theme .profile .submit-bar form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .profile .submit-bar input[type='submit'], body.buy-sell-theme .profile .submit-bar section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .profile .submit-bar a {
  width: 45%;
  margin-left: 20px;
}

body.buy-sell-theme .profile .profile-listings {
  box-sizing: border-box;
  background: #fff;
  padding: 30px;
  border-bottom: solid #D5D5D5 2px;
}

body.buy-sell-theme .profile .profile-listings .row-fluid {
  margin-bottom: 40px;
}

body.buy-sell-theme .profile .profile-listings .row-fluid.compact {
  margin-bottom: 10px;
}

body.buy-sell-theme .profile .item-img {
  max-height: 186px;
  overflow: hidden;
}

body.buy-sell-theme .profile .item img {
  width: 100%;
  min-height: 186px;
}

body.buy-sell-theme .profile .item .details {
  background: #fff;
  margin-top: -8px;
  padding: 5px 15px;
}

body.buy-sell-theme .profile .item .details p {
  font-size: 14px;
}

body.buy-sell-theme .profile .details .name {
  float: left;
  color: #858585;
  font-weight: 300;
}

body.buy-sell-theme .profile .details .price {
  float: right;
  color: #5dc988;
  font-weight: 300;
}

body.buy-sell-theme .profile .btn.cta, body.buy-sell-theme .profile form:not(.no_inheritance) input.cta[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .profile input.cta[type='submit'], body.buy-sell-theme .profile section.dashboard ul.locations li.location .links a.cta, section.dashboard ul.locations li.location .links body.buy-sell-theme .profile a.cta {
  width: 240px;
}

body.buy-sell-theme .profile article.post {
  border-bottom: 1px solid #D5D5D5;
  padding: 0;
  margin-bottom: 20px;
}

body.buy-sell-theme .profile article.post .post-head {
  margin-bottom: 20px;
}

body.buy-sell-theme .profile article.post .post-body {
  clear: both;
}

body.buy-sell-theme .profile article.post p {
  font-size: 14px;
}

body.buy-sell-theme .profile article.post p.date {
  margin-bottom: 0;
}

@media (max-width: 1555px) {
  body.buy-sell-theme .profile .page-wrapper {
    width: 90%;
  }
}

@media (max-width: 1166px) {
  body.buy-sell-theme .profile .page-wrapper {
    width: 100%;
  }
  body.buy-sell-theme .profile .user-badge {
    float: none;
    width: 80%;
    margin: 0 auto;
    padding: 30px;
    margin-bottom: 20px;
  }
  body.buy-sell-theme .profile .profile-content {
    float: none;
    width: 80%;
    margin: 0 auto;
  }
  body.buy-sell-theme .profile .profile-listings {
    width: 100%;
  }
}

body.buy-sell-theme .reviews-summary {
  margin-bottom: 20px;
}

body.buy-sell-theme .rating-averages {
  margin-bottom: 35px;
}

body.buy-sell-theme .see-more {
  text-align: center;
  padding: 30px 0;
  border-top: solid #cacbd3 1px;
}

body.buy-sell-theme .see-more a {
  color: #5dbded;
  font-size: 14px;
}

body.buy-sell-theme .review-date {
  float: right;
  font-size: 12px;
  color: #858585;
}

body.buy-sell-theme .reviews-dropdown {
  display: inline-block;
}

body.buy-sell-theme .reviews-dropdown .title {
  font-size: 14px;
  color: #5dbded;
}

body.buy-sell-theme .reviews-dropdown .ico-chevron-down, body.buy-sell-theme .reviews-dropdown .booking-module-container .order_dates .select2-container .select2-choice .select2-arrow, .booking-module-container .order_dates .select2-container .select2-choice body.buy-sell-theme .reviews-dropdown .select2-arrow {
  font-size: 14px;
  margin-left: 5px;
  color: gray;
}

body.buy-sell-theme .reviews-dropdown .dropdown-menu {
  min-width: 200px;
}

body.buy-sell-theme .reviews-dropdown .dropdown-menu li {
  padding: 0 15px;
  cursor: pointer;
}

body.buy-sell-theme .reviews-dropdown .dropdown-menu li:hover {
  background-color: #f1f1f1;
}

body.buy-sell-theme .cart-view {
  margin-top: -30px;
  padding-top: 30px;
  background: #ffffff;
  /*===== BUTTONS AND INPUTS =====*/
  /*===== CART LAYOUT =====*/
  /*===== ITEM LISTS =====*/
  /*===== MEDIA QUERIES =====*/
}

body.buy-sell-theme .cart-view .button-row {
  margin-bottom: 40px;
}

body.buy-sell-theme .cart-view .btn, body.buy-sell-theme .cart-view form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .cart-view input[type='submit'], body.buy-sell-theme .cart-view section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .cart-view a {
  width: inherit;
  padding: 7px 25px;
  font-size: 14px;
  font-weight: 300;
}

body.buy-sell-theme .cart-view .checkout {
  text-align: right;
}

body.buy-sell-theme .cart-view .checkout .btn.blue, body.buy-sell-theme .cart-view .checkout form:not(.no_inheritance) input.blue[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .cart-view .checkout input.blue[type='submit'], body.buy-sell-theme .cart-view .checkout section.dashboard ul.locations li.location .links a.blue, section.dashboard ul.locations li.location .links body.buy-sell-theme .cart-view .checkout a.blue {
  margin: 20px 0 80px 0;
  padding: 7px 50px;
}

body.buy-sell-theme .cart-view .checkout img {
  margin-right: 10px;
}

body.buy-sell-theme .cart-view .checkout .express-checkout img {
  margin-top: 20px;
}

body.buy-sell-theme .cart-view .cart-head h3 {
  margin-bottom: 40px;
}

body.buy-sell-theme .cart-view .cart-head .btn.green, body.buy-sell-theme .cart-view .cart-head form:not(.no_inheritance) input.green[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .cart-view .cart-head input.green[type='submit'], body.buy-sell-theme .cart-view .cart-head section.dashboard ul.locations li.location .links a.green, section.dashboard ul.locations li.location .links body.buy-sell-theme .cart-view .cart-head a.green {
  float: right;
}

body.buy-sell-theme .cart-view .cart-item-list {
  border: solid #f0f0f0 1px;
}

body.buy-sell-theme .cart-view .list-body {
  padding: 20px 30px 0px 30px;
}

body.buy-sell-theme .cart-view .list-body-header p {
  font-size: 16px;
}

body.buy-sell-theme .cart-view .list-head, body.buy-sell-theme .cart-view .list-footer {
  background: #efefef;
  padding: 15px 35px;
}

body.buy-sell-theme .cart-view .clear-all {
  float: right;
}

body.buy-sell-theme .cart-view .clear-all p {
  margin-right: 15px;
}

body.buy-sell-theme .cart-view .list-footer {
  text-align: right;
}

body.buy-sell-theme .cart-view .list-footer h3 {
  color: #5dbded;
  font-weight: 300;
  margin: 0;
}

body.buy-sell-theme .cart-view .list-head p {
  display: inline-block;
}

body.buy-sell-theme .cart-view .list-head a {
  font-size: 16px;
}

body.buy-sell-theme .cart-view .list-item {
  padding: 10px 0;
}

body.buy-sell-theme .cart-view .list-item p {
  font-size: 16px;
}

body.buy-sell-theme .cart-view .list-item.additional-item {
  border-top: solid #f0f0f0 1px;
}

body.buy-sell-theme .cart-view .list-item .heading {
  margin-bottom: 35px;
}

body.buy-sell-theme .cart-view .item img {
  float: left;
  margin-right: 15px;
}

body.buy-sell-theme .cart-view .value {
  margin-top: 35px;
}

@media (max-width: 767px) {
  body.buy-sell-theme .cart-view .value {
    margin: 0;
  }
  body.buy-sell-theme .cart-view .list-item .span2 {
    margin: 20px 0;
  }
  body.buy-sell-theme .cart-view .list-item .span2 p:nth-child(1) {
    color: #000;
    font-weight: 600;
  }
  body.buy-sell-theme .cart-view .page-wrapper {
    width: 90%;
  }
  body.buy-sell-theme .cart-view .checkout {
    text-align: center;
  }
  body.buy-sell-theme .cart-view .item img {
    float: none;
  }
}

body.buy-sell-theme .checkout-wizard {
  /*===== BUTTONS =====*/
  /*===== INPUTS AND FIELDS =====*/
  /*===== TABLES ===== */
  /*===== BREADCRUMBS =====*/
  /*===== MEDIA QUERIES =====*/
}

body.buy-sell-theme .checkout-wizard:after {
  content: "";
  clear: both;
  display: table;
}

body.buy-sell-theme .checkout-wizard td {
  font-weight: 300;
  font-size: 15px;
}

body.buy-sell-theme .checkout-wizard thead th {
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 20px;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-main {
  box-sizing: border-box;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-aside {
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  border-bottom: solid #d5d5d5 2px;
  margin-bottom: 25px;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-steps {
  margin: 30px 0;
  padding: 0;
  text-align: center;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-steps li {
  display: inline-block;
  list-style: none;
  color: #5dbded;
  font-size: 1.5em;
  line-height: 1.4;
  font-weight: 300;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-steps li + li:before {
  content: "»";
  margin: 0 0.5em;
  font-weight: normal;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-steps .current {
  font-weight: 600;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-steps .current ~ li {
  color: #b6b6b6;
}

body.buy-sell-theme .checkout-wizard .checkout-wizard-actions {
  margin: 30px 0;
}

@media (min-width: 792px) {
  body.buy-sell-theme .checkout-wizard .checkout-wizard-aside {
    float: right;
    width: 300px;
  }
  body.buy-sell-theme .checkout-wizard .checkout-wizard-aside + .checkout-wizard-main {
    float: left;
    width: calc(100% - 320px);
  }
}

body.buy-sell-theme .checkout-wizard .delivery-options .radio {
  display: block;
  max-width: 845px;
}

body.buy-sell-theme .checkout-wizard .delivery-options .controls {
  margin-left: 0px !important;
}

body.buy-sell-theme .checkout-wizard .inline {
  display: inline-block;
}

body.buy-sell-theme .checkout-wizard .page-wrapper {
  padding: 60px 0;
  margin: 0 auto;
}

body.buy-sell-theme .checkout-wizard section.checkout-form {
  background: #fff;
  padding: 40px;
  border-bottom: solid #d5d5d5 2px;
  margin-bottom: 25px;
}

body.buy-sell-theme .checkout-wizard section.checkout-form h2, body.buy-sell-theme .checkout-wizard section.checkout-form form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard section.checkout-form fieldset > legend, body.buy-sell-theme .checkout-wizard section.checkout-form
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard section.checkout-form fieldset > .legend, body.buy-sell-theme .checkout-wizard section.checkout-form
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard section.checkout-form .fieldset > legend, body.buy-sell-theme .checkout-wizard section.checkout-form
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard section.checkout-form .fieldset > .legend {
  color: #5dbded;
  font-weight: 300;
  margin-bottom: 40px;
}

body.buy-sell-theme .checkout-wizard section.checkout-form #options {
  padding-top: 10px;
  padding-bottom: 5px;
}

body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload .btn-blue, body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload a {
  background: #5dbded none repeat scroll 0 0;
  color: #FFFFFF;
}

body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload .fileupload .fileinput-button {
  cursor: pointer;
  margin-bottom: 8px;
  text-align: center;
  height: 35px;
}

body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload .btn, body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload input[type='submit'], body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard section.checkout-form#checkout-extra-fields .control-fileupload a {
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0;
}

body.buy-sell-theme .checkout-wizard h2.page-heading, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) fieldset > legend.page-heading, form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard fieldset > legend.page-heading, body.buy-sell-theme .checkout-wizard
form:not(.no_inheritance) fieldset > .page-heading.legend,
form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard fieldset > .page-heading.legend, body.buy-sell-theme .checkout-wizard
form:not(.no_inheritance) .fieldset > legend.page-heading,
form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard .fieldset > legend.page-heading, body.buy-sell-theme .checkout-wizard
form:not(.no_inheritance) .fieldset > .page-heading.legend,
form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard .fieldset > .page-heading.legend {
  margin-bottom: 30px;
}

body.buy-sell-theme .checkout-wizard .divider {
  border: 1px solid #d5d5d5;
  margin: 40px 0;
}

body.buy-sell-theme .checkout-wizard .whats-this p {
  font-size: 14px;
}

body.buy-sell-theme .checkout-wizard .whats-this-link {
  color: #5dbded;
}

body.buy-sell-theme .checkout-wizard #whats-this-target.in {
  background: #f2f2f2;
  padding: 10px;
}

body.buy-sell-theme .checkout-wizard .order-complete {
  background: #5dbded;
  padding: 10px;
}

body.buy-sell-theme .checkout-wizard .order-complete p {
  color: #fff;
  font-weight: 300;
}

body.buy-sell-theme .checkout-wizard .shipping-details {
  border: solid #fafafa 2px;
  margin-bottom: 15px;
}

body.buy-sell-theme .checkout-wizard .shipping-details .heading {
  margin-bottom: 20px;
  color: #858585;
  font-weight: 300;
}

body.buy-sell-theme .checkout-wizard .shipping-options {
  padding: 10px 10px;
}

body.buy-sell-theme .checkout-wizard .item-list {
  background: #f2f2f2;
  padding: 10px 40px;
}

body.buy-sell-theme .checkout-wizard .item-list .row-fluid {
  margin-bottom: 20px;
}

body.buy-sell-theme .checkout-wizard .item-list p {
  font-weight: 300;
  font-size: 16px;
}

body.buy-sell-theme .checkout-wizard .button-row {
  margin-bottom: 40px;
  text-align: center;
}

body.buy-sell-theme .checkout-wizard .button-row .btn, body.buy-sell-theme .checkout-wizard .button-row form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard .button-row input[type='submit'], body.buy-sell-theme .checkout-wizard .button-row section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard .button-row a {
  float: right;
}

body.buy-sell-theme .checkout-wizard .button-row.complete {
  text-align: center;
}

body.buy-sell-theme .checkout-wizard .button-row.complete .btn, body.buy-sell-theme .checkout-wizard .button-row.complete form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard .button-row.complete input[type='submit'], body.buy-sell-theme .checkout-wizard .button-row.complete section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard .button-row.complete a {
  float: none;
}

body.buy-sell-theme .checkout-wizard .btn, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a {
  padding: 7px 25px;
  font-size: 14px;
  font-weight: 300;
  width: inherit;
}

body.buy-sell-theme .checkout-wizard .btn.blue, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input.blue[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input.blue[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a.blue, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a.blue {
  background: #5dbded;
  color: #fff;
}

body.buy-sell-theme .checkout-wizard .btn.gray, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input.gray[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input.gray[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a.gray, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a.gray {
  background: #909090;
  color: #fff;
}

body.buy-sell-theme .checkout-wizard .btn.back, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input.back[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input.back[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a.back, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a.back {
  padding: 7px 125px;
}

body.buy-sell-theme .checkout-wizard .btn.next, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input.next[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input.next[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a.next, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a.next {
  padding: 12px 0px;
  cursor: pointer;
}

body.buy-sell-theme .checkout-wizard .btn.back, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input.back[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input.back[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a.back, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a.back {
  margin-right: 50px;
}

body.buy-sell-theme .checkout-wizard .action-row {
  text-align: right;
  margin-bottom: 15px;
}

body.buy-sell-theme .checkout-wizard .action-row .btn, body.buy-sell-theme .checkout-wizard .action-row form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard .action-row input[type='submit'], body.buy-sell-theme .checkout-wizard .action-row section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard .action-row a {
  margin-left: 15px;
}

body.buy-sell-theme .checkout-wizard .customSelect {
  border: solid #D4D4D4 1px;
  border-radius: 0;
  background: #fff url("/assets/themes/buy_sell/arrow.png") right no-repeat;
  width: 100%;
  padding: 5px 0 5px 5px;
  font-size: 14px;
}

body.buy-sell-theme .checkout-wizard .hasCustomSelect {
  background: #fff url("/assets/themes/buy_sell/arrow-down.png") right no-repeat;
  border: solid #D4D4D4 1px;
  font-size: 14px;
  padding: 0 5px;
  width: 60px;
  display: inline-block;
}

body.buy-sell-theme .checkout-wizard label, body.buy-sell-theme .checkout-wizard .input-container {
  display: inline-block;
}

body.buy-sell-theme .checkout-wizard label {
  vertical-align: top;
  font-weight: 600;
  color: #555555;
  font-size: 16px;
}

body.buy-sell-theme .checkout-wizard form input[type="text"] {
  background: #fff;
  border: solid #D4D4D4 1px;
  border-radius: 0;
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
}

body.buy-sell-theme .checkout-wizard .radio-select p {
  display: inline-block;
  font-size: 14px;
}

body.buy-sell-theme .checkout-wizard .radio-select span {
  color: #5dbded;
  font-size: 14px;
}

body.buy-sell-theme .checkout-wizard .radio-select img {
  vertical-align: middle;
  margin-left: 20px;
}

body.buy-sell-theme .checkout-wizard form .row-fluid {
  margin-bottom: 20px;
}

body.buy-sell-theme .checkout-wizard ::-webkit-input-placeholder {
  color: #cccccc;
}

body.buy-sell-theme .checkout-wizard :-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

body.buy-sell-theme .checkout-wizard ::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

body.buy-sell-theme .checkout-wizard :-ms-input-placeholder {
  color: #cccccc;
}

body.buy-sell-theme .checkout-wizard table, body.buy-sell-theme .checkout-wizard table.payment-summary {
  width: 100%;
}

body.buy-sell-theme .checkout-wizard .payment-summary tbody td {
  padding: 5px 0;
}

body.buy-sell-theme .checkout-wizard .payment-summary thead th,
body.buy-sell-theme .checkout-wizard .payment-summary thead td {
  padding: 10px 0;
  font-weight: bold;
  color: #333;
}

body.buy-sell-theme .checkout-wizard .payment-summary tfoot td,
body.buy-sell-theme .checkout-wizard .payment-summary tfoot th {
  font-weight: bold;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  font-size: 0.85em;
  color: #5dbded;
}

body.buy-sell-theme .checkout-wizard table.payment-totals {
  width: 55%;
}

body.buy-sell-theme .checkout-wizard .payment-totals td {
  text-align: right;
}

body.buy-sell-theme .checkout-wizard .payment-totals .total {
  font-size: 18px;
  font-weight: 400;
  color: #3a3a3a;
}

body.buy-sell-theme .checkout-wizard .document-requirements {
  margin-top: 30px;
}

body.buy-sell-theme .checkout-wizard .document-requirements .title {
  font-weight: bold;
}

body.buy-sell-theme .checkout-wizard .document-requirements .upload .btn-upload-payment-document {
  color: black;
  background-color: white;
  border: 1px solid #eee;
  width: 30%;
  padding: 10px 20px;
}

body.buy-sell-theme .checkout-wizard .document-requirements .upload span.file-name {
  padding-left: 20px;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs {
  background: #d4effc;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs ul {
  list-style-type: none;
  width: 100%;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs li {
  display: inline-block;
  text-align: center;
  padding: 20px 0;
  width: 33%;
  position: relative;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs li.active {
  background: #5dbded;
  color: #fff;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs li.last {
  float: right;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs li.active.first:after {
  content: "";
  border-top: 34px solid transparent;
  border-bottom: 34px solid transparent;
  border-left: 20px solid #5dbded;
  position: absolute;
  right: -20px;
  top: 0;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs li.active.middle:after {
  content: "";
  border-top: 34px solid transparent;
  border-bottom: 34px solid transparent;
  border-left: 20px solid #5dbded;
  position: absolute;
  right: -20px;
  top: 0;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs li.active.middle:before {
  content: "";
  border-top: 34px solid transparent;
  border-bottom: 34px solid transparent;
  border-left: 20px solid #d4effc;
  position: absolute;
  left: 0;
  top: 0;
}

body.buy-sell-theme .checkout-wizard .breadcrumbs li.active.last:before {
  content: "";
  border-top: 34px solid transparent;
  border-bottom: 34px solid transparent;
  border-left: 20px solid #d4effc;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 792px) {
  body.buy-sell-theme .checkout-wizard .page-wrapper {
    width: 90%;
  }
  body.buy-sell-theme .checkout-wizard .button-row .btn, body.buy-sell-theme .checkout-wizard .button-row form:not(.no_inheritance) input[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard .button-row input[type='submit'], body.buy-sell-theme .checkout-wizard .button-row section.dashboard ul.locations li.location .links a, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard .button-row a {
    margin: 20px 0;
    width: 40%;
    padding: 7px 0;
    float: none;
  }
  body.buy-sell-theme .checkout-wizard .btn.next, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input.next[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input.next[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a.next, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a.next {
    padding: 12px 0px;
    cursor: pointer;
  }
  body.buy-sell-theme .checkout-wizard .checkout-form .container-fluid {
    width: 90%;
  }
  body.buy-sell-theme .checkout-wizard .btn.login, body.buy-sell-theme .checkout-wizard form:not(.no_inheritance) input.login[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .checkout-wizard input.login[type='submit'], body.buy-sell-theme .checkout-wizard section.dashboard ul.locations li.location .links a.login, section.dashboard ul.locations li.location .links body.buy-sell-theme .checkout-wizard a.login {
    width: 100%;
    padding: 7px 0;
  }
}

@media (max-width: 400px) {
  body.buy-sell-theme .checkout-wizard .breadcrumbs li {
    width: 30%;
  }
  body.buy-sell-theme .checkout-wizard .breadcrumbs li.active:before, body.buy-sell-theme .checkout-wizard .breadcrumbs li.active:after {
    border-left: 0 !important;
  }
}

@media (max-width: 1594px) {
  body.buy-sell-theme section .container-fluid {
    width: 95%;
  }
  body.buy-sell-theme .product-social-wrapper {
    text-align: center;
  }
}

@media (max-width: 924px) {
  body.buy-sell-theme .user-badge {
    float: none;
    width: 50%;
    margin: 50px auto;
  }
  body.buy-sell-theme .tab-content-container {
    width: 100%;
    float: none;
  }
}

@media (max-width: 585px) {
  body.buy-sell-theme body.buy-sell-theme .profile .user-badge {
    width: 90%;
    padding: 0;
  }
  body.buy-sell-theme .nav-tabs li a {
    padding: 15px 7px;
    font-size: 12px;
  }
  body.buy-sell-theme .verifications .row-fluid {
    margin: 0;
  }
  body.buy-sell-theme .date {
    float: left;
  }
}

body.buy-sell-theme .search-view {
  /*===== NAV =====*/
  /*===== SORTING =====*/
  /*===== RESULTS =====*/
  /*===== PAGINATION =====*/
  /*===== MEDIA QUERIES=====*/
}

body.buy-sell-theme .search-view .crumbs a {
  font-size: 14px;
  color: #858585;
  font-weight: 300;
}

body.buy-sell-theme .search-view .page-wrapper {
  padding: 60px 0;
  margin: 0 auto;
  margin-top: 60;
}

body.buy-sell-theme .search-view .slider-float-right {
  float: right;
}

body.buy-sell-theme .search-view .search-bar form input, body.buy-sell-theme .search-view .search-bar form input:focus {
  width: 50%;
  padding: 2px 10px;
  border-radius: 0;
  background: #fff;
  font-size: 14px;
  border: none;
  margin: 0;
  height: 36px;
  font-weight: 300;
}

body.buy-sell-theme .search-view .search-icon {
  vertical-align: middle;
  margin-left: -5px;
  margin-top: 1px;
}

body.buy-sell-theme .search-view .btn.search, body.buy-sell-theme .search-view form:not(.no_inheritance) input.search[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .search-view input.search[type='submit'], body.buy-sell-theme .search-view section.dashboard ul.locations li.location .links a.search, section.dashboard ul.locations li.location .links body.buy-sell-theme .search-view a.search {
  width: initial;
  height: 36px;
  padding: initial;
}

body.buy-sell-theme .search-view.search-view-listing .btn.search, body.buy-sell-theme .search-view.search-view-listing form:not(.no_inheritance) input.search[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .search-view.search-view-listing input.search[type='submit'], body.buy-sell-theme .search-view.search-view-listing section.dashboard ul.locations li.location .links a.search, section.dashboard ul.locations li.location .links body.buy-sell-theme .search-view.search-view-listing a.search {
  padding: 0;
  width: 36px;
}

body.buy-sell-theme .search-view .search-bar.mobile form input {
  width: 70%;
  margin-bottom: 20px;
}

body.buy-sell-theme .search-view .search-bar.mobile form {
  display: none;
  width: 100%;
}

body.buy-sell-theme .search-view nav ul {
  margin-bottom: 30px;
}

body.buy-sell-theme .search-view .categories {
  margin-bottom: 40px;
}

body.buy-sell-theme .search-view .nav-heading {
  font-weight: 300;
  color: #000;
}

body.buy-sell-theme .search-view .nav-item a {
  font-size: 14px;
  color: #858585;
  font-weight: 300;
  padding-left: 20px;
}

body.buy-sell-theme .search-view .nav-item a.active {
  color: #5dbded;
  font-weight: 400;
}

body.buy-sell-theme .search-view .categories-select {
  display: block;
}

body.buy-sell-theme .search-view .categories-select img {
  display: none;
}

body.buy-sell-theme .search-view .sort-wrapper {
  float: right;
}

body.buy-sell-theme .search-view .sort-wrapper * {
  font-size: 14px;
  font-weight: 300;
}

body.buy-sell-theme .search-view .sort label {
  margin-left: 30px;
}

body.buy-sell-theme .search-view .customSelect {
  margin: 0;
  border: inherit;
}

body.buy-sell-theme .search-view .customSelect:hover {
  cursor: pointer;
}

body.buy-sell-theme .search-view .customSelect {
  color: #5dbded;
  background: url("/assets/themes/buy_sell/arrow-down.png") right no-repeat;
}

body.buy-sell-theme .search-view .sort-price .customSelect {
  width: 120px;
}

body.buy-sell-theme .search-view .search-breadcrumbs {
  float: left;
}

body.buy-sell-theme .search-view .search-results .row-fluid {
  margin-bottom: 40px;
}

body.buy-sell-theme .search-view .item-img {
  overflow: hidden;
  max-height: 186px;
}

body.buy-sell-theme .search-view .item img {
  width: 100%;
  min-height: 186px;
}

body.buy-sell-theme .search-view .item .details {
  background: #fff;
  padding: 5px 15px;
}

body.buy-sell-theme .search-view .item .details p {
  font-size: 14px;
}

body.buy-sell-theme .search-view .details .name {
  float: left;
  color: #858585;
  font-weight: 300;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body.buy-sell-theme .search-view .details .price {
  float: right;
  color: #5dc988;
  font-weight: 300;
  width: 25%;
  text-align: right;
}

body.buy-sell-theme .search-view .pagination {
  text-align: center;
}

body.buy-sell-theme .search-view .pagination ul {
  box-shadow: none;
}

body.buy-sell-theme .search-view .pagination ul li a {
  background: transparent;
  border: none;
  float: inherit;
}

body.buy-sell-theme .search-view .pagination img {
  float: inherit;
}

body.buy-sell-theme .search-view .pagination a {
  color: #5dbded;
}

body.buy-sell-theme .search-view .pagination .page-link {
  padding-top: 10px;
  vertical-align: super;
}

body.buy-sell-theme .search-view .pagination .page-link.active {
  color: #000;
}

@media (max-width: 767px) {
  body.buy-sell-theme .search-view .page-wrapper {
    width: 80%;
  }
  body.buy-sell-theme .search-view .search-results .row-fluid {
    margin: 0;
  }
  body.buy-sell-theme .search-view .search-results .span4 {
    margin-bottom: 30px;
  }
  body.buy-sell-theme .search-view .search-breadcrumbs, body.buy-sell-theme .search-view .sort-wrapper {
    float: none;
  }
  body.buy-sell-theme .search-view .sort-wrapper {
    margin-bottom: 15px;
  }
  body.buy-sell-theme .search-view .sort {
    display: block;
  }
  body.buy-sell-theme .search-view .sort label {
    margin: 0;
  }
  body.buy-sell-theme .search-view .search-bar.header {
    display: none;
  }
  body.buy-sell-theme .search-view .search-bar.mobile {
    display: block;
  }
  body.buy-sell-theme .search-view .search-bar.mobile form {
    display: inline-block;
  }
  body.buy-sell-theme .search-view .categories {
    margin-bottom: 30px;
    display: inline-block;
  }
  body.buy-sell-theme .search-view .categories-select img {
    display: inline-block;
  }
}

body.buy-sell-theme .checkout .button-row .next {
  width: 100%;
}

body.buy-sell-theme .checkout .button-row .next button {
  width: 100% !important;
}

body.buy-sell-theme .checkout .button-row .go-back a, body.buy-sell-theme .checkout .button-row .go-back span {
  width: 100%;
}

body.buy-sell-theme .edit-product-inline h2, body.buy-sell-theme .edit-product-inline form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) body.buy-sell-theme .edit-product-inline fieldset > legend, body.buy-sell-theme .edit-product-inline
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme .edit-product-inline fieldset > .legend, body.buy-sell-theme .edit-product-inline
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) body.buy-sell-theme .edit-product-inline .fieldset > legend, body.buy-sell-theme .edit-product-inline
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) body.buy-sell-theme .edit-product-inline .fieldset > .legend {
  margin-top: 30px;
  margin-bottom: 20px;
}

body.buy-sell-theme .edit-product-inline .all-inline {
  overflow: hidden;
}

body.buy-sell-theme .edit-product-inline .form-horizontal .control-group {
  clear: none;
  float: left;
}

body.buy-sell-theme .edit-product-inline .form-horizontal .control-group .control-label {
  width: auto;
}

body.buy-sell-theme .edit-product-inline .form-horizontal .control-group .controls {
  float: left;
  margin-left: 0px;
}

body.buy-sell-theme .edit-product-inline .form-horizontal .control-group .controls input {
  margin-left: 0px;
}

body.buy-sell-theme .edit-product-inline .btn.medium, body.buy-sell-theme .edit-product-inline form:not(.no_inheritance) input.medium[type='submit'], form:not(.no_inheritance) body.buy-sell-theme .edit-product-inline input.medium[type='submit'], body.buy-sell-theme .edit-product-inline section.dashboard ul.locations li.location .links a.medium, section.dashboard ul.locations li.location .links body.buy-sell-theme .edit-product-inline a.medium {
  height: 43px;
  padding-top: 0px;
  margin-top: 0px;
}

body.buy-sell-theme .edit-product-inline .currency {
  font-size: 1.5em;
  float: left;
  margin-top: 11px;
}

body.buy-sell-theme .modal-content .btn[type="submit"], body.buy-sell-theme .modal-content form:not(.no_inheritance) input[type="submit"][type='submit'], form:not(.no_inheritance) body.buy-sell-theme .modal-content input[type="submit"][type='submit'], body.buy-sell-theme .modal-content section.dashboard ul.locations li.location .links a[type="submit"], section.dashboard ul.locations li.location .links body.buy-sell-theme .modal-content a[type="submit"] {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 50px;
  height: auto;
}

body.buy-sell-theme .user-profile.container-fluid {
  margin-bottom: 40px;
}

body.buy-sell-theme .user-profile.container-fluid header {
  background: none;
  height: auto;
  position: relative;
}

body.buy-sell-theme .user-profile.container-fluid .user-profile__header {
  background: #dfdfdf;
  padding: 15px;
}

body.buy-sell-theme .user-profile.container-fluid .tab-content article {
  background: #efefef;
}

body.user-blog {
  background-color: #ffffff;
}

body.user-blog .container-fluid.header-container {
  margin-top: 60px;
}

body.user-blog .container-fluid.header-container span.blog-header {
  font-size: 26px;
  color: #ffffff;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #ffffff;
}

.user-blog .hero-image {
  margin-top: 20px;
}

.user-blog #infinite-scrolling {
  text-align: center;
}

.user-blog .dnm-page {
  padding-top: 60px;
}

.user-blog .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

.user-blog .main-menu .nav-link.btn, .user-blog .main-menu form:not(.no_inheritance) input.nav-link[type='submit'], form:not(.no_inheritance) .user-blog .main-menu input.nav-link[type='submit'], .user-blog .main-menu section.dashboard ul.locations li.location .links a.nav-link, section.dashboard ul.locations li.location .links .user-blog .main-menu a.nav-link {
  display: block;
}

.user-blog .header {
  position: relative;
  display: block;
  overflow: hidden;
  margin: auto;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  height: 262px;
  background-color: #333332;
}

.user-blog .header .container {
  margin: 0;
  padding: 0;
}

.user-blog .header .heading {
  clear: both;
  height: 262px;
  line-height: 262px;
  text-align: center;
}

.user-blog .header .heading a {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
}

.user-blog .header .heading a:hover {
  text-decoration: none;
}

.user-blog .header .header-image {
  margin: auto;
  width: 100%;
  text-align: center;
  max-width: 850px;
}

.user-blog .header .logo {
  position: fixed;
  z-index: 10;
}

.user-blog .header .header-text-and-logo {
  position: absolute;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.user-blog .header .header-text-and-logo .v-wrap {
  height: 100%;
  white-space: nowrap;
  text-align: center;
}

.user-blog .header .header-text-and-logo .v-wrap:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  margin-right: -0.25em;
  height: 100%;
}

.user-blog .header .header-text-and-logo .v-box {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
}

.user-blog .header .header-text-and-logo span.header-text {
  font-size: 24px;
  color: #ffffff;
}

.user-blog .header .header-text-and-logo span.header-text.with-border {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #ffffff;
}

.user-blog .header .header-text-and-logo p.header-motto {
  margin-bottom: 0;
  padding-top: 5px;
  color: #ffffff;
}

.user-blog .header .header-text-and-logo img {
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .user-blog .header .header-text-and-logo img.blog-logo {
    display: none;
  }
}

.user-blog .header .header-text-and-logo img.blog-icon {
  display: none;
}

@media (max-width: 767px) {
  .user-blog .header .header-text-and-logo img.blog-icon {
    display: inline;
  }
}

@media (max-width: 480px) {
  .user-blog .header {
    height: auto;
  }
  .user-blog .header .header-text-and-logo img {
    vertical-align: text-bottom;
  }
}

.user-blog .blog-posts {
  margin-bottom: 20px;
}

.user-blog .blog-post {
  margin-top: 20px;
  padding-bottom: 5px;
}

.user-blog .blog-post h2, .user-blog .blog-post form:not(.no_inheritance) fieldset > legend, form:not(.no_inheritance) .user-blog .blog-post fieldset > legend, .user-blog .blog-post
form:not(.no_inheritance) fieldset > .legend,
form:not(.no_inheritance) .user-blog .blog-post fieldset > .legend, .user-blog .blog-post
form:not(.no_inheritance) .fieldset > legend,
form:not(.no_inheritance) .user-blog .blog-post .fieldset > legend, .user-blog .blog-post
form:not(.no_inheritance) .fieldset > .legend,
form:not(.no_inheritance) .user-blog .blog-post .fieldset > .legend {
  font-size: 28px;
}

.user-blog .blog-post h2 a, .user-blog .blog-post form:not(.no_inheritance) fieldset > legend a, form:not(.no_inheritance) .user-blog .blog-post fieldset > legend a, .user-blog .blog-post
form:not(.no_inheritance) fieldset > .legend a,
form:not(.no_inheritance) .user-blog .blog-post fieldset > .legend a, .user-blog .blog-post
form:not(.no_inheritance) .fieldset > legend a,
form:not(.no_inheritance) .user-blog .blog-post .fieldset > legend a, .user-blog .blog-post
form:not(.no_inheritance) .fieldset > .legend a,
form:not(.no_inheritance) .user-blog .blog-post .fieldset > .legend a {
  font-weight: bold;
  color: #333332;
}

.user-blog .blog-post p {
  font-size: 14px;
  color: #666665;
  line-height: 28px;
}

.user-blog .blog-post b {
  font-weight: bold !important;
}

.user-blog .blog-post i {
  font-style: italic !important;
}

.user-blog .blog-post ol {
  list-style-type: decimal !important;
  padding-left: 20px;
}

.user-blog .blog-post ul {
  list-style-type: disc !important;
  padding-left: 20px;
}

.user-blog .blog-post .date {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #b3b3b1;
  text-align: right;
  line-height: 20px;
}

@media (max-width: 767px) {
  .user-blog .blog-post .date {
    text-align: left;
  }
}

.user-blog .blog-post .avatar {
  min-height: 76px;
}

.user-blog .blog-post .avatar .avatar-container {
  overflow: hidden;
  margin: 13px 5px 20px 0;
  height: 56px;
  width: 56px;
  background-repeat: no-repeat;
  background-size: 56px 56px;
  background-position: center center;
  border-radius: 50%;
}

.user-blog .blog-container {
  width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.user-blog .blog-container .blog-nav {
  padding-bottom: 45px;
  width: 100%;
  float: left;
}

.user-blog .blog-container .blog-nav .rss-feed-button {
  float: right;
  color: #1a7fbb;
  padding-top: 12px;
}

.user-blog .blog-container .blog-post-detail h1 {
  margin: 40px 0 10px 0;
  font-weight: bold;
  font-size: 30px;
}

.user-blog .blog-container .blog-post-detail b {
  font-weight: bold !important;
}

.user-blog .blog-container .blog-post-detail i {
  font-style: italic !important;
}

.user-blog .blog-container .blog-post-detail ol {
  list-style-type: decimal !important;
  padding-left: 20px;
}

.user-blog .blog-container .blog-post-detail ul {
  list-style-type: disc !important;
  padding-left: 20px;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper {
  position: relative;
  display: inline-block;
  padding: 5px 0px 10px 100px;
  background-color: #F9F9F9;
  margin-bottom: 20px;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper.without-facebook {
  padding-left: 10px;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .facebook {
  position: absolute;
  left: 10px;
  top: 2px;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .social-buttons {
  height: 25px;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .social-buttons:after {
  content: "";
  display: table;
  clear: both;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .social-buttons > div {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  height: 22px;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .social-buttons > div:last-child {
  margin-right: 0px;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .social-buttons .twitter iframe {
  width: 81px !important;
}

.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .social-buttons .google-plus iframe,
.user-blog .blog-container .blog-post-detail .social-buttons-wrapper .social-buttons .google-plus > div {
  width: 66px !important;
}

.user-blog .blog-container .blog-post-detail .content {
  margin-bottom: 20px;
  font-size: 17px;
  color: #333332;
}

.user-blog .blog-container .blog-post-detail .content p {
  margin-bottom: 20px;
  line-height: 28px;
}

.user-blog .blog-container .blog-post-detail .info {
  margin: 20px 0;
}

.user-blog .blog-container .blog-post-detail .info .date {
  padding-left: 0;
  color: #b3b3b1;
  font-size: 16px;
  font-style: italic;
  line-height: 32px;
}

.user-blog .blog-container .blog-post-detail .info .social-buttons-wrapper {
  float: right;
  margin-bottom: 0px;
}

.user-blog .blog-container .blog-post-detail .info .social-buttons-wrapper .social {
  padding: 0;
}

@media (max-width: 767px) {
  .user-blog .blog-container .blog-post-detail .info .social-buttons-wrapper .social {
    padding-top: 10px;
  }
}

.user-blog .blog-container .blog-post-detail .info .social-buttons-wrapper .social a {
  display: block;
  float: right;
  margin-left: 5px;
  width: 32px;
  height: 32px;
  text-align: center;
  background: #b3b3b1;
  color: #ffffff;
  font-size: 18px;
  line-height: 36px;
}

.user-blog .blog-container .blog-post-detail .info .social-buttons-wrapper .social a:hover {
  text-decoration: none;
}

.user-blog .blog-container .blog-post-detail .info .social-buttons-wrapper .social a.ico-twitter {
  padding-left: 3px;
  font-size: 16px;
}

@media (max-width: 767px) {
  .user-blog .blog-container .blog-post-detail .info .social-buttons-wrapper .social a {
    float: left;
    margin-left: 0;
    margin-right: 5px;
    margin-top: 10px;
  }
}

.user-blog .blog-container .blog-post-detail .author {
  margin: 20px 0 40px 0;
  padding: 20px 0;
  border-top: 1px solid #dededc;
  border-bottom: 1px solid #dededc;
}

.user-blog .blog-container .blog-post-detail .author .avatar {
  padding-left: 0;
}

.user-blog .blog-container .blog-post-detail .author .avatar .avatar-container {
  overflow: hidden;
  margin-right: 5px;
  height: 112px;
  width: 112px;
  background-repeat: no-repeat;
  background-size: 112px 112px;
  background-position: center center;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.user-blog .blog-container .blog-post-detail .author .biography h3 {
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
}

.user-blog .blog-container .blog-post-detail .links {
  margin-bottom: 20px;
}

.user-blog .blog-container .blog-post-detail .links .previous-blog-post, .user-blog .blog-container .blog-post-detail .links .next-blog-post {
  display: block;
  position: static;
  padding: 5px 20px;
  padding: 0;
  height: 35px;
  background: #1e2222;
  color: #ffffff;
  font-weight: bold;
  line-height: 35px;
  font-size: 12px;
  text-align: center;
}

@media (min-width: 992px) {
  .user-blog .blog-container .blog-post-detail .links .previous-blog-post, .user-blog .blog-container .blog-post-detail .links .next-blog-post {
    position: fixed;
    top: 450px;
    padding: 8px 5px;
    width: 55px;
    height: 55px;
    line-height: 20px;
    text-align: left;
  }
}

.user-blog .blog-container .blog-post-detail .links .previous-blog-post:hover, .user-blog .blog-container .blog-post-detail .links .next-blog-post:hover {
  text-decoration: none;
}

@media (min-width: 767px) {
  .user-blog .blog-container .blog-post-detail .links .previous-blog-post {
    left: 0;
  }
}

@media (min-width: 767px) {
  .user-blog .blog-container .blog-post-detail .links .next-blog-post {
    right: 0;
  }
}

.user-blog .blog-container .blog-post-detail #fbcomments, .user-blog .blog-container .blog-post-detail .fb-comments, .user-blog .blog-container .blog-post-detail .fb-comments iframe[style], .user-blog .blog-container .blog-post-detail .fb-comments span {
  width: 100% !important;
}

.user-blog .blog-container .spinner {
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 850px) {
  .user-blog .blog-container {
    width: 90%;
  }
}

.user-blog .blog-posts {
  margin-bottom: 20px;
}

.user-blog .blog-posts p {
  line-height: 20px;
  margin-bottom: 0px;
}

.user-blog .blog-posts .blog-post .avatar .span1 {
  width: 60px !important;
  float: left;
}

.user-blog .blog-posts .blog-post .avatar .span11 {
  float: left;
}

.user-blog .blog-posts .blog-post .avatar .span11.with-avatar {
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-blog .blog-posts .blog-post .avatar .author-name {
  display: inline-block;
  padding-top: 17px;
  color: #b3b3b1;
}

.user-blog .blog-posts .blog-post .avatar .avatar-container {
  margin-top: 0;
}

.pagination {
  text-align: center;
  display: inherit;
}

.pagination ul {
  box-shadow: none;
  list-style: none;
}

.pagination li {
  display: inline;
}

.pagination ul li a {
  background: transparent;
  border: none;
  font-size: 18px;
  padding: 4px 12px;
}

.pagination img {
  float: inherit;
}

.pagination a {
  color: #5dbded;
}

.pagination .page-link {
  padding-top: 10px;
}

.pagination .page-link.active {
  color: #000;
}

/*! jQuery UI - v1.11.1 - 2014-09-19
* http://jqueryui.com
* Includes: core.css, datepicker.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Trebuchet%20MS%2CTahoma%2CVerdana%2CArial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=4px&bgColorHeader=f6a828&bgTextureHeader=gloss_wave&bgImgOpacityHeader=35&borderColorHeader=e78f08&fcHeader=ffffff&iconColorHeader=ffffff&bgColorContent=eeeeee&bgTextureContent=highlight_soft&bgImgOpacityContent=100&borderColorContent=dddddd&fcContent=333333&iconColorContent=222222&bgColorDefault=f6f6f6&bgTextureDefault=glass&bgImgOpacityDefault=100&borderColorDefault=cccccc&fcDefault=1c94c4&iconColorDefault=ef8c08&bgColorHover=fdf5ce&bgTextureHover=glass&bgImgOpacityHover=100&borderColorHover=fbcb09&fcHover=c77405&iconColorHover=ef8c08&bgColorActive=ffffff&bgTextureActive=glass&bgImgOpacityActive=65&borderColorActive=fbd850&fcActive=eb8f00&iconColorActive=ef8c08&bgColorHighlight=ffe45c&bgTextureHighlight=highlight_soft&bgImgOpacityHighlight=75&borderColorHighlight=fed22f&fcHighlight=363636&iconColorHighlight=228ef1&bgColorError=b81900&bgTextureError=diagonals_thick&bgImgOpacityError=18&borderColorError=cd0a0a&fcError=ffffff&iconColorError=ffd27a&bgColorOverlay=666666&bgTextureOverlay=diagonals_thick&bgImgOpacityOverlay=20&opacityOverlay=50&bgColorShadow=000000&bgTextureShadow=flat&bgImgOpacityShadow=10&opacityShadow=20&thicknessShadow=5px&offsetTopShadow=-5px&offsetLeftShadow=-5px&cornerRadiusShadow=5px
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
  /* support: IE8 */
}

.ui-front {
  z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 45%;
}

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em;
}

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
  font-size: 1.1em;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
  font-size: 1em;
}

.ui-widget-content {
  border: 1px solid #dddddd;
  background-color: #eee;
  color: #333333;
}

.ui-widget-content a {
  color: #333333;
}

.ui-widget-header {
  border: 1px solid #e78f08;
  background: #f6a828 url("images/ui-bg_gloss-wave_35_f6a828_500x100.png") 50% 50% repeat-x;
  color: #ffffff;
  font-weight: bold;
}

.ui-widget-header a {
  color: #ffffff;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #cccccc;
  background: #f6f6f6;
  font-weight: bold;
  color: #1c94c4;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #1c94c4;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #fbcb09;
  background: #fdf5ce;
  font-weight: bold;
  color: #c77405;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
  color: #c77405;
  text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #fbd850;
  background: #ffffff url("images/ui-bg_glass_65_ffffff_1x400.png") 50% 50% repeat-x;
  font-weight: bold;
  color: #eb8f00;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #eb8f00;
  text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #fed22f;
  background: #ffe45c url("images/ui-bg_highlight-soft_75_ffe45c_1x100.png") 50% top repeat-x;
  color: #363636;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #363636;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #cd0a0a;
  background: #b81900 url("images/ui-bg_diagonals-thick_18_b81900_40x40.png") 50% 50% repeat;
  color: #ffffff;
}

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #ffffff;
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #ffffff;
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  /* support: IE8 */
  font-weight: normal;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  /* support: IE8 */
  background-image: none;
}

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35);
  /* support: IE8 - See #6059 */
}

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
}

.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("images/ui-icons_222222_256x240.png");
}

.ui-widget-header .ui-icon {
  background-image: url("images/ui-icons_ffffff_256x240.png");
}

.ui-state-default .ui-icon {
  background-image: url("images/ui-icons_ef8c08_256x240.png");
}

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  background-image: url("images/ui-icons_ef8c08_256x240.png");
}

.ui-state-active .ui-icon {
  background-image: url("images/ui-icons_ef8c08_256x240.png");
}

.ui-state-highlight .ui-icon {
  background-image: url("images/ui-icons_228ef1_256x240.png");
}

.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("images/ui-icons_ffd27a_256x240.png");
}

/* positioning */
.ui-icon-blank {
  background-position: 16px 16px;
}

.ui-icon-carat-1-n {
  background-position: 0 0;
}

.ui-icon-carat-1-ne {
  background-position: -16px 0;
}

.ui-icon-carat-1-e {
  background-position: -32px 0;
}

.ui-icon-carat-1-se {
  background-position: -48px 0;
}

.ui-icon-carat-1-s {
  background-position: -64px 0;
}

.ui-icon-carat-1-sw {
  background-position: -80px 0;
}

.ui-icon-carat-1-w {
  background-position: -96px 0;
}

.ui-icon-carat-1-nw {
  background-position: -112px 0;
}

.ui-icon-carat-2-n-s {
  background-position: -128px 0;
}

.ui-icon-carat-2-e-w {
  background-position: -144px 0;
}

.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
  background-position: -64px -32px;
}

.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
  background-position: 0 -48px;
}

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}

.ui-icon-arrow-4 {
  background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}

.ui-icon-extlink {
  background-position: -32px -80px;
}

.ui-icon-newwin {
  background-position: -48px -80px;
}

.ui-icon-refresh {
  background-position: -64px -80px;
}

.ui-icon-shuffle {
  background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}

.ui-icon-folder-open {
  background-position: -16px -96px;
}

.ui-icon-document {
  background-position: -32px -96px;
}

.ui-icon-document-b {
  background-position: -48px -96px;
}

.ui-icon-note {
  background-position: -64px -96px;
}

.ui-icon-mail-closed {
  background-position: -80px -96px;
}

.ui-icon-mail-open {
  background-position: -96px -96px;
}

.ui-icon-suitcase {
  background-position: -112px -96px;
}

.ui-icon-comment {
  background-position: -128px -96px;
}

.ui-icon-person {
  background-position: -144px -96px;
}

.ui-icon-print {
  background-position: -160px -96px;
}

.ui-icon-trash {
  background-position: -176px -96px;
}

.ui-icon-locked {
  background-position: -192px -96px;
}

.ui-icon-unlocked {
  background-position: -208px -96px;
}

.ui-icon-bookmark {
  background-position: -224px -96px;
}

.ui-icon-tag {
  background-position: -240px -96px;
}

.ui-icon-home {
  background-position: 0 -112px;
}

.ui-icon-flag {
  background-position: -16px -112px;
}

.ui-icon-calendar {
  background-position: -32px -112px;
}

.ui-icon-cart {
  background-position: -48px -112px;
}

.ui-icon-pencil {
  background-position: -64px -112px;
}

.ui-icon-clock {
  background-position: -80px -112px;
}

.ui-icon-disk {
  background-position: -96px -112px;
}

.ui-icon-calculator {
  background-position: -112px -112px;
}

.ui-icon-zoomin {
  background-position: -128px -112px;
}

.ui-icon-zoomout {
  background-position: -144px -112px;
}

.ui-icon-search {
  background-position: -160px -112px;
}

.ui-icon-wrench {
  background-position: -176px -112px;
}

.ui-icon-gear {
  background-position: -192px -112px;
}

.ui-icon-heart {
  background-position: -208px -112px;
}

.ui-icon-star {
  background-position: -224px -112px;
}

.ui-icon-link {
  background-position: -240px -112px;
}

.ui-icon-cancel {
  background-position: 0 -128px;
}

.ui-icon-plus {
  background-position: -16px -128px;
}

.ui-icon-plusthick {
  background-position: -32px -128px;
}

.ui-icon-minus {
  background-position: -48px -128px;
}

.ui-icon-minusthick {
  background-position: -64px -128px;
}

.ui-icon-close {
  background-position: -80px -128px;
}

.ui-icon-closethick {
  background-position: -96px -128px;
}

.ui-icon-key {
  background-position: -112px -128px;
}

.ui-icon-lightbulb {
  background-position: -128px -128px;
}

.ui-icon-scissors {
  background-position: -144px -128px;
}

.ui-icon-clipboard {
  background-position: -160px -128px;
}

.ui-icon-copy {
  background-position: -176px -128px;
}

.ui-icon-contact {
  background-position: -192px -128px;
}

.ui-icon-image {
  background-position: -208px -128px;
}

.ui-icon-video {
  background-position: -224px -128px;
}

.ui-icon-script {
  background-position: -240px -128px;
}

.ui-icon-alert {
  background-position: 0 -144px;
}

.ui-icon-info {
  background-position: -16px -144px;
}

.ui-icon-notice {
  background-position: -32px -144px;
}

.ui-icon-help {
  background-position: -48px -144px;
}

.ui-icon-check {
  background-position: -64px -144px;
}

.ui-icon-bullet {
  background-position: -80px -144px;
}

.ui-icon-radio-on {
  background-position: -96px -144px;
}

.ui-icon-radio-off {
  background-position: -112px -144px;
}

.ui-icon-pin-w {
  background-position: -128px -144px;
}

.ui-icon-pin-s {
  background-position: -144px -144px;
}

.ui-icon-play {
  background-position: 0 -160px;
}

.ui-icon-pause {
  background-position: -16px -160px;
}

.ui-icon-seek-next {
  background-position: -32px -160px;
}

.ui-icon-seek-prev {
  background-position: -48px -160px;
}

.ui-icon-seek-end {
  background-position: -64px -160px;
}

.ui-icon-seek-start {
  background-position: -80px -160px;
}

/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px;
}

.ui-icon-stop {
  background-position: -96px -160px;
}

.ui-icon-eject {
  background-position: -112px -160px;
}

.ui-icon-volume-off {
  background-position: -128px -160px;
}

.ui-icon-volume-on {
  background-position: -144px -160px;
}

.ui-icon-power {
  background-position: 0 -176px;
}

.ui-icon-signal-diag {
  background-position: -16px -176px;
}

.ui-icon-signal {
  background-position: -32px -176px;
}

.ui-icon-battery-0 {
  background-position: -48px -176px;
}

.ui-icon-battery-1 {
  background-position: -64px -176px;
}

.ui-icon-battery-2 {
  background-position: -80px -176px;
}

.ui-icon-battery-3 {
  background-position: -96px -176px;
}

.ui-icon-circle-plus {
  background-position: 0 -192px;
}

.ui-icon-circle-minus {
  background-position: -16px -192px;
}

.ui-icon-circle-close {
  background-position: -32px -192px;
}

.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}

.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}

.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}

.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}

.ui-icon-circle-check {
  background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}

/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 4px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 4px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 4px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 4px;
}

/* Overlays */
.ui-widget-overlay {
  background: #666666 url("images/ui-bg_diagonals-thick_20_666666_40x40.png") 50% 50% repeat;
  opacity: .5;
  filter: Alpha(Opacity=50);
  /* support: IE8 */
}

.ui-widget-shadow {
  margin: -5px 0 0 -5px;
  padding: 5px;
  background: #000000 url("images/ui-bg_flat_10_000000_40x100.png") 50% 50% repeat-x;
  opacity: .2;
  filter: Alpha(Opacity=20);
  /* support: IE8 */
  border-radius: 5px;
}

.nm-new-credit-card-form, .payment-source-form {
  margin: 30px 0;
}

.nm-new-credit-card-form .control-group .control-label, .payment-source-form .control-group .control-label {
  display: block;
  float: none;
  width: 100%;
  text-align: left;
  margin-top: 0;
}

.nm-new-credit-card-form .control-group .controls, .payment-source-form .control-group .controls {
  position: relative;
}

.form-horizontal .nm-new-credit-card-form .control-group .controls, .form-horizontal .payment-source-form .control-group .controls {
  margin-left: 0;
}

.nm-new-credit-card-form .control-group .controls select.hasCustomSelect, .payment-source-form .control-group .controls select.hasCustomSelect {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.nm-credit-card-fields .control-group.nm-credit-card-option-select .controls, .nm-credit-card-fields .control-group.payment-source-option-select .controls, .nm-payment-subscription-fields .control-group.nm-credit-card-option-select .controls, .nm-payment-subscription-fields .control-group.payment-source-option-select .controls {
  margin-left: 0;
}

.nm-credit-card-fields .control-group.nm-credit-card-option-select .controls span.radio, .nm-credit-card-fields .control-group.payment-source-option-select .controls span.radio, .nm-payment-subscription-fields .control-group.nm-credit-card-option-select .controls span.radio, .nm-payment-subscription-fields .control-group.payment-source-option-select .controls span.radio {
  display: block;
}

.nm-new-credit-card-form-header:after {
  display: table;
  content: "";
  clear: both;
}

.nm-new-credit-card-form-header h3:before {
  font-family: FontAwesome;
  content: "\f023";
  margin-right: 10px;
}

.nm-new-credit-card-form-header img {
  display: block;
  margin: 20px auto;
}

@media (min-width: 750px) {
  .nm-new-credit-card-form-header {
    margin: 0 0 20px;
  }
  .nm-new-credit-card-form-header h3 {
    float: left;
  }
  .nm-new-credit-card-form-header img {
    float: right;
    margin: 0;
  }
}

@media (min-width: 600px) {
  .nm-new-credit-card-form-name-container {
    display: flex;
    justify-content: space-between;
  }
  .nm-new-credit-card-form-name-container .form-group, .nm-new-credit-card-form-name-container .control-group {
    width: 49%;
  }
}

@media (min-width: 600px) {
  .nm-new-credit-card-form-exp-date-container {
    display: flex;
    justify-content: space-between;
  }
  .nm-new-credit-card-form-exp-date-container .form-group, .nm-new-credit-card-form-exp-date-container .control-group {
    width: 32%;
  }
  .nm-new-credit-card-form-exp-date-container .form-group label span, .nm-new-credit-card-form-exp-date-container .control-group label span {
    position: absolute;
    left: -999em;
  }
}

.accordion-body.collapse {
  border-bottom: 1px solid #E2E2E2;
}

#accordion2 fieldset {
  border: 0px;
}

.payment-method + .payment-method {
  margin-top: 20px;
}

.payment-method-header {
  font-size: 20px;
  font-weight: normal;
  color: #5dbded;
  cursor: pointer;
  padding-left: 20px;
  position: relative;
}

.payment-method-header::before {
  position: absolute;
  content: "";
  font-family: FontAwesome;
  font-size: 12px;
  top: 50%;
  left: 0;
  transform: translateY(calc(-50%));
}

.payment-method-body {
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  margin: 10px 0 0 20px;
}
